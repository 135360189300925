import { actions } from "../actions/index";

const { FETCH_USER } = actions;

const initialState = {
  users: [],
};

export function allUsers(state = initialState, action) {
  switch (action.type) {
    case FETCH_USER:
      return {
        ...state,
        users: action.payload.users,
      };
    default:
      return state;
  }
}
