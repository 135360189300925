import React, { useEffect } from "react";
import styled from "styled-components";
import { useNavigate } from "react-router-dom";
import { useParams } from "react-router-dom";
import { activateAccount } from "../../../lib/state/actions";
import { useDispatch } from "react-redux";
import contente from "./image/contente.jpg";

const ConfirmRegistration = () => {
  const navigate = useNavigate();
  const { token } = useParams();

  const dispatch = useDispatch();

  useEffect(() => {
    if (token) {
      const body = JSON.stringify({
        token: token,
      });
      dispatch(activateAccount(body));
    }
  }, [dispatch, token]);

  const goToConnectPage = () => navigate("/");
  return (
    <Wrapper>
      <Img src={contente} alt="fille qui est triste" />
      <P marginTop={25} className="montserratSemiBold">
        Merci, le compte est maintenant activé !
      </P>
      <Button onClick={() => goToConnectPage()}> se connecter</Button>
    </Wrapper>
  );
};

const Wrapper = styled.div`
  width: 100%;
  min-height: calc(100dvh - 120px);
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background-color: white;
`;

const P = styled.p`
  text-align: center;
  font-size: 35px;
  margin-top: ${(props) =>
    props.marginTop ? `${props.marginTop}px` : "unset"};
  @media (max-width: 750px) {
    width: 300px;
  }
`;

const Img = styled.img`
  width: 480px;
  @media (max-width: 500px) {
    width: 300px;
  }
`;

const Button = styled.div`
  background: ${(props) => props.theme.hentai.lightColor};
  color: white;
  text-transform: uppercase;
  padding: 1em;
  border-radius: 10px;
  font-weight: bold;
  margin-top: 2em;
  cursor: pointer;
  transition: all 0.2s ease-in-out;
  &:hover {
    background: ${(props) => props.theme.hentai.darkColor};
    color: white;
  }
`;

export default ConfirmRegistration;
