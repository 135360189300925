import React from "react";

const Like = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="55.475"
      height="55.475"
      viewBox="0 0 55.475 55.475"
    >
      <g id="Groupe_774" dataname="Groupe 774" transform="translate(0 0)">
        <g id="Layer_2" dataname="Layer 2" transform="translate(0 0)">
          <g
            id="_01.facebook"
            dataname="01.facebook"
            transform="translate(0 0)"
          >
            <path
              className="color"
              id="background"
              d="M45.546,54.523a167.86,167.86,0,0,1-35.615,0A10.108,10.108,0,0,1,.942,45.546a167.862,167.862,0,0,1,0-35.616A10.108,10.108,0,0,1,9.918.942a167.86,167.86,0,0,1,35.615,0,10.108,10.108,0,0,1,8.989,8.976,167.863,167.863,0,0,1,0,35.616,10.108,10.108,0,0,1-8.976,8.989Z"
              transform="translate(0.005 0.005)"
              fill="#c00"
            />
          </g>
        </g>
        <path
          className="heart"
          id="Tracé_318"
          dataname="Tracé 318"
          d="M43.547,19.668a8.376,8.376,0,0,0-12.261,0l-.732.762-.732-.762a8.376,8.376,0,0,0-12.261,0,9.237,9.237,0,0,0,0,12.77L29.914,45.3a.86.86,0,0,0,1.281,0L43.547,32.438a9.237,9.237,0,0,0,0-12.77Z"
          transform="translate(-2.436 -3.293)"
          fill="#fff"
        />
      </g>
    </svg>
  );
};

export default Like;
