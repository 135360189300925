import React from "react";
import styled from "styled-components";

const Radio = ({ legend, name, currentValue, onchange }) => {
  return (
    <InputBlock>
      <Wrapper>
        <Legend>{legend} :</Legend>
        <Container>
          <Label htmlFor="yes">oui</Label>
          <Input
            type="radio"
            id="yes"
            name={name}
            value="true"
            checked={currentValue === true}
            onChange={(e) => onchange(e)}
          />
        </Container>
        <Container>
          <Label htmlFor="no">non</Label>
          <Input
            type="radio"
            id="no"
            name={name}
            value="false"
            checked={currentValue === false}
            onChange={(e) => onchange(e)}
          />
        </Container>
      </Wrapper>
    </InputBlock>
  );
};

const InputBlock = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 1em 0em;
  border-bottom: solid 1px lightGrey;
`;

const Wrapper = styled.div`
  width: 700px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  align-self: flex-start;
  padding: 1em 0em;
`;

const Container = styled.div`
  display: flex;
  align-items: center;
`;

const Legend = styled.legend`
  font-weight: bold;
  font-size: 12px;
`;

const Label = styled.label`
  font-weight: bold;
  font-size: 10px;
  text-transform: uppercase;
`;

const Input = styled.input`
  accent-color: ${(props) =>
    props.initialValue === props.state ? "blue" : "red"};
`;

export default Radio;
