import React from "react";
import styled from "styled-components";

const WrapperContainer = (props) => {
  return <Container center={props.center}>{props.children}</Container>;
};

const Container = styled.div`
  position: ${(props) => (props.center ? "absolute" : "static")};
  top: ${(props) => (props.center ? "50%" : "unset")};
  left: ${(props) => (props.center ? "50%" : "unset")};
  transform: ${(props) => (props.center ? "translate(-50%, -50%)" : "unset")};
  margin: 0 auto;
  box-sizing: border-box;
  background-color: white;
  width: 100%;
  max-width: 1200px;
  margin-top: ${(props) => (props.center ? "unset" : "60px")};
  padding: 30px 30px;
  border-radius: 5px;
  box-shadow: 0px 3px 5px rgba(0, 0, 0, 0.15);
  display: flex;
  @media (max-width: 1200px) {
    border-radius: unset;
  }
`;

export default WrapperContainer;
