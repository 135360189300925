import React from "react";
import styled from "styled-components";
import Cross from "./picto/Cross";

const CloseButton = ({ close }) => {
  return (
    <Wrapper onClick={close}>
      <Cross />
    </Wrapper>
  );
};

const Wrapper = styled.div`
  position: absolute;
  cursor: pointer;
  width: 25px;
  height: 25px;
  right: -10px;
  top: -10px;
  background-color: ${(props) => props.theme.primary.black};
  z-index: 5;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  transition: transform 0.2s ease-in-out;
  &:hover {
    transform: rotate(360deg);
  }
`;

export default CloseButton;
