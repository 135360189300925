import React from "react";
import styled from "styled-components";
import { swithColors } from "../../../../../views/marketplace/categoryPage/controllers/themeCtrl";
import theme from "../../../../../theme";

const Cartouche = ({ cat }) => {
  return (
    <Wrapper type="button" className="segoesc" color={swithColors(cat, theme)}>
      {cat}
    </Wrapper>
  );
};

const Wrapper = styled.button`
  border: none;
  box-sizing: border-box;
  padding: 5px 15px;
  border-radius: 20px;
  background-color: ${(props) => props.color};
  color: white;
  font-size: 10px;
  text-transform: uppercase;
  max-height: 20px;
  display: flex;
  justify-content: center;
  align-items: center;
  line-height: 20px;
  white-space: nowrap;
`;

export default Cartouche;
