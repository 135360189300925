import { postProduct, updateProduct, fetchMessage } from "../../state/actions";

export const messageNotif = (message, dispatch) => {
  dispatch(
    fetchMessage({
      message: message,
    })
  );
};

export const inputDate = (date) => {
  const day = ("0" + date.getDate()).slice(-2);
  const month = ("0" + (date.getMonth() + 1)).slice(-2);

  const today = date.getFullYear() + "-" + month + "-" + day;
  return today;
};

export const requiredMessage = (required, dispatch) => {
  messageNotif(`le champs ${required} est vide !`, dispatch);
};

export const validateProduct = (
  title,
  e,
  selectedProduct,
  navigate,
  dispatch
) => {
  e.preventDefault();

  const form = e.target;
  let myFormData = new FormData();

  const coverElement = Array.from(form.elements).find(
    (input) => input.name === "cover"
  );

  if (coverElement.value) {
    let file = coverElement.files[0];
    myFormData.append("cover", file);
  }

  if (!selectedProduct.author.length) {
    requiredMessage("Auteur", dispatch);
  }
  if (!selectedProduct.tags.length) {
    requiredMessage("Tags", dispatch);
  }
  if (!selectedProduct.category) {
    requiredMessage("Catégorie", dispatch);
  }
  if (!selectedProduct.readingDirection) {
    requiredMessage("Sens de lecture", dispatch);
  }
  if (!selectedProduct.frenchPublisher) {
    requiredMessage("édition française", dispatch);
  }
  if (!selectedProduct.frenchTitle) {
    requiredMessage("Titre français", dispatch);
  }
  if (!selectedProduct.originalTitle) {
    requiredMessage("Titre original", dispatch);
  }
  if (!selectedProduct.synopsis) {
    requiredMessage("Synopsis", dispatch);
  }
  if (!selectedProduct.illustrator) {
    requiredMessage("Illustrateur", dispatch);
  }
  if (
    selectedProduct.author.length > 0 &&
    selectedProduct.tags.length > 0 &&
    selectedProduct.category &&
    selectedProduct.readingDirection &&
    selectedProduct.frenchPublisher &&
    selectedProduct.frenchTitle &&
    selectedProduct.originalTitle &&
    selectedProduct.synopsis &&
    selectedProduct.illustrator
  ) {
    if (title === "créer un nouveau titre") {
      if (!coverElement.value) {
        requiredMessage("d'enregistrement de fichier", dispatch);
      } else {
        for (let key of myFormData.keys()) {
          if (key === "cover") {
            myFormData.append("product", JSON.stringify(selectedProduct));
            dispatch(postProduct(myFormData, navigate, true));
          }
        }
      }
    }
    if (title === "modifier un titre") {
      myFormData.append("product", JSON.stringify(selectedProduct));
      dispatch(updateProduct(myFormData, selectedProduct._id, navigate, true));
    }
  }
};
