import React from "react";
import styled, { keyframes } from "styled-components";

const Bubble = ({ number, bottom, right }) => {
  return (
    <Wrapper className="segoesc" number={number} bottom={bottom} right={right}>
      {number}
    </Wrapper>
  );
};

const test = keyframes`
 0% { transform: scale(1); opacity:0;}
 50% { transform: scale(1.2); opacity:1; }
 100% { transform: scale(1); opacity:1;}
`;

const Wrapper = styled.div`
  display: ${(props) => (props.number ? "flex" : "none")};
  cursor: pointer;
  justify-content: center;
  align-items: center;
  width: 20px;
  height: 20px;
  position: absolute;
  bottom: ${(props) => props.bottom}px;
  right: ${(props) => props.right}px;
  font-size: 15px;
  color: white;
  background-color: ${(props) => props.theme.primary.lightColor};
  border-radius: 50%;
  animation: ${test} 0.5s ease-in-out forwards;
`;

export default Bubble;
