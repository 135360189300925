import { actions } from "../actions/index";

const { FETCH_CAROUSEL } = actions;

const initialState = {
  bannieres: [],
};

export function carousel(state = initialState, action) {
  switch (action.type) {
    case FETCH_CAROUSEL:
      return {
        ...state,
        bannieres: action.payload.bannieres,
      };
    default:
      return state;
  }
}
