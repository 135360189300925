import React from "react";
import styled from "styled-components";

const Digital = ({ height }) => {
  return (
    <Svg
      xmlns="http://www.w3.org/2000/svg"
      width="131"
      height={height}
      viewBox="0 0 131 119.456"
    >
      <g
        id="Groupe_781"
        data-name="Groupe 781"
        transform="translate(-90.512 -123.068)"
      >
        <g id="Groupe_780" data-name="Groupe 780">
          <g id="Groupe_779" data-name="Groupe 779">
            <g id="Groupe_778" data-name="Groupe 778">
              <g id="Groupe_777" data-name="Groupe 777">
                <g id="Groupe_844" data-name="Groupe 844">
                  <g id="g2084" transform="translate(141.85 172.132)">
                    <g id="path2086" transform="translate(0 0)">
                      <path
                        id="Tracé_321"
                        data-name="Tracé 321"
                        d="M-29.3,4.021H-51.927a.811.811,0,0,1-.776-.576.811.811,0,0,1,.326-.91l2.847-1.9A3.961,3.961,0,0,0-47.846-2.65V-6.689a.811.811,0,0,1,.811-.811h12.842a.811.811,0,0,1,.811.811V-2.65A3.961,3.961,0,0,0-31.7.636l2.847,1.9a.811.811,0,0,1,.326.91A.812.812,0,0,1-29.3,4.021ZM-49.25,2.4h17.27l-.62-.414A5.525,5.525,0,0,1-35-2.65V-5.878h-11.22V-2.65A5.526,5.526,0,0,1-48.63,1.986Z"
                        transform="translate(52.737 7.5)"
                      />
                    </g>
                  </g>
                  <g id="g2088" transform="translate(165.292 141.132)">
                    <g id="path2090" transform="translate(0 0)">
                      <path
                        id="Tracé_322"
                        data-name="Tracé 322"
                        d="M-189.258,39.324h-19.031a3.916,3.916,0,0,1-3.911-3.911v-39A3.916,3.916,0,0,1-208.289-7.5h19.031a3.916,3.916,0,0,1,3.912,3.912v39A3.916,3.916,0,0,1-189.258,39.324Zm-19.031-45.2a2.292,2.292,0,0,0-2.289,2.29v39a2.292,2.292,0,0,0,2.289,2.29h19.031a2.293,2.293,0,0,0,2.29-2.29v-39a2.293,2.293,0,0,0-2.29-2.29Z"
                        transform="translate(212.201 7.5)"
                      />
                    </g>
                  </g>
                  <g id="path2092" transform="translate(165.292 152.129)">
                    <path
                      id="Tracé_323"
                      data-name="Tracé 323"
                      d="M457.832-345.9H432.6a.811.811,0,0,1-.811-.811v-25.1a.811.811,0,0,1,.811-.811h25.232a.811.811,0,0,1,.811.811v25.1A.811.811,0,0,1,457.832-345.9Zm-24.422-1.622h23.611V-371H433.41Z"
                      transform="translate(-431.788 372.624)"
                    />
                  </g>
                  <g id="g2094" transform="translate(176.027 179.807)">
                    <g id="path2096" transform="translate(0 0)">
                      <path
                        id="Tracé_324"
                        data-name="Tracé 324"
                        d="M-39.612-19.518A2.7,2.7,0,0,1-42.3-22.21,2.7,2.7,0,0,1-39.612-24.9a2.7,2.7,0,0,1,2.692,2.692A2.7,2.7,0,0,1-39.612-19.518Zm0-3.763a1.072,1.072,0,0,0-1.071,1.071,1.072,1.072,0,0,0,1.071,1.07,1.072,1.072,0,0,0,1.071-1.07A1.072,1.072,0,0,0-39.612-23.281Z"
                        transform="translate(42.304 24.903)"
                        fill="#00a3a3"
                      />
                    </g>
                  </g>
                  <g id="path2098" transform="translate(169.863 147.567)">
                    <path
                      id="Tracé_325"
                      data-name="Tracé 325"
                      d="M495.54-408.634H474.879a.811.811,0,0,1-.811-.811v-4.562a.811.811,0,0,1,.811-.811H495.54a.811.811,0,0,1,.811.811v4.562A.811.811,0,0,1,495.54-408.634Zm-19.85-1.622h19.039v-2.94H475.69Z"
                      transform="translate(-474.068 414.818)"
                    />
                  </g>
                  <g id="path2100" transform="translate(165.292 147.567)">
                    <path
                      id="Tracé_326"
                      data-name="Tracé 326"
                      d="M437.169-408.634H432.6a.811.811,0,0,1-.811-.811v-4.562a.811.811,0,0,1,.811-.811h4.571a.811.811,0,0,1,.811.811v4.562A.811.811,0,0,1,437.169-408.634Zm-3.76-1.622h2.95v-2.94h-2.95Z"
                      transform="translate(-431.787 414.818)"
                    />
                  </g>
                  <g id="g2102" transform="translate(123.182 132.488)">
                    <g id="path2104" transform="translate(0 0)">
                      <path
                        id="Tracé_327"
                        data-name="Tracé 327"
                        d="M-354.06-79.161H-395.6a1.384,1.384,0,0,1-1.383-1.383v-34.767a.811.811,0,0,1,.811-.811h59.959a.811.811,0,0,1,.811.811v8.643a.811.811,0,0,1-.811.811h-14.749a2.292,2.292,0,0,0-2.29,2.29v23.6A.811.811,0,0,1-354.06-79.161Zm-41.3-1.622h40.488v-22.785a3.916,3.916,0,0,1,3.912-3.911h13.938V-114.5h-58.338v33.718Z"
                        transform="translate(396.98 116.122)"
                      />
                    </g>
                  </g>
                  <g id="g2106" transform="translate(141.34 145.33)">
                    <g id="path2108" transform="translate(0 0)">
                      <path
                        id="Tracé_328"
                        data-name="Tracé 328"
                        d="M7.37-5.878H-6.689A.811.811,0,0,1-7.5-6.689.811.811,0,0,1-6.689-7.5H7.37a.811.811,0,0,1,.811.811A.811.811,0,0,1,7.37-5.878Z"
                        transform="translate(7.5 7.5)"
                        fill="#00a3a3"
                      />
                    </g>
                  </g>
                  <g id="g2110" transform="translate(155.545 142.185)">
                    <g id="path2112" transform="translate(0)">
                      <path
                        id="Tracé_329"
                        data-name="Tracé 329"
                        d="M-51.939-25.142A1.266,1.266,0,0,1-53.2-26.406V-31.79a1.267,1.267,0,0,1,1.265-1.265,1.263,1.263,0,0,1,.676.2l4.245,2.692A1.258,1.258,0,0,1-46.43-29.1a1.258,1.258,0,0,1-.587,1.067l-4.246,2.692A1.257,1.257,0,0,1-51.939-25.142Zm.357-6v4.083l3.22-2.041Z"
                        transform="translate(53.204 33.055)"
                        fill="#00a3a3"
                      />
                    </g>
                  </g>
                  <g id="g2114" transform="translate(146.493 154.986)">
                    <g id="path2116" transform="translate(0 0)">
                      <path
                        id="Tracé_330"
                        data-name="Tracé 330"
                        d="M-122.662-5.878h-14.059a.811.811,0,0,1-.811-.811.811.811,0,0,1,.811-.811h14.059a.811.811,0,0,1,.811.811A.811.811,0,0,1-122.662-5.878Z"
                        transform="translate(137.532 7.5)"
                        fill="#00a3a3"
                      />
                    </g>
                  </g>
                  <g id="g2118" transform="translate(141.195 151.84)">
                    <g id="path2120" transform="translate(0 0)">
                      <path
                        id="Tracé_331"
                        data-name="Tracé 331"
                        d="M-3.938-25.143a1.261,1.261,0,0,1-.677-.2l-4.245-2.691A1.258,1.258,0,0,1-9.447-29.1a1.258,1.258,0,0,1,.587-1.068l4.246-2.692a1.26,1.26,0,0,1,.676-.2,1.266,1.266,0,0,1,1.265,1.265v5.383A1.266,1.266,0,0,1-3.938-25.143ZM-7.515-29.1l3.22,2.041v-4.082Z"
                        transform="translate(9.447 33.056)"
                        fill="#00a3a3"
                      />
                    </g>
                  </g>
                  <g id="g2122" transform="translate(129.396 127.372)">
                    <g id="path2124" transform="translate(0 0)">
                      <path
                        id="Tracé_332"
                        data-name="Tracé 332"
                        d="M-444.765-.761h-53.747a.811.811,0,0,1-.811-.811V-6.689a.811.811,0,0,1,.811-.811h53.175a1.385,1.385,0,0,1,1.383,1.383v4.545A.811.811,0,0,1-444.765-.761ZM-497.7-2.383h52.125v-3.5H-497.7v3.5Z"
                        transform="translate(499.323 7.5)"
                      />
                    </g>
                  </g>
                  <g id="g2126" transform="translate(138.101 182.03)">
                    <g id="path2128" transform="translate(0 0)">
                      <path
                        id="Tracé_333"
                        data-name="Tracé 333"
                        d="M-140.583-41.381h-11.158a.811.811,0,0,1-.811-.811.811.811,0,0,1,.811-.811h9.531a3.906,3.906,0,0,1-.736-2.29v-.392h-25a.569.569,0,0,0-.569.569v1.544a.569.569,0,0,0,.569.569h11.158a.811.811,0,0,1,.811.811.811.811,0,0,1-.811.811h-11.158a2.193,2.193,0,0,1-2.191-2.19v-1.544a2.193,2.193,0,0,1,2.191-2.191h25.811a.811.811,0,0,1,.811.811v1.2a2.281,2.281,0,0,0,1.6,2.183.812.812,0,0,1,.564.71.811.811,0,0,1-.448.789A2.17,2.17,0,0,1-140.583-41.381Z"
                        transform="translate(170.136 47.307)"
                      />
                    </g>
                  </g>
                  <g id="g2130" transform="translate(118.878 123.068)">
                    <g id="path2132" transform="translate(0 0)">
                      <path
                        id="Tracé_334"
                        data-name="Tracé 334"
                        d="M-594.474-20.616h-42.437A4.792,4.792,0,0,1-641.7-25.4V-66.514a4.792,4.792,0,0,1,4.787-4.787H-576.3a4.792,4.792,0,0,1,4.787,4.787v2.922a.811.811,0,0,1-.811.811.811.811,0,0,1-.811-.811v-2.922a3.169,3.169,0,0,0-3.165-3.165h-60.616a3.169,3.169,0,0,0-3.165,3.165V-25.4a3.169,3.169,0,0,0,3.165,3.165h41.627V-49.326a3.916,3.916,0,0,1,3.912-3.911h18.243v-5.31a.811.811,0,0,1,.811-.811.811.811,0,0,1,.811.811v6.122a.811.811,0,0,1-.811.811h-19.053a2.293,2.293,0,0,0-2.29,2.29v27.9A.811.811,0,0,1-594.474-20.616Z"
                        transform="translate(641.698 71.301)"
                      />
                    </g>
                  </g>
                  <g id="g2080" transform="translate(123.182 127.372)">
                    <g id="path2082" transform="translate(0 0)">
                      <path
                        id="Tracé_335"
                        data-name="Tracé 335"
                        d="M-57.944-.761h-6.213a.811.811,0,0,1-.811-.811V-6.117A1.384,1.384,0,0,1-63.585-7.5h5.641a.811.811,0,0,1,.811.811v5.117A.811.811,0,0,1-57.944-.761Zm-5.4-1.622h4.591v-3.5h-4.591Z"
                        transform="translate(64.968 7.5)"
                        fill="#00a3a3"
                      />
                    </g>
                  </g>
                </g>
              </g>
            </g>
          </g>
        </g>
      </g>
      <text
        id="_100_numérique_Tout_appareil"
        data-name="100% numérique
Tout appareil"
        transform="translate(56.5 90.456)"
        fill="#120909"
        fontSize="14"
        className="segoesc"
      >
        <tspan x="-64.606" y="0">
          100% numérique
        </tspan>
        <tspan x="-51.345" y="22">
          Tout appareil
        </tspan>
      </text>
    </Svg>
  );
};

const Svg = styled.svg`
  & text {
    font-size: 16px;
  }
`;

export default Digital;
