export const cutString = (title, titleLength) => {
  if (title?.length > titleLength) {
    return title.substring(0, titleLength) + "...";
  } else {
    return title;
  }
};

export const firstToUpperCase = (string) => {
  let first = string?.substring(0, 1);
  let rest = string?.substring(1);
  return first?.toUpperCase() + rest;
};
