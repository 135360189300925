import React, { useEffect, useState } from "react";
import styled from "styled-components";
import { adminLogin, fetchMessage } from "../../../lib/state/actions";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { useAdminState } from "../../../views/marketplace/categoryPage/controllers/stateCtrl";
const FormId = () => {
  const [form, setForm] = useState({ pseudo: "", email: "", password: "" });

  const adminConnected = useAdminState(useSelector);
  const navigate = useNavigate();

  useEffect(() => {
    if (adminConnected !== null) {
      navigate("/admin/products");
    }
  }, [adminConnected, navigate]);

  const dispatch = useDispatch();

  const handleKeyDown = (e) => {
    if (e.keyCode === 13) {
      login();
    }
  };

  const login = () => {
    let regex = new RegExp(
      // eslint-disable-next-line
      "([!#-'*+/-9=?A-Z^-~-]+(.[!#-'*+/-9=?A-Z^-~-]+)*|\"([]!#-[^-~ \t]|(\\[\t -~]))+\")@([!#-'*+/-9=?A-Z^-~-]+(.[!#-'*+/-9=?A-Z^-~-]+)*|[[\t -Z^-~]*])"
    );
    if (
      form.email.match(regex) &&
      form.password.length > 8 &&
      form.password.length < 30 &&
      form.email.length < 50
    ) {
      const body = JSON.stringify({
        ...form,
      });
      setForm({ pseudo: "", email: "", password: "" });
      dispatch(adminLogin(body));
    } else {
      dispatch(fetchMessage("l'un des champs n'est pas valide..."));
    }
  };
  return (
    <Wrapper>
      <H2>identification administrateur</H2>
      <Container>
        <InputCont>
          <Label name="pseudo">pseudo :</Label>
          <Input
            type="text"
            name="pseudo"
            placeholder="pseudo"
            value={form.pseudo}
            onChange={(e) => setForm({ ...form, pseudo: e.target.value })}
          />
        </InputCont>
        <InputCont>
          <Label name="email">email :</Label>
          <Input
            type="email"
            name="email"
            placeholder="email"
            value={form.email}
            onChange={(e) => setForm({ ...form, email: e.target.value })}
          />
        </InputCont>
        <InputCont>
          <Label name="password">mot de passe :</Label>
          <Input
            type="password"
            name="password"
            placeholder="password"
            value={form.password}
            onChange={(e) => setForm({ ...form, password: e.target.value })}
            onKeyDown={handleKeyDown}
          />
        </InputCont>
      </Container>
      <Button type="button" onClick={() => login()}>
        envoyer
      </Button>
    </Wrapper>
  );
};

const Wrapper = styled.div`
  position: absolute;
  background-color: lightBlue;
  width: 300px;
  height: 280px;
  transform: translate(50%, 50%);
  right: 50%;
  bottom: 50%;
  border-radius: 10px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-direction: column;
  padding: 1em 1em;
  box-sizing: border-box;
`;

const H2 = styled.h2`
  font-size: 13px;
  font-weight: bold;
  text-transform: uppercase;
  text-align: center;
`;

const Label = styled.label`
  font-weight: bold;
  font-size: 12px;
`;

const Input = styled.input`
  &::placeholder {
    color: blue;
  }
  &:focus {
    outline: solid black 2px;
    border-radius: 2px;
  }
  color: "blue";
  height: 25px;
  font-size: 12px;
  border: none;
  margin-top: 3px;

  border-radius: 2px;
  width: 250px;
`;

const Container = styled.div`
  display: flex;
  flex-direction: column;
  height: 170px;
  justify-content: space-between;
`;

const InputCont = styled.div`
  display: flex;
  flex-direction: column;
`;

const Button = styled.div`
  padding: 0.8em;
  background: white;
  border-radius: 10px;
  color: grey;
  font-weight: bold;
  font-size: 12px;
  text-transform: uppercase;
  cursor: pointer;
  transition: all 0.2s ease-in-out;
  &:hover {
    color: black;
  }
`;

export default FormId;
