import React, { useEffect, useState } from "react";
import styled from "styled-components";
import CloseButton from "../connectForm/CloseButton";

const ContactVerif = ({ toggleVerif, sendMessage }) => {
  const [randomNumber, setRandomNumber] = useState([]);
  const [number, setNumber] = useState();

  const randomIntFromInterval = (min, max) => {
    // min and max included
    return Math.floor(Math.random() * (max - min + 1) + min);
  };

  useEffect(() => {
    const number = randomIntFromInterval(2, 10);
    setRandomNumber(number);
  }, []);

  const numberToString = (number) => {
    switch (number) {
      case 1:
        return "un";
      case 2:
        return "deux";
      case 3:
        return "trois";
      case 4:
        return "quatre";
      case 5:
        return "cinq";
      case 6:
        return "six";
      case 7:
        return "sept";
      case 8:
        return "huit";
      case 9:
        return "neuf";
      case 10:
        return "dix";
      default:
        return number;
    }
  };

  useEffect(() => {
    if (number && randomNumber) {
      if (number === randomNumber) {
        sendMessage();
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [randomNumber, number]);

  return (
    <Wrapper>
      <CloseButton close={() => toggleVerif()} />
      {randomNumber && (
        <>
          <P>
            Écrivez le chiffre{" "}
            <span className="montserratSemiBold">
              {numberToString(randomNumber)}
            </span>{" "}
            :
          </P>
          <Input
            className="segoesc"
            type="text"
            name="number"
            placeholder="?"
            onChange={(e) => setNumber(parseInt(e.target.value))}
          />
        </>
      )}
    </Wrapper>
  );
};

const Wrapper = styled.div`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  box-sizing: border-box;
  border-radius: 5px;
  color: white;
  background-color: ${(props) => props.theme.primary.darkColor};
  padding: 20px;
  z-index: 1000;
  font-size: 18px;
`;

const Input = styled.input`
  display: block;

  margin: 0 auto;
  &:focus {
    outline: none;
  }
  outline: none;
  border: none;
  width: 50px;
  height: 50px;
  font-size: 30px;
  text-align: center;
  border-radius: 3px;
`;

const P = styled.p`
  margin-bottom: 20px;
  line-height: 18px;
  font-size: 18px;
  & span {
    font-size: 18px;
    text-transform: uppercase;
  }
`;

export default ContactVerif;
