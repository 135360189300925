import React from "react";
import styled from "styled-components";

const Secure = ({ height }) => {
  return (
    <Svg
      xmlns="http://www.w3.org/2000/svg"
      width="140"
      height={height}
      viewBox="0 0 140 119.598"
    >
      <text
        id="Paiement_sécurisé_par_carte_bancaire"
        data-name="Paiement sécurisé
par carte bancaire"
        transform="translate(60.5 90.598)"
        fill="#120909"
        fontSize="14"
        className="segoesc"
      >
        <tspan x="-67.512" y="0">
          Paiement sécurisé
        </tspan>
        <tspan x="-69.019" y="22">
          par carte bancaire
        </tspan>
      </text>
      <g id="paiement" transform="translate(33.58)">
        <path
          id="Tracé_449"
          data-name="Tracé 449"
          d="M192.483,244.9c-.6.458-1.323,1.028-2.09,1.63-3.115,2.448-7.823,6.148-9.848,6.808-1.265.412-2.876-2.252-3.417-3.53l-1.91-4.542-.341-.811-.521.219.353.839,1.949,4.65c.552,1.3,2.2,4.023,3.488,3.6,2.067-.674,6.866-4.45,10.031-6.949.783-.616,1.521-1.211,2.134-1.678,2.783-2.127,5.978-1.008,7.908,1.059a6.105,6.105,0,0,1,1.729,3.4,5.742,5.742,0,0,0-1.717-3.672C198.339,243.909,195.209,242.813,192.483,244.9Z"
          transform="translate(-150.423 -213.143)"
          fill="none"
        />
        <path
          id="Tracé_450"
          data-name="Tracé 450"
          d="M276.7,358.065q-.223.252-.444.506l.48-.471Z"
          transform="translate(-236.115 -309.253)"
          fill="none"
        />
        <path
          id="Tracé_451"
          data-name="Tracé 451"
          d="M377.727,187.131l-1.631-3.877-8.137,3.422,1.63,3.877Zm-2.006-3.465,1.486,3.531-7.413,3.116-1.484-3.531Z"
          transform="translate(-313.229 -162.249)"
          fill="none"
        />
        <path
          id="Tracé_452"
          data-name="Tracé 452"
          d="M417.84,273.846l.011.022,1.114-.5Z"
          transform="translate(-355.176 -238.031)"
          fill="none"
        />
        <path
          id="Tracé_453"
          data-name="Tracé 453"
          d="M194.414,76.8l-.021-.049-.582-1.384h0l-1.917-4.556-.542-1.289-1.541-3.661a3.262,3.262,0,0,0-4.251-1.734l-36.416,15.32a3.212,3.212,0,0,0-1.747,1.765,3.673,3.673,0,0,0,.277,3.135l.4.944,42.353-17.807-.6-1.421,1.541,3.662-.263.11.506,1.2.955,2.269L151,90.788l-1.465-3.472-.522.219,1.518,3.6,42.35-17.809-.974-2.316,1.918,4.557-.262.11.495,1.177.02.048,4.673,11.111a3.181,3.181,0,0,1-1.692,4.169l-.008,0h-.008l-16.99,7.172a1.2,1.2,0,0,0-.39.159l-.755.319a2.858,2.858,0,0,1-.224.485L179.7,99.9a1.223,1.223,0,0,1,.4-.162L197.44,92.4h.008l.009,0a3.247,3.247,0,0,0,1.727-4.255Zm-4.259-9.22L148.593,85.058c-.108-.262-.22-.512-.325-.757A3.6,3.6,0,0,1,148,81.229a3.147,3.147,0,0,1,1.712-1.729l35.7-15.01a3.2,3.2,0,0,1,4.164,1.7Z"
          transform="translate(-127.546 -61.855)"
          fill="none"
        />
        <path
          id="Tracé_454"
          data-name="Tracé 454"
          d="M77.855,199.477l-.259.2c2.679-1.48,5.8,2.253,5.086,3.573-5.384,5.284-13.468,12.525-17,19.847-3.094,6.318-8.957,6.41-14.283,5.669a13.817,13.817,0,0,0-3.579-.027c-4.99.608-7.9-.477-10.925-8.311-2.852-7.379,2.227-10.608,3.278-11.179,1.608-.88,2.283-2.628,3.4-5.526.983-2.926,5.677-13.556,8.281-14.814.7,1.68,2.776,6.606,3.511,8.362l2.793,6.628c1.059,2.5,3.527,5.874,6.363,4.874a15.235,15.235,0,0,0,3.733-2.07,13.97,13.97,0,0,1-3.423,1.847c-2.922,1.031-5.463-2.45-6.555-5.021L55.4,196.7c-.757-1.809-2.9-6.884-3.616-8.615-2.683,1.3-7.518,12.248-8.531,15.262-1.15,2.986-1.845,4.786-3.5,5.693-1.083.588-6.315,3.915-3.377,11.516,3.118,8.07,6.114,9.188,11.254,8.562a14.233,14.233,0,0,1,3.687.028c5.486.764,11.526.669,14.714-5.84,3.639-7.543,11.963-15,17.509-20.446C84.313,201.43,80.7,197.235,77.855,199.477Z"
          transform="translate(-33.644 -166.315)"
          fill="none"
        />
        <path
          id="Tracé_455"
          data-name="Tracé 455"
          d="M439.428,139.3l-.495-1.177h0Z"
          transform="translate(-372.914 -124.301)"
          fill="none"
        />
        <path
          id="Tracé_456"
          data-name="Tracé 456"
          d="M188.6,66.441l-35.7,15.01a3.147,3.147,0,0,0-1.712,1.729,3.6,3.6,0,0,0,.271,3.072l41.3-18.112A3.2,3.2,0,0,0,188.6,66.441Z"
          transform="translate(-130.739 -63.806)"
          fill="none"
        />
        <path
          id="Tracé_457"
          data-name="Tracé 457"
          d="M61.054,208.251l-2.793-6.628c-.735-1.756-2.814-6.682-3.511-8.362-2.6,1.258-7.3,11.889-8.281,14.814-1.117,2.9-1.791,4.646-3.4,5.526-1.051.571-6.131,3.8-3.278,11.179,3.027,7.834,5.935,8.919,10.925,8.311a13.816,13.816,0,0,1,3.579.027c5.326.741,11.189.649,14.283-5.669,3.533-7.322,11.617-14.563,17-19.847.715-1.321-2.407-5.053-5.086-3.573-2.755,2.157-6.476,5.115-9.343,7.026a15.236,15.236,0,0,1-3.733,2.07C64.58,214.125,62.113,210.746,61.054,208.251Z"
          transform="translate(-36.541 -170.663)"
          fill="none"
        />
        <path
          id="Tracé_458"
          data-name="Tracé 458"
          d="M379.067,189.381l-1.486-3.531-7.411,3.116,1.484,3.531Z"
          transform="translate(-315.089 -164.432)"
          fill="none"
        />
        <path
          id="Tracé_459"
          data-name="Tracé 459"
          d="M207.489,168.286l16.99-7.172h.008l.008,0a3.181,3.181,0,0,0,1.692-4.169l-4.673-11.111L179.775,162.9l1.91,4.542c.541,1.277,2.152,3.942,3.417,3.53,2.025-.66,6.733-4.36,9.848-6.808.767-.6,1.49-1.172,2.09-1.63,2.726-2.084,5.857-.988,7.748,1.024a5.742,5.742,0,0,1,1.717,3.672,3.4,3.4,0,0,1-.161,1.535l.755-.319A1.2,1.2,0,0,1,207.489,168.286Zm.547-14.711,9.611-4.04a1.2,1.2,0,0,1,1.562.636l2.408,5.736a1.193,1.193,0,0,1-.636,1.562L211.37,161.5a1.2,1.2,0,0,1-1.562-.636l-2.408-5.726h0A1.193,1.193,0,0,1,208.036,153.575Z"
          transform="translate(-154.98 -130.778)"
          fill="none"
        />
        <path
          id="Tracé_460"
          data-name="Tracé 460"
          d="M352.941,174.239l2.409,5.726a1.2,1.2,0,0,0,1.562.636l9.611-4.032a1.193,1.193,0,0,0,.636-1.562l-2.408-5.736a1.2,1.2,0,0,0-1.562-.636l-9.611,4.04a1.193,1.193,0,0,0-.637,1.562Zm10.447-3.356,1.631,3.877-8.139,3.422-1.63-3.877Z"
          transform="translate(-300.522 -149.878)"
        />
        <path
          id="Tracé_461"
          data-name="Tracé 461"
          d="M97.3,76.454,91.579,62.833l-2.41-5.726c-1.017-2.337-1.457-4.411-4.076-5.486a5.532,5.532,0,0,0-4.26.022L45.134,66.65a5.582,5.582,0,0,0-3.395,4.721c-3.7.668-8.975,13.029-10.139,16.384-.923,2.386-1.478,3.83-2.318,4.295-4.6,2.512-6.84,8.125-4.36,14.135,2.62,7.171,6.318,10.825,13.438,9.819,2.636-.318,4.161.409,7.2.371,4.117,0,9.506-1.188,12.514-7.331a41.415,41.415,0,0,1,5.962-8.52h0q.221-.254.444-.506l1.61-1.76c1.055-1.125,2.1-2.184,3.084-3.15l1.836-1.54,5.429-2.289,2.435-1.029,5.481-2.3c.014.022,2.205-.918,2.2-.925l1.125-.475,0,0h0l6.638-2.8A5.568,5.568,0,0,0,97.3,76.454ZM73.79,87.754c-5.547,5.443-13.87,12.9-17.509,20.446-3.187,6.509-9.227,6.6-14.714,5.84a14.239,14.239,0,0,0-3.687-.028c-5.14.626-8.136-.492-11.254-8.562C23.688,97.848,28.92,94.521,30,93.933c1.657-.906,2.352-2.707,3.5-5.693,1.013-3.014,5.848-13.966,8.531-15.262.718,1.73,2.859,6.805,3.616,8.615l2.878,6.829c1.091,2.571,3.633,6.051,6.555,5.021A13.982,13.982,0,0,0,58.507,91.6c2.812-1.917,6.345-4.732,8.914-6.741a3.17,3.17,0,0,1,.429-.286l.259-.2C70.952,82.124,74.567,86.32,73.79,87.754Zm20.019-6.008-.009,0h-.008L76.45,89.085a1.216,1.216,0,0,0-.4.162l-1.015.429a2.858,2.858,0,0,0,.224-.485l-.239.1a3.055,3.055,0,0,0,.4-1.636,6.1,6.1,0,0,0-1.729-3.4c-1.93-2.067-5.126-3.186-7.908-1.059-.612.468-1.351,1.062-2.134,1.678-3.165,2.5-7.964,6.275-10.031,6.949-1.291.421-2.935-2.3-3.488-3.6l-1.949-4.65-.353-.839-.091.038.425,1.01L44,73.9c.108.25.222.506.332.773l.086-.036-.4-.944a3.673,3.673,0,0,1-.277-3.135A3.211,3.211,0,0,1,45.5,68.8l36.416-15.32a3.262,3.262,0,0,1,4.251,1.734L87.7,58.871l.542,1.289,1.917,4.556h0l.582,1.384.021.049,4.769,11.341A3.247,3.247,0,0,1,93.809,81.746Z"
          transform="translate(-23.898 -51.205)"
        />
        <path
          id="Tracé_462"
          data-name="Tracé 462"
          d="M159.825,216.285l.088-.037-1.518-3.6-.091.038Z"
          transform="translate(-136.925 -186.963)"
        />
        <path
          id="Tracé_463"
          data-name="Tracé 463"
          d="M292.255,327.2c-.983.966-2.03,2.025-3.085,3.15Z"
          transform="translate(-246.974 -283.298)"
        />
        <line
          id="Ligne_1"
          data-name="Ligne 1"
          x1="5.429"
          y2="2.289"
          transform="translate(47.116 40.073)"
        />
        <path
          id="Tracé_464"
          data-name="Tracé 464"
          d="M250.385,261.241c-2.569,2.009-6.1,4.824-8.914,6.741,2.867-1.911,6.588-4.869,9.343-7.026A3.163,3.163,0,0,0,250.385,261.241Z"
          transform="translate(-206.862 -227.59)"
        />
        <path
          id="Tracé_465"
          data-name="Tracé 465"
          d="M345.7,280.353a3.054,3.054,0,0,1-.4,1.637l.239-.1A3.4,3.4,0,0,0,345.7,280.353Z"
          transform="translate(-294.174 -243.903)"
        />
        <path
          id="Tracé_466"
          data-name="Tracé 466"
          d="M154.165,96.562c.106.245.218.5.325.757l41.563-17.477-.585-1.392Z"
          transform="translate(-133.444 -74.116)"
          fill="none"
        />
        <path
          id="Tracé_467"
          data-name="Tracé 467"
          d="M177.63,155.612l.341.811,41.739-17.069-.02-.049-.5-1.177Z"
          transform="translate(-153.176 -124.302)"
          fill="none"
        />
        <path
          id="Tracé_468"
          data-name="Tracé 468"
          d="M163.618,122.326l41.563-17.48-.955-2.269-.506-1.2-41.567,17.48Z"
          transform="translate(-140.161 -93.394)"
          fill="none"
        />
        <path
          id="Tracé_469"
          data-name="Tracé 469"
          d="M154.4,98.91l-.024-.058L195.943,81.37l.025.059.263-.111-1.541-3.662.6,1.421L152.935,96.885l.945,2.244Z"
          transform="translate(-132.409 -73.449)"
          fill="#00a3a3"
        />
        <path
          id="Tracé_470"
          data-name="Tracé 470"
          d="M169.806,130.757l41.564-17.485h0l.076.179.262-.11-1.918-4.557.974,2.316-42.35,17.809.945,2.245.521-.219Z"
          transform="translate(-145.427 -99.625)"
          fill="#00a3a3"
        />
        <rect
          id="Rectangle_464"
          data-name="Rectangle 464"
          width="0.195"
          transform="matrix(-0.388, -0.922, 0.922, -0.388, 66.022, 13.83)"
          fill="#00a3a3"
        />
        <path
          id="Tracé_471"
          data-name="Tracé 471"
          d="M153.1,200.527l-1.52-3.6.091-.038-.945-2.244-.086.036c-.11-.267-.224-.523-.332-.773l4.159,9.879-.425-1.01.091-.038-.945-2.245Z"
          transform="translate(-130.198 -171.205)"
          fill="#00a3a3"
        />
        <path
          id="Tracé_472"
          data-name="Tracé 472"
          d="M218.724,137,177.16,154.486l.075.178L218.8,137.18Z"
          transform="translate(-152.781 -123.354)"
          fill="#00a3a3"
        />
        <path
          id="Tracé_473"
          data-name="Tracé 473"
          d="M162,118.482l.024.058,41.567-17.48-.025-.059Z"
          transform="translate(-140.032 -93.079)"
          fill="#00a3a3"
        />
        <path
          id="Tracé_474"
          data-name="Tracé 474"
          d="M375.3,284.56H375.3c-.593,1.856-2.868,2.93-4.3,1.241l-.089-.089-1.082-.977-.289.122,1.6,1.443.1.1c1.665,1.969,4.317.718,5.009-1.447h.009a3.31,3.31,0,0,0-.33-2.787l-.743.313A2.836,2.836,0,0,1,375.3,284.56Z"
          transform="translate(-314.558 -245.43)"
          fill="none"
        />
        <path
          id="Tracé_475"
          data-name="Tracé 475"
          d="M372.438,287.365l.089.089c1.428,1.689,3.7.616,4.3-1.241h.008a2.837,2.837,0,0,0-.117-2.078l-5.359,2.253Z"
          transform="translate(-316.086 -247.083)"
          fill="none"
        />
        <path
          id="Tracé_476"
          data-name="Tracé 476"
          d="M323.635,318.093c1.071,1.239,2.254,1.863,3.283,1.635a3,3,0,0,0,2.1-2.068h.008a2.491,2.491,0,0,0-.347-2.41l-7.373.551Z"
          transform="translate(-273.999 -273.249)"
          fill="none"
        />
        <path
          id="Tracé_477"
          data-name="Tracé 477"
          d="M424.915,273.361l0,0h0Z"
          transform="translate(-361.126 -238.021)"
        />
        <path
          id="Tracé_478"
          data-name="Tracé 478"
          d="M281.3,285.163a5.026,5.026,0,0,0,4.554-3.177c3.215,1.4,6.188-1.19,6.326-4.383,3.573.433,6.291-3.751,4.564-6.925l-1.946.868a2.466,2.466,0,0,1,.115.219l.665-.28a2.961,2.961,0,0,1,.3,2.494h-.008c-.619,1.936-2.992,3.056-4.482,1.294l-.093-.093-1.43-1.291.258-.109-.11-.1-1.938.864-.209.086v0l.391-.165L290.4,276.4a2.492,2.492,0,0,1,.347,2.411h-.008a3,3,0,0,1-2.1,2.069c-1.03.228-2.214-.4-3.285-1.636l-2.521-2.482.172-.073-.034-.034-.366.151-.676.706-.566.555.008.008.113-.111,2.677,2.637c.754.871.208,1.706-.388,2.251-.906.83-2.394,1.26-3.033.592l-.034-.035-2.343-2.259.246-.261-.063-.061-1.524,1.494L279,284.235A3.131,3.131,0,0,0,281.3,285.163Z"
          transform="translate(-236.755 -235.767)"
        />
        <path
          id="Tracé_479"
          data-name="Tracé 479"
          d="M276.252,361.246h0Z"
          transform="translate(-236.11 -311.927)"
        />
        <line
          id="Ligne_2"
          data-name="Ligne 2"
          x2="0.038"
          y2="0.037"
          transform="translate(47.271 42.515)"
        />
      </g>
    </Svg>
  );
};

const Svg = styled.svg`
  & text {
    font-size: 16px;
  }
`;

export default Secure;
