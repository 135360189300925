import React from "react";
import styled from "styled-components";

const BigGlass = () => {
  return (
    <Svg
      xmlns="http://www.w3.org/2000/svg"
      width="24.549"
      height="26"
      viewBox="0 0 24.549 26"
    >
      <g id="_x32_03" transform="translate(-5.937 -4.131)">
        <path
          id="Tracé_559"
          data-name="Tracé 559"
          d="M13.15,6.1a9.9,9.9,0,0,0-6.1,4.925,10.155,10.155,0,0,0-.206,8.826,9.986,9.986,0,0,0,13.944,4.424,37.722,37.722,0,0,0,4.239,5.544,3.031,3.031,0,0,0,3.146.938,1.972,1.972,0,0,0,1.56-1.936,2,2,0,0,0-.479-1.3c-1.774-2.069-3.457-4.16-5.019-6.226a8.971,8.971,0,0,0,1.581-3.452C28.293,10.345,20.674,3.161,13.15,6.1ZM28.8,27.91a1.386,1.386,0,1,1-2.177,1.711c-1.433-2.032-2.985-4.088-4.612-6.122a10.49,10.49,0,0,0,1.842-1.717c1.543,2.034,3.2,4.092,4.946,6.128ZM25.563,16.376A9.2,9.2,0,0,1,18.14,23.8,9.153,9.153,0,0,1,8.5,18.571,8.926,8.926,0,0,1,14.729,6.24,9.024,9.024,0,0,1,25.563,16.376Z"
          transform="translate(0 -0.773)"
          fill="#120909"
        />
        <path
          id="Tracé_560"
          data-name="Tracé 560"
          d="M57.736,60.931a.3.3,0,0,0,.165.049.3.3,0,0,0,.166-.555,1.273,1.273,0,0,1-.493-.616.3.3,0,0,0-.567.211A1.884,1.884,0,0,0,57.736,60.931Z"
          transform="translate(-30.56 -33.211)"
          fill="#120909"
        />
        <path
          id="Tracé_561"
          data-name="Tracé 561"
          d="M35.563,11.838a.3.3,0,1,0-.194.572A7.225,7.225,0,0,1,40.117,18.8a.3.3,0,0,0,.3.292h.011a.3.3,0,0,0,.292-.313,7.746,7.746,0,0,0-5.158-6.94Z"
          transform="translate(-17.499 -4.592)"
          fill="#120909"
        />
        <path
          id="Tracé_562"
          data-name="Tracé 562"
          d="M47.468,31.487l-.049.049a.3.3,0,0,0,.424.43l.078-.078-.214-.214Z"
          transform="translate(-24.769 -16.37)"
          fill="#120909"
        />
        <path
          id="Tracé_563"
          data-name="Tracé 563"
          d="M53.979,4.147a.3.3,0,0,0-.384.188l-.921,2.688a.3.3,0,0,0,.572.2l.921-2.688A.3.3,0,0,0,53.979,4.147Z"
          transform="translate(-27.961)"
          fill="#120909"
        />
        <path
          id="Tracé_564"
          data-name="Tracé 564"
          d="M56.069,12.207a.3.3,0,0,0,.126-.028,15.265,15.265,0,0,0,2.446-1.412.3.3,0,0,0-.349-.493,14.629,14.629,0,0,1-2.35,1.357.3.3,0,0,0,.126.577Z"
          transform="translate(-29.831 -3.635)"
          fill="#120909"
        />
        <path
          id="Tracé_565"
          data-name="Tracé 565"
          d="M60.942,19.484a9.621,9.621,0,0,0-3.486-.345.3.3,0,0,0,.05.6,9.738,9.738,0,0,1,3.352.335.3.3,0,0,0,.083-.592Z"
          transform="translate(-30.675 -8.961)"
          fill="#120909"
        />
      </g>
    </Svg>
  );
};

const Svg = styled.svg`
  &:hover {
    transform: scale(1.1);
    transition: all 0.2s ease-in-out;
    & path {
      fill: ${(props) => props.theme.primary.color};
      transition: all 0.2s ease-in-out;
    }
  }
`;

export default BigGlass;
