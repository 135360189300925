import { useEffect, useState, useMemo } from "react";

export default function useOnScreen(ref) {
  const [isIntersecting, setIntersecting] = useState(false);

  const observer = useMemo(
    () =>
      new IntersectionObserver(([entry]) =>
        setIntersecting(entry.isIntersecting)
      ),
    []
  );

  useEffect(() => {
    if (ref) {
      observer.observe(ref);
      return () => observer.disconnect();
    }
  });

  return isIntersecting;
}
