import React, { useEffect } from "react";
import styled, { keyframes } from "styled-components";
import { useSelector, useDispatch } from "react-redux";
import { fetchMessage } from "../../../lib/state/actions";
import { useMessageState } from "../../../views/marketplace/categoryPage/controllers/stateCtrl";

const Notif = () => {
  const message = useMessageState(useSelector);

  const dispatch = useDispatch();

  useEffect(() => {
    if (message !== "") {
      const timer = setTimeout(() => {
        dispatch(fetchMessage({ message: "" }));
      }, 7000);
      return () => clearTimeout(timer);
    }
  }, [message, dispatch]);

  return <Wrapper>{message}</Wrapper>;
};

const fade = keyframes`
  0% {
    opacity: 0;
    padding: 0;
  }
  3% {
    opacity: 1;
    padding: 1.2em;
  }
  97% {
    opacity: 1;
    padding: 1.2em;
  }
  100% {
    opacity: 0;
    padding: 0;
  }
`;

const Wrapper = styled.div`
  position: fixed;
  bottom: 5px;
  right: 5px;
  border-radius: 5px;
  background-color: black;
  color: white;
  font-weight: bold;
  z-index: 999;
  border: solid white 2px;
  animation: ${fade} 7s linear forwards;
`;

export default Notif;
