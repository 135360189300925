import React from "react";
import styled from "styled-components";
import Heart from "./picto/Heart";
import Cartouche from "./Cartouche";

const LittleFooter = ({ category, likes }) => {
  return (
    <Wrapper>
      <Cartouche cat={category} />
      <Like className="segoesc">
        <span>{likes}</span>

        <Heart />
      </Like>
    </Wrapper>
  );
};

const Wrapper = styled.div`
  box-sizing: border-box;
  height: 20px;
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0 5px;
`;

const Like = styled.div`
  display: flex;
  justify-content: flex-end;
  align-items: center;
  color: ${(props) => props.theme.primary.black};
  font-weight: bold;
  font-size: 12px;
  height: 20px;
  line-height: 20px;
  min-width: 70px;
  & span {
    margin-right: 5px;
  }
`;

export default LittleFooter;
