import React from "react";
import styled from "styled-components";

const Burger = () => {
  return (
    <Svg
      xmlns="http://www.w3.org/2000/svg"
      width="19.75"
      height="19.75"
      viewBox="0 0 19.75 19.75"
    >
      <g id="menu-burger" transform="translate(-140 -140)">
        <path
          id="Tracé_267"
          data-name="Tracé 267"
          d="M158.269,142.962H141.481A1.482,1.482,0,0,1,140,141.481h0A1.482,1.482,0,0,1,141.481,140h16.787a1.482,1.482,0,0,1,1.481,1.481h0A1.482,1.482,0,0,1,158.269,142.962Z"
          fill="#fff"
        />
        <path
          id="Tracé_268"
          data-name="Tracé 268"
          d="M158.269,482.962H141.481A1.482,1.482,0,0,1,140,481.481h0A1.482,1.482,0,0,1,141.481,480h16.787a1.482,1.482,0,0,1,1.481,1.481h0A1.482,1.482,0,0,1,158.269,482.962Z"
          transform="translate(0 -331.606)"
          fill="#fff"
        />
        <path
          id="Tracé_269"
          data-name="Tracé 269"
          d="M158.269,822.963H141.481A1.482,1.482,0,0,1,140,821.481h0A1.482,1.482,0,0,1,141.481,820h16.787a1.482,1.482,0,0,1,1.481,1.481h0A1.482,1.482,0,0,1,158.269,822.963Z"
          transform="translate(0 -663.213)"
          fill="#fff"
        />
      </g>
    </Svg>
  );
};

const Svg = styled.svg`
  position: fixed;
  top: 0px;
  right: 0px;
  cursor: pointer;
  z-index: 10000;
  filter: drop-shadow(2px 2px 2px rgb(0 0 0 / 0.6));
  transform: scale(1);
  transition: transform 0.2s ease-in-out;
  padding: 12px 20px 20px 20px;
  &:hover {
    transform: scale(1.2);
  }
`;

export default Burger;
