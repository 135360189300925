import { actions } from "../actions/index";

const { FETCH_PENDING, FETCH_SUCCESS, FETCH_ERROR } = actions;

const initialState = {
  isFetching: false,
  hasError: null,
};

export function reducer(state = initialState, action) {
  switch (action.type) {
    case FETCH_PENDING:
      return {
        ...state,
        isFetching: true,
      };

    case FETCH_SUCCESS:
      return {
        ...state,
        isFetching: false,
      };
    case FETCH_ERROR:
      return {
        ...state,
        hasError: action.payload.error,
      };
    default:
      return state;
  }
}
