import React from "react";
import styled from "styled-components";
import { NavLink as Link } from "react-router-dom";
import { adminLogout } from "../lib/state/actions";
import { useDispatch, useSelector } from "react-redux";
import { useAdminState } from "../views/marketplace/categoryPage/controllers/stateCtrl";

const Header = () => {
  const dispatch = useDispatch();
  const adminConnected = useAdminState(useSelector);
  const tabs = [
    { link: "/admin/carousel", text: "Carousel" },
    { link: "/admin/products", text: "Produits" },
    { link: "/admin/category", text: "Catégories" },
    { link: "/admin/tva", text: "TVA" },
    { link: "/admin/notifications", text: "Notifications" },
    { link: "/admin/admins", text: "Administrateurs" },
    { link: "/admin/users", text: "Utilisateurs" },
    { link: "/admin/export", text: "Exporter" },
  ];

  return (
    <MyHeader>
      <nav>
        <Ul>
          {tabs.map((tab, i) => (
            <Li key={i}>
              <Link to={tab.link} active="">
                {tab.text}
              </Link>
            </Li>
          ))}
        </Ul>
      </nav>
      {adminConnected && (
        <Button
          onClick={() => {
            dispatch(adminLogout());
          }}
        >
          se déconnecter
        </Button>
      )}
      {adminConnected && <Span>{adminConnected} est connecté...</Span>}
    </MyHeader>
  );
};

const MyHeader = styled.header`
  background-color: lightgrey;
  height: 100px;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  border-bottom: solid black 1px;
  box-sizing: border-box;
  position: relative;
`;

const Ul = styled.menu`
  display: flex;
  justify-content: space-between;
  font-weight: bold;
  font-size: 17px;
  list-style: none;
`;

const Li = styled.li`
  margin-right: 10px;
  & a {
    padding: 10px;
    border: solid black 1px;
    background-color: white;
    &:hover {
      background-color: black;
      color: white;
      transition: all ease-in-out 0.2s;
    }
    &.active {
      background-color: black;
      color: white;
    }
  }
  &:last-child {
    margin-right: 0px;
  }
`;

const Button = styled.div`
  position: absolute;
  right: 0px;
  top: 0px;
  margin: 5px;
  padding: 0.8em;
  background: lightgreen;
  border-radius: 10px;
  color: grey;
  font-weight: bold;
  font-size: 12px;
  text-transform: uppercase;
  cursor: pointer;
  transition: all 0.2s ease-in-out;
  &:hover {
    color: black;
    background: green;
  }
`;

const Span = styled.span`
  position: absolute;
  right: 0px;
  bottom: 0px;
  padding: 0.5em;
  color: purple;
  font-size: 12px;
  font-weight: bold;
`;

export default Header;
