import React from "react";
import styled from "styled-components";
import { swithColors } from "../../../../views/marketplace/categoryPage/controllers/themeCtrl";
import theme from "../../../../theme";
import { firstLetterUpper } from "../../controllers/marketPlaceControlers";

const TagButton = ({ tag, cat, selectedTags }) => {
  const active = () => {
    const isActive = selectedTags.find((t) => t === tag);
    if (isActive) {
      return true;
    }
    return false;
  };

  return (
    <Button
      color={swithColors(cat, theme)}
      className="montserratSemiBold"
      type="button"
      active={active()}
    >
      {`# 
      ${
        tag === "ecchi" ||
        tag === "seinen" ||
        tag === "shojo" ||
        tag === "shonen" ||
        tag === "one-shot"
          ? tag.toUpperCase()
          : firstLetterUpper(tag)
      }
        `}
    </Button>
  );
};

const Button = styled.button`
  background-color: ${(props) => (props.active ? props.color : "white")};
  padding: 4px 6px;
  border: solid 1px ${(props) => props.color};
  border-radius: 8px;
  font-size: 12px;
  color: ${(props) => (props.active ? "white" : props.theme.primary.black)};
  transition: all 0.3s ease-in-out;
  cursor: pointer;
`;

export default TagButton;
