import React, { useState, useEffect } from "react";
import styled from "styled-components";
import { useSelector, useDispatch } from "react-redux";
import Cart from "../navbar/picto/Cart";
import Cross from "../connectForm/picto/Cross";
import {
  firstToUpperCase,
  cutString,
} from "../profil/controllers/shortTitleCtrl";
import {
  addToCart,
  fetchPopupMessage,
  removeArticleFromCart,
} from "../../../lib/state/actions";
import {
  isVolumeWithSameChapter,
  sameInCart,
  volumePrice,
} from "./controller/cartController";
import { Link } from "react-router-dom";
import Library from "../navbar/picto/Library";
import {
  useTvaState,
  useUserState,
  useCartState,
} from "../../../views/marketplace/categoryPage/controllers/stateCtrl";
import { REACT_APP_API_HOST } from "../../../service";
import { allReplace } from "../reader/lib/readerCtrl";

const ChapterItem = ({ product, chapter, isCart, togglePopup }) => {
  const tva = useTvaState(useSelector);
  const cart = useCartState(useSelector);
  const { userConnected, userId, userEbooks } = useUserState(useSelector);

  const [fade, setFade] = useState(false);

  const dispatch = useDispatch();

  const chapterIsSold = () => {
    if (chapter && userEbooks && !isCart) {
      let soldVolume = userEbooks.find(
        (ebook) => ebook._id === chapter.volumeId
      );
      if (soldVolume && soldVolume.chapters.length > 0) {
        const soldChapter = soldVolume.chapters.find(
          (chap) => chap === chapter._id
        );
        if (soldChapter) {
          return true;
        } else {
          return false;
        }
      } else {
        return false;
      }
    } else {
      return false;
    }
  };

  const addChapterToCart = (chapter) => {
    const chapterObject = {
      _id: chapter._id,
      productId: product._id,
      imageURL: chapter.imageURL,
      price: chapter.price,
      tva: chapter.tva,
      volume: chapter.volume,
      chapterNumber: chapter.chapterNumber,
      volumeId: chapter.volumeId,
    };
    if (
      userConnected &&
      chapterObject.price &&
      chapterObject.tva &&
      chapterObject._id &&
      chapterObject.productId &&
      chapterObject.volume &&
      chapterObject.volumeId
    ) {
      if (
        !sameInCart(cart, chapter._id, chapter.volumeId) &&
        !isVolumeWithSameChapter(cart, chapter.volumeId)
      ) {
        dispatch(addToCart(JSON.stringify(chapterObject), userId));
      }
      if (sameInCart(cart, chapter._id, chapter.volumeId)) {
        dispatch(
          fetchPopupMessage("Cet article existe déjà dans votre panier.")
        );
      }
      if (isVolumeWithSameChapter(cart, chapter.volumeId)) {
        dispatch(
          fetchPopupMessage(
            "Le volume contenant ce chapitre est déjà dans votre panier !"
          )
        );
      }
    } else {
      togglePopup();
    }
  };

  useEffect(() => {
    if (fade) {
      const timeOut = setTimeout(() => {
        dispatch(removeArticleFromCart(chapter._id, userId));
      }, 300);

      return () => clearTimeout(timeOut);
    }
  }, [fade, dispatch, chapter._id, userId]);

  const volumeIsSold = () => {
    if (userEbooks && !isCart) {
      let soldVolume = userEbooks.find(
        (ebook) => ebook._id === chapter.volumeId
      );

      if (soldVolume && soldVolume.chapters.length > 0) {
        const soldChapter = soldVolume.chapters.find(
          (chap) => chap === chapter._id
        );
        if (soldChapter) {
          return true;
        } else {
          return false;
        }
      } else {
        return false;
      }
    }
  };

  const volumeIsRead = (volumeId) => {
    if (userEbooks && userEbooks.length > 0) {
      const vol = userEbooks.find((ebook) => ebook._id === volumeId);
      if (vol && vol.isRead) {
        return true;
      } else {
        return false;
      }
    }
    return false;
  };

  return (
    <>
      {((chapter && chapter.isActive && !isCart) || (chapter && isCart)) && (
        <Wrapper
          isCart={isCart}
          fade={fade}
          volumeIsRead={volumeIsRead(chapter.volumeId)}
          volumeIsSold={volumeIsSold()}
        >
          <Image src={REACT_APP_API_HOST + chapter?.imageURL} fade={fade} />
          <Container>
            <Column>
              {isCart && (
                <H2 className="montserratSemiBold">
                  {cutString(firstToUpperCase(product.frenchTitle), 40)}
                </H2>
              )}
              <Title isCart={isCart} className="montserratSemiBold" fade={fade}>
                {product?.readingDirection === "webtoon"
                  ? `épisode ${chapter.chapterNumber}`
                  : chapter?.volume === 0
                  ? `One-shot `
                  : `Volume ${chapter?.volume} `}
                <Chap className="montserrat large">
                  {product?.readingDirection === "webtoon"
                    ? ""
                    : ` - Chapitre ${chapter?.chapterNumber}`}
                </Chap>
                <Chap className="montserrat small">
                  {product?.readingDirection === "webtoon"
                    ? ""
                    : ` - Chap. ${chapter?.chapterNumber}`}
                </Chap>
              </Title>
              {!chapterIsSold() && !chapter?.gift && (
                <Price
                  className="montserratSemiBold"
                  isCart={isCart}
                  fade={fade}
                >
                  {volumePrice(chapter, tva)
                    ?.price.toString()
                    .replaceAll(".", ",")}
                  €
                </Price>
              )}
              {chapter.title && (
                <ChapterTitle className="montserrat">
                  {cutString(firstToUpperCase(chapter.title), 45)}
                </ChapterTitle>
              )}
            </Column>
            {!isCart && !chapterIsSold() && (
              <SoldContainer>
                {chapter.gift &&
                  (userConnected ? (
                    <Link
                      to={`/liseuse/${allReplace(product.frenchTitle)}/${
                        product._id
                      }/${chapter.volumeId}/${chapter._id}/gift`}
                    >
                      <IconContainer>
                        <Column className="icon">
                          <Library />
                          <p
                            style={{
                              fontSize: "11px",
                            }}
                          >
                            Gratuit
                          </p>
                        </Column>
                      </IconContainer>
                    </Link>
                  ) : (
                    <IconContainer onClick={() => togglePopup()}>
                      <Column className="icon">
                        <Library />
                        <p
                          style={{
                            fontSize: "11px",
                          }}
                        >
                          Gratuit
                        </p>
                      </Column>
                    </IconContainer>
                  ))}
                <CartContainer
                  inCart={
                    isVolumeWithSameChapter(cart, chapter.volumeId) ||
                    sameInCart(cart, chapter._id, chapter.volumeId)
                  }
                  onClick={
                    sameInCart(cart, chapter._id, chapter.volumeId)
                      ? () =>
                          dispatch(removeArticleFromCart(chapter._id, userId))
                      : () => addChapterToCart(chapter)
                  }
                >
                  <Cart />
                  {chapter.digitalExclusive && (
                    <span className="segoesc">Exclu</span>
                  )}
                </CartContainer>
              </SoldContainer>
            )}
            {!isCart && chapterIsSold() && (
              <Link
                to={`/liseuse/${allReplace(product.frenchTitle)}/${
                  product._id
                }/${chapter.volumeId}/${chapter._id}`}
              >
                <CartContainer>
                  <Library />
                </CartContainer>
              </Link>
            )}
            {isCart && (
              <CrossContainer onClick={() => setFade(true)}>
                <Cross />
              </CrossContainer>
            )}
          </Container>
        </Wrapper>
      )}
    </>
  );
};

const Wrapper = styled.div`
  opacity: ${(props) => (props.fade ? "0" : "1")};
  height: ${(props) => (props.fade ? "0px" : "85px")};
  transition: all 0.3s ease-in-out;
  box-sizing: border-box;
  width: 80%;
  padding: ${(props) => (props.fade ? "0px 10px" : "0 20px 0 0")};
  border-radius: 0px;
  margin: ${(props) => (props.fade ? "0" : "0 0 10px 0")};
  background-color: ${(props) =>
    props.volumeIsRead
      ? `rgb(${props.theme.primary.article},0.08)`
      : props.volumeIsSold
      ? `rgb(${props.theme.primary.readArticle},0.15)`
      : `rgb(${props.theme.primary.article},0.2)`};
  display: flex;
  align-items: center;
  justify-content: space-between;
  &:last-child {
    margin-bottom: 0px;
  }
  @media (max-width: 1000px) {
    width: ${(props) => (props.isCart ? "390px" : "95%")};
  }
  @media (max-width: 450px) {
    width: ${(props) => (props.isCart ? "100%" : "95%")};
    padding: ${(props) => (props.fade ? "0 5px 0 0" : "0 10px 0 0")};
  }
  @media (max-width: 415px) {
    padding: ${(props) => (props.fade ? "0 5px 0 0" : "0 10px 0 0")};
  }
`;

const Container = styled.div`
  display: flex;
  width: 100%;
  align-items: center;
  align-self: stretch;
  justify-content: space-between;
  padding-left: 10px;
  @media (max-width: 450px) {
    padding-left: 5px;
  }
`;

const Column = styled.div`
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  align-self: stretch;
  width: auto;
  align-items: unset;
  justify-content: space-around;
  &.icon {
    align-items: center;
  }
`;

const Image = styled.img`
  height: ${(props) => (props.fade ? "0" : "85px")};
  transition: all 0.1s ease-in-out;
`;

const H2 = styled.h2`
  font-size: 14px;
  letter-spacing: 0.3px;
`;

const Title = styled.p`
  opacity: ${(props) => (props.fade ? "0" : "1")};
  transition: all 0.1s ease-in-out;
  font-size: 14px;
  letter-spacing: 0.3px;
  & span {
    display: inline-block;
    text-transform: none;
    &.hidden {
      display: ${(props) => (props.isCart ? "none" : "inline-block")};
    }
  }
  @media (max-width: 450px) {
    line-height: 16px;
    font-size: 12px;
  }
`;

const Price = styled.p`
  font-size: 14px;
  letter-spacing: 0.5px;
  opacity: ${(props) => (props.fade ? "0" : "1")};
  transition: all 0.1s ease-in-out;
  @media (max-width: 450px) {
    font-size: 12px;
  }
`;

const CartContainer = styled.div`
  & svg {
    width: 32px;
    & path {
      transition: fill 0.3s ease-in-out;
      fill: ${(props) =>
        props.inCart
          ? props.theme.primary.darkColor
          : props.theme.primary.black};
    }
  }
  width: 50px;
  height: 50px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background-color: white;
  border-radius: 5px;
  cursor: pointer;
  & span {
    font-size: 10px;
    text-transform: uppercase;
    letter-spacing: 1px;
  }
  @media (max-width: 415px) {
    width: 43px;
    height: 43px;
    margin-left: ${(props) => (props.marginLeft ? "15px" : "0")};
  }
`;

const ChapterTitle = styled.h3`
  font-size: 13px;
  word-break: break-word;
  @media (max-width: 500px) {
    display: none;
  }
`;

const CrossContainer = styled.div`
  padding: 8px;
  background-color: ${(props) => props.theme.primary.black};
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 50%;
  cursor: pointer;
`;

const Chap = styled.span`
  &.small {
    display: none;
  }
  @media (max-width: 450px) {
    &.large {
      display: none;
    }
    &.small {
      display: inline-block;
      @media (max-width: 360px) {
        display: block;
      }
    }
  }
  @media (max-width: 450px) {
    line-height: 16px;
    font-size: 12px;
  }
`;

const IconContainer = styled.div`
  cursor: pointer;
  width: 50px;
  height: 50px;
  background-color: white;
  border-radius: 5px;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-right: 20px;
  @media (max-width: 415px) {
    margin-right: 15px;
    width: 43px;
    height: 43px;
  }
`;

const SoldContainer = styled.div`
  display: flex;
  align-items: center;
`;

export default ChapterItem;
