import React from "react";
import moue from "./image/moue.jpg";
import styled from "styled-components";

const Censored = () => {
  return (
    <Wrapper>
      <Img src={moue} alt="fille qui fait la moue" />
      <P className="montserratSemiBold">Vous n'avez pas 18 ans !</P>
    </Wrapper>
  );
};

const Wrapper = styled.div`
  width: 100%;
  min-height: calc(100dvh - 120px);
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background-color: white;
`;

const P = styled.p`
  font-size: 35px;
  margin-top: 20px;
  @media (max-width: 500px) {
    font-size: 25px;
  }
`;

const Img = styled.img`
  width: 480px;
  @media (max-width: 500px) {
    width: 300px;
  }
`;

export default Censored;
