import React, { useState, useEffect } from "react";
import styled from "styled-components";
import Text from "../../../components/admin/input/Text";
import ValidateButton from "../../../components/admin/button/ValidateButton";
import { useParams, useNavigate } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import { postCarousel, updateCarousel } from "../../../lib/state/actions";
import InputFile from "../../../components/admin/input/InputFile";
import { useCarouselState } from "../../marketplace/categoryPage/controllers/stateCtrl";
import { formatDate } from "../../../lib/adminControler/notifObject";

const CarouselUpdate = ({ title }) => {
  const { banniereId } = useParams();
  const carousel = useCarouselState(useSelector);

  const [inputValues, setInputValues] = useState({
    date: formatDate(new Date()),
    link: "",
    imageURL: "",
  });

  useEffect(() => {
    if (carousel && carousel.length > 0 && banniereId) {
      const car = carousel.find((ban) => ban._id === banniereId);
      setInputValues(car);
    }
  }, [carousel, banniereId]);

  const navigate = useNavigate();

  function handleClose() {
    navigate("/admin/carousel");
  }
  const dispatch = useDispatch();

  const validate = (e) => {
    e.preventDefault();
    const myFormData = new FormData();
    myFormData.append("carousel", JSON.stringify(inputValues));

    Array.from(e.target.elements).forEach((input) => {
      let name = input.name;
      let value = input.value;
      if (name === "carouselImage" && value) {
        let file = input.files[0];
        myFormData.append("carouselImage", file);
      }
    });

    if (title === "Ajouter une bannière") {
      dispatch(postCarousel(myFormData, true, navigate));
    }
    if (title === "Modifier une bannière") {
      dispatch(updateCarousel(myFormData, inputValues._id, true, navigate));
    }
  };

  return (
    <Wrapper>
      <H2>{title} :</H2>
      <Span onClick={handleClose}>page précédente</Span>
      {inputValues && (
        <FormEl
          action="/api/carousel"
          enctype="multipart/form-data"
          onSubmit={(e) => validate(e)}
        >
          <Text
            title="date"
            type="date"
            value={inputValues?.date ?? ""}
            onchange={(e) =>
              setInputValues({ ...inputValues, date: e.target.value })
            }
          />
          <Text
            title="lien"
            type="text"
            value={inputValues?.link ?? ""}
            onchange={(e) =>
              setInputValues({ ...inputValues, link: e.target.value })
            }
          />
          <InputFile
            name="carouselImage"
            src={process.env.REACT_APP_API_HOST + inputValues.imageURL}
            alt="carousel"
          />
          <ValidateButton message={title} pad="1.5em" />
        </FormEl>
      )}
    </Wrapper>
  );
};

const Wrapper = styled.div`  
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  box-sizing: border-box;
  background-color: lightblue;
  min-height: calc(100dvh - 100px);
  z-index: 100;
  padding: 2em;
  }
`;

const H2 = styled.h2`
  margin: 20px;
  text-transform: uppercase;
  font-size: 16px;
  font-weight: bold;
`;

const Span = styled.span`
  position: absolute;
  padding: 7px;
  right: 0px;
  top: 100px;
  text-transform: uppercase;
  font-weight: bold;
  font-size: 12px;
  cursor: pointer;
`;

const FormEl = styled.form`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
`;

export default CarouselUpdate;
