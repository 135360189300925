import React from "react";
import styled from "styled-components";
import { Link, useParams } from "react-router-dom";
import { deleteVolume } from "../../../lib/state/actions";
import { useDispatch, useSelector } from "react-redux";
import { useVolumesState } from "../../marketplace/categoryPage/controllers/stateCtrl";
import { REACT_APP_API_HOST } from "../../../service";

const VolumesList = () => {
  const { productId } = useParams();

  const dispatch = useDispatch();
  const volumes = useVolumesState(useSelector);

  const DeleteButton = ({ productId, volumeId }) => {
    const deleteImgAndState = () => {
      dispatch(deleteVolume(productId, volumeId));
    };

    return (
      <Button type="button" onClick={() => deleteImgAndState()}>
        supprimer
      </Button>
    );
  };

  const vols =
    volumes &&
    volumes.length > 0 &&
    volumes.filter((vol) => vol.productId === productId);

  const allProductLikes = (vols) => {
    if (vols && vols.length > 0) {
      let likeCount = 0;
      vols.forEach((vol) => {
        likeCount += vol.likes.length;
      });
      return likeCount;
    }
  };

  return (
    <Wrapper>
      <p>Total likes: {allProductLikes(vols)}</p>
      {vols &&
        vols
          .sort((a, b) => a.volume - b.volume)
          .map((v) => (
            <Book key={v._id}>
              {<Img src={REACT_APP_API_HOST + v.imageURL} />}

              <Container>
                <p>
                  {v.volume !== 0
                    ? `volume ${v.volume}`
                    : v.readingDirection === "webtoon"
                    ? "webtoon"
                    : "one-shot"}
                </p>
                <p style={{ fontSize: "12px" }}>{v._id}</p>
              </Container>
              <Container>
                <Link
                  to={`/admin/products/updatevolumes/${productId}/${v._id}`}
                >
                  <Button type="button">modifier</Button>
                </Link>
                {v.chapters.length > 0 ? (
                  <P>Chapitres actifs.</P>
                ) : (
                  <DeleteButton productId={productId} volumeId={v._id} />
                )}
                <Link to={`/admin/products/chapters/${v.productId}/${v._id}`}>
                  <Button type="button">chapitres</Button>
                </Link>
              </Container>
            </Book>
          ))}
    </Wrapper>
  );
};

const Wrapper = styled.section`
  padding: 20px;
  width: 700px;
`;

const Book = styled.div`
  font-size: 18px;
  font-weight: bold;
  border: solid white 2px;
  border-radius: 5px;
  color: black;
  padding: 10px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  background-color: lightGrey;
  margin-top: 5px;
`;

const Img = styled.img`
  width: 50px;
`;

const Button = styled.button`
  cursor: pointer;
  border: none;
  padding: 5px 10px;
  margin-left: 10px;
  background-color: white;
  color: black;
  font-weight: bold;
`;

const Container = styled.div`
  width: 300px;
  display: flex;
  justify-content: space-around;
`;

const P = styled.p`
  font-size: 12px;
  align-self: center;
`;

export default VolumesList;
