import React from "react";
import styled from "styled-components";
import { useNavigate } from "react-router-dom";

const CookiesAdvert = ({ toggleVisible }) => {
  const navigate = useNavigate();

  return (
    <Wrapper>
      <p>
        Ce site utilise des cookies afin d'offrir une meilleure expérience. En
        vous inscrivant et en vous connectant, vous acceptez l'utilisation de
        cookies.
      </p>
      <div>
        <Button type="button" onClick={() => toggleVisible(true)}>
          J'accepte
        </Button>
        <Button type="button" onClick={() => toggleVisible(false)}>
          Je refuse
        </Button>
        <Button type="button" onClick={() => navigate("/cgu/cookies")}>
          En savoir plus
        </Button>
      </div>
    </Wrapper>
  );
};

const Wrapper = styled.div`
  font-size: 16px;
  z-index: 999;
  position: fixed;
  width: 100%;
  color: white;
  left: 0px;
  bottom: 0px;
  padding: 10px;
  background-color: ${(props) => props.theme.primary.black};
  & p {
    display: inline-block;
    margin-right: 20px;
  }
  & div {
    display: inline-block;
  }
`;

const Button = styled.button`
  cursor: pointer;
  border: none;
  color: white;
  padding: 10px;
  background-color: ${(props) => props.theme.secondary.color};
  border-radius: 5px;
  margin-right: 10px;
  margin-top: 10px;
  margin-bottom: 10px;
  &:last-child {
    margin-right: 0;
  }
`;

export default CookiesAdvert;
