import React from "react";
import styled from "styled-components";
import enlarge from "./icon/agrandir.png";
import reduce from "./icon/reduire.png";

const Open = ({ toggleOpen, open }) => {
  return (
    <Span className="segoesc" onClick={toggleOpen}>
      {open ? (
        <Img src={reduce} alt="réduire" />
      ) : (
        <Img src={enlarge} alt="agrandir" />
      )}
    </Span>
  );
};

const Span = styled.span`
  position: absolute;
  z-index: 99;
  top: 2px;
  right: 2px;
  font-size: 12px;
  font-weight: bold;
  text-transform: lowercase;
  letter-spacing: 1px;
  cursor: pointer;
  @media (max-width: 1200px) {
    display: none;
  }
`;

const Img = styled.img`
  width: 25px;
`;

export default Open;
