import React from "react";
import styled from "styled-components";

const ValidateButton = ({ message, pad, onclick }) => {
  return (
    <Wrapper
      pad={pad}
      type={onclick ? "button" : "submit"}
      onClick={onclick ? onclick : null}
    >
      {message}
    </Wrapper>
  );
};
const Wrapper = styled.button`
  border: none;
  min-width: 100px;
  padding: ${(props) => props.pad};
  font-weight: bold;
  font-size: 12px;
  background-color: black;
  color: white;
  border-radius: 5px;
  text-transform: uppercase;
  text-align: center;
  cursor: pointer;
  transition: all ease-in-out 0.2s;
  &:hover {
    background-color: darkred;
  }
`;

export default ValidateButton;
