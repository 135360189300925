import React, { useState } from "react";
import styled from "styled-components";

const QuestionEl = ({ question, response, mention }) => {
  const [open, setOpen] = useState(false);

  const toggleOpen = () => {
    setOpen(!open);
  };
  return (
    <QuestionContainer onClick={toggleOpen}>
      <Quest className="montserratSemiBold">
        <p>{question}</p>
      </Quest>
      <Resp className={open ? "open montserrat" : "montserrat"}>
        {response}
        {mention && <Mention>* {mention}</Mention>}
      </Resp>
    </QuestionContainer>
  );
};

const QuestionContainer = styled.div`
  width: 100%;
  cursor: pointer;
  color: ${(props) => props.theme.primary.black};
  margin-bottom: 20px;
  &:last-child {
    margin-bottom: 0;
  }
`;

const Quest = styled.div`
  font-size: 15px;
  letter-spacing: 0.2px;
  width: 100%;
  padding: 20px 0 20px 10px;
  background: rgba(${(props) => props.theme.primary.article}, 0.07);
  border-radius: 10px;
`;

const Resp = styled.div`
  font-size: 16px;
  letter-spacing: 0.2px;
  line-height: 22px;
  max-height: 0;
  overflow: hidden;
  transition: max-height ease-in-out 0.4s;
  margin-top: 10px;
  &.open {
    max-height: 500px;
  }
`;

const Mention = styled.p`
  font-size: 15px;
  color: ${(props) => props.theme.primary.darkColor};
`;

export default QuestionEl;
