import React from "react";
import styled from "styled-components";
import seductrice from "./image/seductrice.jpg";
import logo from "./image/logo_large.png";
import { useSelector } from "react-redux";
import { useNetworkState } from "../categoryPage/controllers/stateCtrl";

const LoadingPage = () => {
  const { startDate } = useNetworkState(useSelector);

  const returnDate = (startDate) => {
    const [y, m, d] = startDate.split(/-|\//);
    const date = new Date(y, m - 1, d);
    const startTimeStamp = date.getTime();
    const today = Date.now();
    if (startTimeStamp > today) {
      return date.toLocaleDateString("fr");
    } else {
      return null;
    }
  };

  return (
    <Wrapper>
      <Img src={seductrice} alt="fille séductrice" />
      <Img src={logo} alt="logo" />
      <P marginTop={0} className="segoesc">
        Patience... On arrive
        {returnDate(startDate) ? ` le ${returnDate(startDate)} !` : ` !`}
      </P>
    </Wrapper>
  );
};

const Wrapper = styled.div`
  width: 100%;
  min-height: calc(100dvh - 120px);
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background-color: white;
`;

const P = styled.p`
  width: 480px;
  text-align: center;
  font-size: 40px;
  margin-top: ${(props) =>
    props.marginTop ? `${props.marginTop}px` : "unset"};
  @media (max-width: 500px) {
    font-size: 25px;
    width: 300px;
  }
`;

const Img = styled.img`
  width: 480px;
  margin-bottom: 25px;
  @media (max-width: 500px) {
    width: 300px;
  }
`;

export default LoadingPage;
