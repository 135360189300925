import { createSelector } from "@reduxjs/toolkit";

export const useUserState = (useSelector) => {
  const getUserConnected = (state) => state.userConnected;
  const getUserId = (state) => state.userId;
  const getUserEmail = (state) => state.userEmail;
  const getUserDate = (state) => state.date;
  const getUserEbooks = (state) => state.userEbooks;
  const getUserGoogle = (state) => state.google;

  const userConnectedState = useSelector((state) => state.userIsConnected);
  const userConnectedSelector = createSelector(
    getUserConnected,
    getUserId,
    getUserEmail,
    getUserDate,
    getUserEbooks,
    getUserGoogle,
    (userConnected, userId, userEmail, date, userEbooks, google) => {
      if (userConnected) {
        return { userConnected, userId, userEmail, date, userEbooks, google };
      } else {
        return {
          userConnected: null,
          userId: null,
          userEbooks: [],
          userEmail: null,
          date: null,
          google: null,
        };
      }
    }
  );

  return userConnectedSelector(userConnectedState);
};

export const useNetworkState = (useSelector) => {
  const getNetworks = (state) => state.networks;

  const networkState = useSelector((state) => state.network);
  const networkSelector = createSelector(getNetworks, (networks) => {
    if (networks && networks.length > 0) {
      return {
        loadingPage: networks[0].loadingPage,
        startDate: networks[0].startDate,
        maintenance: networks[0].maintenance,
        facebook: networks[0].facebook,
        twitter: networks[0].twitter,
        _id: networks[0]._id,
      };
    } else {
      return {
        _id: null,
        loadingPage: false,
        startDate: new Date().toLocaleDateString("fr"),
        maintenance: false,
        facebook: "",
        twitter: "",
      };
    }
  });

  return networkSelector(networkState);
};

export const useFetchState = (useSelector) => {
  const getFetch = (state) => state.isFetching;
  const reducer = useSelector((state) => state.reducer);
  const isFetchingSelector = createSelector(getFetch, (isFetch) =>
    isFetch === true ? true : false
  );
  return isFetchingSelector(reducer);
};

export const useMessageState = (useSelector) => {
  const getMessage = (state) => state.message;
  const messageState = useSelector((state) => state.messages);
  const messageSelector = createSelector(
    getMessage,
    (message) => message && message.length > 0 && message
  );
  return messageSelector(messageState);
};

export const usePopupMessageState = (useSelector) => {
  const getPopupMessage = (state) => state.message;
  const popupMessageState = useSelector((state) => state.popupMessages);
  const popupMessageSelector = createSelector(
    getPopupMessage,
    (message) => message && message.length > 0 && message
  );
  return popupMessageSelector(popupMessageState);
};

export const useAdminState = (useSelector) => {
  const getAdminConnected = (state) => state.adminConnected;
  const adminConnectedState = useSelector((state) => state.adminIsConnected);
  const adminSelector = createSelector(getAdminConnected, (adminConnected) =>
    adminConnected !== null && typeof adminConnected === "string"
      ? adminConnected
      : null
  );
  return adminSelector(adminConnectedState);
};

export const useSuperAdminState = (useSelector) => {
  const getSuperAdmin = (state) => state.superAdmin;
  const superAdminState = useSelector((state) => state.adminIsConnected);
  const superAdminSelector = createSelector(getSuperAdmin, (superAdmin) =>
    superAdmin ? true : false
  );
  return superAdminSelector(superAdminState);
};

export const useProductsState = (useSelector) => {
  const getProducts = (state) => state.products;
  const productsState = useSelector((state) => state.allProducts);
  const productsSelector = createSelector(
    getProducts,
    (products) => products.length > 0 && products
  );
  return productsSelector(productsState);
};

export const useCategoryState = (useSelector) => {
  const getCategories = (state) => state.category;
  const categoryState = useSelector((state) => state.allCategories);
  const categorySelector = createSelector(
    getCategories,
    (category) => category.length > 0 && category
  );
  return categorySelector(categoryState);
};

export const useTvaState = (useSelector) => {
  const getTva = (state) => state.tva;
  const tvaState = useSelector((state) => state.tvas);
  const tvaSelector = createSelector(getTva, (tva) => tva.length > 0 && tva);
  return tvaSelector(tvaState);
};

export const useKeysState = (useSelector) => {
  const getkey = (state) => state.key;
  const keysState = useSelector((state) => state.keys);
  const keysSelector = createSelector(getkey, (key) => key.length > 0 && key);
  return keysSelector(keysState);
};

export const useCensored = (useSelector) => {
  const getCensored = (state) => state.isCensored;
  const isCensoredState = useSelector((state) => state.censorship);
  const censoredSelector = createSelector(getCensored, (isCensored) =>
    isCensored ? true : false
  );
  return censoredSelector(isCensoredState);
};

export const useCartState = (useSelector) => {
  const getCart = (state) => state;
  const cartState = useSelector((state) => state.cart);
  const cartSelector = createSelector(getCart, (cart) =>
    cart.length > 0 ? cart : []
  );
  return cartSelector(cartState);
};

export const useNotifState = (useSelector) => {
  const getNotifs = (state) => state.notifications;
  const allNotifState = useSelector((state) => state.allNotifications);
  const allNotifSelector = createSelector(getNotifs, (notifications) => {
    if (notifications.length > 0) {
      return notifications;
    }
  });

  return allNotifSelector(allNotifState);
};

export const useCarouselState = (useSelector) => {
  const getBanniere = (state) => state.bannieres;
  const carouselState = useSelector((state) => state.carousel);
  const carouselSelector = createSelector(getBanniere, (carousel) => {
    if (carousel.length > 0) {
      return carousel;
    }
  });

  return carouselSelector(carouselState);
};

export const useVolumesState = (useSelector) => {
  const getVolumes = (state) => state.volumes;
  const volumesState = useSelector((state) => state.allVolumes);
  const volumesSelector = createSelector(
    getVolumes,
    (volumes) => volumes.length > 0 && volumes
  );
  return volumesSelector(volumesState);
};

export const useAdminsState = (useSelector) => {
  const getAdmins = (state) => state.admin;
  const adminsState = useSelector((state) => state.allAdmins);
  const adminsSelector = createSelector(
    getAdmins,
    (admin) => admin && admin.length > 0 && admin
  );
  return adminsSelector(adminsState);
};

export const useUsersState = (useSelector) => {
  const getUsers = (state) => state.users;
  const usersState = useSelector((state) => state.allUsers);
  const usersSelector = createSelector(
    getUsers,
    (users) => users && users.length > 0 && users
  );
  return usersSelector(usersState);
};
