import { actions } from "../actions/index";

const { FETCH_CENSORSHIP } = actions;

const initialState = {
  isCensored: true,
};

export function censorship(state = initialState, action) {
  switch (action.type) {
    case FETCH_CENSORSHIP:
      return {
        ...state,
        isCensored: action.payload,
      };
    default:
      return state;
  }
}
