import React from "react";
import WrapperContainer from "../../../components/marketplace/WrapperContainer";
import styled from "styled-components";
import { Helmet } from "react-helmet-async";

const About = () => {
  return (
    <>
      <Helmet>
        <title>Qui sommes nous?</title>
        <meta
          name="description"
          content={`Présentation de nos éditions et de MangaeBook.`}
        />
        <meta name="title" content={`Qui sommes nous?`} />
        <meta
          name="prerender-header"
          content="Location: http://www.mangaebook.com/about"
        ></meta>
        <meta id="og-title" property="og:title" content={`Qui sommes nous?`} />
        <meta
          property="og:description"
          content={`Présentation de nos éditions et de MangaeBook.`}
        />
        <meta property="og:url" content="https://mangaebook.com/about" />
        <meta
          id="og-image"
          property="og:image"
          content="http://mangaebook.com/og-img.jpg"
        />
        <meta
          property="og:image:secure_url"
          content="https://mangaebook.com/og-img.jpg"
        />
        <meta property="og:image:type" content="image/jpeg" />
        <meta property="og:image:width" content="1200" />
        <meta property="og:image:height" content="630" />
      </Helmet>
      <H1 className="segoesc">
        <a href="/">MangaeBook /</a>À propos
      </H1>
      <WrapperContainer>En travaux...</WrapperContainer>
    </>
  );
};

const H1 = styled.h1`
  position: absolute;
  top: 70px;
  left: 30px;
  font-size: 14px;
  @media (max-width: 1150px) {
    left: 10px;
  }
`;

export default About;
