import { actions } from "../actions/index";

const { FETCH_MESSAGE } = actions;

const initialState = {
  message: "", //uniquement message pour admin car à chaques messages le state est chargé de nouveau
};

export function messages(state = initialState, action) {
  switch (action.type) {
    case FETCH_MESSAGE:
      return {
        ...state,
        message: action.payload.message,
      };
    default:
      return state;
  }
}
