import React, { useState } from "react";
import styled from "styled-components";
import { addToCategory } from "../../../lib/state/actions";
import { useDispatch } from "react-redux";
import Text from "./Text";

const AddFieldToCategory = ({ name, title, categoryId, arrayToUpdate }) => {
  const [field, setField] = useState("");
  const dispatch = useDispatch();

  const body = JSON.stringify({ categoryId, arrayToUpdate, field });

  const validate = () => {
    field && categoryId && dispatch(addToCategory(body));
    setField("");
  };

  return (
    <Wrapper>
      <Button
        type="button"
        onClick={validate}
        style={{ alignSelf: "flex-start", marginBottom: "10px" }}
      >
        {title}
      </Button>
      <Text
        type="text"
        name={name}
        onchange={(e) => setField(e.target.value)}
        title={`Ces données seront ajoutées dans la catégorie correspondante`}
        value={field}
      />
    </Wrapper>
  );
};

const Wrapper = styled.div`
  width: 60%;
  display: flex;
  align-items: center;
  align-self: start;
  height: 20px;
  margin-bottom: 30px;
`;

const Button = styled.button`
  width: 300px;
  margin: 3px 20px 12px 0;
`;

export default AddFieldToCategory;
