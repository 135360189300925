import React, { useState } from "react";
import styled from "styled-components";
import WrapperContainer from "../../../components/marketplace/WrapperContainer";
import { useDispatch } from "react-redux";
import { fetchPopupMessage } from "../../../lib/state/actions";
import { regex } from "../../../lib/adminControler/validateUser/validate";
import { postContactMessage } from "../../../lib/state/actions";
import ContactVerif from "../../../components/marketplace/contact/ContactVerif";
import { Helmet } from "react-helmet-async";

const Contact = () => {
  const formObject = {
    email: "",
    reason: "",
    message: "",
  };

  const [inputValues, setInputValues] = useState(formObject);

  const [verif, setVerif] = useState(false);

  const dispatch = useDispatch();

  const toggleVerif = () => {
    setVerif(!verif);
  };

  const validate = (form) => {
    if (!form.email || !form.reason || !form.message) {
      dispatch(fetchPopupMessage("Tous les champs ne sont pas complétés !"));
    } else {
      if (form.message && form.message.length < 100) {
        dispatch(fetchPopupMessage("Le message est trop court !"));
      }
      if (form.message && form.message.length > 1000) {
        dispatch(fetchPopupMessage("Le message est trop long !"));
      }
      if (form.email && !form.email.match(regex)) {
        dispatch(fetchPopupMessage("Format de l'email non valide !"));
      }
      if (
        form.message &&
        form.message.length >= 100 &&
        form.message.length <= 1000 &&
        form.email &&
        form.email.match(regex) &&
        form.reason
      ) {
        setVerif(true);
      }
    }
  };

  const cleanForm = () => setInputValues(formObject);

  const sendMessage = () => {
    const body = JSON.stringify(inputValues);
    dispatch(postContactMessage(body));
    cleanForm();
    toggleVerif();
  };

  return (
    <>
      <Helmet>
        <title>Nous répondons à vos questions.</title>
        <meta name="description" content={`Formulaire de contact.`} />
        <meta name="title" content={`Nous répondons à vos questions.`} />
        <meta
          name="prerender-header"
          content="Location: http://www.mangaebook.com/contact"
        ></meta>
      </Helmet>
      <H1 className="segoesc">
        <a href="/">MangaeBook /</a>Nous contactez
      </H1>
      <WrapperContainer>
        <Form action="" method="post">
          <Container>
            <Label htmlFor="email">Votre email :</Label>
            <Input
              type="email"
              name="email"
              placeholder="adresse@gmail.com"
              value={inputValues.email}
              onChange={(e) =>
                setInputValues({ ...inputValues, email: e.target.value })
              }
            />
          </Container>
          <Container>
            <Label htmlFor="reason">Motif :</Label>
            <Input
              type="text"
              name="reason"
              value={inputValues.reason}
              placeholder="Raison pour laquelle vous nous écrivez ce mail."
              onChange={(e) =>
                setInputValues({ ...inputValues, reason: e.target.value })
              }
            />
          </Container>
          <Container>
            <Label htmlFor="message">Message :</Label>
            <Textarea
              placeholder="Message..."
              value={inputValues.message}
              name="message"
              rows="10"
              onChange={(e) =>
                setInputValues({ ...inputValues, message: e.target.value })
              }
            />
          </Container>
          <Button
            className="montserratSemiBold"
            type="button"
            onClick={() => validate(inputValues)}
          >
            Envoyer
          </Button>
        </Form>
      </WrapperContainer>
      {verif && (
        <ContactVerif toggleVerif={toggleVerif} sendMessage={sendMessage} />
      )}
    </>
  );
};

const H1 = styled.h1`
  position: absolute;
  top: 70px;
  left: 30px;
  font-size: 14px;
  @media (max-width: 1150px) {
    left: 10px;
  }
`;

const Form = styled.form`
  width: 100%;
  display: flex;
  flex-direction: column;
  margin: 20px 0;
`;

const Container = styled.div`
  box-sizing: border-box;
  width: 100%;
  display: flex;
  flex-direction: column;
  margin-bottom: 20px;
`;

const Textarea = styled.textarea`
  &:focus {
    border: solid ${(props) => props.theme.primary.lightColor} 1px;
  }
  border-radius: 2px;
  outline: none;
  color: ${(props) => props.theme.primary.black};
  padding: 8px;
  font-size: 16px;
  border: solid ${(props) => props.theme.primary.darkColor} 1px;
  resize: none;
`;

const Label = styled.label`
  margin-bottom: 5px;
  color: ${(props) => props.theme.primary.black};
`;

const Input = styled.input`
  &:focus {
    border: solid ${(props) => props.theme.primary.lightColor} 1px;
  }
  outline: none;
  border-radius: 2px;
  color: ${(props) => props.theme.primary.black};
  padding: 8px;
  font-size: 16px;
  border: solid ${(props) => props.theme.primary.darkColor} 1px;
`;

const Button = styled.button`
  margin: 0 auto;
  padding: 10px;
  width: 160px;
  font-size: 15px;
  border: none;
  color: white;
  background-color: ${(props) => props.theme.primary.darkColor};
  cursor: pointer;
`;

export default Contact;
