import React from "react";
import styled from "styled-components";
import {
  cutString,
  firstToUpperCase,
} from "../../../profil/controllers/shortTitleCtrl";
import LittleFooter from "./LittleFooter";
import {
  calculLikes,
  isOneShot,
} from "../../../../../views/marketplace/categoryPage/controllers/algoCtrl";
import { swithColors } from "../../../../../views/marketplace/categoryPage/controllers/themeCtrl";
import theme from "../../../../../theme";
import { Link } from "react-router-dom";
import { useSelector } from "react-redux";
import censoredImage from "../../shopCard/image/18.png";
import { useCensored } from "../../../../../views/marketplace/categoryPage/controllers/stateCtrl";
import { REACT_APP_API_HOST } from "../../../../../service";
import { allReplace } from "../../../reader/lib/readerCtrl";

const Exclu = ({ book }) => {
  const isCensored = useCensored(useSelector);

  return (
    <>
      {book ? (
        <Wrapper>
          <Link to={`/item/${allReplace(book.frenchTitle)}/${book._id}`}>
            <LinkContainer>
              <ImgContainer iscensored={isCensored.toString()}>
                {!isCensored && (
                  <Image src={REACT_APP_API_HOST + book.imageURL} />
                )}
              </ImgContainer>
              <Container>
                <Info>
                  <Title className="montserratSemiBold">
                    {firstToUpperCase(cutString(book.frenchTitle, 39))}
                  </Title>

                  <Tome
                    className="segoesc"
                    color={swithColors(book.category, theme)}
                  >
                    {isOneShot(book)
                      ? "one-shot"
                      : book.ended
                      ? "série termniée"
                      : "série en cours"}
                  </Tome>
                </Info>
                <Info>
                  <Who className="montserratSemiBold">auteur</Who>
                  <Name className="segoesc">
                    {firstToUpperCase(cutString(book.author[0], 23))}
                  </Name>
                </Info>
                <Info>
                  <Who className="montserratSemiBold">illustrateur</Who>
                  <Name className="segoesc">
                    {firstToUpperCase(cutString(book.illustrator, 23))}
                  </Name>
                </Info>
                <Info>
                  <Who className="montserratSemiBold">édition française</Who>
                  <Name className="segoesc">
                    {firstToUpperCase(cutString(book.originalPublisher, 23))}
                  </Name>
                </Info>
                <LittleFooter
                  category={book.category}
                  likes={calculLikes(book)}
                />
              </Container>
            </LinkContainer>
          </Link>
        </Wrapper>
      ) : null}
    </>
  );
};

const Wrapper = styled.div`
  display: flex;
  justify-content: center;
`;

const LinkContainer = styled.div`
  position: relative;
  width: 420px;
  border-radius: 20px;
  box-shadow: 0px 3px 5px rgba(0, 0, 0, 0.15);
  display: flex;
  overflow: hidden;
  cursor: pointer;
  @media (max-width: 2300px) {
    flex-direction: column;
    width: 210px;
  }
  transition: transform 0.2s ease-in-out;
`;

const Image = styled.img`
  width: 100%;
`;

const ImgContainer = styled.div`
  width: 210px;
  height: 293px;
  border-radius: 20px 0 0 20px;
  overflow: hidden;
  background: ${(props) =>
    props.iscensored === "true"
      ? `center no-repeat white url(${censoredImage})`
      : "unset"};
  background-size: ${(props) =>
    props.iscensored === "true" ? `70%` : "unset"};
  @media (max-width: 2300px) {
    width: 210px;
    height: 293px;
    border-radius: 20px 20px 0 0;
  }
`;

const Container = styled.article`
  box-sizing: border-box;
  width: 210px;
  border-radius: 0 20px 20px 0;
  padding: 8px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  background-color: white;
  @media (max-width: 2300px) {
    border-radius: 0 0 20px 20px;
    height: 250px;
  }
`;

const Title = styled.h3`
  font-size: 16px;
  word-break: break-word;
  color: ${(props) => props.theme.primary.black};
  @media (max-width: 400px) {
    font-size: 15px;
  }
`;

const Tome = styled.span`
  text-transform: uppercase;
  margin-top: 0px;
  font-size: 14px;
  color: ${(props) => props.color};
`;

const Info = styled.div`
  min-height: 36px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  text-align: center;
  @media (max-width: 400px) {
    min-height: 35px;
  }
`;

const Who = styled.p`
  text-transform: uppercase;
  font-size: 13px;
  word-break: break-word;
  color: ${(props) => props.theme.primary.offBlack};
  @media (max-width: 400px) {
    font-size: 13px;
  }
`;

const Name = styled.p`
  font-size: 14px;
  word-break: break-word;
  color: ${(props) => props.theme.primary.black};
  @media (max-width: 400px) {
    font-size: 13px;
  }
`;

export default Exclu;
