import React from "react";
import styled from "styled-components";

const InputFile = ({ title, name, src, alt, multiple }) => {
  return (
    <>
      <Container>
        <Label>{title}</Label>
        <InputField type="file" id={name} name={name} multiple={multiple} />
        <ImageContainer>
          <Image src={src ? src : null} alt={alt} />
        </ImageContainer>
      </Container>
    </>
  );
};
const Label = styled.label`
  font-weight: bold;
  font-size: 12px;
`;

const InputField = styled.input`
  color: blue;
  font-size: 15px;
  margin-top: 3px;
  margin-bottom: 1em;
`;

const Container = styled.div`
  display: flex;
  width: 300px;
  flex-direction: column;
  padding: 1em;
  border: solid 1px lightGrey;
  background-color: white;
  margin: 0 20px 20px 0px;
  align-self: start;
`;

const ImageContainer = styled.div`
  width: 150px;
`;

const Image = styled.img`
  width: 100%;
`;

export default InputFile;
