import React, { useEffect, useState } from "react";
import styled, { keyframes } from "styled-components";
import Mouse from "./picto/Mouse";
import Keyboard from "./picto/Keyboard";
import Touch from "./picto/Touch";

const Tuto = ({ landscape }) => {
  const [tuto, setTuto] = useState(true);

  //showtuto at begin
  useEffect(() => {
    const timer = setTimeout(() => {
      setTuto(false);
    }, 2800);
    return () => {
      clearTimeout(timer);
    };
  }, []);

  return (
    <Wrapper tuto={tuto}>
      <Left landscape={landscape}>
        <Touch />
      </Left>
      <Middle>
        <Container>
          <Points landscape={landscape} />
          <Div>
            <Keyboard landscape={landscape} />
          </Div>
          <Points landscape={landscape} />
          {landscape === false && (
            <Div className="touch">
              <Touch />
            </Div>
          )}
          <Div>
            <Mouse />
          </Div>
          <Points landscape={landscape} />
        </Container>
      </Middle>
      <Right landscape={landscape}>
        <Touch />
      </Right>
    </Wrapper>
  );
};

const Wrapper = styled.div`
  opacity: ${(props) => (props.tuto ? "1" : "0")};
  position: fixed;
  display: flex;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.8);
  z-index: 99;
  transform: translateY(${(props) => (props.tuto ? "0" : "100%")});
  transition: all 0.3s ease-in-out;
  touch-action: none;
`;

const touch = keyframes`
0%{transform: translatey(-20%);}
50%{transform: translatey(20%);}
100%{transform: translatey(-20%);}
`;

const touchX = keyframes`
0%{transform: translatex(-20%);}
50%{transform: translatex(20%);}
100%{transform: translatex(-20%);}
`;

const Left = styled.div`
  display: ${(props) => (props.landscape ? "flex" : "none")};
  width: 50%;
  height: 100%;
  align-items: center;
  justify-content: center;
  animation: ${touchX} 3s ease-in-out infinite;
  @media (max-width: 1200px) {
    width: 100%;
  }
`;

const Right = styled.div`
  display: ${(props) => (props.landscape ? "flex" : "none")};
  width: 50%;
  height: 100%;
  align-items: center;
  justify-content: center;
  animation: ${touchX} 3s ease-in-out infinite;
  @media (max-width: 1200px) {
    display: none;
  }
`;

const Middle = styled.div`
  position: absolute;
  top: 0;
  left: 50%;
  height: 100%;
  transform: translate(-50%);
`;

const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  height: 100%;
`;

const Points = styled.div`
  display: ${(props) => (props.landscape ? "inline-block" : "none")};
  flex-grow: 5;
  text-align: center;
  background-color: white;
  width: 2px;
`;

const Div = styled.div`
  flex-grow: 1;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 10%;
  @media (max-width: 1200px) {
    display: none;
  }
  &.touch {
    animation: ${touch} 3s ease-in-out infinite;
    @media (max-width: 1200px) {
      display: flex;
    }
  }
`;

export default Tuto;
