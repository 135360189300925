import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import styled from "styled-components";
import NavBar from "./navBar";
import { Routes, Route, useLocation } from "react-router-dom";
import Home from "./Home";
import Library from "./library/index";
import NotFound from "./pageNotFound/NotFound";
import Cart from "./cart";
import Profil from "./profil";
import ConnectForm from "../../components/marketplace/connectForm/index";
import Notifs from "./notifs";
import { fetchMessage, getUsersCategory } from "../../lib/state/actions";
import CategoryPage from "./categoryPage";
import ProductSheet from "./productSheet/index";
import CatPop from "../../components/marketplace/navbar/CatPop";
import Search from "./search";
import Popup from "../../components/marketplace/reader/popup/Popup";
import Censored from "./censored";
import { loadCensorship } from "../../lib/state/actions";
import {
  useUserState,
  useCensored,
  useNotifState,
} from "./categoryPage/controllers/stateCtrl";
import Footer from "../../components/marketplace/footer/Footer";
import About from "./about";
import Cgu from "./cgu";
import Faq from "./faq";
import Contact from "./contact";
import CookiesAdvert from "../../components/marketplace/reader/popup/CookiesAdvert";

const Marketplace = () => {
  const [connectForm, setConnectForm] = useState(false);
  const [notifs, setNotifs] = useState(false); //open close notif
  const [universMenu, setUniversMenu] = useState(false); //category menu
  const [list, setList] = useState(false); //open close burger menu
  const [censoredPopup, setCensoredPopup] = useState(true); //open/close 18+ popup
  const [visible, setVisible] = useState(true); // cookies

  const { userConnected } = useUserState(useSelector);
  let location = useLocation();

  const notifications = useNotifState(useSelector);

  const isCensored = useCensored(useSelector);

  const dispatch = useDispatch();

  //to load categories for user or admin
  useEffect(() => {
    dispatch(getUsersCategory());
  }, [dispatch]);

  const closeOnclickOutside = () => {
    //close category menu
    if (universMenu) {
      setUniversMenu(false);
    }
    //close burger
    if (list) {
      setList(false);
    }
    //close notifs
    if (notifs) {
      setNotifs(false);
    }
  };

  const toggleUniversMenu = () => setUniversMenu(!universMenu);

  const toggleCensoredPopup = () => {
    setCensoredPopup(!censoredPopup);
  };

  const displayCenseoredPopup = () => {
    setCensoredPopup(true);
  };

  const toggleList = () => {
    setList(!list);
    setUniversMenu(!universMenu);
  };

  const toggleConnectForm = () => {
    setConnectForm(!connectForm);
  };

  //display notifs
  const toggleNotifs = () => {
    if (notifications && notifications.length > 0) {
      setNotifs(!notifs);
    } else {
      dispatch(fetchMessage({ message: "Pas de nouvelle notification !" }));
    }
  };

  //cookies
  const toggleVisible = (response) => {
    const oneDay = 24 * 60 * 60;
    document.cookie = `cookies=${response};max-age=${oneDay}`;
    setVisible(false);
  };

  //close catPop
  const closeUniversMenu = () => {
    setList(false);
    setUniversMenu(false);
  };

  //close menu on change url
  useEffect(() => {
    setList(false);
    closeOnclickOutside();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location]);

  //cookie advert
  useEffect(() => {
    const cookieValue = document.cookie
      .split("; ")
      .find((row) => row.startsWith("cookies="))
      ?.split("=")[1];
    if (cookieValue) {
      setVisible(false);
    }
  }, []);

  //save 18+ state if user connected
  useEffect(() => {
    if (userConnected && isCensored) {
      dispatch(loadCensorship(false));
    }
  }, [userConnected, isCensored, dispatch]);

  return (
    <>
      {censoredPopup && isCensored && (
        <Popup
          visible={censoredPopup.toString()}
          message="Êtes-vous majeur ?"
          togglePopup={() => toggleCensoredPopup()}
          censored={true.toString()}
        />
      )}
      {
        <NavBar
          list={list}
          toggleList={toggleList}
          userConnected={userConnected}
          toggleConnectForm={toggleConnectForm}
          toggleNotifs={toggleNotifs}
          toggleUniversMenu={toggleUniversMenu}
          displayCenseoredPopup={displayCenseoredPopup}
        />
      }

      <CatPop universMenu={universMenu} closeUniversMenu={closeUniversMenu} />

      {
        <Main className="App">
          {/*to quit all menus*/}
          {(universMenu || list || notifs) && (
            <BlockToQuitMenus onClick={closeOnclickOutside} />
          )}

          {connectForm && !userConnected && !isCensored && (
            <ConnectForm toggleConnectForm={toggleConnectForm} />
          )}

          {
            <Routes>
              <Route path="/" element={<Home />} />
              <Route path="/search" element={<Search />} />
              <Route path="/category/:categorie" element={<CategoryPage />} />
              <Route
                path="/category/:categorie/tag/:tag"
                element={<CategoryPage />}
              />
              <Route
                path="/item/:frenchtitle/:id/"
                element={<ProductSheet />}
              />
              <Route
                path="/item/:frenchtitle/:id/:chapter"
                element={isCensored ? <Censored /> : <ProductSheet />}
              />
              <Route
                path="/library/*"
                element={userConnected ? <Library /> : <Home />}
              />
              <Route
                path="/cart"
                element={userConnected ? <Cart /> : <Home />}
              />
              <Route
                path="/profil"
                element={userConnected ? <Profil /> : <Home />}
              />
              <Route
                path="/item/:frenchtitle/:id/:chapter/*"
                element={<NotFound />}
              />
              <Route path="/about" element={<About />} />
              <Route path="/faq" element={<Faq />} />
              <Route path="/cgu" element={<Cgu />} />
              <Route path="/cgu/cookies" element={<Cgu />} />
              <Route path="/contact" element={<Contact />} />
              <Route path="*" element={<NotFound />} />
            </Routes>
          }
          {visible && !userConnected && (
            <CookiesAdvert toggleVisible={toggleVisible} />
          )}
        </Main>
      }
      <Footer />
      {userConnected && <Notifs hide={notifs} />}
    </>
  );
};

const Main = styled.main`
  padding-top: 60px;
  padding-bottom: 60px;
  background-color: rgba(120, 0, 0, 0.02);
  box-sizing: border-box;
  width: 100%;
  min-height: calc(100dvh - 60px);
  position: relative;
`;

const BlockToQuitMenus = styled.div`
  position: absolute;
  z-index: 10;
  width: 100%;
  height: 100%;
`;

export default Marketplace;
