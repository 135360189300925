import React from "react";
import styled from "styled-components";

const Sticker = ({ title, center, marginTop, marginBottom }) => {
  return (
    <Wrapper
      className="montseratSemiBold"
      margintop={marginTop}
      marginbottom={marginBottom}
      center={center}
    >
      <Container center={center}>
        <h2>{title}</h2>
      </Container>
    </Wrapper>
  );
};

const Wrapper = styled.div`
  box-sizing: border-box;
  display: inline-block;
  width: 100%;
  margin-top: ${(props) => props.margintop}px;
  margin-bottom: ${(props) => props.marginbottom}px;
  padding: 0.8em 0em;
  background-color: white;
  text-transform: uppercase;
  font-weight: bold;
  font-size: 16px;
  letter-spacing: 0.5px;
  color: ${(props) => props.theme.primary.black};
  @media (max-width: 1200px) {
    padding-left: ${(props) => (props.center ? "none" : "10px")};
  }
`;

const Container = styled.div`
  width: ${(props) => (props.center ? "auto" : "100%")};
  margin: 0 auto;
  text-align: ${(props) => (props.center ? "center" : "unset")};
`;

export default Sticker;
