import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import styled from "styled-components";
import { useNavigate } from "react-router-dom";
import { productObject } from "../../../lib/adminControler/productObject";
import { validateProduct } from "../../../lib/adminControler/validateProduct/validate";
import ValidateButton from "../../../components/admin/button/ValidateButton";
import Form from "./Form";
import { useProductsState } from "../../marketplace/categoryPage/controllers/stateCtrl";

const ProductUpdate = ({ title }) => {
  const products = useProductsState(useSelector);

  const { productId } = useParams();
  const dispatch = useDispatch();
  const navigate = useNavigate();

  //selectedProduct empty if create new product, else product_id param
  const [selectedProduct, setSelectedProduct] = useState({ ...productObject });

  useEffect(() => {
    if (productId && products && products.length > 0) {
      const state = products.find((product) => product._id === productId);
      if (state) {
        setSelectedProduct({ ...state });
      }
    } else {
      setSelectedProduct({ ...productObject });
    }
  }, [products, productId]);

  const addRandomLike = () => {
    let randomId =
      Math.floor(Math.random() * (999999999999999 - 111111111111111)) +
      111111111111111;
    setSelectedProduct({
      ...selectedProduct,
      likes: [...selectedProduct.likes, randomId],
    });
  };

  const setNewLastModifyDate = () => {
    setSelectedProduct({ ...selectedProduct, lastModify: Date.now() });
  };

  const onChangeProperty = (e) => {
    let type = e.target.type;
    let name = e.target.name;

    if (type === "radio") {
      let bool = e.target.value === "true" ? true : false;
      setSelectedProduct({ ...selectedProduct, [name]: bool });
    }
    if (type === "number" || name === "price") {
      setSelectedProduct({
        ...selectedProduct,
        [name]: Number(e.target.value),
      });
    }
    if (name === "tags" || name === "author" || name === "chapters") {
      if (!selectedProduct[name].find((val) => val === e.target.value)) {
        setSelectedProduct({
          ...selectedProduct,
          [name]: [...selectedProduct[name], e.target.value],
        });
      }
    }

    if (
      type !== "radio" &&
      type !== "number" &&
      name !== "price" &&
      name !== "tags" &&
      name !== "chapters" &&
      name !== "author"
    ) {
      setSelectedProduct({ ...selectedProduct, [name]: e.target.value });
    }
  };

  const deleteFromList = (item, string) => {
    setSelectedProduct({
      ...selectedProduct,
      [string]: [...selectedProduct[string].filter((el) => el !== item)],
    });
  };

  return (
    <Wrapper>
      <H1>{title}</H1>
      {
        <FormEl
          action="/api/products"
          enctype="multipart/form-data"
          method="post"
          onSubmit={(e) =>
            validateProduct(title, e, selectedProduct, navigate, dispatch)
          }
        >
          <Form
            selectedProduct={selectedProduct}
            onChangeProperty={onChangeProperty}
            deleteFromList={deleteFromList}
            addRandomLike={addRandomLike}
            setNewLastModifyDate={setNewLastModifyDate}
          />

          <ValidateButton
            message={
              title !== "modifier un titre"
                ? title
                : "valider les modifications"
            }
            pad="1.5em"
          />
        </FormEl>
      }
    </Wrapper>
  );
};

const Wrapper = styled.div`  
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  box-sizing: border-box;
  background-color: lightblue;
  
  min-height: 100%;
  z-index: 100;
  padding: 2em;
  }
`;

const FormEl = styled.form`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
`;

const H1 = styled.h1`
  margin: 20px;
  text-transform: uppercase;
  font-size: 16px;
  font-weight: bold;
`;

export default ProductUpdate;
