import React from "react";
import styled from "styled-components";
import NotifCard from "../../../components/marketplace/notifs/NotifCard";
import { useSelector } from "react-redux";
import {
  useNotifState,
  useUserState,
} from "../categoryPage/controllers/stateCtrl";

const Notifs = ({ hide }) => {
  const notifications = useNotifState(useSelector);
  const { userId, userConnected } = useUserState(useSelector);

  return (
    <>
      {notifications && notifications.length > 0 && userId ? (
        <Wrapper hide={hide}>
          {notifications?.map((notif) => (
            <NotifCard
              key={notif._id}
              title={notif.title}
              message={notif.message}
              image={notif.imageURL}
              link={notif.link}
              id={notif._id}
              notifDate={notif.date}
              userId={userId}
              pseudo={userConnected}
            />
          ))}
        </Wrapper>
      ) : null}
    </>
  );
};

const Wrapper = styled.aside`
  max-width: 500px;
  position: fixed;
  right: ${(props) => (props.hide ? "0" : "-500px")};
  bottom: 0;
  transition: right 0.2s ease-in-out;
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  z-index: 99;
`;
export default Notifs;
