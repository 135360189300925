import { actions } from "../actions/index";
import { localstorageCtrl } from "../localStorage/localStorageCtrl";

const { FETCH_ADMINCONNECTED, FETCH_ADMINDISCONNECTED } = actions;

const initialState = {
  adminConnected: null,
};

export function adminIsConnected(state = initialState, action) {
  switch (action.type) {
    case FETCH_ADMINCONNECTED:
      localstorageCtrl("adminLogin", action.payload.token);
      return {
        ...state,
        adminConnected: action.payload.adminPseudo,
        superAdmin: action.payload.superAdmin,
      };
    case FETCH_ADMINDISCONNECTED:
      localstorageCtrl("adminLogout");
      return {
        ...state,
        adminConnected: null,
      };
    default:
      return state;
  }
}
