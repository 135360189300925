import React from "react";
import styled from "styled-components";

const Title = ({ title }) => {
  return <H3 className="segoesc">{title}</H3>;
};

const H3 = styled.h3`
  position: absolute;
  top: -5px;
  left: 0px;
  font-size: 15px;
  font-weight: bold;
  text-transform: uppercase;
  transform: rotate(-4deg);
  letter-spacing: 1px;
  @media (max-width: 1200px) {
    left: 10px;
    top: -10px;
  }
`;

export default Title;
