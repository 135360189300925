import React from "react";
import styled from "styled-components";
import { useNavigate } from "react-router-dom";

const ButtonContainer = ({ link, message, newTab }) => {
  const navigate = useNavigate();
  const handleClick = () => {
    if (newTab) {
      window.open(link);
    } else {
      navigate(link);
    }
  };

  return (
    <Wrapper>
      {link && (
        <Button className="segoesc" type="button" onClick={handleClick}>
          {message}
        </Button>
      )}
    </Wrapper>
  );
};

const Wrapper = styled.div`
  width: 100%;
  margin-top: 20px;
`;

const Button = styled.button`
  cursor: pointer;
  display: block;
  color: ${(props) => props.theme.primary.black};
  background-color: white;
  border: solid 2px ${(props) => props.theme.secondary.color};
  margin: 0 auto;
  padding: 5px 10px;
  text-transform: uppercase;
  font-size: 12px;
  font-weight: bold;
  transition: all 0.3s ease-in-out;
  &:hover {
    border: solid 2px ${(props) => props.theme.secondary.color};
    color: white;
    background-color: ${(props) => props.theme.secondary.color};
  }
  @media (max-width: 840px) {
    display: block;
    margin-bottom: 20px;
    &:last-child {
      margin-bottom: 0px;
    }
  }
`;

export default ButtonContainer;
