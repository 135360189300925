import React from "react";
import styled from "styled-components";
import Glass from "../../../components/marketplace/navbar/picto/Glass";
import CloseButton from "../connectForm/CloseButton";

const SearchBar = ({ onchange, search }) => {
  return (
    <Wrapper>
      <Container>
        <Glass />
        <CloseButton close={() => onchange("")} />
        <Input
          type="text"
          placeholder="Titre, auteur ..."
          onChange={(e) => onchange(e.target.value)}
          value={search}
        />
      </Container>
    </Wrapper>
  );
};

const Wrapper = styled.div`
  width: 100%;
  text-align: center;
`;

const Container = styled.div`
  margin-top: 55px;
  position: relative;
  display: inline-block;
`;

const Input = styled.input`
  position: relative;
  box-shadow: 3px 3px 5px rgba(0, 0, 0, 0.3);
  box-sizing: border-box;
  border: none;
  border: solid 3px ${(props) => props.theme.primary.lightColor};
  border-radius: 5px;
  height: 40px;
  width: 280px;
  font-size: 17px;
  letter-spacing: 0.5px;
  text-align: center;
  line-height: 17px;
  transition: all 0.2s ease-in-out;
  color: ${(props) => props.theme.primary.black};
  background-color: white;
  &:focus {
    transform: scale(1.1);
    outline: none;
    z-index: 3;
    border: solid 3px ${(props) => props.theme.secondary.color};
    &::placeholder {
      opacity: 0;
    }
  }
  &::placeholder {
    text-align: center;
    font-size: 17px;
    line-height: 17px;
    color: ${(props) => props.theme.primary.black};
    opacity: 0.5;
  }
  &:-webkit-autofill {
    -webkit-box-shadow: 0 0 0px 1000px white inset;
  }
  @media (max-width: 600px) {
    width: 250px;
    height: 50px;
    font-size: 16px;
    &::placeholder {
      font-size: 13px;
      line-height: 13px;
    }
  }
`;

export default SearchBar;
