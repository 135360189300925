import React, { useEffect, useState } from "react";
import styled from "styled-components";
import Slider from "../../../components/marketplace/library/Slider/Slider";
import { useSelector } from "react-redux";
import {
  sortByVolume,
  sortByOrder,
  sortByReleaseDate,
} from "../categoryPage/controllers/algoCtrl";
import { useProductsState } from "../categoryPage/controllers/stateCtrl";

const AllVolumes = ({ product, productId }) => {
  const [SameAuthor, setSameAuthor] = useState([]);
  const [sameTag, setSameTag] = useState([]);
  const [sameTag_1, setSameTag_1] = useState([]);
  const [sameTag_2, setSameTag_2] = useState([]);
  const [sameTag_3, setSameTag_3] = useState([]);
  const [hasSpinOff, setHasSpinOff] = useState([]);

  const products = useProductsState(useSelector);

  const filterByAuthor = () => {
    const author = product.author[0];

    const byAuthor = products.filter((p) => {
      if (p.frenchTitle !== product.frenchTitle) {
        return p.author[0] === author;
      } else {
        return "";
      }
    });

    setSameAuthor(byAuthor);
  };

  useEffect(() => {
    if (products && product) {
      const filter = products.filter((p) => p.frenchTitle === product.spinOff);

      if (filter.length > 0) {
        setHasSpinOff(filter);
      }
      if (product.author) {
        filterByAuthor();
      }
      if (product.tags?.length > 0 && products) {
        setSameTag([]);
        setSameTag_1([]);
        setSameTag_2([]);
        products.forEach((p) => {
          const intersection = product.tags.filter((tag) =>
            p.tags.includes(tag)
          );
          if (intersection && intersection.length >= 2) {
            if (
              intersection.length > 1 &&
              intersection.length === product.tags.length &&
              product._id !== p._id
            ) {
              setSameTag((oldValues) => [...oldValues, { ...p, intersection }]);
            }
            if (
              intersection.length > 1 &&
              intersection.length === product.tags.length - 1 &&
              product._id !== p._id
            ) {
              setSameTag_1((oldValues) => [
                ...oldValues,
                { ...p, intersection },
              ]);
            }
            if (
              intersection.length > 1 &&
              intersection.length === product.tags.length - 2 &&
              product._id !== p._id
            ) {
              setSameTag_2((oldValues) => [
                ...oldValues,
                { ...p, intersection },
              ]);
            }
            if (
              intersection.length > 1 &&
              intersection.length === product.tags.length - 3 &&
              product._id !== p._id
            ) {
              setSameTag_3((oldValues) => [
                ...oldValues,
                { ...p, intersection },
              ]);
            }
          }
        });
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [product, products]);

  useEffect(() => {
    setSameTag([]);
    setSameTag_1([]);
    setSameTag_2([]);
    setSameTag_3([]);
  }, [productId]);

  return (
    <>
      {(SameAuthor.length > 0 ||
        sameTag.length > 0 ||
        sameTag_1.length > 0 ||
        sameTag_2.length > 0 ||
        hasSpinOff.length > 0) && (
        <H2 className="segoesc">découvrez aussi :</H2>
      )}

      {product && hasSpinOff.length > 0 && (
        <Slider
          title="Spin-off"
          array={sortByVolume(hasSpinOff)}
          article={true}
        />
      )}
      {product && SameAuthor.length > 0 && (
        <Slider
          title="du même auteur"
          array={SameAuthor.length > 0 && sortByOrder(SameAuthor)}
          article={true}
        />
      )}
      {sameTag && sameTag.length > 0 && (
        <Slider
          title={`${sameTag[0].intersection?.length} tags similaires`}
          array={sortByReleaseDate(sameTag)}
          article={true}
        />
      )}
      {sameTag_1 && sameTag_1.length > 0 && (
        <Slider
          title={`${sameTag_1[0].intersection?.length} tags similaires`}
          array={sortByReleaseDate(sameTag_1)}
          article={true}
        />
      )}
      {sameTag_2 && sameTag_2.length > 0 && (
        <Slider
          title={`${sameTag_2[0].intersection?.length} tags similaires`}
          array={sortByReleaseDate(sameTag_2)}
          article={true}
        />
      )}
      {sameTag_3 && sameTag_3.length > 0 && (
        <Slider
          title={`${sameTag_3[0].intersection?.length} tags similaires`}
          array={sortByReleaseDate(sameTag_3)}
          article={true}
        />
      )}
    </>
  );
};

const H2 = styled.h2`
  text-transform: uppercase;
  display: inline-block;
  width: 100%;
  text-align: center;
  padding-top: 45px;
  @media (max-width: 1150px) {
  }
`;

export default AllVolumes;
