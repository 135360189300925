import React from "react";
import styled from "styled-components";

const ReadDir = ({ landscape }) => {
  return (
    <Svg
      xmlns="http://www.w3.org/2000/svg"
      width="11.563"
      height="20"
      viewBox="0 0 11.563 20"
      landscape={landscape}
    >
      <path
        id="fleche-double_1_"
        data-name="fleche-double (1)"
        d="M115.344,5.335v9.331l1.551-1.551a1.563,1.563,0,1,1,2.21,2.21l-4.219,4.219a1.563,1.563,0,0,1-2.21,0l-4.219-4.219a1.563,1.563,0,1,1,2.21-2.21l1.551,1.551V5.335l-1.551,1.551a1.563,1.563,0,1,1-2.21-2.21L112.677.458a1.563,1.563,0,0,1,2.21,0l4.219,4.219a1.563,1.563,0,1,1-2.21,2.21Z"
        transform="translate(-108 -0.001)"
        fill="#fff"
      />
    </Svg>
  );
};

const Svg = styled.svg`
  transform: ${(prop) => (prop.landscape ? "rotate(90deg)" : "rotate(0deg)")}
    scale(1);
  transition: transform 0.2s ease-in-out;
  width: 20px;
  filter: drop-shadow(2px 2px 2px rgb(0 0 0 / 0.6));
  &:hover {
    transform: ${(prop) => (prop.landscape ? "rotate(90deg)" : "rotate(0deg)")}
      scale(1.2);
  }
`;

export default ReadDir;
