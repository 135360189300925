import React, { useState } from "react";
import styled from "styled-components";

const Input = ({ title, name, type, value, onchange }) => {
  const [mouseDown, setMouseDown] = useState(false);

  const SeePass = () => (
    <Button type="button" onClick={() => setMouseDown(!mouseDown)}>
      changer le mot de passe
    </Button>
  );
  return (
    <InputBlock>
      <Container>
        <Label htmlFor={type}>{title} :</Label>

        <InputField
          type={type}
          onkeydown="return false"
          id={type}
          name={name}
          value={value}
          onChange={onchange}
          autocomplete="false"
          disabled={
            (mouseDown === false && name === "password") ||
            name === "likes" ||
            name === "lastModify"
              ? "disabled"
              : false
          }
        />

        {name === "password" && <SeePass />}
      </Container>
    </InputBlock>
  );
};

const Label = styled.label`
  font-weight: bold;
  font-size: 12px;
`;

const InputField = styled.input`
  &::placeholder {
    color: blue;
  }
  &:focus {
    outline: solid black 2px;
    border-radius: 2px;
  }
  color: blue;
  height: 25px;
  font-size: 12px;
  border: none;
  margin-top: 3px;
  margin-bottom: 1em;
  width: 100%;
`;

const Container = styled.div`
  display: flex;
  flex-direction: column;
  width: 700px;
`;

const Button = styled.button`
  width: 300px;
  text-transform: uppercase;
`;

const InputBlock = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 1em 0em;
  border-bottom: solid 1px lightGrey;
`;

export default Input;
