import React from "react";
import styled, { keyframes } from "styled-components";

const Mouse = () => {
  return (
    <Svg
      xmlns="http://www.w3.org/2000/svg"
      width="40"
      height="63.158"
      viewBox="0 0 40 63.158"
    >
      <g id="mouseLeft" transform="translate(-302.667)">
        <g
          id="Groupe_799"
          data-name="Groupe 799"
          transform="translate(302.667)"
        >
          <path
            id="Tracé_527"
            data-name="Tracé 527"
            d="M322.667,0a20.022,20.022,0,0,0-20,20V43.158a20,20,0,1,0,40,0V20A20.022,20.022,0,0,0,322.667,0Zm2.105,10.526v6.316a2.105,2.105,0,1,1-4.211,0V10.526a2.105,2.105,0,1,1,4.211,0Zm15.789,32.632a17.895,17.895,0,0,1-35.79,0V27.369h35.789v15.79Zm0-17.895H323.72V20.9a4.21,4.21,0,0,0,3.158-4.061V10.526a4.21,4.21,0,0,0-3.158-4.061V2.159A17.9,17.9,0,0,1,340.562,20Z"
            transform="translate(-302.667)"
            fill="#fff"
          />
        </g>
      </g>
    </Svg>
  );
};

const clint = keyframes`
0%{fill: #fff;}
50%{fill: #ff6333;}
100%{fill: #fff;}
`;

const Svg = styled.svg`
  & path {
    animation: ${clint} 1s ease-in-out infinite;
  }
`;

export default Mouse;
