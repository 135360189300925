import React from "react";
import { useSelector } from "react-redux";
import Option from "../../../components/admin/input/optionInput/Option";
import Textarea from "../../../components/admin/input/TextArea";
import Text from "../../../components/admin/input/Text";
import Radio from "../../../components/admin/input/Radio";
import InputFile from "../../../components/admin/input/InputFile";
import AddFieldToCategory from "../../../components/admin/input/AddFieldToCategory";
import { useCategoryState } from "../../marketplace/categoryPage/controllers/stateCtrl";
import { REACT_APP_API_HOST } from "../../../service";

const Form = ({
  selectedProduct,
  onChangeProperty,
  deleteFromList,
  setNewLastModifyDate,
}) => {
  const category = useCategoryState(useSelector);

  const categoryArray =
    category &&
    category.length > 0 &&
    category.find((cat) => cat.name === selectedProduct?.category);

  const lpad = (st, length) => {
    let str = st.toString();
    var lengthDiff = length - str.length;
    while (lengthDiff > 0) {
      str = "0" + str;
      lengthDiff--;
    }
    return str;
  };

  const timestampToDate = (timestamp) => {
    const date = new Date(Number(timestamp));
    return `${date.getFullYear()}-${lpad(date.getMonth(), 2)}-${lpad(
      date.getDate(),
      2
    )}`;
  };

  return (
    <>
      {
        <>
          <Text
            type="date"
            name="lastModify"
            onchange={(e) => onChangeProperty(e)}
            title="Dernière modification (faire remonter un produit)"
            value={timestampToDate(selectedProduct?.lastModify)}
          />
          <button type="button" onClick={() => setNewLastModifyDate()}>
            Faire remonter dans les nouveautés
          </button>
        </>
      }
      {
        <Text
          type="date"
          name="releaseDate"
          onchange={(e) => onChangeProperty(e)}
          title="Date de création"
          value={selectedProduct?.releaseDate}
        />
      }
      <Option
        name="category"
        title="Catégorie"
        onchange={(e) => onChangeProperty(e)}
        array={category}
        property="name"
        currentValue={selectedProduct?.category}
      />
      {
        <Radio
          legend="Produit actif"
          name="active"
          currentValue={selectedProduct?.active}
          onchange={onChangeProperty}
        />
      }
      <Radio
        legend="Série terminée"
        name="ended"
        currentValue={selectedProduct?.ended}
        onchange={onChangeProperty}
      />
      <Radio
        legend="Produit comportant des exclusivités numériques"
        name="digitalExclusive"
        currentValue={selectedProduct?.digitalExclusive}
        onchange={onChangeProperty}
      />
      <Option
        name="readingDirection"
        title="sens de lecture"
        onchange={(e) => onChangeProperty(e)}
        array={["europe", "original", "webtoon"]}
        currentValue={selectedProduct?.readingDirection}
      />
      <Option
        name="frenchTitle"
        title="Titre français"
        onchange={(e) => onChangeProperty(e)}
        array={categoryArray?.frenchTitles}
        currentValue={selectedProduct?.frenchTitle}
      />
      <AddFieldToCategory
        name="addFrenchTitle"
        title="enregistrer un nouveau titre français"
        categoryId={categoryArray?._id}
        arrayToUpdate="frenchTitles"
      />
      <Option
        name="spinOff"
        title="titre si spin-off"
        onchange={(e) => onChangeProperty(e)}
        array={categoryArray?.frenchTitles}
        currentValue={selectedProduct?.spinOff}
      />
      <Option
        name="originalTitle"
        title="Titre original"
        onchange={(e) => onChangeProperty(e)}
        array={categoryArray?.originalTitles}
        currentValue={selectedProduct?.originalTitle}
      />
      <AddFieldToCategory
        name="addOriginalTitle"
        title="enregistrer un nouveau titre original"
        categoryId={categoryArray?._id}
        arrayToUpdate="originalTitles"
      />
      <Text
        type="number"
        name="age"
        onchange={(e) => onChangeProperty(e)}
        title="Age conseillé"
        value={selectedProduct?.age}
      />
      <Option
        name="author"
        title="Auteur"
        onchange={onChangeProperty}
        array={categoryArray?.authors}
        currentValue={selectedProduct?.author}
        deleteFromList={deleteFromList}
      />
      <AddFieldToCategory
        name="addAuthor"
        title="enregistrer un nouvel auteur"
        categoryId={categoryArray?._id}
        arrayToUpdate="authors"
      />
      <Option
        name="illustrator"
        title="Illustrateur"
        onchange={(e) => onChangeProperty(e)}
        array={categoryArray?.illustrators}
        currentValue={selectedProduct?.illustrator}
      />
      <AddFieldToCategory
        name="addIlustrator"
        title="enregistrer un nouvel illustrateur"
        categoryId={categoryArray?._id}
        arrayToUpdate="illustrators"
      />
      <Textarea
        name="synopsis"
        title="synopsis"
        currentValue={selectedProduct?.synopsis}
        onchange={(e) => onChangeProperty(e)}
      />
      <Option
        name="tags"
        title="Tags"
        onchange={(e) => onChangeProperty(e)}
        array={categoryArray?.tags}
        currentValue={selectedProduct?.tags}
        deleteFromList={deleteFromList}
      />
      <AddFieldToCategory
        name="addTag"
        title="enregistrer un tag"
        categoryId={categoryArray?._id}
        arrayToUpdate="tags"
      />
      <Option
        name="frenchPublisher"
        title="Edition française"
        onchange={(e) => onChangeProperty(e)}
        array={categoryArray?.frenchPublishers}
        currentValue={selectedProduct?.frenchPublisher}
      />
      <AddFieldToCategory
        name="addfrenchPublisher"
        title="enregistrer un nouvel éditeur français"
        categoryId={categoryArray?._id}
        arrayToUpdate="frenchPublishers"
      />
      <Option
        name="originalPublisher"
        title="Edition originale"
        onchange={(e) => onChangeProperty(e)}
        array={categoryArray?.originalPublishers}
        currentValue={selectedProduct?.originalPublisher}
      />
      <AddFieldToCategory
        name="addOriginalPublisher"
        title="enregistrer un nouvel éditeur d'origine"
        categoryId={categoryArray?._id}
        arrayToUpdate="originalPublishers"
      />
      {
        <InputFile
          title="présentation du titre"
          name="cover"
          src={REACT_APP_API_HOST + selectedProduct?.imageURL}
          alt="jaquette"
        />
      }
      <p>URL de l'image: {selectedProduct?.imageURL} </p>
      <Text
        type="text"
        name="paperURL"
        onchange={(e) => onChangeProperty(e)}
        title="URL vers le papier"
        value={selectedProduct?.paperURL}
      />
      <p>Nombre total de Likes:</p>
      <p>{selectedProduct.totalLikes}</p>
    </>
  );
};

export default Form;
