export const regex = new RegExp(
  //eslint-disable-next-line
  "([!#-'*+/-9=?A-Z^-~-]+(.[!#-'*+/-9=?A-Z^-~-]+)*|\"([]!#-[^-~ \t]|(\\[\t -~]))+\")@([!#-'*+/-9=?A-Z^-~-]+(.[!#-'*+/-9=?A-Z^-~-]+)*|[[\t -Z^-~]*])"
);

export const emailVerify = (email, messageNotif) => {
  if (email && !email.match(regex)) {
    messageNotif("Email non valide !");
    return false;
  }
  if (email && email.match(regex) && email.length > 255) {
    messageNotif("Email non valide !");
    return false;
  }
  if (email && email.match(regex)) {
    return true;
  }
};

export const passwordVerify = (password, messageNotif) => {
  const regex = new RegExp("^(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[0-9]).{8,}$"); //8 caractères dont minuscule majuscule chiffre
  if (password && password.length < 8) {
    messageNotif("Le mot de passe doit comporter au moins 8 caractères !");
    return false;
  }
  if (password && password.length >= 30) {
    messageNotif("Le mot de passe est trop long !");
    return false;
  }
  if (!password.match(regex)) {
    messageNotif(
      "Le mot de passe doit comporter au moins une majuscule, une minuscule et un chiffre !"
    );
    return false;
  }
  if (password.match(regex)) {
    return true;
  }
};

export const pseudoVerify = (pseudo, messageNotif) => {
  if (pseudo && pseudo.length < 3) {
    messageNotif("Votre pseudo doit comporter au minimum 3 caractères !");
    return false;
  }
  if (pseudo && pseudo.length >= 15) {
    messageNotif("Votre pseudo est trop long !");
    return false;
  }
  if (pseudo && pseudo.length >= 3) {
    return true;
  }
};
