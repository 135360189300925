import React from "react";
import styled from "styled-components";
import Cross from "../../../../components/marketplace/reader/picto/Cross";
import Fullscreen from "../../../../components/marketplace/reader/picto/Fullscreen";
import Zoom from "../../../../components/marketplace/reader/picto/Zoom";
import ReadDir from "../../../../components/marketplace/reader/picto/ReadDir";
import { useNavigate, useParams } from "react-router-dom";
import { useSelector } from "react-redux";
import { useProductsState } from "../../../../views/marketplace/categoryPage/controllers/stateCtrl";
import { allReplace } from "../lib/readerCtrl";

const Bar = ({
  visible,
  toggleReadDirection,
  landscape,
  readingDirection,
  zoom,
  mobileAgent,
}) => {
  const { productId } = useParams();
  const products = useProductsState(useSelector);

  const navigate = useNavigate();

  function toggleFullScreen() {
    if (!document.fullscreenElement) {
      document.documentElement.requestFullscreen();
    } else {
      if (document.exitFullscreen) {
        document.exitFullscreen();
      }
    }
  }

  const findFrenchTitle = (productId) => {
    if (products && products.length > 0 && productId) {
      const selectProduct = products.find(
        (product) => product._id === productId
      );
      return allReplace(selectProduct.frenchTitle);
    }
  };

  return (
    <Wrapper visible={visible}>
      <Container>
        <DivFullScreen onClick={toggleFullScreen} mobileAgent={mobileAgent}>
          <Fullscreen />
        </DivFullScreen>
        <DivReadDir
          onClick={toggleReadDirection}
          readingDirection={readingDirection}
        >
          <ReadDir landscape={landscape} readingDirection={readingDirection} />
        </DivReadDir>
        {
          <Div onClick={zoom}>
            <Zoom />
          </Div>
        }
        <Div
          onClick={() =>
            navigate(`/item/${findFrenchTitle(productId)}/${productId}`)
          }
        >
          <Cross />
        </Div>
      </Container>
    </Wrapper>
  );
};

const Wrapper = styled.div`
  box-sizing: border-box;
  position: fixed;
  display: flex;
  align-items: center;
  justify-content: center;
  top: ${(prop) => (prop.visible ? "0" : "-46")}px;
  width: 100%;
  height: 45px;
  background-color: rgba(15, 24, 31, 0.8);
  transition: top 0.3s ease-in-out;
  z-index: 99;
  border-bottom: solid 1px rgba(255, 255, 255, 0.1);
`;

const Container = styled.div`
  display: flex;
  justify-content: space-between;
`;

const Div = styled.div`
  cursor: pointer;
  padding: 0 25px;
  @media (max-width: 350px) {
    padding: 0 20px;
  }
`;

const DivReadDir = styled.div`
  cursor: pointer;
  padding: 0 25px;
  display: ${(props) =>
    props.readingDirection === "webtoon" ? "none" : "block"};
  @media (max-width: 350px) {
    padding: 0 20px;
  }
`;

const DivFullScreen = styled.div`
  cursor: pointer;
  padding: 0 25px;
  display: ${(prop) => (prop.mobileAgent === "iOS" ? "none" : "block")};
  @media (max-width: 350px) {
    padding: 0 20px;
  }
`;
export default Bar;
