import { actions } from "../actions/index";

const { FETCH_KEY } = actions;

const initialState = {
  key: [],
};

export function keys(state = initialState, action) {
  switch (action.type) {
    case FETCH_KEY:
      return {
        ...state,
        key: action.payload.key,
      };
    default:
      return state;
  }
}
