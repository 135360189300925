import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import styled from "styled-components";
import { Link } from "react-router-dom";
import ConfirmButton from "../../../components/admin/button/ConfirmButton";
import { deleteProduct } from "../../../lib/state/actions/index";
import { titleToProduct } from "../../../lib/adminControler/isbnControler";
import Network from "./Network";
import {
  useVolumesState,
  useProductsState,
  useSuperAdminState,
} from "../../marketplace/categoryPage/controllers/stateCtrl";
import { REACT_APP_API_HOST } from "../../../service";

const Products = () => {
  const [search, setSearch] = useState("");
  const products = useProductsState(useSelector);
  const volumes = useVolumesState(useSelector);
  const [searchResults, setSearchResults] = useState();
  const dispatch = useDispatch();
  const superAdmin = useSuperAdminState(useSelector);

  const admToken = JSON.parse(localStorage.getItem("admtoken"));

  useEffect(() => {
    if (
      search !== "" &&
      products &&
      products.length > 0 &&
      volumes &&
      volumes.length > 0
    ) {
      titleToProduct(products, volumes, search, setSearchResults);
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [search, products, volumes]);

  const validateDeleteProduct = (id) => {
    dispatch(deleteProduct(id));
  };

  const ProductsView = ({ result }) => {
    return (
      <tbody>
        <tr style={{ border: "1px solid black" }}>
          <Td>{result._id}</Td>
          <Td>{result.frenchTitle}</Td>
          <Td>
            {result.active ? (
              <Span className="blue">oui</Span>
            ) : (
              <Span className="red">non</Span>
            )}
          </Td>
          <Td>
            {superAdmin && (
              <Link to={`/admin/products/update/${result._id}`}>
                <button
                  type="button"
                  style={{
                    backgroundColor: "lightgreen",
                    borderColor: "lightgreen",
                    textTransform: "uppercase",
                  }}
                >
                  Modifier
                </button>
              </Link>
            )}
          </Td>
          <Td>
            {superAdmin && (
              <Link to={`/admin/products/volumes/${result._id}`}>
                <button
                  type="button"
                  style={{
                    backgroundColor: "lightgreen",
                    borderColor: "lightgreen",
                    textTransform: "uppercase",
                  }}
                >
                  gérer les volumes
                </button>
              </Link>
            )}
          </Td>
          <Td className="delete">
            {result?.volumes?.length > 0
              ? "Volumes en cours."
              : superAdmin && (
                  <ConfirmButton
                    message="effacer"
                    validate={() => validateDeleteProduct(result._id)}
                    pad="0.4em"
                  />
                )}
          </Td>
        </tr>
      </tbody>
    );
  };

  return (
    <Wrapper>
      <Network />
      <div style={{ padding: "20px 0", textAlign: "center" }}>
        <p>
          Générer et télécharger le sitemap.xml :{" "}
          <Link
            to={`${REACT_APP_API_HOST}/api/products/${admToken}/generatesitemap`}
          >
            <button type="button">télécharger et coller dans public</button>
          </Link>
        </p>
        <p>(Après création d'un nouveau produit)</p>
      </div>
      <H2>Liste de tous les titres:</H2>
      <p>
        {products
          ? `
               Total: ${products.length} produits.`
          : "chargement..."}
      </p>
      <Search>
        <label>Recherche par titre: </label>
        <input
          type="text"
          value={search}
          onChange={(e) => setSearch(e.target.value)}
        ></input>
      </Search>
      <Link to={`/admin/products/create`}>
        {superAdmin && <Button type="button">Créer un nouveau titre</Button>}
      </Link>
      <Table>
        <thead>
          <tr>
            <Th>_id</Th>
            <Th>Titre français :</Th>
            <Th>Actif :</Th>
            <Th>Modifier le titre :</Th>
            <Th>Gérer les volumes :</Th>
            <Th>Supprimer :</Th>
          </tr>
        </thead>
        {search
          ? searchResults?.map((result, i) => (
              <ProductsView key={i} result={result} />
            ))
          : products &&
            products.length > 0 &&
            products
              ?.toReversed()
              .map((result, i) => <ProductsView key={i} result={result} />)}
      </Table>
    </Wrapper>
  );
};

const Wrapper = styled.div`
  position: static;
  display: flex;
  align-items: center;
  flex-direction: column;
  user-select: text;
`;

const Search = styled.div`
  padding-bottom: 20px;
  font-weight: bold;
  font-size: 14px;
  color: grey;
  & input {
    width: 500px;
  }
`;

const Button = styled.button`
  padding: 1em;
  border: solid 1px black;
  text-transform: uppercase;
  font-weight: bold;
  cursor: pointer;
`;

const H2 = styled.h2`
  margin: 20px;
  text-transform: uppercase;
  font-size: 16px;
  font-weight: bold;
`;

const Table = styled.table`
  border: 1px solid black;
  width: 80%;
  margin-top: 22px;
`;

const Td = styled.td`
  border: 1px solid black;
  padding: 10px;
  text-align: center;
  &.delete {
    width: 150px;
  }
`;

const Th = styled.th`
  border: 1px solid black;
  padding: 10px;
  text-align: center;
  font-weight: bold;
  text-transform: uppercase;
`;

const Span = styled.span`
  text-transform: uppercase;
  &.blue {
    color: blue;
  }
  &.red {
    color: red;
  }
`;

export default Products;
