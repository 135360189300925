import React, { useState, useRef, useEffect, useMemo } from "react";
import styled from "styled-components";
import {
  debounce,
  handleKeyUpHori,
  toggleLandscape,
  toggleTabView,
  prevPage,
  nextPage,
} from "./lib/readerCtrl";
import Reader from "./Reader";
import { imageSizeCalculate } from "./lib/imageSizeCtrl";
import TopBar from "./TopBar";
import { getMobileOperatingSystem } from "./lib/getMobileSysteme";
import Tuto from "../../../views/marketplace/reader/tutorial";
import { updateBookmarkPage } from "../../../lib/state/localStorage/localStorageCtrl";
import { useUserState } from "../../../views/marketplace/categoryPage/controllers/stateCtrl";
import { useSelector } from "react-redux";
import Popup from "./popup/Popup";

const Container = ({
  book,
  paperURL,
  chapterId,
  chapters,
  myBook,
  zoomFactor,
  changeZoomFactor,
  extract,
  gift,
  nextChapter,
  selectChapter,
  completeVolumeChapters,
}) => {
  const [scroll, setScroll] = useState(0);
  const [pageNumber, setPageNumber] = useState(0);
  const [landscape, setLandscape] = useState();
  const [mobileAgent, setMobileAgent] = useState();
  const [windowSize, setWindowSize] = useState({ width: 0, height: 0 });
  const [imageCorrection, setImageCorrection] = useState();
  const [tabView, setTabView] = useState(false);
  const [touch, setTouch] = useState(false);
  const [clientY, setClientY] = useState(-1);
  const [bookmark, setBookmark] = useState(false);

  const { userId } = useUserState(useSelector);

  const wrapperRef = useRef();

  const changePageNumber = (n) => setPageNumber(n);

  function handleResize() {
    setWindowSize({
      width: window.innerWidth,
      height: window.innerHeight,
    });
  }

  const debouncedHandleResize = useMemo(() => debounce(handleResize, 100), []);

  //bookmark page and chapter on change page
  useEffect(() => {
    updateBookmarkPage(userId, book, chapterId, pageNumber);
  }, [pageNumber, userId, book, chapterId]);

  //detect bookmark
  useEffect(() => {
    if (book && !extract && userId) {
      const storage = localStorage.getItem(userId);
      if (storage !== null && storage) {
        const storageObject = JSON.parse(storage);
        const bookmarkExist = storageObject.bookMark.find(
          (obj) => obj.book === book
        );
        if (bookmarkExist) {
          setBookmark({
            chapter: bookmarkExist.chapter,
            page: bookmarkExist.page,
          });
        }
      }
    }
  }, [book, userId, extract]);

  //since bookmark
  const goToChapter = () => {
    if (userId && !extract) {
      const storage = localStorage.getItem(userId);
      if (storage !== null && storage) {
        const storageObject = JSON.parse(storage);
        const bookmarkExist = storageObject.bookMark.find(
          (obj) => obj.book === book
        );
        if (bookmarkExist) {
          selectChapter(bookmarkExist.chapter);
          if (landscape) {
            setTimeout(() => changePage(bookmarkExist.page), 300);
            setBookmark(false);
          } else {
            setTimeout(
              () =>
                wrapperRef.current.scrollTo({
                  top: bookmarkExist.page * imageCorrection?.divHeight,
                  behavior: "smooth",
                }),
              300
            );
            setBookmark(false);
          }
        }
      }
    }
  };

  //go to pagenumber when change landscape to portrait
  useEffect(() => {
    if (!landscape && pageNumber !== 0) {
      wrapperRef.current.scrollTo({
        top: pageNumber * imageCorrection?.divHeight,

        behavior: "auto",
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [landscape, imageCorrection?.divHeight]);

  //landscape or portrait
  useEffect(() => {
    toggleLandscape(windowSize, myBook, setLandscape);
  }, [windowSize, myBook]);

  //image correction on canvas fullscreen
  useEffect(() => {
    imageSizeCalculate(
      windowSize,
      myBook,
      landscape,
      tabView,
      setImageCorrection
    );
    //tab && mobile mode
    toggleTabView(windowSize, landscape, setTabView);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [myBook, windowSize, landscape]);

  //change read direction except "webtoon"
  const toggleReadDirection = () => {
    if (myBook?.readingDirection !== "webtoon") {
      setLandscape(!landscape);
    }
  };

  //show tools at begin/ setMobileAgent/resize
  useEffect(() => {
    getMobileOperatingSystem(setMobileAgent);
    window.addEventListener("resize", debouncedHandleResize);
    handleResize();

    return () => {
      window.removeEventListener("resize", debouncedHandleResize);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // set button position on scroolBar when portrait page change
  const scrollNavOnBar = (page) => {
    if (!landscape) {
      wrapperRef.current.scrollTo({
        top: page,

        behavior: "auto",
      });
    } else {
      changePage(page);
    }
  };

  //for chapterPop navigate
  const goToPage = (page) => {
    if (!landscape) {
      wrapperRef.current.scrollTo({
        top: page * imageCorrection?.divHeight,

        behavior: "auto",
      });
    } else {
      changePage(page);
    }
  };

  //setPageNumber on scroll
  useEffect(() => {
    if (!landscape) {
      let result = Math.floor(scroll / imageCorrection?.divHeight);
      if (!isNaN(result) && result !== undefined) {
        if (result <= 0 && pageNumber !== 0) {
          changePageNumber(0);
        }
        if (result >= myBook?.pages - 1 && pageNumber !== myBook?.pages - 1) {
          changePageNumber(myBook?.pages - 1);
        }
        if (result > 0 && result < myBook?.pages - 1 && pageNumber !== result) {
          changePageNumber(result);
        }
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [scroll, landscape, imageCorrection, pageNumber]);

  //zoom onclick
  const zoom = () => {
    /////refacto
    if (zoomFactor === 1) {
      changeZoomFactor(1.1);
    }
    if (zoomFactor === 1.1) {
      changeZoomFactor(1.2);
    }
    if (zoomFactor === 1.2) {
      changeZoomFactor(1.3);
    }
    if (zoomFactor === 1.3) {
      changeZoomFactor(1);
    }
  };

  //observe scroll event
  useEffect(() => {
    if (!landscape) {
      const wrapperEl = wrapperRef?.current;
      wrapperEl.addEventListener("scroll", debouncedhandleScroll);

      return () => {
        wrapperEl.removeEventListener("scroll", debouncedhandleScroll);
      };
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [landscape]);

  const debouncedhandleScroll = (e) => {
    if (!landscape) {
      if (e.target.scrollTop) {
        setScroll(e.target.scrollTop);
      }
    }
  };

  const pressKey = (e) => {
    debouncedhandleKeyUpHori(e);
  };

  const debouncedhandleKeyUpHori = useMemo(
    () =>
      debounce(
        (e) => handleKeyUpHori(e, pageNumber, changePageNumber, myBook),
        100
      ),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [pageNumber, myBook]
  );

  //changePage
  const changePage = (n) => {
    if (!landscape || (landscape && n % 2 === 0)) {
      changePageNumber(n);
    }
    if (landscape && n % 2 !== 0) {
      changePageNumber(n - 1);
    }
  };

  //if landscape, display pair page
  useEffect(() => {
    changePage(pageNumber);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [landscape, pageNumber]);

  //mouse wheel horizontal change page
  const handleMouseWheel = (event) => {
    if (event.deltaY > 0) {
      myBook?.readingDirection === "original"
        ? nextPage(pageNumber, changePage, myBook)
        : prevPage(pageNumber, changePage);
    } else {
      myBook?.readingDirection === "original"
        ? prevPage(pageNumber, changePage)
        : nextPage(pageNumber, changePage, myBook);
    }
  };

  //mousewheel
  useEffect(() => {
    if (landscape) {
      const wrapperEl = wrapperRef?.current;
      wrapperEl.addEventListener("wheel", handleMouseWheel);
      return () => {
        wrapperEl.removeEventListener("wheel", handleMouseWheel);
      };
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [landscape, myBook, pageNumber]);

  //arrow touch
  useEffect(() => {
    if (landscape) {
      window.addEventListener("keyup", pressKey);
      return () => {
        window.removeEventListener("keyup", pressKey);
      };
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [landscape, myBook, pageNumber]);

  //if new chapter, set / scroll to page 0
  useEffect(() => {
    setScroll(0);
    setPageNumber(0);
  }, [chapterId]);

  //invert images if original or european direction
  const toggleDirection = () => {
    if (myBook?.readingDirection === "europe") {
      return "row";
    }
    if (myBook?.readingDirection === "original") {
      return "row-reverse";
    }
  };

  //ios touchStart zoom
  const pointerDownHandler = (e) => {
    if (e.touches.length === 2) {
      setTouch(true);
      e.preventDefault();
      e.stopPropagation();
    }
  };

  //ios touchMove zoom
  const pointermoveHandler = (e) => {
    if (e.touches.length === 2 && touch) {
      e.preventDefault();
      e.stopPropagation();

      const YDiff = Math.abs(e.touches[0].clientY - e.touches[1].clientY);
      if (clientY > -1 && touch) {
        if (YDiff > clientY) {
          if (zoomFactor < 2.0) {
            changeZoomFactor(zoomFactor + 0.05);
          }
        }
        if (YDiff < clientY) {
          if (zoomFactor > 1.0) {
            changeZoomFactor(zoomFactor - 0.05);
          }
        }
      }
      setClientY(YDiff);
    }
  };

  //ios touchEnd zoom
  const pointerupHandler = (e) => {
    if (e.touches.length < 2 && touch) {
      setClientY(-1);
      setTouch(false);
    }
  };

  //ios touch zoom
  useEffect(() => {
    const wrapperEl = wrapperRef?.current;
    if (mobileAgent === "iOS") {
      wrapperEl.addEventListener("touchstart", pointerDownHandler, {
        passive: false,
      });
      wrapperEl.addEventListener("touchmove", pointermoveHandler, {
        passive: false,
      });
      wrapperEl.addEventListener("touchend", pointerupHandler, {
        passive: false,
      });
    }

    return () => {
      wrapperEl.removeEventListener("touchstart", pointerDownHandler, {
        passive: false,
      });
      wrapperEl.removeEventListener("touchmove", pointermoveHandler, {
        passive: false,
      });
      wrapperEl.removeEventListener("touchend", pointerupHandler, {
        passive: false,
      });
    };
  });

  const chapterIdToNumber = (id) => {
    const chapterExist = chapters.find((chap) => chap._id === id);
    if (chapterExist) {
      return chapterExist.chapterNumber;
    }
  };

  return (
    <>
      <Tuto landscape={landscape} />
      <TopBar
        wrapperRef={wrapperRef}
        toggleReadDirection={toggleReadDirection}
        landscape={landscape}
        myBook={myBook}
        zoom={zoom}
        pageNumber={pageNumber}
        scroll={scroll}
        imageCorrection={imageCorrection}
        windowSize={windowSize}
        scrollNavOnBar={scrollNavOnBar}
        mobileAgent={mobileAgent}
        extract={extract}
        chapters={chapters}
        selectChapter={selectChapter}
        completeVolumeChapters={completeVolumeChapters}
        goToPage={goToPage}
      />

      <Popup
        visible={bookmark}
        message={`Reprendre ${
          completeVolumeChapters
            ? ""
            : `chapitre ${chapterIdToNumber(bookmark.chapter)}`
        } page ${bookmark.page} ?`}
        togglePopup={() => goToChapter()}
        censored={false}
        close={() => {
          setBookmark(false);
        }}
      />

      <Wrapper
        ref={wrapperRef}
        maxWidth={windowSize?.width}
        maxHeight={windowSize?.height}
        landscape={landscape}
        tabIndex="0"
        zoomFactor={zoomFactor}
      >
        <Reader
          wrapperRef={wrapperRef}
          windowSize={windowSize}
          pageNumber={pageNumber}
          setPageNumber={changePageNumber}
          landscape={landscape}
          myBook={myBook}
          imageCorrection={imageCorrection}
          toggleDirection={toggleDirection}
          zoomFactor={zoomFactor}
          changeZoomFactor={changeZoomFactor}
          mobileAgent={mobileAgent}
          scroll={scroll}
          id={book}
          chapterId={chapterId}
          chapters={chapters}
          extract={extract}
          nextChapter={nextChapter}
          gift={gift}
          paperURL={paperURL}
        />
      </Wrapper>
    </>
  );
};

const Wrapper = styled.div`
  box-sizing: border-box;
  position: fixed;
  top: 0;
  bottom: 0;
  display: flex;
  align-items: ${(prop) => (prop.landscape ? "center" : "unset")};
  width: 100%;
  height: 100%;
  background-color: #0f181f;
  overflow: ${(prop) => (prop.landscape ? "hidden" : "scroll")};
  -ms-overflow-style: none;
  scrollbar-width: none;
  &&::-webkit-scrollbar {
    display: none;
  }
  transform: scale(
    ${(props) => (props.zoomFactor !== 1 ? props.zoomFactor : "unset")}
  );
`;

export default Container;
