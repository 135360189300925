import React from "react";
import styled from "styled-components";
import triste from "./image/triste.jpg";

const JapanPage = () => {
  return (
    <Wrapper>
      <Img src={triste} alt="fille qui est triste" />
      <P marginTop={25} className="montserratSemiBold">
        Erreur 406 :
      </P>
      <P marginTop={5}>Ce site n'est pas disponible au Japon !</P>
    </Wrapper>
  );
};

const Wrapper = styled.div`
  width: 100%;
  min-height: calc(100dvh - 120px);
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background-color: white;
`;

const P = styled.p`
  font-size: 35px;
  margin-top: ${(props) =>
    props.marginTop ? `${props.marginTop}px` : "unset"};
  @media (max-width: 500px) {
    font-size: 25px;
  }
`;

const Img = styled.img`
  width: 480px;
  @media (max-width: 500px) {
    width: 300px;
  }
`;

export default JapanPage;
