import React, { useRef, useEffect } from "react";
import styled from "styled-components";
import WrapperContainer from "../../../components/marketplace/WrapperContainer";
import { Helmet } from "react-helmet-async";
import { useLocation } from "react-router-dom";

const Cgu = () => {
  const cookiesRef = useRef();
  const { pathname } = useLocation();

  useEffect(() => {
    if (pathname && pathname === "/cgu/cookies") {
      cookiesRef.current.scrollIntoView({
        behavior: "smooth",
        block: "start",
        inline: "center",
      });
    }
  }, [pathname]);

  return (
    <>
      <Helmet>
        <title>Conditions générales de vente et d'utilisation.</title>
        <meta
          name="description"
          content={`Toutes les mentions légales concernant la vente et l'utilisation du site.`}
        />
        <meta
          name="title"
          content={`Conditions générales de vente et d'utilisation.`}
        />
        <meta
          name="prerender-header"
          content="Location: http://www.mangaebook.com/cgu"
        ></meta>
        <meta
          id="og-title"
          property="og:title"
          content={`Conditions générales de vente et d'utilisation.`}
        />
        <meta
          property="og:description"
          content={`Toutes les mentions légales concernant la vente et l'utilisation du site.`}
        />
        <meta property="og:url" content="https://mangaebook.com/cgu" />
        <meta
          id="og-image"
          property="og:image"
          content="http://mangaebook.com/og-img.jpg"
        />
        <meta
          property="og:image:secure_url"
          content="https://mangaebook.com/og-img.jpg"
        />
        <meta property="og:image:type" content="image/jpeg" />
        <meta property="og:image:width" content="1200" />
        <meta property="og:image:height" content="630" />
      </Helmet>
      <H1 className="segoesc">
        <a href="/">MangaeBook /</a>Conditions générales de vente et
        d'utilisation
      </H1>
      <WrapperContainer>
        <Container>
          <article>
            <p>
              L'Utilisateur s'engage à lire attentivement, avant toute commande,
              les termes et conditions établies dans les présentes Conditions
              Générales de vente de d'utilisation présentées ci-dessous.
              Commander sur le Service implique que l'Utilisateur a pris
              connaissance, lu, compris et accepté sans réserve le contenu des
              Conditions Générales de Vente. De même, l'Utilisateur comprend et
              accepte que le contenu du présent Site Web et du Service proposé,
              peut être à caractère érotique et/ou pornographique et qu'il est
              composé de textes, sons et images (fixes et animées). le présent
              contenu du Service est exclusivement réservé à des personnes
              majeures et désireuses de tels services. L'Utilisateur s'engage à
              ne pas permettre l'accès au Service à tout mineur ou à toute toute
              personne susceptible d’être choquée ou offensée.{" "}
            </p>
          </article>
          <article>
            <H2>1. Généralités</H2>
            <p>
              L’utilisateur accepte que les termes suivants soient utilisés dans
              le cadre des présentes Conditions Générales d’Utilisation et de
              Vente et accepte les significations données ci-dessous :
            </p>
            <p>
              « mangaebook » désigne le site Web du Service fonctionnant sur un
              navigateur Web et accessible à l’adresse; mangaebook.com
            </p>
            <p>
              « Titre » désigne le nom d’un produit contenant un ou plusieurs
              volumes et/ou un ou plusieurs chapitres.
            </p>
            <p>
              « compte utilisateur » : nom de l’espace personnel sur le site web
              mangaebook.com de l’utilisateur.
            </p>
            <p>
              « Volume » : désigne un livre, une bande dessinée, un manga, un
              roman, un web manga, un webtoon, plus largement un livre destiné à
              la lecture.
            </p>
            <p>
              « Catalogue » : désigne l’ensemble des contenus proposés par le
              Service mangaebook à un instant donné ;
            </p>
            <p>
              « Compte Personnel » : désigne l’espace personnel d’un Utilisateur
              accessible via un identifiant et un mot de passe lui permettant
              d’accéder à son profil, ses commandes, etc.
            </p>
            <p>
              « Contenus » : désigne l’ensemble des contenus, des livres ou
              chapitres électroniques (mangas, romans, audio, web mangas, etc.)
              et leurs fichiers électroniques qui peuvent être consultés via le
              Site ;
            </p>
            <p>
              « CGUV » : désigne les présentes Conditions générales
              d’Utilisation et de Vente dont l’objet est de définir les
              conditions de navigation de l’Utilisateur sur le Site, l’accès au
              Service et les relations entre Mangaebook et l’Utilisateur.
            </p>
            <p>
              « CHAPITRE » : désigne les parties d’un même TITRE de contenus en
              ligne de longueur équivalente ou non. La publication des CHAPITRES
              d’une même Série peut être simultanée ou effectuée progressivement
              dans le temps (avec un rythme de publication spécifique), à la
              seule discrétion de mangaebook ;
            </p>
            <p>
              « identifiant utilisateur » : désigne le numéro unique attribué
              pour identifier les Utilisateurs lors de l’utilisation d’un
              équipement technique donné (notamment tablette ou smartphone ou
              ordinateur), cet Identifiant permet notamment de mémoriser, les
              commandes passées et donne accès au service.
            </p>
            <p>
              « Prestataire » : désigne la société OPHELBE, SAS immatriculée au
              RCS de Paris sous le n°489833442 dont le siège social est 19
              CHEMIN DE LA CROIX JAVOT 94430 CHENNEVIERES SUR MARNE, prise en la
              personne de son représentant légal, YVES HUCHEZ Directeur Général
              qui exploite le Service via l’Application et le Site mangaebook.
            </p>
            <p>
              « RGPD » : désigne le Règlement (UE) 2016/679 du Parlement
              européen et du Conseil du 27 avril 2016 relatif à la protection
              des personnes physiques à l'égard du Traitement des Données à
              Caractère personnel et à la libre circulation de ces données.
            </p>
            <p>
              « Service » : désigne le service d’accès aux Contenus du Catalogue
              « mangaebook » via le Site Internet mangaebook.com
            </p>
            <p>
              « Site Internet » : désigne le site Web du Service fonctionnant
              sur un navigateur Web et accessible à l’adresse mangaebook.com
            </p>
            <p>
              « Utilisateur » : désigne tout internaute naviguant sur le Site
              qu’il ait un Compte personnel d’accès au Service ou non.
            </p>
            <p>
              « compte utilisateur » : nom de l’espace personnel sur le site web
              mangaebook.com de l’utilisateur
            </p>
            <H2>2. Objet – Acceptation – Modification des CGUV</H2>
            <p>
              Les présentes Conditions générales d’Utilisation et de Vente ont
              pour objet de régir les conditions de navigation de l’Utilisateur
              sur le Site et l’accès au Service et les rapports entre
              l’Utilisateur et le Prestataire.
            </p>
            <p>
              En naviguant sur le Site, l’Utilisateur est tenu de respecter les
              CGUV.
            </p>
            <p>
              L’Utilisateur doit accepter expressément les présentes CGUV avant
              toute utilisation des Services lors du premier accès au Service
              via le Site ou encore lors de la création de son Compte
              Utilisateur. Le cas échéant, l’Utilisateur sera invité à
              renouveler son consentement aux dernières CGUV en vigueur lors de
              chaque accès au Service. A défaut, il ne pourra pas accéder au
              Service.
            </p>
            <p>
              Le Prestataire se réserve le droit de modifier à tout moment les
              CGUV. Les CGUV dans leur version modifiée seront alors notifiées
              par le Prestataire à l’ensemble des Utilisateurs disposant d’un
              Compte par courrier électronique à l’adresse renseignée lors de
              leur inscription. Elles seront également mises à disposition des
              Utilisateurs sur le Site, de manière à ce qu’ils aient la
              possibilité de les consulter et de les sauvegarder à tout moment.
            </p>
            <p>
              Tout utilisateur doit avoir plus de 18 ans pour accéder au
              Service. Le Prestataire se réserve le droit de suspendre l’accès
              au Service à tout utilisateur ayant moins de 18 ans.
            </p>
            <H2>3. Création d’un Compte</H2>
            <p>
              La création d’un compte est facultative et n'entraîne aucune
              obligation de paiement.
            </p>
            <p>
              Lors de la création de son Compte personnel, l’Utilisateur doit
              renseigner :
            </p>
            <ul>
              <li>- Un pseudo</li>
              <li>- Une adresse mail</li>
              <li>- Un mot de passe</li>
              <li>
                - L’acceptation des Conditions générales d’Utilisation et de
                Vente.
              </li>
            </ul>

            <p>
              L’acceptation des Conditions générales d’Utilisation et de Vente
              implique automatiquement l’acceptation et l’utilisation de
              cookies.
            </p>
            <p>
              L’utilisation d’un compte est strictement personnelle, il est
              interdit de communiquer ses identifiants de connexion à quiconque.
            </p>
            <p>
              Le Prestataire se réserve le droit de supprimer le compte en cas
              de :
            </p>
            <ul>
              <li>- Communication d’informations erronées.</li>
              <li>- Violation des présentes CGUV.</li>
              <li>
                - non-utilisation du Service pendant une durée de plus d’un an
                sous réserve d’avoir au préalable prévenu l’Utilisateur de la
                suppression de son compte au moins un mois avant ladite
                suppression
              </li>
            </ul>
            <p>
              L’Utilisateur peut supprimer son Compte personnel à tout moment
              via le formulaire de contact.
            </p>
            <p>
              En cas de suppression du Compte dans les conditions du présent
              article, les Contenus payants résultant de commandes en cours ne
              seront plus accessibles.
            </p>
            <H2>4. Contenus</H2>
            <H3>4.1. Caractéristiques des Contenus</H3>
            <p>
              Tout les contenus présents ou rendu disponible à travers le
              service, tels que les textes, les graphiques, les logos, les
              boutons, les images, les téléchargements numériques sont la
              propriété de la société OPHELBE ou de ses Fournisseurs de contenu
              et sont t protégés par le droit Français et international de la
              propriété intellectuelle, d'auteur et de protection des bases de
              données.
            </p>
            <p>
              l’accès aux contenus numérique se fait automatiquement après
              l’achat pour une durée correspondante à la durée des droits acquis
              par les fournisseurs de contenus et pour les territoires France,
              Europe francophone et Canada. en cas de suppressions d’un contenu,
              l’utilisateur sera avisé via les notifications et/ou email.
            </p>
            <p>
              L'Utilisateur bénéficie sur le contenu d'un droit d'utilisation
              non-exclusif et temporaire pour ses besoins propres et personnels.
              En aucun cas, le contenu consulté ne peut être communiqué,
              dupliqué ou cédé à titre gracieux ou payant à quiconque, sous
              peine de sanctions pénales et de dommages-intérêts.
            </p>
            <p>
              Les contenus du service peuvent être à caractère érotique et/ou
              pornographique, composés de textes, sons, vidéos et images et
              peuvent offenser ou porter atteinte à la sensibilité du public. En
              conséquence, le présent contenu du Service est exclusivement
              réservé à des personnes majeures dans leur pays de résidence et
              désireuses de tels services. . L'Utilisateur s'engage à ne pas
              permettre l'accès au Service à tout mineur ou à toute personne
              susceptible d’être choquée ou offensée et à ne pas transmettre les
              fichiers téléchargés à tout mineur ou à toute personne susceptible
              d’être choquée ou offensée.
            </p>
            <p>
              Les Contenus proposés par mangaebook sont des volumes ou chapitres
              électroniques de livres en langue française permettant la lecture
              en ligne et/ou uniquement pour les volumes le téléchargement au
              format epub nominatif et limité à 1 téléchargement par volume par
              24 heures.
            </p>
            <p>
              Les contenus téléchargés ne peuvent être communiqués, dupliqués ou
              cédés à titre gracieux ou payant à quiconque, sous peine de
              sanctions pénales et de dommages-intérêts. L’Utilisateur comprend
              et accepte que les fichiers téléchargés soit marqués et identifiés
              par tous moyens techniques pour lutter contre le piratage et pour
              protéger la propriété intellectuelle des auteurs et des éditeurs.
            </p>
            <p>
              Pour chaque Contenu, l’Utilisateur est informé de ses
              caractéristiques principales (notamment présentation de l’œuvre,
              le titre, numéro de volume ou de chapitre, catégorie…), du prix en
              Euros.
            </p>
            <p>
              L’ensemble des Contenus est réservé à un public de plus de 18
              (dix-huit) ans.
            </p>
            <p>
              Le Service confère à l’Utilisateur un droit d’accès pour la
              visualisation des Contenus du Service. Ce droit d’accès peut
              porter sur des Contenus gratuits et/ou payants.
            </p>
            <H3>4.2. Accès aux Contenus gratuits</H3>
            <p>Certains Contenus sont accessibles gratuitement.</p>
            <p>
              Pour y accéder, l’Utilisateur doit sélectionner le Contenu dans le
              catalogue et lancer la lecture.
            </p>
            <p>
              L’accès à un Contenu peut être temporairement et
              exceptionnellement gratuit dans le cadre d’une opération
              promotionnelle. Cet accès peut porter sur tout ou partie d’un ou
              plusieurs Contenus.
            </p>
            <H3>4.3. Commande de Contenus payants</H3>
            <p>
              Les TITRES sont accessibles en volumes et/ou en chapitres.
              Lorsqu’un Utilisateur achète un titre par volume, la lecture
              électronique sera disponible seulement en volume et non en
              chapitre et lorsque l’utilisateur achète un titre par chapitre, la
              lecture électronique sera disponible seulement par chapitre.
            </p>
            <p>
              Pour accéder au Contenu payant d’un Titre accessible en volume ou
              en Chapitre, l’Utilisateur doit :
            </p>
            <p>
              Valider son panier via le bouton passé au paiement , Il parviendra
              ensuite à l’écran de paiement sécurisé et pourra ainsi valider
              définitivement sa commande en payant le prix en Euros indiqués via
              les prestataires de paiement partenaires du Prestataire.
            </p>
            <p>
              Lors du processus de commande, l’Utilisateur peut à tout moment
              visualiser le détail de sa commande ainsi que son prix total et
              corriger d’éventuelles erreurs.
            </p>
            <H3>4.4. Disponibilité des Contenus</H3>
            <p>
              l’accès aux contenus numérique se fait automatiquement après
              l’achat pour une durée correspondante à la durée des droits acquis
              par les fournisseurs de contenus et pour les territoires France,
              Europe francophone et Canada. en cas de suppressions d’un contenu,
              l’utilisateur sera avisé via les notifications et/ou email.
            </p>
            <p>
              Les Contenus sont protégés par les droits de propriété
              intellectuelle. En conséquence, l’Utilisateur ne pourra
              transmettre les contenus télécharger à titre gratuit ou onéreux à
              des tiers.
            </p>
            <p>
              Les Contenus sont accessibles en flux continu (dit « en streaming
              » ) via le Site. L’Utilisateur titulaire d’un Compte personnel
              dispose également, lorsque cela est proposé par le Service, de la
              faculté de les télécharger afin de pouvoir y accéder hors
              connexion.
            </p>
            <p>
              Lors d’un téléchargement, les Contenus pourront faire l’objet d’un
              téléchargement seulement sur le disque dur de l’appareil de
              lecture de l’Utilisateur. Les Contenus téléchargés sont protégés
              par les droits de propriété intellectuelle. En conséquence,
              l’Utilisateur ne pourra transmettre les contenus télécharger à
              titre gratuit ou onéreux à des tiers.
            </p>
            <p>
              la disponibilité des Contenus au sein du Service dépend des
              engagements contractuels entre le Prestataire et les titulaires
              des droits sur ces Contenus. En conséquence, le Prestataire ne
              peut garantir la disponibilité des Contenus au sein de son
              Catalogue.
            </p>
            <p>
              Le Prestataire se réserve par ailleurs le droit de supprimer ou
              suspendre à tout moment l’accès à un Contenu qui porterait
              atteinte aux droits des tiers ou dont l’illégalité serait avérée
              ou fortement présumée. La suppression d’un Contenu ne donnera lieu
              à aucun remboursement de l’Utilisateur.
            </p>
            <H3>4.5. Signalement de Contenus illégaux</H3>
            <p>
              L’Utilisateur est invité à signaler au Prestataire, dans le cadre
              de la procédure d’alerte ci-dessous décrite, toute utilisation
              frauduleuse du Site dont il aurait connaissance et notamment tout
              message dont le contenu contreviendrait aux lois et dispositions
              réglementaires en vigueur.
            </p>
            <p>
              De même, toute personne estimant qu’il y a violation sur le Site
              et/ou l’Application d’un droit dont elle serait titulaire a la
              possibilité de le signaler au Prestataire (conformément à
              l’article 6-1-5 de la loi du 21 juin 2004 n°2004-575) par courrier
              avec accusé de réception, précisant l’ensemble des informations
              suivantes :
            </p>
            <ul>
              <li>
                l’identité du notifiant : s’il s’agit d’une personne physique :
                nom, prénom, date de naissance, nationalité, domicile et
                profession. S’il s’agit d’une personne morale : sa forme, sa
                dénomination sociale, son siège social et l’organe qui la
                représente légalement ;
              </li>
              <li>
                la description des faits litigieux et leur localisation précise
                sur le Site et/ou l’Application ;
              </li>
              <li>
                les motifs pour lesquels le Contenu doit être retiré comprenant
                la mention des dispositions légales applicables ;
              </li>
            </ul>
            <p>
              L’Utilisateur est informé de ce que le Prestataire dispose de la
              possibilité de retirer, conformément à la loi, toute information
              ou contenu, ou d’en rendre l’accès impossible, et ce dès lors
              qu’il prend connaissance de leur caractère manifestement illicite.
            </p>
            <H3>4.6.</H3>
            <p>
              Les contenus du service sont à caractère érotique et/ou
              pornographique, composés de textes, sons, vidéos et images et
              peuvent offenser ou porter atteinte à la sensibilité du public. En
              conséquence, le présent contenu du Service est exclusivement
              réservé à des personnes majeures dans leur pays de résidence et
              désireuses de tels services. Ce Service ainsi que les relations
              contractuelles entre l'Utilisateur et la société OPHELBE sont
              régis selon les conditions établies dans les présentes Conditions
              Générales de Vente.
            </p>
            <H2>5. Prix et modalités de paiement</H2>
            <p>
              Le prix de chaque Contenu est indiqué en Euros sur la page
              descriptive dudit Contenu.
            </p>
            <p>
              Les prix des volumes ou des chapitres sont indiqués en T.T.C
              (toutes taxes comprises), selon le taux de T.V.A. applicable en
              France au jour et l’heure de l’achat.
            </p>
            <p>
              Cliquer dans le panier sur le bouton passer au paiement pour payer
              ;
            </p>
            <p>
              l’Utilisateur accepte les CGUV et confirme son obligation de
              paiement pour passer à l’étape suivante ; la fourniture
              instantanée du Contenu numérique s’oppose à l’existence d’une
              faculté de rétractation, l’Utilisateur renonce en conséquence
              expressément audit droit de rétractation ;
            </p>
            <p>
              Il parviendra ensuite à l’écran de paiement sécurisé et pourra
              ainsi valider définitivement sa commande en payant via les
              prestataires de paiement, partenaires du Prestataire.
            </p>
            <H2>6. Preuve de la transaction – conservation du contrat</H2>
            <p>
              L'archivage des communications, des commandes et des factures est
              effectué sur un support fiable de manière à constituer une copie
              fidèle et durable conformément à l'article 1379 du Code civil.
            </p>
            <p>
              Ces communications, commandes et factures peuvent être produites à
              titre de preuve du contrat.
            </p>
            <H2>7. Opérations promotionnelles</H2>
            <p>
              Les conditions des opérations promotionnelles, notamment la durée
              de validité, sont précisées à l’occasion de leur mise en œuvre.
            </p>
            <H2>8. Droit de rétractation</H2>
            <p className="bold">
              Conformément à l’article L221-28 du Code de la consommation, le
              Service ayant pour objet la fourniture d'un Contenu numérique non
              fourni sur un support matériel dont l'exécution commence après
              accord préalable exprès du consommateur et renoncement exprès à
              son droit de rétractation, le droit de rétractation institué par
              le Code de la Consommation n’est pas applicable au présent
              contrat.
            </p>
            <H2>9. Responsabilités</H2>
            <H3>9.1. Responsabilité de l’Utilisateur</H3>
            <p>
              L’Utilisateur est seul responsable des équipements techniques
              (ordinateur, tablette, smartphone…) et des terminaux de connexion
              lui permettant d’accéder au Service. Il lui appartient de protéger
              lesdits équipements et terminaux contre toute tentative
              d'intrusion et notamment contre les virus. En cas de dommage
              (virus) lié à l’utilisation du Site ou de l’Application, la
              responsabilité du Prestataire à l’égard de l’Utilisateur sera
              limitée au dommage certain, direct, personnel et légitime. En
              aucun cas le Prestataire ne pourra être tenu d’indemniser les
              dommages indirects tels que la perte d’exploitation, les
              préjudices d’image et de réputation, le préjudice moral et les
              dommages incertains tels que la perte de chance.
            </p>
            <p>
              L’utilisation d’un compte personnel est strictement réservée à
              l’utilisation personnelle de son titulaire ou dans le cadre d’une
              utilisation privée limitée au cercle de la famille. L’Utilisateur
              s’engage à ne pas communiquer les identifiants de connexion à son
              Compte personnel à des tiers.
            </p>
            <p>
              Les Contenus sont protégés par des droits de propriété
              intellectuelle. En conséquence, l’Utilisateur ne peut télécharger
              et enregistrer les Contenus de manière durable sur ses propres
              équipements de même qu’il ne peut transmettre les contenus à des
              tiers.
            </p>
            <p>
              Le Prestataire se réserve le droit de suspendre, voire supprimer
              l’accès au Service à tout Utilisateur qui ne respecterait pas les
              présentes CGUV.
            </p>
            <H3>9.2. Responsabilité de Mangaebook</H3>
            <p>
              Le Prestataire, en sa qualité d’hébergeur du Service ici
              considéré, au sens des dispositions de la loi n° 2004-575 du 21
              juin 2004 pour la confiance dans l’économie numérique, ne saurait
              être responsable du contenu publié par les Utilisateurs sur le
              Site et sur lesquels le Prestataire n’exerce aucun pouvoir de
              contrôle et de surveillance. L’Utilisateur s’engage expressément,
              sous peine de voir sa responsabilité pénale ou civile engagée, à
              ce que les messages qu’il met en ligne ne soient pas de nature à,
              sans que cette liste ne présente un caractère exhaustif, notamment
              :
            </p>
            <ul>
              <li>
                constituer une violation des droits de propriété intellectuelle
                de tiers ; à cet égard le membre s’engage à ne pas reproduire ni
                communiquer au public sur le Site des éléments protégés au titre
                du droit d’auteur, déposés à titre de marque ou plus
                généralement protégés par tout autre droit de propriété
                intellectuelle détenu par des tiers sans le consentement
                préalable du ou des détenteurs de tels droits de propriété ;
              </li>
              <li>
                contenir des virus informatiques de nature à interrompre,
                détruire ou altérer les fonctionnalités du Site ou de
                l’Application ;
              </li>
              <li>
                faire l’apologie de crimes ou contenir des messages illégaux,
                menaçants, ou à caractère pédophile, pornographique,
                diffamatoire, obscène, haineux, raciste, antisémite, xénophobe,
                révisionniste ou contraire à l’ordre public ou aux bonnes mœurs
                ;
              </li>
              <li>
                porter atteinte à la vie privée ou au respect de la dignité des
                tiers ;
              </li>
              <li>
                inciter à la violence, au fanatisme, au crime, au suicide, à la
                haine en raison de la religion, de la race, du sexe, de
                l’orientation sexuelle, de l’ethnie ;
              </li>
              <li>harceler les autres Utilisateurs ;</li>
              <li>
                favoriser ou encourager toute activité ou entreprise criminelle
                ;
              </li>
              <li>
                solliciter et/ou communiquer des mots de passe et/ou des
                informations personnelles à des fins commerciales ou illégales ;
              </li>
              <li>
                transmettre des chaînes d’e-mails, de mailings massifs non
                sollicités, de messages instantanés, de messages publicitaires
                non souhaités ou de courriers non sollicités ;
              </li>
              <li>
                comporter des annonces publicitaires et/ou sollicitations afin
                de proposer des produits et/ou services à la commercialisation
                par l’intermédiaire du Site ;
              </li>
              <li>
                contenir des adresses ou des liens hypertextes renvoyant vers
                des sites extérieurs dont le contenu est contraire aux lois et
                règlements en vigueur, qui portent atteinte aux droits des tiers
                ou qui seraient contraires aux présentes conditions.
              </li>
            </ul>
            <p>
              Le Prestataire s’engage à mettre tout en œuvre pour assurer le bon
              fonctionnement et l’accessibilité du Site. Il n’est toutefois tenu
              qu’à une obligation de moyen au regard la continuité de l’accès au
              Site. En conséquence, sa responsabilité ne saurait être engagée en
              cas d’interruption temporaire ou définitive du Service.
            </p>
            <H3>Arrêt du service :</H3>
            <p className="bold">
              L’utilisateur comprend et accepte que le prestataire peut mettre
              fin au service après lui avoir notifié l’arrêt du service par
              email, l’utilisateur aura un mois à partir de la réception du mail
              de la notification de l’arrêt du service pour télécharger les
              contenus payés conformément aux conditions générales de vente et
              ‘utilisation. Notamment Les contenus téléchargés ne peuvent être
              communiqués, dupliqués ou cédés à titre gracieux ou payant à
              quiconque, sous peine de sanctions pénales et de
              dommages-intérêts. L’Utilisateur comprend et accepte que les
              fichiers téléchargés soit marqués et identifiés par tous moyens
              techniques pour lutter contre le piratage et pour protéger la
              propriété intellectuelle des auteurs et des éditeurs.
            </p>
            <H2 ref={cookiesRef}>10. Données à caractère personnel</H2>
            <p>
              Afin de permettre aux Utilisateurs d’utiliser le Service le Site,
              le Prestataire réalise un Traitement des Données à Caractère
              personnel de l’Utilisateur dans le respect du Règlement général
              pour la protection des Données à Caractère personnel mis en œuvre
              dans les conditions suivantes :
            </p>
            <H3>Modalité de collecte :</H3>
            <p>
              Un cookie est un petit fichier stocké par un serveur dans le
              terminal (ordinateur, téléphone, etc.) d’un utilisateur et associé
              à un domaine web (c’est à dire dans la majorité des cas à
              l’ensemble des pages d’un même site web). Ce fichier est
              automatiquement renvoyé lors de contacts ultérieurs avec le même
              domaine. Les cookies ont de multiples usages : ils peuvent servir
              à mémoriser votre identifiant client auprès d'un site marchand, le
              contenu courant de votre panier d'achat, la langue d’affichage de
              la page web, un identifiant permettant de tracer votre navigation
              à des fins statistiques ou publicitaires, etc. Certains de ces
              usages sont strictement nécessaires aux fonctionnalités
              expressément demandées par l’utilisateur ou bien à l’établissement
              de la communication et donc exemptés de consentement. D’autres,
              qui ne correspondent pas à ces critères, nécessitent un
              consentement de l’utilisateur avant lecture ou écriture.
            </p>

            <p>
              Les cookies sont necessaires au bon fonctionnement du site et pour
              privilégier une expérience utilisateur améliorée.
            </p>
            <p>Nous utilisons des cookies pour:</p>
            <ul>
              <li>- Authentifier l'utilisateur lors de ses connections.</li>
              <li>- Authentifier l'utilisateur lors de ses achats.</li>
              <li>- Conserver le contenu du panier de l'utilisateur.</li>
              <li>
                - Mémoriser les titres en cours de lecture dans la librairie
                personnelle de l'utilisateur.
              </li>
            </ul>
            <p>Après acceptation expresse pour les cookies ;</p>
            <p>
              Via le formulaire de collecte lors de la création d’un Compte
              personnel par l’Utilisateur ou lors d’une commande sans création
              d’un compte, les Utilisateurs étant informés lors de ladite
              collecte, du caractère obligatoire des données à communiquer.
            </p>
            <p>
              Responsable de traitement : La OPHELBE immatriculée au RCS de
              Paris sous le n°489 833 442 dont le siège social est 19 Chemin de
              la Croix Javot 94430, prise en la personne de son représentant
              légal, Yves Huchez, DG.
            </p>
            <p>
              L’Utilisateur peut accéder aux données le concernant, les
              rectifier ou les faire effacer. Il dispose également d'un droit à
              la portabilité et d’un droit à la limitation du Traitement de ses
              données.
            </p>
            <p>
              Pour exercer ces droits ou pour toute question sur le Traitement
              des Données à Caractère personnel dans ce contrat, le titulaire
              des données peut contacter le Responsable de traitement : Par
              courrier : OPHELBE, 19 chemin de la croix Javot 94430 ou via le
              formulaire de contact.
            </p>
            <p>
              Il est toutefois précisé qu’en cas d’opposition, de demande
              d’effacement ou de limitation au Traitement des données, le
              Prestataire ne pourra garantir l’accès au Service.
            </p>
            <p>
              L’Utilisateur peut également modifier ses coordonnées personnelles
              depuis son Compte personnel en s’identifiant à l’aide de son
              identifiant et de son mot de passe.
            </p>
            <p>
              L’Utilisateur peut introduire une réclamation relative au
              Traitement des données le concernant auprès de l’autorité de
              contrôle du lieu d’établissement du Responsable de traitement ou
              de l’autorité de contrôle de l’Etat membre de l’Union européenne
              dans lequel il réside (CNIL pour la France).
            </p>
            <H2>11. Propriété intellectuelle</H2>
            <p>
              La structure générale du Site et/ou tous autres éléments les
              composant (tels que notamment les Contenus, la charte graphique,
              les textes, visuels, photographies, logos, nom de domaine,
              éléments associés aux Contenus en ce compris les photographies,
              images, textes et biographie des auteurs, et/ou de tout autre
              ayant droit des Contenus) sont la propriété exclusive du
              Prestataire ou ont fait l’objet d’une autorisation préalable
              d’utilisation au bénéfice du Prestataire.
            </p>
            <p>
              Toute reproduction et représentation, totale ou partielle, du Site
              et/ou des éléments les composant par quelque procédé que ce soit,
              sans l’autorisation expresse du Prestataire sont dès lors
              interdites et constitueraient une contrefaçon sanctionnée par les
              articles L. 335-2 et suivants du Code de la propriété
              intellectuelle par des peines pouvant aller jusqu’à 3 ans
              d’emprisonnement et 300.000 € d’amende.
            </p>
            <p>
              Tout lien hypertexte renvoyant au Site et utilisant la technique
              du « framing » (technique de programmation offrant la possibilité
              de diviser la fenêtre d’un navigateur web en plusieurs cadres
              autonomes dans le but d’afficher le contenu d’un site extérieur)
              ou du « in-line linking » (procédé pour faire apparaître dans une
              page web un seul élément extrait d’un autre site, ce qui économise
              de l’espace de stockage sur le disque dur de la machine où est
              hébergé le site et qui a pour effet de dissimuler à un utilisateur
              non averti l’environnement d’origine auquel appartient cet
              élément) est formellement interdit.
            </p>
            <p>
              Les droits consentis à l’Utilisateur sur les Contenus sont
              strictement limités à son usage personnel, non collectif et non
              marchand.
            </p>
            <H2>12. Force majeure</H2>
            <p>
              Conformément à l’article 1218 du Code civil, il y a force majeure
              en matière contractuelle lorsqu'un événement échappant au contrôle
              du débiteur, qui ne pouvait être raisonnablement prévu lors de la
              conclusion du contrat et dont les effets ne peuvent être évités
              par des mesures appropriées, empêche l'exécution de son obligation
              par le débiteur.
            </p>
            <p>
              Le Prestataire s’engage à informer l’Utilisateur de la survenance
              d’un cas de force majeure dans les 3 jours de sa survenance en
              indiquant si possible la durée prévisible d’empêchement. Si
              l'empêchement est temporaire, l'exécution des obligations du
              Prestataire est suspendue. Si l'empêchement est définitif, le
              contrat est résolu de plein droit et les parties sont libérées de
              leurs obligations dans les conditions prévues aux articles 1351 et
              1351-1 du Code civil.
            </p>
            <H2>13. Langue du contrat - loi applicable</H2>
            <p>
              Les présentes Conditions d'utilisation sont soumises au droit
              français.
            </p>
            <p>
              La langue du présent contrat est le français de même que
              l’ensemble des échanges entre les parties.
            </p>
            <p>
              Dans le cas où les présentes seraient traduites en une ou
              plusieurs langues, seul le texte français ferait foi en cas de
              litige.
            </p>
            <p>
              Les relations contractuelles entre mangaebook et les Utilisateurs
              sont régies par le droit français.
            </p>
          </article>
        </Container>
      </WrapperContainer>
    </>
  );
};

const H1 = styled.h1`
  position: absolute;
  top: 70px;
  left: 30px;
  font-size: 14px;
  @media (max-width: 1150px) {
    left: 10px;
  }
`;

const H2 = styled.h2`
  text-transform: uppercase;
  font-weight: bold;
  padding: 20px 0 10px 0;
`;

const H3 = styled.h3`
  text-transform: uppercase;
  font-size: 13px;
  font-weight: bold;
  padding: 0;
`;

const Container = styled.div`
  & p {
    padding: 10px 0;
    &.bold {
      font-weight: bold;
    }
  }
  & li {
    font-weight: bold;
    font-size: 14px;
  }
`;

export default Cgu;
