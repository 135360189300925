import React from "react";
import List from "../../../components/admin/input/List";
import styled from "styled-components";
import AddToList from "../../../components/admin/input/AddToList";
import { propertyToTitle } from "../../../lib/adminControler/categoryControler/inputControler";

const Form = ({ inputValues, onchange, deleteElement }) => {
  let choice = [
    "frenchTitles",
    "originalTitles",
    "tags",
    "authors",
    "illustrators",
    "traductors",
    "agents",
    "frenchPublishers",
    "originalPublishers",
    "prices",
  ];

  return (
    <>
      {choice?.map((property, i) => (
        <Container key={i}>
          <List
            name={propertyToTitle(property)}
            myArray={inputValues[property]}
            deleteElement={(el) => deleteElement(property, el)}
          />
          {inputValues[property] && (
            <AddToList
              name={property}
              title={`ajouter des ${propertyToTitle(property)}`}
              type={property === "prices" ? "number" : "text"}
              list={inputValues[property]}
              addToMyList={(e) => onchange(property, e)}
            />
          )}
        </Container>
      ))}
    </>
  );
};

const Container = styled.div`
  width: 100%;
  border: solid grey 1px;
  padding: 1em;
  margin-bottom: 1em;
`;

export default Form;
