import React from "react";
import styled from "styled-components";
import Secure from "./icons/Secure";
import Digital from "./icons/Digital";
import NoAdvert from "./icons/NoAdvert";
import NoSubscripte from "./icons/NoSubscripte";

const IconComponent = () => {
  return (
    <Wrapper>
      <Ul>
        <Container className="noAdvert">
          <Li>
            <NoAdvert height="95" />
          </Li>
        </Container>
        <Container>
          <Li>
            {" "}
            <Digital height="95" />
          </Li>
        </Container>
        <Container>
          <Li>
            <NoSubscripte height="95" />
          </Li>
        </Container>
        <Container>
          <Li>
            <Secure height="95" />
          </Li>
        </Container>
      </Ul>
    </Wrapper>
  );
};

const Wrapper = styled.section`
  width: 100%;
  margin-top: 30px;
  margin-bottom: 30px;
`;
/*@media (max-width: 600px) {
   display:none;*/

const Ul = styled.ul`
  margin: 0 auto;
  max-width: 800px;
  display: flex;
  justify-content: space-between;
  @media (max-width: 800px) {
    padding: 0 10px;
  }
  @media (max-width: 720px) {
    flex-wrap: wrap;
    max-width: 350px;
  }
`;

const Container = styled.div`
  @media (max-width: 720px) {
    box-sizing: border-box;
    flex-grow: 0;
    flex-shrink: 0;
    flex-basis: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    &:first-child {
      margin-bottom: 10px;
    }
    &:nth-child(2) {
      margin-bottom: 10px;
    }
  }
  @media (max-width: 380px) {
    flex-basis: 100%;
    margin-bottom: 10px;
    &:last-child {
      margin-bottom: 0;
    }
  }
`;

const Li = styled.li`
  display: inline-block;
  text-align: center;
  width: 140px;
  padding: 10px 10px 0px 10px;
  background-color: white;
  border-radius: 20px;
  border: 1px solid ${(props) => props.theme.secondary.color};
`;

export default IconComponent;
