import { actions } from "../actions/index";

const { FETCH_POPUPMESSAGE, DELETE_MESSAGE } = actions;

const initialState = {
  message: "",
};

export function popupMessages(state = initialState, action) {
  switch (action.type) {
    case FETCH_POPUPMESSAGE:
      return {
        ...state,
        message: action.payload,
      };
    case DELETE_MESSAGE:
      return {
        ...state,
        message: "",
      };
    default:
      return state;
  }
}
