import {
  legacy_createStore as createStore,
  compose,
  applyMiddleware,
  combineReducers,
} from "redux";
import { reducer } from "../reducers/index";
import { censorship } from "../reducers/censorship";
import { adminIsConnected } from "../reducers/adminConnected";
import { userIsConnected } from "../reducers/userConnected";
import { allUsers } from "../reducers/users";
import { allAdmins } from "../reducers/admin";
import { allProducts } from "../reducers/products";
import { allCategories } from "../reducers/category";
import { tvas } from "../reducers/tva";
import { keys } from "../reducers/key";
import { allNotifications } from "../reducers/notifications";
import { messages } from "../reducers/message";
import { cart } from "../reducers/cart";
import { network } from "../reducers/network";
import { allVolumes } from "../reducers/allVolumes";
import { popupMessages } from "../reducers/popupMessage";
import { carousel } from "../reducers/carousel";
import thunk from "redux-thunk";

const composeEnhancer = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;

const rootReducer = combineReducers({
  reducer,
  network,
  censorship,
  adminIsConnected,
  userIsConnected,
  allAdmins,
  allUsers,
  allProducts,
  allVolumes,
  allCategories,
  tvas,
  keys,
  allNotifications,
  messages,
  cart,
  popupMessages,
  carousel,
});

export const store = createStore(
  rootReducer,
  composeEnhancer(applyMiddleware(thunk))
);
