import React, { useState, useEffect } from "react";
import styled from "styled-components";

const AddToList = ({ type, title, list, addToMyList }) => {
  const [myValue, setMyValue] = useState("");
  const [searchValue, setSearchValue] = useState();

  useEffect(() => {
    if (list.length > 0) {
      const mySearch = list.filter((stuff) => {
        if (typeof stuff === "string") {
          let searchString = stuff.substring(0, myValue.length);
          return searchString === myValue;
        }
        return false;
      });
      setSearchValue(mySearch);
    }
  }, [myValue, list]);

  const handleKeyDown = (e) => {
    if (e.keyCode === 13) {
      addToListAfterVerif();
    }
  };

  const addToListAfterVerif = () => {
    let sameValue = list.find(
      (input) => input.toString() === myValue.toString()
    );
    if (myValue.length > 0 && sameValue === undefined) {
      addToMyList(myValue);
      setMyValue("");
    }
  };

  return (
    <InputBlock>
      <Container>
        <Label>{title} :</Label>
        <InputField
          type={type}
          value={myValue}
          onChange={(e) => setMyValue(e.target.value)}
          onKeyDown={handleKeyDown}
        />
      </Container>
      <button type="button" onClick={addToListAfterVerif}>
        ajouter
      </button>
      {myValue?.length > 0 && searchValue?.length > 0 && (
        <Advert>
          <Label> Vous voulez dire :</Label>
          <textarea
            style={{ margin: "0em 1em" }}
            rows="2"
            cols="30"
            defaultValue={searchValue?.map((mySearch) => `${mySearch} \n`)}
          />
          <p> car cette valeur existe déjà en base de donnée !</p>
        </Advert>
      )}
    </InputBlock>
  );
};

const Label = styled.label`
  font-weight: bold;
  font-size: 12px;
`;

const Advert = styled.div`
  display: flex;
  align-items: center;
  margin-left: 2em;
`;

const InputField = styled.input`
  &::placeholder {
    color: blue;
  }
  &:focus {
    outline: solid black 2px;
    border-radius: 2px;
  }
  color: "blue";
  height: 25px;
  font-size: 12px;
  border: none;
  margin-top: 3px;
  margin-bottom: 1em;
  width: 100%;
`;

const Container = styled.div`
  display: flex;
  flex-direction: column;
  width: 48%;
  margin-right: 2em;
`;

const InputBlock = styled.div`
  width: 100%;
  display: flex;

  align-items: center;
  padding: 1em 0em;
`;

export default AddToList;
