import { REACT_APP_API_HOST } from "../../../../service";

const xyz = (array, ref, volumeId, chapterId, p, isLoading, setLoaded) => {
  const url = `${REACT_APP_API_HOST}/encrypt/${volumeId}/${chapterId}/${p}.jpg`;

  const resolve = (img, array, ref, isLoading, setLoaded) => {
    const canvas = ref;
    let ctx = canvas?.getContext("2d");
    canvas.width = img.width;
    canvas.height = img.height;
    var verticalSlices = 27;
    var horizontalSlices = 27;
    var sliceWidth = canvas.width / verticalSlices;
    var sliceHeight = canvas.height / horizontalSlices;

    for (var i = 0; i < array?.length; i++) {
      var s = array[i];
      var row = parseInt(s / verticalSlices);
      var col = s - row * verticalSlices;
      var x = col * sliceWidth;
      var y = row * sliceHeight;

      var canvasRow = parseInt(i / verticalSlices);
      var canvasCol = i - canvasRow * verticalSlices;
      var canvasX = canvasCol * sliceWidth;
      var canvasY = canvasRow * sliceHeight;

      ctx.drawImage(
        img,
        canvasX,
        canvasY,
        sliceWidth,
        sliceHeight,
        x,
        y,
        sliceWidth,
        sliceHeight
      );
    }
    setLoaded(p);
    isLoading(false);
  };

  const reject0 = (ref, isLoading, setLoaded) => {
    const canvas = ref;
    let ctx = canvas?.getContext("2d");
    canvas.width = 1000;
    canvas.height = 1500;
    ctx.fillStyle = "white";
    ctx.fillRect(0, 0, 1000, 1500);

    setLoaded(p);
    isLoading(false);
  };

  testImg(url, p).then(
    function fulfilled(img) {
      resolve(img, array, ref, isLoading, setLoaded);
    },

    function rejected() {
      if (p === 0) {
        reject0(ref, isLoading, setLoaded);
      } else {
        console.log(`That image was not found ${p}`);
      }
    }
  );
};

const testImg = (url, p) => {
  const imgPromise = new Promise(function imgPromise(resolve, reject) {
    // Create the image
    const imgElement = new Image();
    imgElement.crossOrigin = "anonymous";
    imgElement.decoding = "async";

    // When image is loaded, resolve the promise
    imgElement.addEventListener("load", function imgOnLoad() {
      resolve(this);
    });

    // When there's an error during load, reject the promise
    imgElement.addEventListener("error", function imgOnError() {
      reject(p);
    });

    // Assign URL
    imgElement.src = url;
  });
  return imgPromise;
};

export const decryptPage = (
  ref,
  volumeId,
  chapterId,
  p,
  bookInfo,
  isLoading,
  setLoaded
) => {
  isLoading(true);

  const array = bookInfo.idty;

  xyz(array, ref, volumeId, chapterId, p, isLoading, setLoaded);
};
