import React, { useState, useEffect } from "react";
import styled from "styled-components";
import SearchBar from "../../../components/marketplace/navbar/SearchBar";
import { useSelector } from "react-redux";
import Slider from "../../../components/marketplace/library/Slider/Slider";
import { useProductsState } from "../categoryPage/controllers/stateCtrl";
import { Helmet } from "react-helmet-async";
import { Link } from "react-router-dom";

const Search = () => {
  const products = useProductsState(useSelector);

  const [search, setSearch] = useState("");
  const [searchResult, setSearchResult] = useState([]);
  const [activeProducts, setActiveProducts] = useState([]);

  const onchange = (value) => {
    setSearch(value);
  };

  useEffect(() => {
    if (products && products.length > 0) {
      const active = products && products.filter((p) => p.active);
      if (active && active.length > 0) {
        setActiveProducts(active);
      }
    }
  }, [products]);

  useEffect(() => {
    if (activeProducts && search && search.length > 0) {
      let byAuthor = activeProducts.filter(
        (book) =>
          book.author[0].slice(0, search.length).toLowerCase() ===
          search.toLowerCase()
      );

      let byIllustrator = activeProducts.filter(
        (book) =>
          book.illustrator.slice(0, search.length).toLowerCase() ===
          search.toLowerCase()
      );

      let byOriginalPublisher = activeProducts.filter(
        (book) =>
          book.originalPublisher.slice(0, search.length).toLowerCase() ===
          search.toLowerCase()
      );

      let byKeyWord = () => {
        let array = [];

        activeProducts.forEach((book) => {
          if (
            book.frenchTitle.toLowerCase().indexOf(search.toLowerCase()) >= 0
          ) {
            array.push(book);
          }
        });
        return array;
      };

      let sortSearch = [
        byKeyWord(),
        byAuthor,
        byIllustrator,
        byOriginalPublisher,
      ];
      const sort = sortSearch.sort((a, b) => b.length - a.length);
      setSearchResult([...sort[0]]);
    }
    if (activeProducts && search.length === 0) {
      setSearchResult(activeProducts);
    }
  }, [search, activeProducts]);

  return (
    <Wrapper>
      <Helmet>
        <title>Recherchez vos yaoi/hentai par titre ou auteur.</title>
        <meta
          id="og-title"
          property="og:title"
          content={`Recherchez vos yaoi/hentai par titre ou auteur.`}
        />
        <meta
          name="title"
          content={`Recherchez vos yaoi/hentai par titre ou par auteur.`}
        />
        <meta name="description" content={`Recherche par titre ou auteur.`} />
        <meta
          property="og:description"
          content="Recherche par titre ou auteur."
        />
        <meta
          name="prerender-header"
          content="Location: http://www.mangaebook.com/search"
        ></meta>
        <meta id="og-image" property="og:image" content="/og-img.jpg" />
        <meta property="og:url" content="http://www.mangaebook.com/search" />
      </Helmet>
      <H1 className="segoesc">
        <Link to="/">MangaeBook /</Link>Rechecher
      </H1>
      <SearchBar onchange={onchange} search={search} />
      {searchResult && searchResult.length > 0 ? (
        <Slider
          array={searchResult}
          isFixed={true}
          title="résultats"
          article={true}
        />
      ) : (
        <Span className="segoesc">aucun résultat...</Span>
      )}
    </Wrapper>
  );
};

const Wrapper = styled.div`
  max-width: 1200px;
  margin: 0 auto;
`;

const H1 = styled.h1`
  position: absolute;
  top: 70px;
  left: 30px;
  font-size: 14px;
  @media (max-width: 1150px) {
    left: 10px;
  }
`;

const Span = styled.span`
  display: inline-block;
  width: 100%;
  text-transform: uppercase;
  text-align: center;
  margin-top: 50px;
`;

export default Search;
