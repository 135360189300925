import React from "react";
import styled from "styled-components";
import { Link } from "react-router-dom";
//import facebookIcon from "./rsIcon/facebook.png";
import twitterIcon from "./rsIcon/twitter.png";
import { useSelector } from "react-redux";
import { useNetworkState } from "../../../views/marketplace/categoryPage/controllers/stateCtrl";

const Footer = () => {
  const { /*facebook,*/ twitter } = useNetworkState(useSelector);
  return (
    <Wrapper>
      <Ul>
        <Li>
          <Link to="/about">À propos</Link>
        </Li>
        <Li>
          <Link to="/faq">FAQ</Link>
        </Li>
        <Li>
          <Link to="/cgu">CGU</Link>
        </Li>
        <Li>
          <Link to="/contact">Contact</Link>
        </Li>
        <Li className="rs">
          {/*<Link to={facebook} target="_blank" rel="noopener noreferrer">
            <Img src={facebookIcon} alt="facebook" />
          </Link>*/}
          <Link to={twitter} target="_blank" rel="noopener noreferrer">
            {<Img src={twitterIcon} alt="twitter" />}
          </Link>
        </Li>
      </Ul>
    </Wrapper>
  );
};

const Wrapper = styled.footer`
  background-color: ${(props) => props.theme.primary.black};
  width: 100%;
  height: 60px;
  color: white;
  font-size: 16px;
  @media (max-width: 550px) {
    font-size: 14px;
  }
`;

const Ul = styled.ul`
  margin: 0 auto;
  max-width: 1200px;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: space-around;
`;

const Li = styled.li`
  cursor: pointer;
  &.rs {
    width: 85px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    @media (max-width: 550px) {
      width: 50px;
    }
  }
`;

const Img = styled.img`
  height: 30px;
  color: white;
  filter: invert(100%) sepia(100%) saturate(0%) hue-rotate(288deg)
    brightness(102%) contrast(102%);
  @media (max-width: 550px) {
    height: 23px;
  }
`;

export default Footer;
