import React, { useState, useEffect } from "react";
import styled from "styled-components";
import ConnectButton from "./ConnectButton";
import CloseButton from "./CloseButton";
import { Link } from "react-router-dom";

const ConnectForm = ({ toggleConnectForm, forgetPass = false, token }) => {
  const [form, setForm] = useState({
    logOrSign: forgetPass ? "forgetpass" : "login",
    pseudo: "",
    email: "",
    password: "",
    passwordBis: "",
    resetPassword: false,
    token: "",
    cgu: false,
  });

  const toggleLoginForm = () => {
    setForm({ ...form, logOrSign: "login" });
  };

  const toggleSignupForm = () => {
    setForm({ ...form, logOrSign: "signup" });
  };

  //choose if "login" "signup" or "forgetPassword" request and !resetPassword if signup
  useEffect(() => {
    if (form.logOrSign === "signup") {
      setForm({ ...form, resetPassword: false });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [form.logOrSign]);

  useEffect(() => {
    if (form.resetPassword) {
      setForm({ ...form, password: "" });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [form.resetPassword]);

  //for reset password
  useEffect(() => {
    if (token) {
      setForm({ ...form, token: token });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [token]);

  const message = (log) => {
    if (log === "login") {
      if (!form.resetPassword) {
        return "Connexion";
      } else {
        return "Changer de mot de passe";
      }
    }
    if (log === "signup") {
      return "Créer un compte";
    }
    if (log === "forgetpass") {
      return "Réinitialiser le mot de passe";
    }
  };

  return (
    <Wrapper autocomplete="off">
      {!forgetPass && <CloseButton close={toggleConnectForm} />}
      {form.logOrSign !== "forgetpass" && (
        <Container>
          <Button
            className="left"
            connect={form.logOrSign}
            onClick={toggleLoginForm}
          >
            se connecter
          </Button>
          <Button
            className="right"
            connect={form.logOrSign}
            onClick={toggleSignupForm}
          >
            s'inscrire
          </Button>
        </Container>
      )}
      <Container2>
        {form.logOrSign === "signup" && (
          <Input
            name="pseudo"
            type="text"
            placeholder="Pseudo"
            value={form.pseudo}
            onChange={(e) => setForm({ ...form, pseudo: e.target.value })}
          />
        )}
        <Input
          name="email"
          type="email"
          placeholder="Email"
          value={form.email}
          onChange={(e) => setForm({ ...form, email: e.target.value })}
        />
        <Input
          name="password"
          type="password"
          placeholder="Mot de passe (8 caractères)"
          value={form.password}
          onChange={(e) =>
            setForm({
              ...form,
              password: !form.resetPassword ? e.target.value : "",
            })
          }
        />
        {form.logOrSign !== "login" && (
          <>
            <Input
              name="passwordBis"
              type="password"
              placeholder="Mot de passe"
              value={form.passwordBis}
              onChange={(e) =>
                setForm({ ...form, passwordBis: e.target.value })
              }
            />
            <Span>
              * Au moins une majuscule / une minuscule / un chiffre et 8
              caractères.
            </Span>
          </>
        )}

        {form.logOrSign === "login" && (
          <Checkbox>
            <label htmlFor="reinitPassword">Mot de passe oublié? </label>
            <Test>
              <input
                type="checkbox"
                id="reinitPassword"
                checked={form.resetPassword}
                onChange={() =>
                  setForm({ ...form, resetPassword: !form.resetPassword })
                }
              />
              <label
                htmlFor="reinitPassword"
                bckcolor={form.resetPassword.toString()}
              ></label>
            </Test>
          </Checkbox>
        )}
        {form.logOrSign === "signup" && (
          <Checkbox>
            <label htmlFor="cgu">
              Acceptez-vous les{" "}
              <Link to="/cgu" target="_blank" rel="noopener noreferrer">
                <Strong>CGU </Strong>
              </Link>
              ?{" "}
            </label>
            <Test>
              <input
                type="checkbox"
                id="cgu"
                checked={form.cgu}
                onChange={() => setForm({ ...form, cgu: !form.cgu })}
              />
              <label htmlFor="cgu" bckcolor={form.cgu.toString()}></label>
            </Test>
          </Checkbox>
        )}
        <ConnectButton
          message={message(form.logOrSign)}
          form={form}
          token={token}
          toggleConnectForm={toggleConnectForm}
        />
      </Container2>
    </Wrapper>
  );
};

const Wrapper = styled.form`
  position: fixed;
  background-color: white;
  transform: translate(50%, 50%);
  right: 50%;
  bottom: 50%;
  border-radius: 5px;
  min-width: 300px;
  box-shadow: 0px 3px 9px rgba(0, 0, 0, 0.15);
  z-index: 999;
`;

const Strong = styled.strong`
  color: ${(props) => props.theme.secondary.color};
`;

const Container = styled.div`
  position: relative;
  width: 100%;
  display: flex;
  align-items: center;
  border-radius: 5px;
  height: 40px;
`;

const Container2 = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`;

const Button = styled.div`
  box-sizing: border-box;
  display: flex;
  flex-wrap: nowrap;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  width: 150px;
  height: 40px;
  text-transform: uppercase;
  font-size: 12px;
  font-weight: bold;
  letter-spacing: 0.5px;
  transition: all 0.2s ease-in-out;
  &.left {
    background-color: ${(props) =>
      props.connect === "login" ? "white" : props.theme.primary.color};
    color: ${(props) =>
      props.connect === "signup" ? "white" : props.theme.primary.color};
    border-radius: 5px 0 0 0;
    border-bottom: ${(props) =>
      props.connect === "login" ? `solid ${props.theme.color} 1px` : "none"};
  }
  &.right {
    background-color: ${(props) =>
      props.connect === "signup" ? "white" : props.theme.primary.color};
    color: ${(props) =>
      props.connect === "login" ? "white" : props.theme.primary.color};
    border-radius: 0 5px 0 0;
  }
`;

const Input = styled.input`
  width: 80%;
  border: none;
  border-bottom: solid 2px ${(props) => props.theme.primary.black};
  border-radius: 0;
  padding: 20px 0;
  font-size: 15px;
  letter-spacing: 0.5px;
  line-height: 20px;
  outline: none;
  &:focus {
    outline: none;
    border-bottom: solid 2px ${(props) => props.theme.primary.color};
  }
  &::placeholder {
    font-size: 14px;
    font-weight: bold;
    color: #8a8a8a;
  }
  &:-webkit-autofill {
    -webkit-box-shadow: 0 0 0px 1000px white inset;
  }
`; //no blue background when google autocomplete

const Checkbox = styled.div`
  display: flex;
  width: 80%;
  justify-content: space-between;
  align-items: center;
  font-size: 12px;
  font-weight: 500;
  letter-spacing: 0.5px;
  color: ${(props) => props.theme.primary.black};
  padding: 30px 0 0 0;
`;

const Test = styled.div`
  & input {
    display: none;
    & + label {
      display: block;
      position: relative;
      width: 3em;
      height: 1.6em;

      border-radius: 1em;
      background: #f3f3f3;
      box-shadow: inset 0px 0px 3px 1px rgba(0, 0, 0, 0.3);
      cursor: pointer;
      select: none;
      -webkit-user-select: none;
      -moz-user-select: none;
      -ms-user-select: none;
      -webkit-transition: background 0.1s ease-in-out;
      transition: background 0.1s ease-in-out;
      &:before {
        content: "";
        display: block;
        width: 1.2em;
        height: 1.2em;
        border-radius: 1em;
        background: #fff;
        box-shadow: 2px 0px 5px rgba(0, 0, 0, 0.2);
        position: absolute;
        left: 0.2em;
        top: 0.2em;
        -webkit-transition: all 0.2s ease-in-out;
        transition: all 0.2s ease-in-out;
      }
    }
    &:checked {
      & + label {
        background: ${(props) =>
          props.bckcolor === "false" ? "#fff" : props.theme.secondary.color};
        transition: all 0.2s ease-in-out;
        &:before {
          box-shadow: -2px 0px 5px rgba(0, 0, 0, 0.2);
          left: 1.6em;
        }
      }
    }
  }
`;

const Span = styled.span`
  font-size: 12px;
  width: 240px;
  padding-top: 10px;
  color: grey;
`;

export default ConnectForm;
