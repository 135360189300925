import React from "react";
import styled from "styled-components";

const Download = () => {
  return (
    <Svg
      version="1.1"
      id="Calque_1"
      xmlns="http://www.w3.org/2000/svg"
      x="0px"
      y="0px"
      width="384px"
      height="384px"
      viewBox="0 0 384 384"
      enable-background="new 0 0 384 384"
    >
      <g>
        <g>
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M121.324,138.585c0.18-2.658,0.535-5.12,0.482-7.572
			c-0.446-20.643-1.026-41.281-1.426-61.925c-0.106-5.476-0.076-10.997,0.496-16.435c1.373-13.055,8.482-21.952,21.396-24.358
			c12.852-2.394,25.964-3.868,39.024-4.52c10.593-0.527,21.297,0.491,31.913,1.317c11.465,0.892,19.918,10.447,19.682,22.514
			c-0.509,25.978-1.426,51.947-2.129,77.922c-0.124,4.601-0.018,9.208-0.018,14.335c8.119,0,15.743-0.001,23.368,0
			c9.328,0.001,18.657,0.024,27.986-0.001c4.786-0.014,8.463,1.883,10.425,6.32c1.942,4.395,1.034,8.44-2.12,12.04
			c-32.374,36.931-64.764,73.848-97.095,110.814c-10.284,11.758-23.317,11.284-33.126,2.595
			c-11.44-10.136-22.518-20.787-32.847-32.046c-17.101-18.642-33.589-37.853-50.07-57.051c-3.526-4.107-6.47-8.934-8.727-13.865
			c-4.603-10.055-0.802-19.025,5.628-26.727c1.842-2.206,6.192-3.117,9.434-3.213C95.907,138.369,108.23,138.585,121.324,138.585z
			 M222.681,94.579c0.785-0.001,1.57-0.002,2.355-0.003c0-15.492,0.048-30.985-0.018-46.478
			c-0.038-8.843-4.825-14.349-13.263-15.237c-19.133-2.015-38.271-1.954-57.365,0.435c-11.136,1.394-15.351,6.361-15.28,17.6
			c0.185,29.497,0.431,58.993,0.611,88.49c0.04,6.585-0.854,7.429-7.545,7.391c-15.165-0.088-30.33-0.297-45.494-0.27
			c-2.489,0.004-4.976,0.961-7.464,1.479c1.147,2.407,1.829,5.217,3.51,7.163c31.354,36.302,62.821,72.506,94.283,108.715
			c4.877,5.612,5.188,5.76,10.242,0.014c32.117-36.516,64.148-73.107,96.128-109.744c1.046-1.199,1.233-3.148,1.821-4.748
			c-1.628-0.532-3.253-1.516-4.885-1.526c-16.998-0.113-33.997-0.047-50.996-0.086c-5.754-0.013-6.633-0.954-6.638-6.721
			C222.672,125.562,222.681,110.07,222.681,94.579z"
          />
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M175.96,360.487c-30.645-0.547-61.29-1.066-91.934-1.662
			c-6.158-0.12-12.332-0.3-18.461-0.861c-9.981-0.914-15.924-6.017-18.195-15.713c-2.43-10.372-2.268-20.881,0.134-31.208
			c2.317-9.957,13.851-23.077,28.695-22.975c70.957,0.49,141.917,0.569,212.876,0.93c13.43,0.068,20.622,7.25,21.016,20.563
			c0.291,9.805,0.333,19.625,0.173,29.434c-0.19,11.715-6.543,19.579-18.018,21.873c-5.505,1.101-11.241,1.563-16.861,1.485
			c-33.144-0.45-66.285-1.145-99.427-1.757C175.959,360.561,175.96,360.523,175.96,360.487z M181.648,339.071
			c0,0.268,0,0.534,0,0.802c35.303,0,70.607,0.017,105.91-0.039c2.302-0.003,4.671-0.312,6.892-0.909
			c6.019-1.62,8.265-4.457,8.254-10.74c-0.012-6.988-0.472-13.976-0.52-20.966c-0.03-4.48-2.351-7.349-6.185-8.738
			c-2.903-1.053-6.146-1.612-9.24-1.626c-69.938-0.309-139.876-0.52-209.814-0.695c-2.471-0.007-5.017,0.259-7.398,0.889
			c-5.83,1.541-8.148,4.669-8.399,10.757c-0.253,6.155-0.409,12.315-0.735,18.467c-0.375,7.071,1.617,10.157,8.429,11.961
			c2.375,0.629,4.929,0.802,7.401,0.806C111.378,339.087,146.513,339.071,181.648,339.071z"
          />
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M336.221,159.441c-9.88-1.372-18.327-2.355-26.675-3.881
			c-1.46-0.267-2.504-2.813-3.74-4.305c1.588-1.093,3.272-3.238,4.748-3.104c8.451,0.772,16.891,1.88,25.239,3.394
			c1.629,0.296,2.843,2.882,4.248,4.411C338.548,157.318,337.055,158.68,336.221,159.441z"
          />
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M307.067,137.357c-0.868-0.594-2.515-1.119-2.801-2.058
			c-0.423-1.394-0.5-3.705,0.347-4.542c6.485-6.406,13.144-12.649,19.998-18.657c0.902-0.79,3.797-0.573,4.729,0.294
			c0.923,0.859,1.324,3.927,0.591,4.66c-6.68,6.672-13.648,13.056-20.572,19.479C308.919,136.942,308.143,136.986,307.067,137.357z"
          />
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M300.833,102.232c-2.536,8.466-4.676,16.136-7.263,23.651
			c-0.376,1.094-3.113,2.263-4.354,1.922c-1.227-0.337-2.981-2.8-2.712-3.885c1.916-7.715,4.126-15.37,6.691-22.892
			c0.402-1.179,3.165-2.286,4.685-2.111C299.063,99.054,300.01,101.236,300.833,102.232z"
          />
        </g>
      </g>
    </Svg>
  );
};

const Svg = styled.svg`
  height: 55%;
  width: 55%;
  @media (min-width: 1200px) {
    &:hover {
      transform: scale(1.1);
      transition: all 0.2s ease-in-out;
      & path {
        transition: all 0.2s ease-in-out;
      }
    }
  }
`;

export default Download;
