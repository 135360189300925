import React from "react";
import styled, { keyframes, css } from "styled-components";
import Arrow from "./Arrow";

const Keyboard = ({ landscape }) => {
  return (
    <Wrapper>
      <Top>
        <Key>
          <Up landscape={landscape}>
            <Arrow />
          </Up>
        </Key>
      </Top>
      <Bottom>
        <Key>
          <Left landscape={landscape}>
            <Arrow />
          </Left>
        </Key>
        <Key>
          <Down landscape={landscape}>
            <Arrow />
          </Down>
        </Key>
        <Key>
          <Right landscape={landscape}>
            <Arrow />
          </Right>
        </Key>
      </Bottom>
    </Wrapper>
  );
};

const clint = keyframes`
0%{fill: #fff;}
50%{fill: #ff6333;}
100%{fill: #fff;}
`;

const Wrapper = styled.div`
  width: 128px;
  height: 84px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
`;

const Top = styled.div`
  width: 100%;
  height: 40px;
  display: flex;
  justify-content: center;
`;

const Bottom = styled.div`
  width: 100%;
  height: 40px;
  display: flex;
  justify-content: space-between;
`;

const Key = styled.div`
  display: inline-block;
  width: 40px;
  height: 40px;
  border: 2px solid white;
  box-sizing: border-box;
  border-radius: 10px;
`;

const Up = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  transform: rotate(90deg);
  & svg {
    & path {
      animation: ${(props) =>
        !props.landscape &&
        css`
          ${clint} 1s ease-in-out infinite
        `};
    }
  }
`;

const Left = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  & svg {
    & path {
      animation:${(props) =>
        props.landscape &&
        css`
          ${clint} 1s ease-in-out infinite
        `}
      }
    }
  }
`;

const Right = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  transform: rotate(180deg);
  & svg {
    & path {
      animation:${(props) =>
        props.landscape &&
        css`
          ${clint} 1s ease-in-out infinite
        `}
      }
    }
  }
`;

const Down = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  transform: rotate(270deg);
  & svg {
    & path {
      animation:${(props) =>
        !props.landscape &&
        css`
          ${clint} 1s ease-in-out infinite
        `}
      }
    }
  }
`;

export default Keyboard;
