import React from "react";
import { useParams } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import styled from "styled-components";

import VolumesList from "./VolumesList";
import { useNavigate } from "react-router-dom";
import { fetchMessage } from "../../../lib/state/actions";
import { useProductsState } from "../../marketplace/categoryPage/controllers/stateCtrl";

const Volumes = () => {
  const { productId } = useParams();
  const products = useProductsState(useSelector);

  const product =
    products &&
    productId &&
    products?.find((product) => product._id === productId);
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const addNewVolume = () => {
    if (product.volumes.length > 1 && product.readingDirection === "webtoon") {
      dispatch(
        fetchMessage({ message: "Un webtoon ne compte qu'un seul volume !" })
      );
    } else {
      navigate(`/admin/products/createvolumes/${productId}`);
    }
  };

  return (
    <Wrapper>
      <Button type="button" onClick={() => addNewVolume()}>
        ajouter un volume
      </Button>
      {product?.volumes?.length > 0 && <VolumesList />}
    </Wrapper>
  );
};

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  box-sizing: border-box;
  background-color: lightblue;
  min-height: 100vh;
  z-index: 100;
  padding: 2em;
`;

const Button = styled.button`
  margin-top: 70px;
  border: none;
  padding: 10px 10px;
  background-color: black;
  color: white;
  font-weight: bold;
  border-radius: 5px;
  text-transform: uppercase;
  margin-bottom: 60px;
  cursor: pointer;
`;

export default Volumes;
