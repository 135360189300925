import { actions } from "../actions/index";

const { FETCH_ADMIN } = actions;

const initialState = {
  admin: [],
};

export function allAdmins(state = initialState, action) {
  switch (action.type) {
    case FETCH_ADMIN:
      return {
        ...state,
        admin: action.payload.admin,
      };
    default:
      return state;
  }
}
