import { actions } from "../actions/index";
import {
  localstorageCtrl,
  clearCartStorage,
} from "../localStorage/localStorageCtrl";

const {
  ADD_PRODUCT_TO_CART,
  REMOVE_ARTICLE_FROM_CART,
  LOAD_CARTSTORAGE,
  CLEAR_CART,
} = actions;

const initialState = [];

export function cart(state = initialState, action) {
  switch (action.type) {
    case ADD_PRODUCT_TO_CART:
      localstorageCtrl(
        "addToCart",
        action.payload.product,
        action.payload.userId
      );
      return [...state, action.payload.product];
    case REMOVE_ARTICLE_FROM_CART:
      localstorageCtrl(
        "removeFromCart",
        action.payload.id,
        action.payload.userId
      );
      return [...state.filter((art) => art._id !== action.payload.id)];

    case LOAD_CARTSTORAGE:
      return [...state, ...action.payload];
    case CLEAR_CART:
      clearCartStorage(action.payload);
      return [];
    default:
      return state;
  }
}
