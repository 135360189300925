import React from "react";
import styled from "styled-components";
import WrapperContainer from "../../../components/marketplace/WrapperContainer";
import QuestionEl from "../../../components/marketplace/faq/QuestionEl";
import { Helmet } from "react-helmet-async";

const Faq = () => {
  return (
    <>
      <Helmet>
        <title>Les questions fréquentes sur MangaeBook.</title>
        <meta
          name="description"
          content={`Nous répondons à vos questions les plus fréquentes sur MangaeBook et son fonctionnement.`}
        />
        <meta
          name="title"
          content={`Les questions fréquentes sur MangaeBook.`}
        />
        <meta
          name="prerender-header"
          content="Location: http://www.mangaebook.com/faq"
        ></meta>
        <meta
          id="og-title"
          property="og:title"
          content={`Les questions fréquentes sur MangaeBook.`}
        />
        <meta
          property="og:description"
          content={`Nous répondons à vos questions les plus fréquentes sur MangaeBook et son fonctionnement.`}
        />
        <meta property="og:url" content="https://mangaebook.com/faq" />
        <meta
          id="og-image"
          property="og:image"
          content="http://mangaebook.com/og-img.jpg"
        />
        <meta
          property="og:image:secure_url"
          content="https://mangaebook.com/og-img.jpg"
        />
        <meta property="og:image:type" content="image/jpeg" />
        <meta property="og:image:width" content="1200" />
        <meta property="og:image:height" content="630" />
      </Helmet>
      <H1 className="segoesc">
        <a href="/">MangaeBook /</a>FAQ
      </H1>
      <WrapperContainer>
        <Container>
          <QuestionEl
            question="Qu'est-ce que MangaeBook.com ?"
            response="MangaeBook.com est une plateforme de vente, de lecture en ligne et de téléchargement* de Manga."
            mention="Téléchargement limité disponible après l'achat selon le produit."
          />
          <QuestionEl
            question="Comment fonctionne MangaeBook.com ?"
            response="Le site propose de la vente de volume à l'unité. Une fois acheté, vous pouvez retouver ce volume dans votre librairie ou directement sur la fiche du produit. Vous pourrez ensuite le lire en ligne ou le télécharger au format epub*. "
            mention="Si option disponible."
          />
          <QuestionEl
            question="Proposez-vous de l'abonnement ?"
            response="Non. Nous tenons à proposer les meilleures titres mais il parait difficile (impossible ?) que tous les titres plaisent à tout le monde. C'est pour cette raison que nous proposons de la vente à l'unité, ainsi, vous ne choisissez que vos titres préférés."
          />
          <QuestionEl
            question="Peut-on télécharger les volumes après l'achat?"
            response="Oui. Pour la majorité des titres, le téléchargement est disponible au format epub. Une icone sur la fiche du produit vous informe au préalable si le téléchargement est disponible, et il en est fait mention* avant le paiement."
            mention="Dans le panier, chaques titres mentionnent : téléchargement disponible OU lecture en ligne uniquement."
          />
          <QuestionEl
            question="Est-ce que le téléchargement est illimité ?"
            response="Non. Lorsqu'il est disponible, le téléchargement est nominatif et limité*. C'est à dire que chaque fichier généré est crypté avec des données permettant d'identifier l'utilisateur en cas de partage sur les réseaux. La copie et la diffusion de ces fichiers constituent une infraction pouvant engendrer des poursuites. Dans ce cas, nous nous réservons de droit de désactiver le compte de l'utilisateur et de le poursuivre en justice."
            mention="Téléchargement limité à un par volume acheté par jour."
          />
          <QuestionEl
            question="Qu'est-ce qu'une exclusivité numérique ?"
            response="Il s'agit d'un volume uniquement disponible en version numérique, encore jamais publié au format papier."
          />
          <QuestionEl
            question="Vendez-vous au chapitre ?"
            response="Pas pour le moment... Peut-être plus tard mais dans l'éventualité d'une vente au chapitre, seule la lecture en ligne sera diponible."
          />
          <QuestionEl
            question="Comment résilier mon compte ?"
            response="Nous vous rappelons que chaque compte est gratuit car sans abonnement. Toutefois, si vous désirez réellement résilier votre compte, il vous suffit d'en faire la demande dans la rubrique Contact. Nous vous conseillons au préalable de télécharger tous les volumes achetés car la résiliation aura pour effet immédiat de bloquer l'accès à votre compte de manière définitive."
          />
        </Container>
      </WrapperContainer>
    </>
  );
};

const H1 = styled.h1`
  position: absolute;
  top: 70px;
  left: 30px;
  font-size: 14px;
  @media (max-width: 1150px) {
    left: 10px;
  }
`;

const Container = styled.div`
  width: 100%;
`;

export default Faq;
