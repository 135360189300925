import { actions } from "../actions/index";

const { FETCH_ALLVOLUME } = actions;

const initialState = {
  volumes: [],
};

export function allVolumes(state = initialState, action) {
  switch (action.type) {
    case FETCH_ALLVOLUME:
      return {
        ...state,
        volumes: action.payload.volumes,
      };
    default:
      return state;
  }
}
