import React from "react";
import styled from "styled-components";

const Textarea = ({ name, title, currentValue, onchange, originalValue }) => {
  return (
    <InputBlock>
      <Container>
        <Label htmlFor={title}>{title} :</Label>
        <InputField name={name} value={currentValue} onChange={onchange} />
      </Container>
    </InputBlock>
  );
};

const Label = styled.label`
  font-weight: bold;
  font-size: 12px;
`;

const InputField = styled.textarea`
  &::placeholder {
    color: blue;
  }
  &:focus {
    outline: solid black 2px;
    border-radius: 2px;
  }
  color: blue;
  height: 25px;
  font-size: 12px;
  border: none;
  margin-top: 3px;
  margin-bottom: 1em;
  width: 100%;
  height: 100px;
`;

const Container = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
`;

const InputBlock = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 1em 0em;
  border-bottom: solid 1px lightGrey;
`;

export default Textarea;
