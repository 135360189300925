import React, { useState, useEffect } from "react";
import styled from "styled-components";
import Text from "../../../components/admin/input/Text";
import Radio from "../../../components/admin/input/Radio";
import Option from "../../../components/admin/input/optionInput/Option";
import { validateChapter } from "../../../lib/adminControler/validateVolume/validate";
import { useSelector, useDispatch } from "react-redux";
import {
  inputDate,
  messageNotif,
} from "../../../lib/adminControler/validateProduct/validate";
import { useParams, useNavigate } from "react-router-dom";
import { fetchMessage, getCurrentVolume } from "../../../lib/state/actions";
import { isbnIsExist } from "../../../lib/adminControler/isbnControler";
import {
  useProductsState,
  useTvaState,
  useCategoryState,
  useVolumesState,
} from "../../marketplace/categoryPage/controllers/stateCtrl";
import AddFieldToCategory from "../../../components/admin/input/AddFieldToCategory";

const ChapterUpdate = ({ title }) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const products = useProductsState(useSelector);
  const volumes = useVolumesState(useSelector);
  const tva = useTvaState(useSelector);
  const category = useCategoryState(useSelector);

  const { productId, volumeId, chapterId } = useParams();

  const [currentVolume, setCurrentVolume] = useState();

  const chapterObject = {
    releaseDate: inputDate(new Date()),
    chapterNumber: "",
    title: "",
    totalOfPage: 0,
    ISBN: "",
    isActive: false,
    gift: false,
    traductor: "",
    agent: "",
    price: 0,
    tva: "",
    royaltyBuyingPrice: 0,
    royaltyRevenu: 0,
  };

  const [chaptersObject, setChaptersObject] = useState({
    ...chapterObject,
  });
  const [sameIsbn, setSameIsbn] = useState(false);
  const [book, setBook] = useState({});

  //add agent and traductor
  useEffect(() => {
    if (currentVolume && title === "créer un chapitre") {
      setChaptersObject({
        ...chapterObject,
        traductor: currentVolume.traductor,
        agent: currentVolume.agent,
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentVolume, title]);

  //verify if isbn already exist
  useEffect(() => {
    const advert = () =>
      messageNotif("Cet ISBN existe déjà en BDD !", dispatch);
    if (title === "créer un chapitre") {
      if (isbnIsExist(volumes, chaptersObject.ISBN)) {
        advert();
        setSameIsbn(true);
      } else {
        setSameIsbn(false);
      }
    }
    if (title === "modifier un chapitre") {
      if (isbnIsExist(volumes, chaptersObject.ISBN)) {
        if (isbnIsExist(volumes, chaptersObject.ISBN) === chaptersObject._id) {
          setSameIsbn(false);
        } else {
          advert();
          setSameIsbn(true);
        }
      } else {
        setSameIsbn(false);
      }
    }
  }, [chaptersObject.ISBN, chaptersObject._id, title, volumes, dispatch]);

  useEffect(() => {
    if (products && products.length > 0 && productId) {
      const product = products?.find((product) => product._id === productId);
      if (product) {
        setBook(product);
      }
    }
  }, [products, productId]);

  const findChapter = () => {
    if (currentVolume) {
      const chapter = currentVolume.chapters.find(
        (chap) => chap._id === chapterId
      );
      if (chapter) {
        setChaptersObject(chapter);
      } else {
        dispatch(
          fetchMessage({ message: "Problème : Chapitre introuvable !" })
        );
        setChaptersObject(chapterObject);
      }
    }
  };

  const categoryArray = () => {
    if (category && category.length > 0) {
      const categoryArray = category?.find(
        (cat) => cat.name === book?.category
      );
      return categoryArray;
    }
  };

  useEffect(() => {
    /* if (title === "modifier un chapitre") {*/
    dispatch(getCurrentVolume(volumeId, setCurrentVolume));
    /* }*/
  }, [title, dispatch, volumeId]);

  useEffect(() => {
    if (title === "modifier un chapitre" && currentVolume) {
      findChapter();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [title, currentVolume]);

  return (
    <Wrapper>
      {
        <Advert>
          {title === "modifier un chapitre"
            ? `Modifier le chapitre numéro ${chaptersObject?.chapterNumber}`
            : title}
        </Advert>
      }

      {
        <Container>
          <Text
            type="date"
            name="releaseDate"
            onchange={(e) =>
              setChaptersObject({
                ...chaptersObject,
                releaseDate: e.target.value,
              })
            }
            title="Date de création du chapitre"
            value={chaptersObject?.releaseDate}
          />
          {currentVolume && (
            <p>
              {" "}
              Le chapitre suivant est le{" "}
              <span style={{ color: "red" }}>
                {currentVolume?.chapters.length + 1}
              </span>
            </p>
          )}
          <Text
            type="text"
            name="chapterNumber"
            onchange={(e) =>
              setChaptersObject({
                ...chaptersObject,
                chapterNumber: e.target.value,
              })
            }
            title="Numéro du chapitre"
            value={chaptersObject?.chapterNumber}
          />
          <Text
            type="text"
            name="title"
            onchange={(e) =>
              setChaptersObject({
                ...chaptersObject,
                title: e.target.value,
              })
            }
            title="Titre du chapitre"
            value={chaptersObject?.title}
          />
          <Text
            type="text"
            name="totalOfPage"
            onchange={(e) =>
              setChaptersObject({
                ...chaptersObject,
                totalOfPage: e.target.value,
              })
            }
            title="Nombre de page"
            value={chaptersObject?.totalOfPage || 0}
          />
          <Text
            type="text"
            name="ISBN"
            onchange={(e) =>
              setChaptersObject({ ...chaptersObject, ISBN: e.target.value })
            }
            title="ISBN"
            value={chaptersObject?.ISBN}
          />
          <Radio
            legend="actif"
            name="isactive"
            currentValue={chaptersObject?.isActive}
            onchange={(e) =>
              setChaptersObject({
                ...chaptersObject,
                isActive: e.target.value === "false" ? false : true,
              })
            }
          />
          <Radio
            legend="gratuit"
            name="gift"
            currentValue={chaptersObject?.gift}
            onchange={(e) =>
              setChaptersObject({
                ...chaptersObject,
                gift: e.target.value === "false" ? false : true,
              })
            }
          />
          <Option
            name="traductor"
            title="Traducteur"
            onchange={(e) =>
              setChaptersObject({
                ...chaptersObject,
                traductor: e.target.value,
              })
            }
            array={categoryArray()?.traductors}
            currentValue={chaptersObject?.traductor}
          />
          <AddFieldToCategory
            name="addTraductor"
            title="enregistrer un nouveau traducteur"
            categoryId={categoryArray()?._id}
            arrayToUpdate="traductors"
          />
          <Option
            name="agent"
            title="Agent"
            onchange={(e) =>
              setChaptersObject({
                ...chaptersObject,
                agent: e.target.value,
              })
            }
            array={categoryArray()?.agents}
            currentValue={chaptersObject?.agent}
          />
          <AddFieldToCategory
            name="addAgent"
            title="enregistrer un nouvel agent"
            categoryId={categoryArray()?._id}
            arrayToUpdate="agents"
          />
          <Option
            name="price"
            title="Prix"
            onchange={(e) =>
              setChaptersObject({
                ...chaptersObject,
                price: Number(e.target.value),
              })
            }
            array={categoryArray()?.prices}
            currentValue={chaptersObject?.price}
          />
          <AddFieldToCategory
            name="addPrice"
            title="enregistrer un nouveau prix"
            categoryId={categoryArray()?._id}
            arrayToUpdate="prices"
          />
          <Option
            name="tva"
            title="TVA"
            onchange={(e) =>
              setChaptersObject({
                ...chaptersObject,
                tva: e.target.value,
              })
            }
            array={tva}
            property="name"
            currentValue={chaptersObject?.tva}
          />
          <Text
            type="number"
            name="royaltyBuyingPrice"
            onchange={(e) =>
              setChaptersObject({
                ...chaptersObject,
                royaltyBuyingPrice: Number(e.target.value),
              })
            }
            title="Royalty prix d'achat"
            value={chaptersObject?.royaltyBuyingPrice || 0}
          />
          <Text
            type="number"
            name="royaltyRevenu"
            onchange={(e) =>
              setChaptersObject({
                ...chaptersObject,
                royaltyRevenu: Number(e.target.value),
              })
            }
            title="Royalty revenu"
            value={chaptersObject?.royaltyRevenu || 0}
          />
          {!sameIsbn && (
            <Button
              type="button"
              onClick={() =>
                validateChapter(
                  title,
                  volumeId,
                  chaptersObject,
                  dispatch,
                  navigate
                )
              }
            >
              {title === "modifier un chapitre" ? "Modifier" : "Ajouter"}
            </Button>
          )}
        </Container>
      }
    </Wrapper>
  );
};

const Wrapper = styled.div`
  box-sizing: border-box;
  background-color: lightblue;
  width: 100%;
  min-height: calc(100vh - 100px);
  padding: 20px 20px 20px 20px;
`;

const Container = styled.form`
  width: 100%;
`;

const Advert = styled.h2`
  text-align: center;
  font-size: 20px;
  font-weight: bold;
  margin-bottom: 20px;
  text-transform: uppercase;
`;

const Button = styled.div`
  display: block;
  background-color: lightgrey;
  padding: 10px;
  text-align: center;
  margin: 20px 0;
  max-width: 200px;
  cursor: pointer;
  &.big {
    padding: 20px 20px;
    margin: 0 auto;
    font-size: 18px;
    font-weight: bold;
  }
`;

export default ChapterUpdate;
