import React, { useEffect, useState, useCallback, useRef } from "react";
import styled from "styled-components";
import Exclu from "./exclu/index";
import Left from "./picto/Left";
import Right from "./picto/Right";
import Sticker from "../../Sticker";
const ExcluSlider = ({ products }) => {
  const [currentItem, setCurrentItem] = useState(0);
  const [tabMod, setTabMod] = useState(false);
  const [exclu, setExclu] = useState([]);
  const [elementVisible, setElementVisible] = useState(5);
  const [windowSize, setWindowSize] = useState();
  const [offset, setOffset] = useState();
  let [ratio, setRatio] = useState();

  const elementToScroll = 1;
  const wrapperRef = useRef();
  const sliderRef = useRef();

  //number of element before and after
  useEffect(() => {
    setOffset(elementVisible * 2 - 1);
  }, [elementVisible]);

  const handleResize = () => {
    setWindowSize({
      width: window.innerWidth,
    });
  };

  useEffect(() => {
    window.addEventListener("resize", handleResize);
    handleResize();
    return () => {
      window.removeEventListener("resize", handleResize);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  //filter by active category
  useEffect(() => {
    if (products && products.length > 0) {
      setExclu(products);
    }
  }, [products]);

  useEffect(() => {
    if (windowSize?.width > 2300) {
      setElementVisible(5);
    }
    if (windowSize?.width <= 2300) {
      setElementVisible(7);
    }
    if (windowSize?.width < 1750) {
      setElementVisible(5);
    }
    if (windowSize?.width > 1200) {
      setTabMod(false);
    }
    if (windowSize?.width <= 1200) {
      setTabMod(true);
    }
    if (windowSize?.width < 1200) {
      setElementVisible(4);
    }
    if (windowSize?.width < 1000) {
      setElementVisible(3);
    }
    if (windowSize?.width < 800) {
      setElementVisible(2);
    }
    if (windowSize?.width < 600) {
      setElementVisible(1);
    }
  }, [windowSize]);

  //new array with element before and after (infinite loop)
  let infiniteArray = useCallback(() => {
    if (exclu?.length > elementVisible && !tabMod) {
      let end = exclu.slice(0, offset);
      let start = exclu.slice(-offset);
      return [...start, ...exclu, ...end];
    } else {
      return exclu;
    }
  }, [exclu, elementVisible, tabMod, offset]);

  //arrow visible or not
  const arrowsAreVisible = () => {
    if (exclu?.length > elementVisible && !tabMod) {
      return true;
    }
    return false;
  };

  useEffect(() => {
    setRatio(infiniteArray()?.length / elementVisible);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [elementVisible, tabMod, products, exclu, infiniteArray()?.length]);

  const goToItem = useCallback(
    (index, transition) => {
      if (index >= 0 && index <= infiniteArray()?.length) {
        setCurrentItem(index);
      }
      if (transition === false) {
        if (sliderRef.current) {
          sliderRef.current.style.transition = "none";
        }
      } else {
        if (sliderRef.current) {
          sliderRef.current.style.transition = "transform 0.3s";
        }
      }
    },
    [infiniteArray]
  );

  //set wrapper position if !tabMod
  useEffect(() => {
    const wrapperEl = wrapperRef.current;
    if (!tabMod) {
      wrapperEl.scrollTo({ left: 0 });
    }
  }, [tabMod]);

  //replace actual slide whitout transition
  const resetInfinite = useCallback(() => {
    if (currentItem <= elementVisible) {
      goToItem(currentItem + exclu.length, false);
    } else if (currentItem >= infiniteArray().length - offset) {
      goToItem(currentItem - exclu.length, false);
    }
  }, [
    currentItem,
    elementVisible,
    exclu?.length,
    infiniteArray,
    goToItem,
    offset,
  ]);

  const next = () => {
    goToItem(currentItem + elementToScroll);
  };

  const prev = () => {
    goToItem(Number(currentItem - elementToScroll));
  };

  //set slider on first item
  useEffect(() => {
    if (exclu.length < offset && !tabMod) {
      goToItem(exclu.length, false);
    }
    if (exclu.length >= offset && !tabMod) {
      goToItem(offset, false);
    }
    if (tabMod || exclu.length <= elementVisible) {
      goToItem(0, false);
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [exclu?.length, elementVisible, offset, infiniteArray()?.length, tabMod]); // !!!!!  <--- array dependance    !!dernier changement!!   ----------------

  //on transitionend, resetInfinite
  useEffect(() => {
    let sliderEl = sliderRef.current;
    if (exclu?.length > elementVisible) {
      sliderEl?.addEventListener("transitionend", resetInfinite);
    }

    return () => sliderEl?.removeEventListener("transitionend", resetInfinite);
  }, [currentItem, products, exclu?.length, elementVisible, resetInfinite]);

  return (
    <>
      <Sticker
        title="en exclusivité numérique *"
        center={true}
        marginTop="10"
      />
      <Wrapper>
        <Slider ref={wrapperRef}>
          <Container
            ratio={ratio}
            ref={sliderRef}
            currentItem={currentItem}
            length={infiniteArray()?.length}
            tabMod={tabMod}
            elementVisible={elementVisible}
            className="products"
          >
            {infiniteArray()?.map((book, i) => (
              <Product
                key={book?._id + i}
                elementVisible={elementVisible}
                ratio={ratio}
              >
                <Exclu book={book} />
              </Product>
            ))}
          </Container>
          <CircleButton
            arrowsAreVisible={arrowsAreVisible()}
            className="left"
            type="button"
            onClick={() => prev()}
          >
            <Left />
          </CircleButton>
          <CircleButton
            arrowsAreVisible={arrowsAreVisible()}
            type="button"
            onClick={() => next()}
          >
            <Right />
          </CircleButton>

          <Span className="segoesc">*uniquement sur MangaeBook</Span>
        </Slider>
      </Wrapper>
    </>
  );
};

const Wrapper = styled.section`
  max-width: 2500px;
  margin: 0 auto;
`;

const Slider = styled.div`
  position: relative;
  text-align: center;
  box-sizing: border-box;
  width: 100%;
  border-radius: 8px;
  margin-top: 10px;
  padding-top: 30px;
  padding-bottom: 30px;
  overflow: hidden;
  @media (max-width: 1200px) {
    overflow-x: scroll;
    overflow-y: hidden;
  }
`;

const Container = styled.div`
  display: flex;
  justify-content: center;
  width: ${(props) => props.ratio * 100}%;
  transform: ${(props) =>
    props.tabMod
      ? `translate3d(0,0,0)`
      : `translate3d(${(props.currentItem * -100) / props.length}%,
    0,
    0)`};
  margin: ${(props) =>
    props.length < props.elementVisible ? "0 auto" : "unset"};
  &.products {
    position: relative;
    z-index: 2;
  }
  @media (max-width: 1199px) {
    width: ${(props) => props.ratio * 97}%;
  }
  @media (max-width: 1199px) {
    width: ${(props) => props.ratio * 94}%;
  }
  @media (max-width: 999px) {
    width: ${(props) => props.ratio * 90}%;
  }
  @media (max-width: 799px) {
    width: ${(props) => props.ratio * 80}%;
  }
  @media (max-width: 599px) {
    width: ${(props) => (props.length > 1 ? props.ratio * 75 : 100)}%;
  }
`;

const Product = styled.article`
  box-sizing: content-box;
  width: ${(props) => 100 / props.elementVisible / props.ratio}%;
`;

const CircleButton = styled.button`
  display: ${(props) => (props.arrowsAreVisible ? "flex" : "none")};
  position: relative;
  z-index: 100;
  border: none;
  background-color: white;
  box-shadow: 0px 3px 5px rgba(0, 0, 0, 0.15);
  border-radius: 50%;
  width: 55px;
  height: 55px;
  padding: 5px;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  position: absolute;
  top: 50%;
  right: 40%;
  transform: translate(50%, -70%);
  @media (max-width: 2300px) {
    right: 43%;
  }
  @media (max-width: 1749px) {
    right: 40%;
  }
  &.left {
    top: 50%;
    right: 60%;
    @media (max-width: 2300px) {
      right: 57%;
    }
    @media (max-width: 1749px) {
      right: 60%;
    }
  }
`;

const Span = styled.span`
  position: absolute;
  font-size: 14px;
  bottom: 2px;
  right: 30px;
  z-index: 1;
`;

export default ExcluSlider;
