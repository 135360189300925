import React from "react";
import styled from "styled-components";

const Logo = () => {
  return (
    <Svg
      xmlns="http://www.w3.org/2000/svg"
      width="202.333"
      height="33.492"
      viewBox="0 0 202.333 33.492"
    >
      <g id="logo" transform="translate(-29.456 -13.5)">
        <path
          id="Tracé_89"
          data-name="Tracé 89"
          d="M386.817,362.266l-6.121,1.988-4.183-10.334H365.7l-1.568,3.886-6.042-2.021,9.613-23.9h6.883Zm-12.308-13.531-3.381-8.236-3.362,8.236Z"
          transform="translate(-224.796 -317.881)"
          fill="#a41916"
          stroke="#000"
          strokeMiterlimit="10"
          strokeWidth="1"
        />
        <g
          id="Groupe_523"
          data-name="Groupe 523"
          transform="translate(109.17 14)"
        >
          <path
            id="Tracé_90"
            data-name="Tracé 90"
            d="M343.124,357.8H332.316a14.7,14.7,0,0,1-10.175-3.763,12.161,12.161,0,0,1,.017-18.373,14.676,14.676,0,0,1,10.157-3.786h10.807V337.7H332.315a8.024,8.024,0,0,0-5.565,2.088,6.689,6.689,0,0,0,.011,10.112,8.038,8.038,0,0,0,5.554,2.089h4.32V347.75h-4.32v-5.816h10.807V357.8Z"
            transform="translate(-317.957 -331.881)"
            fill="#a41916"
            stroke="#000"
            strokeMiterlimit="10"
            strokeWidth="1"
          />
        </g>
        <g
          id="Groupe_524"
          data-name="Groupe 524"
          transform="translate(86.495 14)"
        >
          <path
            id="Tracé_91"
            data-name="Tracé 91"
            d="M303.807,357.8h-6.059L286.3,342.541V357.8h-6.067V331.881H286.3l11.458,15.272-.016-15.272h6.066V357.8Z"
            transform="translate(-280.233 -331.881)"
            fill="#a41916"
            stroke="#000"
            strokeMiterlimit="10"
            strokeWidth="1"
          />
        </g>
        <g
          id="Groupe_525"
          data-name="Groupe 525"
          transform="translate(60.743 14.001)"
        >
          <path
            id="Tracé_92"
            data-name="Tracé 92"
            d="M266.122,362.266,260,364.254l-4.183-10.334H245l-1.566,3.886-6.046-2.021,9.616-23.9h6.88Zm-12.309-13.531-3.379-8.236-3.364,8.236Z"
            transform="translate(-237.389 -331.882)"
            fill="#a41916"
            stroke="#000"
            strokeMiterlimit="10"
            strokeWidth="1"
          />
        </g>
        <g
          id="Groupe_526"
          data-name="Groupe 526"
          transform="translate(30.046 14)"
        >
          <path
            id="Tracé_93"
            data-name="Tracé 93"
            d="M219.742,363.232l-6.371,1.02L209.1,342.2,202.446,355l-6.6-12.8-3.189,16.19-6.337-.984,4.965-25.518h6.4l4.768,9.288,4.845-9.288h6.37Z"
            transform="translate(-186.318 -331.881)"
            fill="#a41916"
            stroke="#000"
            strokeMiterlimit="10"
            strokeWidth="1"
          />
        </g>
        <path
          id="Tracé_94"
          data-name="Tracé 94"
          d="M418.592,360.23q.059.614-2.909,1.439a22.667,22.667,0,0,1-3.859.817c-3.891.34-5.988-1.056-6.295-4.156a6.059,6.059,0,0,1,1.771-4.459,7.486,7.486,0,0,1,5.147-2.793,6.11,6.11,0,0,1,3.352.708q1.672.857,1.78,1.976c.074.765-.477,1.565-1.651,2.412a7.158,7.158,0,0,1-3.523,1.408,6.561,6.561,0,0,1-1.516-.122,5.579,5.579,0,0,0-1.554-.091l-.169.014a1.358,1.358,0,0,0-.8,1.4,1.607,1.607,0,0,0,1.291,1.492,9.086,9.086,0,0,0,2.843.064c.477-.043,1.185-.142,2.132-.3s1.653-.256,2.129-.3C417.94,359.641,418.551,359.8,418.592,360.23Zm-3.735-6.175q-.076-.784-2.342-.585a3.583,3.583,0,0,0-1.558.531q-.759.462-.713.936c.025.243.248.448.673.625a2.61,2.61,0,0,0,1.227.208,4.373,4.373,0,0,0,1.735-.671Q414.9,354.5,414.857,354.055Z"
          transform="translate(-243.715 -325.531)"
        />
        <path
          id="Tracé_95"
          data-name="Tracé 95"
          d="M442.714,349.408a4.053,4.053,0,0,1-1.213,3.707,12.128,12.128,0,0,1-5.111,2.014q-.939.188-6.863,1.134a4,4,0,0,1-.141-.587,3,3,0,0,1,.246-1.273,5.591,5.591,0,0,0,.331-1.265,9.628,9.628,0,0,0-.062-1.887c-.117-1.188-.394-3.006-.829-5.438q-.8-4.34-1.272-4.871-.048-.051-.956-.844a1.588,1.588,0,0,1-.594-.954,1.2,1.2,0,0,1,.535-1.108,2.965,2.965,0,0,1,1.585-.545l.493-.043q.735-.141,2.988-.865a14.814,14.814,0,0,1,3.086-.69c.576-.051,1-.076,1.279-.084a4.9,4.9,0,0,1,3.613,1.678,4.984,4.984,0,0,1,1.578,2.939q.079.809-2.659,4.174a6.036,6.036,0,0,1,2.818,2.044A5.352,5.352,0,0,1,442.714,349.408Zm-4.376-9.372a2.326,2.326,0,0,0-1.058-1.46,2.833,2.833,0,0,0-1.977-.674,8.825,8.825,0,0,0-3.077.782q-1.628.765-1.521,1.818a12.686,12.686,0,0,0,1.3,4.3,11.56,11.56,0,0,0,4.172-1.883Q438.467,341.333,438.338,340.036Zm1.282,9.591a2.642,2.642,0,0,0-.927-1.836,3.259,3.259,0,0,0-2.559-.678q-.74.062-3.546.66c-.044,1.507-.046,2.473-.007,2.868a17.725,17.725,0,0,0,.5,2.951l.865-.073C437.961,353.272,439.854,351.991,439.62,349.626Z"
          transform="translate(-251.987 -319.448)"
        />
        <path
          id="Tracé_96"
          data-name="Tracé 96"
          d="M466.528,351.257a4.085,4.085,0,0,1-1.552,3.192,6.437,6.437,0,0,1-4.043,1.983,5.738,5.738,0,0,1-3.792-.8,3.434,3.434,0,0,1-1.643-2.556,7.168,7.168,0,0,1,.456-2.109q.445-1.371.784-2.136a1.308,1.308,0,0,0,.2-.508,5.4,5.4,0,0,1,3.056-3.092c1.331-.493,2.886.631,4.377,2.262A7.157,7.157,0,0,1,466.528,351.257Zm-3.179-.228a1.887,1.887,0,0,0-2.307-1.913,2.578,2.578,0,0,0-2.133,1.509,3.758,3.758,0,0,0-.49,2.181,1.554,1.554,0,0,0,.369.995,2.931,2.931,0,0,0,1.315.5,1.58,1.58,0,0,0,.459.014,2.882,2.882,0,0,0,2.194-1.31A2.936,2.936,0,0,0,463.349,351.028Z"
          transform="translate(-263.656 -323.161)"
        />
        <path
          id="Tracé_97"
          data-name="Tracé 97"
          d="M486.045,349.96a4.1,4.1,0,0,1-1.551,3.193,6.563,6.563,0,0,1-4.043,1.958,5.738,5.738,0,0,1-3.794-.8,3.379,3.379,0,0,1-1.642-2.536,7.253,7.253,0,0,1,.457-2.107q.445-1.371.783-2.136a1.351,1.351,0,0,0,.2-.507c-.007-.072,1.433-2.434,3.13-2.61,1.424-.148,2.773.4,4.3,1.78A6.027,6.027,0,0,1,486.045,349.96Zm-3.177-.228c-.138-1.4-.933-2.549-2.334-2.428-.932.079-1.618,1.1-2.107,2.024a3.75,3.75,0,0,0-.49,2.181,1.546,1.546,0,0,0,.368.984,2.978,2.978,0,0,0,1.315.505,1.537,1.537,0,0,0,.459.014,2.919,2.919,0,0,0,2.2-1.309A2.951,2.951,0,0,0,482.867,349.732Z"
          transform="translate(-271.442 -322.873)"
        />
        <path
          id="Tracé_98"
          data-name="Tracé 98"
          d="M510.6,351.987q.057.6-.845.677c-.083.007-.207.008-.372.005a45.018,45.018,0,0,0-4.661-2.382,32.144,32.144,0,0,0-5.494-1.605.867.867,0,0,0-.253-.032q-.494.04-.3,2.021c.114,1.152-.163,1.781-.83,1.84-1.5.133-2.306-.387-2.417-1.506a6.715,6.715,0,0,1,.117-1.532,4.963,4.963,0,0,0,.076-1.545l-.021-.215q-.215-1.343-1.065-4.454a27.088,27.088,0,0,1-.983-4.488,4.577,4.577,0,0,1-.036-1.195q.242-.481,1.394-.582a2.278,2.278,0,0,1,2.463,1.8q.265,1.421.535,2.866a8.546,8.546,0,0,0,1,2.581l.37-.031a4.781,4.781,0,0,0,2.28-1.081,4.689,4.689,0,0,1,2.074-1.063,4.257,4.257,0,0,1,1.876.288q.972.368,1.03.959.054.54-2.314,1.574t-2.333,1.384q.047.487,2.254,1.385,3.2,1.331,4.084,1.826Q510.479,350.751,510.6,351.987Z"
          transform="translate(-278.817 -319.917)"
        />
      </g>
    </Svg>
  );
};

const Svg = styled.svg`
  width: 200px;
  @media (max-width: 1150px) {
    width: 150px;
  }
  @media (max-width: 950px) {
    display: none;
  }
`;

export default Logo;
