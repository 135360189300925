import React from "react";
import { useSelector, useDispatch } from "react-redux";
import styled from "styled-components";
import { Link } from "react-router-dom";
import ConfirmButton from "../../../components/admin/button/ConfirmButton";
import { deleteCategory } from "../../../lib/state/actions/index";
import {
  useCategoryState,
  useSuperAdminState,
} from "../../marketplace/categoryPage/controllers/stateCtrl";

const Category = () => {
  const category = useCategoryState(useSelector);
  const superAdmin = useSuperAdminState(useSelector);

  const dispatch = useDispatch();

  const validateDeleteCategory = (id) => {
    dispatch(deleteCategory(id));
  };

  return (
    <Wrapper>
      <H2>Liste et options des catégories:</H2>
      {superAdmin && (
        <Link to={`/admin/category/create`}>
          <Button type="button">Créer une nouvelle catégorie</Button>
        </Link>
      )}
      <Table>
        <thead>
          <tr>
            <Th>nom :</Th>
            <Th>Modifier :</Th>
            <Th>Supprimer :</Th>
          </tr>
        </thead>
        {category &&
          category.length > 0 &&
          category.map((result, i) => (
            <tbody key={i}>
              <tr style={{ border: "1px solid black" }}>
                <Td>{result.name}</Td>
                <Td>
                  {superAdmin && (
                    <Link to={`/admin/category/update/${result._id}`}>
                      <button
                        type="button"
                        style={{
                          backgroundColor: "lightgreen",
                          borderColor: "lightgreen",
                          textTransform: "uppercase",
                        }}
                      >
                        Modifier
                      </button>
                    </Link>
                  )}
                </Td>
                <Td className="delete">
                  {result &&
                  result.frenchTitles &&
                  result.frenchTitles.length > 0 ? (
                    <p>Présence de titres.</p>
                  ) : (
                    superAdmin && (
                      <ConfirmButton
                        message="effacer"
                        validate={() => validateDeleteCategory(result._id)}
                        pad="0.4em"
                      />
                    )
                  )}
                </Td>
              </tr>
            </tbody>
          ))}
      </Table>
    </Wrapper>
  );
};

const Wrapper = styled.div`
  position: relative;
  display: flex;
  align-items: center;
  flex-direction: column;
`;

const Button = styled.button`
  padding: 1em;
  border: solid 1px black;
  text-transform: uppercase;
  font-weight: bold;
  cursor: pointer;
`;

const H2 = styled.h2`
  margin: 20px;
  text-transform: uppercase;
  font-size: 16px;
  font-weight: bold;
`;

const Table = styled.table`
  border: 1px solid black;
  width: 80%;
  margin-top: 22px;
`;

const Td = styled.td`
  border: 1px solid black;
  padding: 10px;
  text-align: center;
  &.delete {
    width: 150px;
  }
`;

const Th = styled.th`
  border: 1px solid black;
  padding: 10px;
  text-align: center;
  font-weight: bold;
  text-transform: uppercase;
`;

export default Category;
