import React from "react";
import { useParams } from "react-router-dom";
import styled from "styled-components";

const ChaptersPop = ({
  isVisible,
  chapters,
  selectChapter,
  completeVolumeChapters,
  goToPage,
  pageNumber,
  landscape,
}) => {
  const { chapterId } = useParams();

  const nextChapterExist = (i) => {
    const chapterExist = Number(completeVolumeChapters[i + 1]);
    if (chapterExist) {
      return chapterExist;
    } else {
      return 999999999;
    }
  };

  return (
    <>
      <Wrapper isVisible={isVisible}>
        {completeVolumeChapters && chapters.length === 1
          ? chapters[0].buy &&
            completeVolumeChapters.map((chap, i) => (
              <Div key={chap} onClick={() => goToPage(Number(chap))}>
                <Span
                  className={
                    landscape
                      ? pageNumber + 1 >= Number(chap) &&
                        pageNumber < nextChapterExist(i) - 1
                        ? "active"
                        : ""
                      : pageNumber >= Number(chap) &&
                        pageNumber < nextChapterExist(i)
                      ? "active"
                      : ""
                  }
                >
                  Chapitre {i + 1}
                </Span>
              </Div>
            ))
          : chapters?.map((chap) => (
              <Div key={chap._id} onClick={() => selectChapter(chap._id)}>
                <Span
                  className={
                    chapterId === chap._id
                      ? "active"
                      : ("inactive" && chap.buy) || chap.gift
                      ? "buy"
                      : "notbuy"
                  }
                >
                  Chapitre {chap.chapterNumber}
                </Span>
              </Div>
            ))}
      </Wrapper>
    </>
  );
};

const Wrapper = styled.div`
  position: fixed;
  left: ${(props) => (props.isVisible ? "20px" : "-250px")};
  top: 45px;
  text-align: center;
  background-color: white;
  transition: left 0.3s ease-in-out;
  padding: 20px;
  z-index: 9999;
  border-radius: 3px;
  color: #0f181f;
  filter: drop-shadow(2px 2px 2px rgb(0 0 0 / 0.6));
`;

const Div = styled.div`
  padding: 8px 0;
  cursor: pointer;
  font-size: 14px;
  transform: scale(1);
  transition: all 0.2s ease-in-out;
  color: #262e33;
  &:hover {
    color: #0f181f;
    font-weight: bold;
  }
`;

const Span = styled.span`
  &.active {
    font-weight: bold;
  }
  &.notbuy {
    color: grey;
    opacity: 0.5;
  }
`;

export default ChaptersPop;
