const theme = {
  primary: {
    color: "#A30000",
    lightColor: "#CC0000",
    darkColor: "#7A0000",

    offWhite: "#f7f2f2",

    offBlack: "#806666",
    black: "#120909",

    article: "122, 0, 0",
    readArticle: "204, 0, 0",
  },

  secondary: {
    color: "#00A3A3",
  },

  forAll: {
    color: "#006ab2",
    lightColor: "#0087e0",
    darkColor: "#00568f",
  },

  hentai: {
    color: "#E600F6",
    lightColor: "#ff33ff",
    darkColor: "#b800b8",
  },

  yuri: {
    color: "#F7567C",
    lightColor: "#f98aa4",
    darkColor: "#f63c67",
  },

  yaoi: {
    color: "#FF430A",
    lightColor: "#ff6333",
    darkColor: "#e03400",
  },
};

export default theme;
