export const categoryObject = {
  name: "nom de la catégorie",
  active: false,
  frenchTitles: [],
  originalTitles: [],
  tags: [],
  authors: [],
  illustrators: [],
  traductors: [],
  agents: [],
  frenchPublishers: [],
  originalPublishers: [],
  prices: [],
  imageURL: "",
};
