import React, { useEffect } from "react";
import Header from "../../components/Header";
import Category from "./categories/Categories";
import CategoryUpdate from "./categories/CategoryUpdate";
import Admins from "./admins/Admins";
import FormId from "../../components/admin/formId/FormId";
import Users from "./users/Users";
import UserUpdate from "./users/UserUpdate";
import Products from "./products/Products";
import ProductUpdate from "./products/ProductUpdate";
import VolumesUpdate from "./products/VolumesUpdate";
import ChapterUpdate from "./products/ChapterUpdate";
import AdminUpdate from "./admins/AdminUpdate";
import Volumes from "./products/Volumes";
import Chapters from "./products/Chapters";
import Export from "./export/Export";
import NotifUpdate from "./notifications/NotifUpdate";
import Notifications from "./notifications/Notifications";
import NotFound from "../../views/marketplace/pageNotFound/NotFound";
import { Route, Routes } from "react-router-dom";
import Tva from "./tva/Tva";
import TvaUpdate from "./tva/TvaUpdate";
import Carousel from "./carousel/Carousel";
import CarouselUpdate from "./carousel/CarouselUpdate";
import { useSelector, useDispatch } from "react-redux";
import {
  adminVerify,
  getAllAdmins,
  getAllProduct,
  getAllUsers,
  getAllVolumes,
  getCategory,
  getKey,
  getNotif,
} from "../../lib/state/actions";
import {
  useAdminState,
  useSuperAdminState,
} from "../marketplace/categoryPage/controllers/stateCtrl";

const Admin = () => {
  const adminConnected = useAdminState(useSelector);
  const superAdmin = useSuperAdminState(useSelector);

  const dispatch = useDispatch();

  //diconnect at refresh if no token
  let adminToken = localStorage.getItem("admtoken");
  useEffect(() => {
    if (!adminConnected && adminToken !== null) {
      dispatch(adminVerify());
    }
  }, [dispatch, adminConnected, adminToken]);

  //refresh after create/modify
  useEffect(() => {
    if (adminConnected) {
      dispatch(getCategory());
      dispatch(getAllUsers());
      dispatch(getAllAdmins());
      dispatch(getKey());
      dispatch(getAllProduct());
      dispatch(getAllVolumes());
      dispatch(getNotif());
    }
  }, [adminConnected, dispatch]);

  return (
    <>
      {adminConnected && <Header />}
      <main>
        <Routes>
          <Route path="*" element={<NotFound />} />

          <Route exact path="/" element={<FormId />} />
          <Route
            path="/category/*"
            element={adminConnected ? <Category /> : <FormId />}
          />
          <Route
            path="/category/update/:myId"
            element={
              adminConnected && superAdmin ? (
                <CategoryUpdate title="modifier une catégorie" />
              ) : (
                <FormId />
              )
            }
          />
          <Route
            path="/category/create"
            element={
              adminConnected && superAdmin ? (
                <CategoryUpdate title="créer une nouvelle catégorie" />
              ) : (
                <FormId />
              )
            }
          />
          <Route
            path="/notifications/*"
            element={adminConnected ? <Notifications /> : <FormId />}
          />
          <Route
            path="/notifications/update/:notifId"
            element={
              adminConnected && superAdmin ? (
                <NotifUpdate title="modifier une notification" />
              ) : (
                <FormId />
              )
            }
          />
          <Route
            path="/notifications/create"
            element={
              adminConnected && superAdmin ? (
                <NotifUpdate title="créer une nouvelle notification" />
              ) : (
                <FormId />
              )
            }
          />
          <Route
            path="/carousel/*"
            element={adminConnected ? <Carousel /> : <FormId />}
          />
          <Route
            path="/carousel/update/:banniereId"
            element={
              adminConnected && superAdmin ? (
                <CarouselUpdate title="Modifier une bannière" />
              ) : (
                <FormId />
              )
            }
          />
          <Route
            path="/carousel/create"
            element={
              adminConnected && superAdmin ? (
                <CarouselUpdate title="Ajouter une bannière" />
              ) : (
                <FormId />
              )
            }
          />
          <Route
            path="/admins/*"
            element={adminConnected ? <Admins /> : <FormId />}
          />
          <Route
            path="/admins/update/:adminId"
            element={
              adminConnected && superAdmin ? (
                <AdminUpdate title="modifier un administrateur" />
              ) : (
                <FormId />
              )
            }
          />
          <Route
            path="/admins/create"
            element={
              adminConnected && superAdmin ? (
                <AdminUpdate title="créer un nouvel administrateur" />
              ) : (
                <FormId />
              )
            }
          />
          <Route
            path="/users/*"
            element={adminConnected ? <Users /> : <FormId />}
          />
          <Route
            path="/users/update/:userId"
            element={
              adminConnected && superAdmin ? (
                <UserUpdate title="modifier un utilisateur" />
              ) : (
                <FormId />
              )
            }
          />
          <Route
            path="/users/create"
            element={
              adminConnected && superAdmin ? (
                <UserUpdate title="créer un nouvel utilisateur" />
              ) : (
                <FormId />
              )
            }
          />
          <Route
            path="/products/*"
            element={adminConnected ? <Products /> : <FormId />}
          />
          <Route
            path="/products/update/:productId/*"
            element={
              adminConnected && superAdmin ? (
                <ProductUpdate title="modifier un titre" />
              ) : (
                <FormId />
              )
            }
          />
          <Route
            path="/products/create/*"
            element={
              adminConnected && superAdmin ? (
                <ProductUpdate title="créer un nouveau titre" />
              ) : (
                <FormId />
              )
            }
          />
          <Route
            path="/products/volumes/:productId"
            element={
              adminConnected && superAdmin ? (
                <Volumes title="modifier un volume" />
              ) : (
                <FormId />
              )
            }
          />
          <Route
            path="/products/createvolumes/:productId/*"
            element={
              adminConnected && superAdmin ? (
                <VolumesUpdate title="créer un nouveau volume" />
              ) : (
                <FormId />
              )
            }
          />
          <Route
            path="/products/updatevolumes/:productId/:volumeId/*"
            element={
              adminConnected && superAdmin ? (
                <VolumesUpdate title="modifier le volume" />
              ) : (
                <FormId />
              )
            }
          />
          <Route
            path="/products/chapters/:productId/:volumeId"
            element={adminConnected && superAdmin ? <Chapters /> : <FormId />}
          />
          <Route
            path="/products/createchapter/:productId/:volumeId"
            element={
              adminConnected && superAdmin ? (
                <ChapterUpdate title="créer un chapitre" />
              ) : (
                <FormId />
              )
            }
          />
          <Route
            path="/products/updatechapter/:productId/:volumeId/:chapterId"
            element={
              adminConnected && superAdmin ? (
                <ChapterUpdate title="modifier un chapitre" />
              ) : (
                <FormId />
              )
            }
          />
          <Route
            path="/tva/*"
            element={adminConnected ? <Tva /> : <FormId />}
          />
          <Route
            path="/tva/update/:tvaId"
            element={
              adminConnected && superAdmin ? (
                <TvaUpdate title="modifier une TVA existante" />
              ) : (
                <FormId />
              )
            }
          />
          <Route
            path="/tva/create"
            element={
              adminConnected && superAdmin ? (
                <TvaUpdate title="créer une nouvelle TVA" />
              ) : (
                <FormId />
              )
            }
          />
          <Route
            path="/export"
            element={adminConnected ? <Export /> : <FormId />}
          />
        </Routes>
      </main>
    </>
  );
};

export default Admin;
