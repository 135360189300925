import React from "react";
import styled from "styled-components";
import { useSelector } from "react-redux";
import { cutString } from "./controllers/shortTitleCtrl";
import {
  useProductsState,
  useTvaState,
} from "../../../views/marketplace/categoryPage/controllers/stateCtrl";
import { REACT_APP_API_HOST } from "../../../service";

const Card = ({ info }) => {
  const products = useProductsState(useSelector);
  const tva = useTvaState(useSelector);

  const productTitle = (productId) => {
    if (products && products.length > 0) {
      const product = products.find((product) => product._id === productId);
      if (product) {
        return product.frenchTitle;
      }
    }
  };

  const dateToLocalString = (timeStamp) => {
    const date = new Date(parseInt(timeStamp));
    return date.toLocaleDateString("fr");
  };

  const userSoldListPrice = (price, tvaName) => {
    if (tva && tva.length > 0) {
      const tvaRate = tva.find((t) => t.name === tvaName);
      const rate = Number(tvaRate.rate);
      if (rate) {
        let volumeTva = (price * rate) / 100;
        let priceWithTaxe = Math.round(Number(price) + volumeTva).toFixed(2);
        if (priceWithTaxe && volumeTva) {
          return Number(priceWithTaxe / 100).toFixed(2);
        }
      }
    }
  };

  return (
    <>
      {info && (
        <Wrapper>
          <Container>
            <Img src={REACT_APP_API_HOST + info.imageURL} alt="jaquette" />

            <LeftColumn>
              <Title className="montserratSemiBold">
                <h3>{cutString(productTitle(info.productId), 20)}</h3>
              </Title>
              <p className="montserrat">
                {info.chapterNumber
                  ? `Volume ${info.volume} / Chap. ${info.chapterNumber}`
                  : info.volume !== 0
                  ? `Volume ${info.volume}`
                  : "one shot"}
              </p>
              <p className="montserrat hidden">
                Acheté le {dateToLocalString(info.date)}
              </p>
              <p className="montserratSemiBold">
                {userSoldListPrice(info.price, info.tva)} €
              </p>
            </LeftColumn>
          </Container>
          <RightColumn>
            <div className="montserrat">
              Acheté le {dateToLocalString(info.date)}
            </div>
          </RightColumn>
        </Wrapper>
      )}
    </>
  );
};

const Wrapper = styled.article`
  display: flex;
  justify-content: space-between;
  box-sizing: border-box;
  background-color: ${(props) => props.theme.primary.black};
  margin-bottom: 10px;
  border-radius: 5px;
  padding: 10px;
  color: white;
  width: 100%;
  max-width: 600px;
  &:last-child {
    margin-bottom: 0;
  }
  @media (max-width: 600px) {
    width: 300px;
  }
`;

const Container = styled.div`
  display: flex;
  justify-content: space-between;
`;

const LeftColumn = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  padding-left: 10px;
  & .hidden {
    @media (min-width: 601px) {
      display: none;
    }
  }
`;

const RightColumn = styled.div`
  align-self: flex-end;
  @media (max-width: 600px) {
    display: none;
  }
`;

const Img = styled.img`
  width: 60px;
`;

const Title = styled.div`
  text-transform: uppercase;
`;

export default Card;
