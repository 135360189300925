import React from "react";
import styled from "styled-components";
import theme from "../../../theme";
import { swithLightColors } from "../categoryPage/controllers/themeCtrl";
import { REACT_APP_API_HOST } from "../../../service";

const CatChoice = ({ cat, toggleCategory, categoryIsActive }) => {
  return (
    <Wrapper>
      <Ul>
        {cat.map((c) => (
          <Li
            onClick={() => toggleCategory(c.name)}
            categoryIsActive={categoryIsActive(c.name)}
            color={swithLightColors(c.name, theme)}
            key={c.name}
          >
            <img src={REACT_APP_API_HOST + c.imageURL} alt="catégorie"></img>
          </Li>
        ))}
      </Ul>
    </Wrapper>
  );
};

const Wrapper = styled.nav`
  margin-top: 16px;
  width: 100%;
  display: flex;
  justify-content: center;
`;

const Ul = styled.ul`
  display: flex;
  @media (max-width: 700px) {
    justify-content: space-between;
    flex-wrap: wrap;
  }
`;

const Li = styled.li`
  & img {
    box-sizing: border-box;
    height: 75px;
    border-radius: 5px;
    border: solid 2px
      ${(props) =>
        props.categoryIsActive ? props.color : props.theme.primary.lightColor};
    cursor: pointer;
    transition: border ease-in-out 0.2s;
  }
  margin-right: 10px;
  &:last-child {
    margin-right: 0;
  }
  height: 75px;
  @media (max-width: 700px) {
    margin-right: 0;
    margin-left: 10px;
    &:first-child {
      margin-left: 0;
    }
  }
`;

export default CatChoice;
