import React from "react";

const Twiter = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="55.475"
      height="55.479"
      viewBox="0 0 55.475 55.479"
    >
      <g id="Layer_2" dataname="Layer 2" transform="translate(0)">
        <g id="_04.twitter" dataname="04.twitter" transform="translate(0 0)">
          <path
            id="Tracé_317"
            dataname="Tracé 317"
            d="M54.522,9.915A10.1,10.1,0,0,0,45.546.942a167.858,167.858,0,0,0-35.615,0A10.1,10.1,0,0,0,.942,9.915a167.86,167.86,0,0,0,0,35.615,10.1,10.1,0,0,0,8.976,8.992,167.043,167.043,0,0,0,35.615,0,10.1,10.1,0,0,0,8.989-8.979,167.863,167.863,0,0,0,0-35.628Zm-8.891,7.637a14.877,14.877,0,0,1-2.972,3.265,1.261,1.261,0,0,0-.476.99v.117a20.818,20.818,0,0,1-1.343,7.23,21.193,21.193,0,0,1-3.949,6.619A19.541,19.541,0,0,1,30.449,40.6a18.247,18.247,0,0,1-2.086.819,21.331,21.331,0,0,1-6.726,1.046,20.527,20.527,0,0,1-10.294-2.783.586.586,0,0,1-.281-.656.57.57,0,0,1,.558-.429h.864A13.808,13.808,0,0,0,20.2,36.242a7.5,7.5,0,0,1-5.919-5.081.391.391,0,0,1,.444-.507,6.8,6.8,0,0,0,1.261.12h.158a7.5,7.5,0,0,1-4.365-6.9.394.394,0,0,1,.583-.337,6.619,6.619,0,0,0,1.7.659,7.486,7.486,0,0,1-1.213-9.074.388.388,0,0,1,.63-.047,19.6,19.6,0,0,0,13.48,7.183h.028a.277.277,0,0,0,.2-.095.268.268,0,0,0,.066-.208,8.066,8.066,0,0,1,.177-2.736,7.11,7.11,0,0,1,.946-2.206,7.249,7.249,0,0,1,3.952-3.029,7.98,7.98,0,0,1,2.367-.362,7.463,7.463,0,0,1,4.945,1.863.974.974,0,0,0,.63.246A.945.945,0,0,0,40.5,15.7a13.849,13.849,0,0,0,3.653-1.5.391.391,0,0,1,.574.454,7.426,7.426,0,0,1-1.967,3.13,13.574,13.574,0,0,0,2.389-.807.391.391,0,0,1,.482.577Z"
            transform="translate(0.005 0.005)"
            fill="#50abf1"
          />
        </g>
      </g>
    </svg>
  );
};

export default Twiter;
