import React from "react";
import styled from "styled-components";
import Library from "./picto/Library";
import Notif from "./picto/Notif";
import Cart from "./picto/Cart";
import Profil2 from "./picto/Profil2";
import { NavLink as Link } from "react-router-dom";
import Bubble from "../bubble/Bubble";
import { useSelector } from "react-redux";
import {
  useNotifState,
  useCartState,
  useUserState,
} from "../../../views/marketplace/categoryPage/controllers/stateCtrl";
import {
  firstToUpperCase,
  cutString,
} from "../profil/controllers/shortTitleCtrl";

const IconNav = ({ toggleNotifs }) => {
  const cart = useCartState(useSelector);
  const notifications = useNotifState(useSelector);
  const { userConnected } = useUserState(useSelector);

  return (
    <Wrapper>
      <Li>
        <Link to="/library" active="">
          <Library />
        </Link>
      </Li>
      <Li onClick={toggleNotifs}>
        <Notif />
        <Bubble
          number={notifications && notifications.length}
          bottom={12}
          right={0}
        />
      </Li>
      <Li>
        <Link to="/cart" active="">
          <Cart />

          {<Bubble number={cart.length} bottom={9} right={-7} />}
        </Link>
      </Li>
      <Li className="hidden profil" userConnected={userConnected}>
        <Link to="/profil" active="">
          <Profil2 />
        </Link>
        <span>
          {userConnected && cutString(firstToUpperCase(userConnected), 12)}
        </span>
      </Li>
    </Wrapper>
  );
};

const Wrapper = styled.menu`
  display: flex;
  align-items: center;
  list-style: none;
`;

const Li = styled.li`
  position: relative;
  margin: 0 30px;
  @media (max-width: 750px) {
    margin: 0 20px;
  }
  @media (max-width: 350px) {
    margin: 0 15px;
  }

  &.hidden {
    @media (max-width: 430px) {
      display: none;
    }
  }
  & .active {
    & svg {
      & path {
        fill: ${(props) => props.theme.primary.color};
      }
    }
  }
  &.profil {
    @media (max-width: 430px) {
      display: none;
    }
    position: relative;
    & span {
      position: absolute;
      bottom: -5px;
      left: 50%;
      transform: translate(-50%);
      font-size: 12px;
      line-height: 7px;
    }
  }
`;

/*
display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
*/

export default IconNav;
