//calculate and return canvas dimension and container dimension
export const imageSizeCalculate = (
  windowSize,
  myBook,
  landscape,
  tabView,
  setImageCorrection
) => {
  const heightRatio = windowSize?.height / myBook?.height;
  const widthRatio = windowSize?.width / myBook?.width;
  let scrambleImageWidth = Math.ceil(myBook?.width / 27) * 27;
  let scrambleImageHeight = Math.ceil(myBook?.height / 27) * 27;
  let widthCorrection = scrambleImageWidth - myBook?.width;
  let canvasWidth = Math.round(scrambleImageWidth * heightRatio);
  let canvasHeight = Math.round(scrambleImageHeight * heightRatio);

  let divHeight = () => {
    if (landscape) {
      //landscape and pageWidth*2 < screen width
      if (myBook?.width * 2 * heightRatio <= windowSize?.width) {
        return windowSize.height;
      }
      //landscape and pageWidth*2 > screen width
      if (myBook?.width * 2 * heightRatio > windowSize?.width) {
        return Math.round((myBook?.height * widthRatio) / 2);
      }
    }
    if (!landscape) {
      if (myBook?.readingDirection === "webtoon") {
        //portrait and page width < screen width and webtoon
        if (myBook?.width <= windowSize?.width) {
          return myBook?.height;
        }
        //portrait and page width > screen width and webtoon
        if (myBook?.width > windowSize?.width) {
          return Math.round(myBook?.height * widthRatio);
        }
      }
      if (!tabView) {
        //portrait and page width < screen width and NO tabView NO webtoon
        if (myBook?.width * heightRatio <= windowSize?.width) {
          return windowSize?.height;
        }

        //portrait and page width > screen width OR tabview
        if (
          myBook?.width * heightRatio >
          windowSize?.width /*||
      tabView*/
        ) {
          return Math.round(myBook?.height * widthRatio);
        }
      }
      if (tabView) {
        return Math.round(myBook?.height * widthRatio);
      }
    }
  };

  //canvas height
  let height = () => {
    if (!landscape) {
      if (
        canvasWidth > windowSize?.width + widthCorrection * widthRatio ||
        tabView
      ) {
        return "auto";
      }
      if (
        canvasWidth <= windowSize?.width + widthCorrection * widthRatio &&
        myBook?.readingDirection !== "webtoon"
      ) {
        return canvasHeight;
      }
      if (myBook?.readingDirection === "webtoon") {
        if (myBook?.width <= windowSize?.width) {
          return scrambleImageHeight;
        }
      }
    }

    if (landscape) {
      if (canvasWidth * 2 > windowSize?.width + widthCorrection * widthRatio) {
        return (scrambleImageHeight * widthRatio) / 2;
      }
      if (canvasWidth * 2 <= windowSize?.width + widthCorrection * widthRatio) {
        return canvasHeight;
      }
    }
  };

  //canvas width
  const width = () => {
    if (!landscape) {
      if (
        (canvasWidth > windowSize?.width + widthCorrection * widthRatio &&
          myBook?.readingDirection !== "webtoon") ||
        (tabView && myBook?.readingDirection !== "webtoon")
      ) {
        return windowSize?.width + widthCorrection * widthRatio;
      }

      if (
        canvasWidth <= windowSize?.width + widthCorrection * widthRatio &&
        myBook?.readingDirection !== "webtoon"
      ) {
        return canvasWidth;
      }

      if (myBook?.readingDirection === "webtoon") {
        //webtoon case
        if (myBook?.width > windowSize?.width) {
          return windowSize?.width + widthCorrection * widthRatio;
        }
        if (myBook?.width <= windowSize?.width) {
          return scrambleImageWidth;
        }
      }
    }

    if (landscape) {
      if (canvasWidth * 2 > windowSize?.width + widthCorrection * widthRatio) {
        return (scrambleImageWidth * widthRatio) / 2;
      }
      if (canvasWidth * 2 <= windowSize?.width + widthCorrection * widthRatio) {
        return canvasWidth;
      }
    }
  };

  const divWidth = () => {
    if (landscape) {
      if (myBook?.width * 2 * heightRatio <= windowSize?.width) {
        return Math.floor(myBook?.width * heightRatio);
      }
      //landscape and pageWidth*2 > screen width
      if (myBook?.width * 2 * heightRatio > windowSize?.width) {
        //return Math.round((myBook?.height * widthRatio) / 2);
        return Math.floor(windowSize?.width / 2);
        //return Math.floor(myBook?.width * heightRatio);
      }
    }
    if (!landscape) {
      if (myBook?.readingDirection === "webtoon") {
        //portrait and page width < screen width and webtoon
        if (myBook?.width <= windowSize?.width) {
          return myBook?.width;
        }
        //portrait and page width > screen width and webtoon
        if (myBook?.width > windowSize?.width) {
          return windowSize?.width;
        }
      }
      if (tabView) {
        return windowSize?.width;
      } else {
        return Math.floor(myBook?.width * heightRatio);
      }
    }
  };

  setImageCorrection({
    heightRatio,
    height: height(),
    width: width(),
    divWidth: divWidth(),
    divHeight: divHeight(),
  });
};
