import React from "react";
import styled from "styled-components";

const Touch = () => {
  return (
    <Svg
      xmlns="http://www.w3.org/2000/svg"
      width="49.566"
      height="40"
      viewBox="0 0 49.566 40"
    >
      <g id="tap" transform="translate(-8.48 -58.402)">
        <g
          id="Groupe_833"
          data-name="Groupe 833"
          transform="translate(8.48 58.402)"
        >
          <g id="Groupe_832" data-name="Groupe 832" transform="translate(0 0)">
            <path
              id="Tracé_544"
              data-name="Tracé 544"
              d="M101.069,98.44l-9.6-10.695A4.872,4.872,0,0,0,83.323,89.9a4.84,4.84,0,0,0-6.423.448,4.946,4.946,0,0,0-1.238,2.138,4.843,4.843,0,0,0-6.421.45,4.913,4.913,0,0,0-1.225,2.089L63.491,90.44a4.84,4.84,0,0,0-6.911.016,5,5,0,0,0,0,6.991l17.381,17.621-10.309,1.641a5.773,5.773,0,0,0-4.886,5.712,3.861,3.861,0,0,0,3.83,3.882H86.667a13.853,13.853,0,0,0,9.929-4.17l4.016-4.071a14.36,14.36,0,0,0,.457-19.621Zm-2.262,17.791L94.791,120.3a11.335,11.335,0,0,1-8.124,3.411H62.594a1.286,1.286,0,0,1-1.277-1.294,3.188,3.188,0,0,1,2.714-3.152l12.8-2.04a1.283,1.283,0,0,0,1.016-.877,1.308,1.308,0,0,0-.312-1.315L58.385,95.616a2.391,2.391,0,0,1,.013-3.349,2.3,2.3,0,0,1,3.288,0l12.773,12.949a1.265,1.265,0,0,0,1.805,0,1.309,1.309,0,0,0,0-1.832L71.045,98.1a2.385,2.385,0,0,1,.015-3.347,2.307,2.307,0,0,1,3.286,0l5.218,5.29a1.265,1.265,0,0,0,1.805,0,1.309,1.309,0,0,0,0-1.832L78.7,95.51a2.385,2.385,0,0,1,.015-3.347,2.307,2.307,0,0,1,3.286,0l2.676,2.712c.005,0,0,0,0,0l0,0,0,0,0,0h0a0,0,0,0,1,0,0,1.262,1.262,0,0,0,1.779-.026,1.308,1.308,0,0,0,0-1.83l-.11-.111a2.379,2.379,0,0,1,.013-3.347,2.3,2.3,0,0,1,3.245-.044l9.559,10.651a11.749,11.749,0,0,1-.375,16.05Z"
              transform="translate(-55.153 -86.302)"
              fill="#fff"
            />
          </g>
        </g>
      </g>
    </Svg>
  );
};

const Svg = styled.svg`
  @media (min-width: 1200px) {
    display: none;
  }
`;

export default Touch;
