import { actions } from "../actions/index";

const { FETCH_TVA } = actions;

const initialState = {
  tva: [],
};

export function tvas(state = initialState, action) {
  switch (action.type) {
    case FETCH_TVA:
      return {
        ...state,
        tva: action.payload.tva,
      };
    default:
      return state;
  }
}
