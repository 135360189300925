import React from "react";
import styled from "styled-components";

const Cross = () => {
  return (
    <Svg
      id="message"
      xmlns="http://www.w3.org/2000/svg"
      width="20.052"
      height="20"
      viewBox="0 0 20.052 20"
    >
      <path
        id="Tracé_256"
        data-name="Tracé 256"
        d="M21.813,4.23a2.136,2.136,0,0,0-.655-1.474,2.179,2.179,0,0,0-3.012,0L11.9,9,5.649,2.756a2.179,2.179,0,0,0-3.005,0,2.136,2.136,0,0,0,0,3.012l6.245,6.245L2.644,18.286A2.136,2.136,0,1,0,5.627,21.27L11.9,15.032l6.252,6.238a2.136,2.136,0,0,0,3.005-3L14.92,12.013l6.238-6.273a2.136,2.136,0,0,0,.655-1.51Z"
        transform="translate(-1.762 -2.151)"
        fill="#fff"
      />
    </Svg>
  );
};

const Svg = styled.svg`
  transform: scale(1);
  transition: transform 0.2s ease-in-out;
  filter: drop-shadow(2px 2px 2px rgb(0 0 0 / 0.6));
  &:hover {
    transform: scale(1.2);
  }
`;

export default Cross;
