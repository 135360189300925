import React, { useState } from "react";
import styled from "styled-components";
import { useVolumesState } from "../../marketplace/categoryPage/controllers/stateCtrl";
import { useSelector } from "react-redux";
import { selectedVolumeChapters } from "./userCtrl";

const UserBookCard = ({
  vol,
  deleteBook,
  volumeInfos,
  deleteChapter,
  allowChanges,
  userId,
  addChapter,
}) => {
  //add new chapter to ebook
  const [newChapter, setNewChapter] = useState();
  const [updateChapters, setUpdateChapters] = useState(false);

  const volumes = useVolumesState(useSelector);

  const displayChapters = () => {
    setUpdateChapters(!updateChapters);
  };

  const chapterIdentify = (volumeId, chapterId) => {
    const volume = volumes.find((volume) => volume._id === volumeId);
    const chapter = volume.chapters.find(
      (chapter) => chapter._id === chapterId
    );

    if (volume && chapter) {
      return `Chapitre ${chapter.chapterNumber}`;
    } else {
      return "numéro de chapitre inconnu";
    }
  };

  return (
    <List>
      <Container>
        <Book>{vol._id}</Book>
        <H3>{volumeInfos(vol._id)}</H3>
        {allowChanges && (
          <Div>
            <button type="button" onClick={() => deleteBook(vol._id, userId)}>
              supprimer
            </button>
            <button type="button" onClick={() => displayChapters()}>
              chapitres
            </button>
          </Div>
        )}
      </Container>
      {updateChapters && (
        <>
          <label htmlFor="addChapter">Ajouter un chapitre :</label>
          <Select
            name="addChapter"
            onChange={(e) => setNewChapter(e.target.value)}
          >
            <option defaultValue="">choisir un chapitre</option>
            {selectedVolumeChapters(vol._id, volumes).map((chapter) => (
              <option key={parseInt(chapter.chapterNumber)} value={chapter._id}>
                chapitre {chapter.chapterNumber}
              </option>
            ))}
          </Select>
          <button type="button" onClick={() => addChapter(vol._id, newChapter)}>
            ajouter
          </button>
          <Col>
            {vol.chapters.map((chap) => (
              <div key={chap}>
                {chapterIdentify(vol._id, chap)}
                <SuppChap
                  type="button"
                  onClick={() => deleteChapter(vol._id, chap)}
                >
                  supprimer
                </SuppChap>
              </div>
            ))}
          </Col>
        </>
      )}
    </List>
  );
};

const H3 = styled.h3`
  margin: 20px;
  text-transform: uppercase;
  font-size: 14px;
  font-weight: bold;
  max-width: 500px;
`;

const Container = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 10px 0;
`;

const Book = styled.div`
  color: white;
  font-size: 12px;
  font-weight: bold;
  padding: 1em;
  background-color: black;
  border-radius: 5px;
  text-align: center;
`;

const List = styled.div`
  width: 900px;
  padding: 5px;
  background-color: white;
  margin: 5px;
  border: solid black 3px;
`;

const Div = styled.div`
  width: 150px;
  display: flex;
  justify-content: space-between;
`;

const Col = styled.div`
  display: flex;
  flex-direction: column;
  padding: 10px 0;
`;

const SuppChap = styled.button`
  margin: 5px;
`;

const Select = styled.select`
  width: 500px;
  margin: 0 10px;
`;

export default UserBookCard;
