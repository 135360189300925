import React from "react";

const Menu = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
      width="35"
      height="35"
      viewBox="0 0 35 35"
    >
      <defs>
        <clipPath id="clip-path">
          <path
            id="path2418"
            d="M0-682.665H35v35H0Z"
            transform="translate(0 682.665)"
          />
        </clipPath>
      </defs>
      <g id="g2412" transform="translate(0 682.665)">
        <g id="g2414" transform="translate(0 -682.665)">
          <g id="g2416" clipPath="url(#clip-path)">
            <g id="g2422" transform="translate(0.513 4.17)">
              <path
                id="path2424"
                d="M-18.975-113.847H12.949a1.025,1.025,0,0,0,1.025-1.025v-4.1A1.025,1.025,0,0,0,12.949-120H-18.975A1.025,1.025,0,0,0-20-118.974v4.1A1.025,1.025,0,0,0-18.975-113.847Z"
                transform="translate(20 120)"
                fill="none"
                stroke="#000"
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeMiterlimit="10"
                strokeWidth="1"
              />
            </g>
            <g id="g2426" transform="translate(0.513 14.424)">
              <path
                id="path2428"
                d="M-75.9-113.847h28.848a1.025,1.025,0,0,0,1.025-1.025v-4.1A1.025,1.025,0,0,0-47.051-120H-78.974A1.025,1.025,0,0,0-80-118.974v4.1a1.025,1.025,0,0,0,1.025,1.025h1.025"
                transform="translate(80 120)"
                fill="none"
                stroke="#000"
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeMiterlimit="10"
                strokeWidth="1"
              />
            </g>
            <g id="g2430" transform="translate(0.513 24.678)">
              <path
                id="path2432"
                d="M-18.975-113.847H12.949a1.025,1.025,0,0,0,1.025-1.025v-4.1A1.025,1.025,0,0,0,12.949-120H-18.975A1.025,1.025,0,0,0-20-118.974v4.1A1.025,1.025,0,0,0-18.975-113.847Z"
                transform="translate(20 120)"
                fill="none"
                stroke="#000"
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeMiterlimit="10"
                strokeWidth="1"
              />
            </g>
          </g>
        </g>
      </g>
    </svg>
  );
};

export default Menu;
