import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { useDispatch } from "react-redux";
import styled from "styled-components";
import { getCurrentVolume } from "../../../lib/state/actions";
import { Link } from "react-router-dom";
import ChaptersList from "./ChaptersList";

const Chapters = () => {
  const { volumeId } = useParams();
  const { productId } = useParams();

  const dispatch = useDispatch();

  const [volume, setVolume] = useState({});
  const [allowChanges, setAllowChanges] = useState(false);

  useEffect(() => {
    dispatch(getCurrentVolume(volumeId, setVolume));
  }, [volumeId, dispatch]);

  //autorize update
  const toggleAllowChanges = () => {
    setAllowChanges(!allowChanges);
  };

  return (
    <Wrapper>
      {volume && (
        <>
          <ChangeButton type="button" onClick={() => toggleAllowChanges()}>
            autoriser les modifications.
          </ChangeButton>
          <Link to={`/admin/products/createchapter/${productId}/${volumeId}`}>
            <Button type="button">ajouter un chapitre</Button>
          </Link>

          <ChaptersList
            chapters={volume.chapters}
            allowChanges={allowChanges}
            setVolume={setVolume}
          />
        </>
      )}
    </Wrapper>
  );
};

const Wrapper = styled.div`  
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  box-sizing: border-box;
  background-color: lightblue;
  min-height: 100vh;
  z-index: 100;
  padding: 2em;
  }
`;

const Button = styled.button`
  margin-top: 70px;
  border: none;
  padding: 10px 10px;
  background-color: black;
  color: white;
  font-weight: bold;
  border-radius: 5px;
  text-transform: uppercase;
  margin-bottom: 60px;
  cursor: pointer;
`;

const ChangeButton = styled.button`
  margin: 20px 20px 0px 0px;
  align-self: end;
`;

export default Chapters;
