import React from "react";
import styled from "styled-components";
import { useSelector } from "react-redux";
import { Link } from "react-router-dom";
import { useCategoryState } from "../../../../views/marketplace/categoryPage/controllers/stateCtrl";
import { REACT_APP_API_HOST } from "../../../../service";

const Category = ({ withTitle = true }) => {
  const category = useCategoryState(useSelector);
  return (
    <>
      {category && (
        <Wrapper withtitle={withTitle.toString()}>
          {withTitle && <H2 className="segoesc">choisissez votre univers</H2>}
          <Ul>
            {category &&
              category.length > 0 &&
              category.map((cat) => (
                <Li key={cat._id} length={category.length} active={cat.active}>
                  <Span className="segoesc" active={cat.active}>
                    Prochainement
                  </Span>
                  <Link to={cat.active && `/category/${cat.name}`}>
                    <Img
                      src={REACT_APP_API_HOST + cat.imageURL}
                      alt={cat.name}
                    />
                  </Link>
                </Li>
              ))}
          </Ul>
        </Wrapper>
      )}
    </>
  );
};

const Wrapper = styled.nav`
  margin-top: 20px;
  width: 100%;
  padding: ${(props) => (props.withtitle ? "20px 0 25px 0" : "0 0 20px 0")};
  background: linear-gradient(
    ${(props) => props.theme.primary.lightColor},
    ${(props) => props.theme.primary.darkColor}
  );
`;

const H2 = styled.h2`
  text-align: center;
  color: white;
  text-transform: uppercase;
  font-size: 15px;
  font-weight: bold;
  letter-spacing: 0.5px;
  @media (max-width: 600px) {
    font-size: 12px;
  }
`;

const Ul = styled.menu`
  margin: 0 auto;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  list-style: none;
`;

const Img = styled.img`
  width: 130px;
  height: 130px;
`;

const Li = styled.li`
  position: relative;
  margin: 20px 40px 0 40px;
  @media (max-width: ${(props) =>
      props.length * 130 + props.length * 80 + 30}px) {
    margin: 20px 20px 0 20px;
  }
  @media (max-width: ${(props) =>
      props.length * 130 + props.length * 40 + 30}px) {
    margin: 20px 10px 0 10px;
  }
  & a {
    opacity: ${(props) => (props.active ? "1" : "0.5")};
    min-width: 130px;
    min-height: 130px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    border-radius: 10px;
    overflow: hidden;
    transition: transform 0.2s ease-in-out;
    cursor: ${(props) => (props.active ? "pointer" : "auto")};

    &:hover {
      transform: ${(props) => (props.active ? "scale(1.1)" : "unset")};
      @media (max-width: 1200px) {
        transform: none;
      }
    }
  }
`;

const Span = styled.span`
  opacity: 1;
  display: ${(props) => (props.active ? "none" : "inline-block")};
  position: absolute;
  font-size: 19px;
  line-height: 0;
  top: 50%;
  right: 50%;
  transform: translate(50%, 0%) rotate(-45deg);
  color: white;
  z-index: 1;
`;

export default Category;
