import React from "react";
import styled from "styled-components";
import { Link } from "react-router-dom";
import ConfirmButton from "../../../components/admin/button/ConfirmButton";
import { useSelector, useDispatch } from "react-redux";
import { deleteCarousel } from "../../../lib/state/actions";
import {
  useCarouselState,
  useSuperAdminState,
} from "../../marketplace/categoryPage/controllers/stateCtrl";

const Carousel = () => {
  const dispatch = useDispatch();
  const carousel = useCarouselState(useSelector);
  const superAdmin = useSuperAdminState(useSelector);

  const validateDeletecarousel = (id) => {
    dispatch(deleteCarousel(id));
  };

  return (
    <Wrapper>
      <H2>Liste des bannières:</H2>
      {superAdmin && (
        <Link to={`/admin/carousel/create`}>
          <Button type="button">Ajouter une bannière</Button>
        </Link>
      )}
      <Table>
        <thead>
          <tr>
            <Th>date</Th>
            <Th>modifier</Th>
            <Th>éffacer</Th>
          </tr>
        </thead>
        {carousel &&
          carousel.length > 0 &&
          carousel?.map((result, i) => (
            <tbody key={i}>
              <tr style={{ border: "1px solid black" }}>
                <Td>
                  {new Date(Date.parse(result.date)).toLocaleDateString("fr")}
                </Td>
                {superAdmin && (
                  <Td>
                    <Link to={`/admin/carousel/update/${result._id}`}>
                      <button
                        type="button"
                        style={{
                          backgroundColor: "lightgreen",
                          borderColor: "lightgreen",
                          textTransform: "uppercase",
                        }}
                      >
                        Modifier
                      </button>
                    </Link>
                  </Td>
                )}

                <Td className="delete">
                  {superAdmin && (
                    <ConfirmButton
                      message="effacer"
                      validate={() => validateDeletecarousel(result._id)}
                      pad="0.4em"
                    />
                  )}
                </Td>
              </tr>
            </tbody>
          ))}
      </Table>
    </Wrapper>
  );
};

const Wrapper = styled.div`
  position: relative;
  display: flex;
  align-items: center;
  flex-direction: column;
`;

const Button = styled.button`
  padding: 1em;
  border: solid 1px black;
  text-transform: uppercase;
  font-weight: bold;
  cursor: pointer;
`;

const H2 = styled.h2`
  margin: 20px;
  text-transform: uppercase;
  font-size: 16px;
  font-weight: bold;
`;

const Table = styled.table`
  border: 1px solid black;
  min-width: 80%;
  margin-top: 22px;
`;

const Td = styled.td`
  border: 1px solid black;
  padding: 10px;
  text-align: center;
  &.delete {
    width: 100px;
  }
`;

const Th = styled.th`
  border: 1px solid black;
  padding: 10px;
  text-align: center;
  font-weight: bold;
  text-transform: uppercase;
`;

export default Carousel;
