import React, { useState, useEffect } from "react";
import styled from "styled-components";
import { inputDate } from "../../../lib/adminControler/validateProduct/validate";
import Radio from "../../../components/admin/input/Radio";
import Text from "../../../components/admin/input/Text";
import { useSelector, useDispatch } from "react-redux";
import {
  postNetwork,
  putNetwork,
  deleteNetwork,
} from "../../../lib/state/actions";
import { useNavigate } from "react-router-dom";
import {
  useNetworkState,
  useSuperAdminState,
} from "../../marketplace/categoryPage/controllers/stateCtrl";

const Network = () => {
  const { loadingPage, maintenance, _id, startDate, facebook, twitter } =
    useNetworkState(useSelector);

  const networkObject = {
    maintenance: false,
    loadingPage: false,
    startDate: inputDate(new Date()),
    facebook: "",
    twitter: "",
  };

  const superAdmin = useSuperAdminState(useSelector);

  const [networkState, setNetworkState] = useState({});

  const dispatch = useDispatch();
  const navigate = useNavigate();

  useEffect(() => {
    if (!_id) {
      setNetworkState(networkObject);
    } else {
      setNetworkState({
        _id,
        maintenance,
        loadingPage,
        startDate,
        facebook,
        twitter,
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [_id, maintenance, loadingPage, startDate]);

  const onChange = (e) => {
    let name = e.target.name;
    let value = e.target.value;
    if (name === "startDate" || name === "facebook" || name === "twitter") {
      setNetworkState({ ...networkState, [name]: value });
    } else {
      setNetworkState({ ...networkState, [name]: value === "true" });
    }
  };

  const createNetwork = (body) => {
    const stringify = JSON.stringify(body);
    dispatch(postNetwork(stringify));
  };

  const modifyNetwork = (body, id) => {
    const stringify = JSON.stringify(body);
    dispatch(putNetwork(stringify, id));
  };

  return (
    <Wrapper>
      <H1>Etat du site :</H1>
      {_id && (
        <>
          <Radio
            legend="Mode MAINTENANCE"
            name="maintenance"
            currentValue={networkState?.maintenance}
            onchange={onChange}
          />
          <Radio
            legend="Mode LOADING PAGE"
            name="loadingPage"
            currentValue={networkState?.loadingPage}
            onchange={onChange}
          />
          {networkState.loadingPage && (
            <Text
              type="date"
              name="startDate"
              onchange={onChange}
              title="Date de lancement"
              value={networkState?.startDate}
            />
          )}
          <Text
            type="text"
            name="facebook"
            onchange={onChange}
            title="facebook"
            value={networkState.facebook ? networkState.facebook : ""}
          />
          <Text
            type="text"
            name="twitter"
            onchange={onChange}
            title="twitter"
            value={networkState.twitter ? networkState.twitter : ""}
          />
        </>
      )}
      {!_id ? (
        superAdmin && (
          <Button onClick={() => createNetwork(networkState)}>
            créer un nouvel état
          </Button>
        )
      ) : (
        <>
          {superAdmin && (
            <Button onClick={() => modifyNetwork(networkState, _id)}>
              modifier l'état
            </Button>
          )}
          {superAdmin && (
            <Button
              className="delete"
              onClick={() => dispatch(deleteNetwork(_id))}
            >
              supprimer l'état
            </Button>
          )}
          <Button onClick={() => navigate("/")}>
            naviguer en mode administrateur
          </Button>
        </>
      )}
    </Wrapper>
  );
};

const Wrapper = styled.section`
  border: solid black 1px;
  padding: 20px;
  margin: 40px 0;
  width: 900px;
`;

const H1 = styled.h1`
  font-size: 18px;
  font-weight: bold;
  text-transform: uppercase;
  text-align: center;
  margin-bottom: 20px;
`;

const Button = styled.button`
  margin-top: 10px;
  padding: 10px;
  font-size: 14px;
  text-transform: uppercase;
  &.delete {
    margin-left: 600px;
  }
`;

export default Network;
