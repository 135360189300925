import React, { useState, useEffect, useRef, useCallback } from "react";
import styled, { keyframes, css } from "styled-components";
import LeftArrow from "../picto/LeftArrow";
import RightArrow from "../picto/RightArrow";
import { Link, useLocation } from "react-router-dom";
import Title from "./Title";
import Open from "./Open";
import Article from "../../home/shopCard/index";
import { cutString } from "../../profil/controllers/shortTitleCtrl";
import { REACT_APP_API_HOST } from "../../../../service";
import { allReplace } from "../../reader/lib/readerCtrl";

//array= array to slide // tilte=title of slider // onclick= cancel link // isfixed = no slide, just window // article = article ti sold or library
const Slider = ({ array, title, onclick, isFixed, article }) => {
  const [currentItem, setCurrentItem] = useState(0);
  const [windowSize, setWindowSize] = useState();
  const [elementVisibleToScroll, setElementVisibleToScroll] = useState(6);
  const [tabMod, setTabMod] = useState(false);
  const [ratio, setRatio] = useState();
  const [open, setOpen] = useState(false);
  const sliderRef = useRef();

  let location = useLocation();

  const wrapperRef = useCallback(
    (node) => {
      if (node !== null && location) {
        node.scrollTo({ left: 0 });
      }
    },
    [location]
  );

  const handleResize = () => {
    setWindowSize({
      width: window.innerWidth,
    });
  };

  const toggleOpen = () => setOpen(!open);

  useEffect(() => {
    window.addEventListener("resize", handleResize);
    handleResize();

    return () => {
      window.removeEventListener("resize", handleResize);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (isFixed) {
      setOpen(true);
    }
  }, [isFixed]);

  useEffect(() => {
    if (windowSize?.width >= 1050) {
      setElementVisibleToScroll(6);
    }
    if (windowSize?.width < 1170) {
      setElementVisibleToScroll(5);
    }
    if (windowSize?.width < 990) {
      setElementVisibleToScroll(4);
    }
    if (windowSize?.width < 830) {
      setElementVisibleToScroll(3);
    }
    if (windowSize?.width < 640) {
      setElementVisibleToScroll(2);
    }
    if (windowSize?.width < 420) {
      setElementVisibleToScroll(1);
    }

    if (windowSize?.width <= 1200) {
      setTabMod(true);
      !isFixed && setOpen(false);
    } else {
      setTabMod(false);
    }
  }, [windowSize, isFixed]);

  //number of element before and after
  let offset = elementVisibleToScroll * 2 - 1;

  //new array with element before and after (infinite loop)
  let infiniteArray = useCallback(() => {
    if (array.length > elementVisibleToScroll && !open && !tabMod) {
      let end = array.slice(0, offset);
      let start = array.slice(-offset);
      return [...start, ...array, ...array, ...end];
    } else {
      return array;
    }
  }, [array, elementVisibleToScroll, tabMod, open, offset]);

  //arrow visible or not
  const arrowsAreVisible = () => {
    if (array?.length > elementVisibleToScroll && !tabMod) {
      return true;
    }
    return false;
  };

  useEffect(() => {
    setRatio(infiniteArray()?.length / elementVisibleToScroll);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [elementVisibleToScroll, tabMod, array, infiniteArray()?.length]);

  const goToItem = (index, transition) => {
    if (index <= infiniteArray()?.length) {
      setCurrentItem(index);
    }
    if (transition === false) {
      sliderRef.current.style.transition = "none";
    } else {
      sliderRef.current.style.transition = "transform 0.3s";
    }
  };

  //replace actual slide whitout transition
  const resetInfinite = () => {
    if (array.length > 0) {
      if (currentItem <= elementVisibleToScroll) {
        goToItem(currentItem + array.length, false);
      }
      if (currentItem >= infiniteArray().length - offset) {
        goToItem(currentItem - array.length, false);
      }
    }
  };

  const next = () => {
    goToItem(currentItem + elementVisibleToScroll);
  };

  const prev = () => {
    goToItem(currentItem - elementVisibleToScroll);
  };

  //set slider on first item
  useEffect(() => {
    if (array.length > 0) {
      if (array?.length < offset) {
        goToItem(array?.length, false);
      }
      if (array?.length >= offset) {
        goToItem(offset, false);
      }
      if (tabMod || array.length <= elementVisibleToScroll) {
        goToItem(0, false);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []); // !!!!!  <--- array dependance    !!dernier changement!!   ----------------

  //on transitionend, resetInfinite
  useEffect(() => {
    let sliderEl = sliderRef.current;
    if (array?.length > elementVisibleToScroll) {
      sliderEl?.addEventListener("transitionend", resetInfinite);
    }

    return () => sliderEl?.removeEventListener("transitionend", resetInfinite);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentItem, array?.length, elementVisibleToScroll]);

  return (
    <Wrapper
      elementVisible={elementVisibleToScroll}
      length={infiniteArray()?.length}
      open={open}
      array={array}
      article={article}
    >
      {array && array.length > 0 && (
        <>
          {!open && (
            <LeftContainer
              arrowsvisible={arrowsAreVisible().toString()}
              onClick={() => prev()}
            >
              <LeftArrow />
            </LeftContainer>
          )}
          <Title title={cutString(title, 25)} />
          {array.length > elementVisibleToScroll && !isFixed && (
            <Open toggleOpen={toggleOpen} open={open} />
          )}

          <Slide open={open} ref={wrapperRef}>
            <Container
              ref={sliderRef}
              ratio={ratio}
              currentItem={currentItem}
              length={infiniteArray()?.length}
              elementVisible={elementVisibleToScroll}
              tabMod={tabMod}
              open={open}
              article={article}
            >
              {ratio &&
                elementVisibleToScroll &&
                infiniteArray().length > 0 && (
                  <>
                    {infiniteArray()?.map((el, i) =>
                      article ? (
                        el.active && (
                          <Article
                            key={el._id + i}
                            ratio={ratio}
                            elementVisible={elementVisibleToScroll}
                            open={open}
                            el={el}
                          />
                        )
                      ) : (
                        /*el.active &&*/ <Card
                          key={el._id + i}
                          ratio={ratio}
                          elementVisible={elementVisibleToScroll}
                          length={infiniteArray()?.length}
                          open={open}
                        >
                          <Link
                            to={
                              !onclick &&
                              `/item/${allReplace(el.frenchTitle)}/${el._id}`
                            }
                          >
                            <ImageContainer
                              src={REACT_APP_API_HOST + el.imageURL}
                            />
                          </Link>
                        </Card>
                      )
                    )}
                  </>
                )}
            </Container>
          </Slide>
          {!open && (
            <RightContainer
              arrowsvisible={arrowsAreVisible().toString()}
              onClick={() => next()}
            >
              <RightArrow />
            </RightContainer>
          )}
        </>
      )}
    </Wrapper>
  );
};

const openAnimation = keyframes`
 0% { opacity: 0;}
 80% { opacity:0;}
 100% { opacity:1; }
`;

const Wrapper = styled.div`
  height: ${(props) =>
    props.open
      ? `${
          Math.ceil(props.array.length / props.elementVisible) *
            ((props.article ? 364 : 241) + 40) +
          30
        }px`
      : props.article
      ? "424px"
      : `${320}px`};
  box-sizing: border-box;
  position: relative;
  display: flex;
  align-items: center;
  background-color: white;
  box-shadow: 0px 3px 5px rgba(0, 0, 0, 0.15);
  border-radius: 8px;
  margin-top: 40px;

  transition: height 0.3s ease-in-out;
  max-width: 1200px;
  @media (max-width: 1200px) {
    border-radius: 0px;
  }
  @media (max-width: 380px) {
    height: ${(props) =>
      props.open
        ? `${
            Math.ceil(props.array.length / props.elementVisible) *
              ((props.article ? 310 : 190) + 40) +
            30
          }px`
        : props.article
        ? "380px"
        : "279px"};
  }
`;

const Slide = styled.div`
  margin: 0 auto;
  width: 1200px;
  overflow: hidden;
  @media (max-width: 1200px) {
    overflow-x: ${(props) => (props.open ? "hidden" : "scroll")};
    max-width: 100%;
  }
`;
//overflow-x: ${(props) => (props.open ? "hidden" : "scroll")};

const Container = styled.div`
  width: ${(props) => (props.open ? "auto" : `${props.ratio * 100}%`)};
  height: auto;
  max-width: ${(props) => (!props.open ? "unset" : "1200px")};
  flex-wrap: ${(props) => (!props.open ? "unset" : "wrap")};
  display: flex;
  padding: ${(props) => (!props.open ? "10px 0" : "15px 0")};
  transform: ${(props) =>
    props.open
      ? "unset"
      : props.tabMod
      ? `translate3d(0,0,0)`
      : `translate3d(${(props.currentItem * -100) / props.length}%,
    0,
    0)`};
  animation: ${(props) =>
    props.open
      ? css`
          ${openAnimation} 0.3s ease-in-out both
        `
      : null};
  margin: ${(props) => (props.open ? "0 auto" : "unset")};
  @media (max-width: 380px) {
    height: auto;
  }
  @media (max-width: 1200px) {
    width: ${(props) => (props.open ? "auto" : `${props.ratio * 97}%`)};
  }
  @media (max-width: 989px) {
    width: ${(props) => (props.open ? "auto" : `${props.ratio * 94}%`)};
  }
  @media (max-width: 829px) {
    width: ${(props) => (props.open ? "auto" : `${props.ratio * 91}%`)};
  }
  @media (max-width: 639px) {
    width: ${(props) => (props.open ? "auto" : `${props.ratio * 87}%`)};
  }
  @media (max-width: 450px) {
    width: ${(props) => (props.open ? "auto" : `${props.ratio * 94}%`)};
  }
  @media (max-width: 419px) {
    width: ${(props) => (props.open ? "auto" : `${props.ratio * 65}%`)};
  }
`;

const Card = styled.article`
  max-height: 241px;
  box-sizing: border-box;
  width: ${(props) =>
    props.open
      ? `${100 / props.elementVisible}%`
      : `${100 / props.elementVisible / props.ratio}%`};
  display: flex;
  justify-content: center;
  margin: 15px 0;
  @media (max-width: 380px) {
    max-height: 190px;
    margin: 10px 0;
  }
  & a {
    max-height: 241px;
  }
`;

const ImageContainer = styled.img`
  border: 1px solid ${(props) => props.theme.primary.offWhite};
  border-radius: 3px;
  height: 241px;
  @media (max-width: 380px) {
    max-height: 190px;
  }
`;
//box-shadow: 0px 3px 5px rgba(0, 0, 0, 0.15);

const LeftContainer = styled.div`
  position: absolute;
  background-color: white;
  left: -25px;
  display: ${(props) => (props.arrowsvisible === "true" ? "block" : "none")};
  width: 50px;
  margin-left: 0px;
  transition: transform 0.2s ease-in-out;
  z-index: 9;
  padding: 5px;
  border: solid ${(props) => props.theme.primary.black} 2px;
  border-radius: 50%;
  &:hover {
    transform: scale(1.1);
  }
  @media (max-width: 1270px) {
    margin-left: 25px;
  }
  @media (max-width: 1200px) {
    display: none;
  }
`;

const RightContainer = styled.div`
  position: absolute;
  background-color: white;
  right: -25px;
  display: ${(props) => (props.arrowsvisible === "true" ? "block" : "none")};
  width: 50px;
  margin-right: 0px;
  transition: transform 0.2s ease-in-out;
  z-index: 9;
  padding: 5px;
  border: solid ${(props) => props.theme.primary.black} 2px;
  border-radius: 50%;
  &:hover {
    transform: scale(1.1);
  }
  @media (max-width: 1270px) {
    margin-right: 25px;
  }
  @media (max-width: 1200px) {
    display: none;
  }
`;

export default Slider;
