import React from "react";
import styled from "styled-components";

const Right = () => {
  return (
    <Svg
      id="right-arrow_1_"
      data-name="right-arrow (1)"
      xmlns="http://www.w3.org/2000/svg"
      width="24.939"
      height="39.592"
      viewBox="0 0 24.939 39.592"
    >
      <path
        id="Tracé_192"
        data-name="Tracé 192"
        d="M13.194,32.881a.566.566,0,0,0-.051.786l4.468,5.208a1.7,1.7,0,0,0,1.2.707h.17a1.578,1.578,0,0,0,.854-.515l5.655-5.655a23.526,23.526,0,0,0,1.578-1.747c.616-.763,2-2.29,3.6-4.06,3.749-4.151,6.662-7.408,7.165-8.483a.566.566,0,0,0-.136-.656c-.978-.888-6.436-6.786-9.693-10.309-1.08-1.171-1.928-2.081-2.3-2.477L20.942.589a1.5,1.5,0,0,0-1.131-.6A1.544,1.544,0,0,0,18.889.4L13.16,5.023a.566.566,0,0,0-.079.8c.616.758,1.329,1.7,2.008,2.635s1.408,1.889,2.042,2.669c2.539,3.1,5.163,6.3,8.573,8.6l-3.156,3.6q-.916,1.041-1.838,2.075a101.97,101.97,0,0,1-7.516,7.482Zm1.171.469a101.392,101.392,0,0,0,7.182-7.193c.622-.69,1.238-1.391,1.849-2.087l3.585-4.089a.571.571,0,0,0-.119-.854c-3.535-2.222-6.221-5.525-8.856-8.72-.622-.758-1.323-1.7-2.008-2.624-.566-.763-1.131-1.55-1.7-2.234L19.59,1.291a1.227,1.227,0,0,1,.254-.164,1.041,1.041,0,0,1,.271.243l4.292,4.564c-.707-.028-1.4-.04-2.1,0a14.263,14.263,0,0,0-2.562.334,6.017,6.017,0,0,0,1.3.215,12.35,12.35,0,0,0,1.3,0,10.016,10.016,0,0,0,1.289-.153,5.593,5.593,0,0,0,.939-.243l.328.345L26.7,8.388a6.786,6.786,0,0,0-1.47-.192,5.559,5.559,0,0,0-2.3.418,16.813,16.813,0,0,0,2.3.147c.566,0,1.176,0,1.781-.04l.17.187L28.814,10.7a3.15,3.15,0,0,0-1.238.085,8.539,8.539,0,0,0-1.436.566,11.863,11.863,0,0,1-1.34.566,3.721,3.721,0,0,1-1.431.254,2.37,2.37,0,0,0,1.544.175,6.221,6.221,0,0,0,1.465-.5,11.729,11.729,0,0,1,2.545-1.018c1.284,1.391,2.692,2.907,3.959,4.281-.633.13-1.233.3-1.838.464a22.564,22.564,0,0,0-2.307.73,6.017,6.017,0,0,0,2.46-.187,6.357,6.357,0,0,0,1.878-.848c1.527,1.629,2.828,3.031,3.54,3.71-.933,1.4-4.275,5.09-6.786,7.861-1.606,1.787-3,3.325-3.636,4.111a22.176,22.176,0,0,1-1.533,1.646L19.03,38.248a1.131,1.131,0,0,1-.226.187s-.1,0-.339-.311Z"
        transform="translate(-12.952 0.01)"
      />
      <path
        id="Tracé_193"
        data-name="Tracé 193"
        d="M35.23,24.245a19.641,19.641,0,0,1,2.6-.86,20.473,20.473,0,0,1,2.681-.566,8.844,8.844,0,0,1-5.276,1.425Z"
        transform="translate(-22.631 -9.909)"
      />
    </Svg>
  );
};

const Svg = styled.svg`
  transition: transform 0.2s ease-in-out;
  &:hover {
    transform: scale(1.2);
  }
`;

export default Right;
