import React, { useEffect, useState } from "react";
import styled from "styled-components";
import CloseButton from "../../connectForm/CloseButton";
import { useParams, useNavigate, Link } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import Twiter from "../picto/Twiter";
import Like from "../picto/Like";
import Facebook from "../picto/Facebook";
import { likeProduct } from "../../../../lib/state/actions";
import { FacebookShareButton, TwitterShareButton } from "react-share";
import { saveVolumeProgress } from "../../../../lib/state/actions";
import { useUserState } from "../../../../views/marketplace/categoryPage/controllers/stateCtrl";
const PopEnd = ({
  bookEnd,
  visible,
  close,
  extract,
  gift,
  nextChapter,
  myBook,
  paperURL,
}) => {
  const { id } = useParams();
  const dispatch = useDispatch();

  const [like, setLike] = useState(false);

  const { userConnected, userEbooks, userId } = useUserState(useSelector);

  const navigate = useNavigate();

  //user like state
  useEffect(() => {
    if (myBook && userConnected) {
      setLike(myBook.isLiked);
    }
  }, [myBook, userConnected]);

  const toggleLike = () => {
    dispatch(likeProduct(id));
    setLike(!like);
  };

  const saveProgress = (userId, volumeId) => {
    if (!extract && userId) {
      const body = JSON.stringify({ userId, volumeId });
      dispatch(saveVolumeProgress(body));
    }
  };

  //set user database volume is read when bookend
  useEffect(() => {
    if (
      userEbooks &&
      userEbooks.length > 0 &&
      !extract &&
      !gift &&
      id &&
      userId
    ) {
      const volume = userEbooks.find((vol) => vol._id === id);
      if (bookEnd() === true && visible && !volume.isRead && userId) {
        saveProgress(userId, id);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [visible, userEbooks, extract, gift, userId, bookEnd(), id]);

  return (
    <Wrapper visible={visible}>
      <CloseButton close={close} />
      {!extract && (
        <Button
          className="segoesc"
          type="button"
          onClick={() => navigate(nextChapter().link)}
        >
          {nextChapter()?.message}
        </Button>
      )}
      {extract && (
        <>
          <Button className="segoesc chooseversion" type="button">
            <Span className="montserratSemiBold">Acheter:</Span>
            <P onClick={() => navigate(-1)}>Version numérique</P>
            {paperURL && (
              <Link to={paperURL} target="_blank" rel="noopener noreferrer">
                Version papier
              </Link>
            )}
          </Button>
        </>
      )}
      {!extract && bookEnd() && (
        <>
          <PictoContainer share={myBook?.share}>
            <Div className="like" isLiked={like} onClick={() => toggleLike()}>
              <Like />
            </Div>
            {myBook?.share && (
              <>
                <Div>
                  <TwitterShareButton
                    url={`https://www.mangaebook/item/${id}.com`}
                    quote={""}
                    hashtag="#mangaeBook"
                    className="shareButton"
                  >
                    <Twiter />
                  </TwitterShareButton>
                </Div>
                <Div>
                  <div>
                    <FacebookShareButton
                      url={`https://www.mangaebook/item/${id}.com`}
                      quote={""}
                      hashtag="#mangaeBook"
                      className="shareButton"
                    >
                      <Facebook />
                    </FacebookShareButton>
                  </div>
                </Div>
              </>
            )}
          </PictoContainer>
        </>
      )}
    </Wrapper>
  );
};

const Wrapper = styled.div`
  box-sizing: border-box;
  width: 280px;
  position: fixed;
  padding: 15px;
  background-color: white;
  right: ${(props) => (props.visible ? "50%" : "-50%")};
  top: 50%;
  transform: translate(50%, -50%);
  letter-spacing: 0.5px;
  border-radius: 3px;
  opacity: ${(props) => (props.visible ? 1 : 0)};
  transition: opacity 0.3s ease-in-out;
  text-align: center;
  box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;
  z-index: 1000;
  color: #0f181f;
  line-height: 19px;
  & p {
    font-size: 16px;
  }
`;

const Button = styled.div`
  border: none;
  box-sizing: border-box;
  min-width: 250px;
  background-color: ${(props) => props.theme.secondary.color};
  color: white;
  padding: 10px 15px;
  font-size: 15px;
  border-radius: 3px;
  text-transform: uppercase;
  font-size: 15px;
  letter-spacing: 1px;
  cursor: pointer;
  &.chooseversion {
    cursor: default;
  }
`;

const P = styled.p`
  margin: 10px 0;
  cursor: pointer;
`;

const Span = styled.span`
  font-size: 14px;
`;

const PictoContainer = styled.div`
  padding-top: 10px;
  height: 55px;
  display: flex;
  justify-content: ${(props) => (props.share ? "space-between" : "center")};
`;

const Div = styled.div`
  cursor: pointer;
  margin: 0px;
  &.like {
    opacity: 1;
    & path {
      transition: fill 0.3s ease-in-out;
      &.heart {
        fill: ${(props) =>
          props.isLiked ? props.theme.primary.color : "white"};
      }
      &.color {
        fill: ${(props) =>
          props.isLiked ? "white" : props.theme.primary.color};
      }
    }
  }
  stroke: ${(props) => (props.isLiked ? "white" : "unset")};
`;

export default PopEnd;
