import React from "react";
import styled from "styled-components";

const Fullscreen = () => {
  return (
    <Svg
      xmlns="http://www.w3.org/2000/svg"
      width="19.968"
      height="20"
      viewBox="0 0 19.968 20"
    >
      <path
        id="resize"
        d="M.409,10.209V18.69C.431,19.885,1.2,20,2.217,20H10.2a1.31,1.31,0,1,0,0-2.619H4.881L17.758,4.5v5.32a1.31,1.31,0,1,0,2.619,0V1.342A1.244,1.244,0,0,0,19.1.032c-2.8-.073-5.7,0-8.515,0a1.31,1.31,0,1,0,0,2.619h5.32L3.029,15.529v-5.32a1.31,1.31,0,1,0-2.619,0Z"
        transform="translate(-0.409 0)"
        fill="#fff"
        fillRule="evenodd"
      />
    </Svg>
  );
};

const Svg = styled.svg`
  transform: scale(1);
  transition: transform 0.2s ease-in-out;
  filter: drop-shadow(2px 2px 2px rgb(0 0 0 / 0.6));
  &:hover {
    transform: scale(1.2);
  }
`;

export default Fullscreen;
