import React, { useState, useEffect } from "react";
import styled from "styled-components";
import { useSelector, useDispatch } from "react-redux";
import Cart from "../navbar/picto/Cart";
import Library from "../navbar/picto/Library";
import { Link } from "react-router-dom";
import {
  addToCart,
  fetchPopupMessage,
  generateDownolad,
  removeArticleFromCart,
} from "../../../lib/state/actions";
import {
  isChapterFromVolumeInCart,
  sameVolInCart,
  volumePrice,
} from "./controller/cartController";
import Cross from "../connectForm/picto/Cross";
import Download from "./picto/Download";
import {
  useProductsState,
  useTvaState,
  useUserState,
  useFetchState,
  useCartState,
} from "../../../views/marketplace/categoryPage/controllers/stateCtrl";
import {
  firstToUpperCase,
  cutString,
} from "../profil/controllers/shortTitleCtrl";
import play from "./picto/play.png";
import { REACT_APP_API_HOST } from "../../../service";
import { allReplace } from "../reader/lib/readerCtrl";

const VolumeItem = ({ volume, isCart, togglePopup }) => {
  const cart = useCartState(useSelector);
  const tva = useTvaState(useSelector);
  const products = useProductsState(useSelector);
  const { userConnected, userId, userEbooks } = useUserState(useSelector);
  const isFetching = useFetchState(useSelector);

  const token = JSON.parse(localStorage.getItem("token"));

  const [fade, setFade] = useState(false);
  const [result, setResult] = useState(false);
  const [volumeGift, setVolumeGift] = useState(false);

  useEffect(() => {
    if (result && volume._id && token) {
      const link = document.createElement("a");
      link.href = `${REACT_APP_API_HOST}/api/download/${volume._id}/${token}`;
      link.click();
      setResult(false);
    }
  }, [result, volume._id, token]);

  useEffect(() => {
    if (!isCart && volume && volume.chapters.length > 0) {
      const totalChapterGift = volume.chapters.filter((chapter) => {
        return chapter.gift === true;
      });
      if (
        totalChapterGift &&
        totalChapterGift.length === volume.chapters.length
      ) {
        setVolumeGift(true);
      } else {
        setVolumeGift(false);
      }
    }
  }, [volume, isCart]);

  const volumeIsSold = () => {
    if (volume.chapters && userEbooks && !isCart) {
      let soldVolume = userEbooks.find((ebook) => ebook._id === volume._id);
      if (soldVolume && soldVolume.chapters.length === volume.chapters.length) {
        return true;
      } else {
        return false;
      }
    } else {
      return false;
    }
  };

  const volumeIsRead = (volumeId) => {
    if (userEbooks && userEbooks.length > 0) {
      const vol = userEbooks.find((ebook) => ebook._id === volumeId);
      if (vol && vol.isRead) {
        return true;
      } else {
        return false;
      }
    }
    return false;
  };

  const dispatch = useDispatch();

  const returnTitle = (productId) => {
    if (productId && products && products.length > 0) {
      const product = products.find((p) => p._id === productId);
      if (product) {
        return product.frenchTitle;
      }
    }
  };

  const addArticleToCart = () => {
    if (userConnected) {
      const volumeObject = {
        _id: volume._id,
        productId: volume.productId,
        download: volume.download,
        imageURL: volume.imageURL,
        price: volume.price,
        tva: volume.tva,
        volume: volume.volume,
        volumeId: volume._id,
      };
      if (
        !sameVolInCart(cart, volume._id) &&
        !isChapterFromVolumeInCart(cart, volume._id)
      ) {
        dispatch(addToCart(JSON.stringify(volumeObject), userId));
      }

      if (sameVolInCart(cart, volume._id)) {
        dispatch(
          fetchPopupMessage("Cet article existe déjà dans votre panier.")
        );
      }
      if (isChapterFromVolumeInCart(cart, volume._id)) {
        dispatch(
          fetchPopupMessage(
            "Un chapitre de ce même volume existe déjà dans votre panier !"
          )
        );
      }
    } else {
      togglePopup();
    }
  };

  useEffect(() => {
    if (fade) {
      const timeOut = setTimeout(() => {
        dispatch(removeArticleFromCart(volume._id, userId));
      }, 300);

      return () => clearTimeout(timeOut);
    }
  }, [fade, dispatch, volume._id, userId]);

  const dateToDays = (date) => {
    if (date) {
      const [y, m, d] = date.split(/-|\//); // splits "26-02-2012" or "26/02/2012"
      const correctDate = new Date(y, m - 1, d);
      const actualDate = Date.now();
      var delta = (correctDate.getTime() - actualDate) / 1000;
      if (delta > 0) {
        var days = Math.ceil(delta / 86400);
        return days;
      } else {
        return null;
      }
    }
  };

  return (
    <>
      {((volume &&
        (volume.isActive || volumeIsSold()) &&
        volume.volumeSale &&
        !isCart &&
        volume.chapters.length > 0) ||
        (volume && isCart)) && (
        <Wrapper
          isCart={isCart}
          fade={fade}
          volumeIsRead={volumeIsRead(volume._id)}
          volumeIsSold={volumeIsSold()}
        >
          <ImgContainer>
            {!isCart ? (
              <Link
                to={
                  volumeIsSold()
                    ? `/liseuse/${allReplace(returnTitle(volume.productId))}/${
                        volume.productId
                      }/${volume._id}/${volume.chapters[0]._id}`
                    : `/liseuse/${allReplace(returnTitle(volume.productId))}/${
                        volume.productId
                      }/${volume._id}/${volume.chapters[0]._id}/extract`
                }
              >
                <Image src={REACT_APP_API_HOST + volume.imageURL} fade={fade} />
              </Link>
            ) : (
              <Link
                to={`../item/${allReplace(returnTitle(volume.productId))}/${
                  volume.productId
                }`}
              >
                <Image src={REACT_APP_API_HOST + volume.imageURL} fade={fade} />
              </Link>
            )}
          </ImgContainer>
          <Container>
            <Column>
              <Title className="montserratSemiBold" fade={fade}>
                {isCart && (
                  <>
                    <span>
                      {`${cutString(
                        firstToUpperCase(returnTitle(volume.productId)),
                        35
                      )}`}
                    </span>{" "}
                    <span className="montserrat">
                      {volume.volume === 0
                        ? `One-shot`
                        : volume.readingDirection === "webtoon"
                        ? `Èp. ${volume.volume}`
                        : `- Vol. ${volume.volume}`}
                    </span>
                  </>
                )}
                {!isCart && (
                  <span>
                    {volume.volume === 0
                      ? `One-shot`
                      : volume.readingDirection === "webtoon"
                      ? `Èpisode ${volume.volume}`
                      : `Volume ${volume.volume}`}
                  </span>
                )}
              </Title>
              {isCart && (
                <P className="segoesc">
                  {volume.download
                    ? "Téléchargement disponible !"
                    : "Lecture en ligne uniquement !"}
                </P>
              )}
              {!isCart && volume.totalOfPage && (
                <P className="montserrat lowercase">
                  {volume.totalOfPage} pages
                </P>
              )}
              {!volumeIsSold() && (
                <Price className="montserratSemiBold" fade={fade}>
                  {volumePrice(volume, tva)
                    ?.price.toString()
                    .replaceAll(".", ",")}{" "}
                  €
                </Price>
              )}
            </Column>

            {!isCart && !volumeIsSold() ? (
              <SoldContainer>
                {dateToDays(volume.freePeriod) &&
                  volumeGift &&
                  volume.chapters.length > 0 &&
                  (userConnected ? (
                    <Link
                      to={`/liseuse/${allReplace(
                        returnTitle(volume.productId)
                      )}/${volume.productId}/${volume._id}/${
                        volume.chapters[0]._id
                      }/gift`}
                    >
                      <IconContainer>
                        <Column className="icon">
                          <p
                            style={{
                              fontSize: "11px",
                            }}
                          >
                            Offert
                          </p>
                          <Library />
                          <p
                            style={{
                              fontSize: "11px",
                            }}
                          >
                            {dateToDays(volume.freePeriod)} jour
                            {dateToDays(volume.freePeriod) > 1 && "s"}
                          </p>
                        </Column>
                      </IconContainer>
                    </Link>
                  ) : (
                    dateToDays(volume.freePeriod) &&
                    volumeGift &&
                    !userConnected && (
                      <IconContainer onClick={() => togglePopup()}>
                        <Column className="icon">
                          <p
                            style={{
                              fontSize: "11px",
                            }}
                          >
                            Offert
                          </p>
                          <Library />
                          <p
                            style={{
                              fontSize: "11px",
                            }}
                          >
                            {dateToDays(volume.freePeriod)} jour
                            {dateToDays(volume.freePeriod) > 1 && "s"}
                          </p>
                        </Column>
                      </IconContainer>
                    )
                  ))}

                {volume.download && (
                  <IconContainer className="passive">
                    <Download />
                  </IconContainer>
                )}
                {Number(volume.extract) > 0 && (
                  <>
                    {!isCart &&
                      !volumeIsSold() &&
                      volume.chapters.length > 0 &&
                      !dateToDays(volume.freePeriod) && (
                        <Link
                          to={`/liseuse/${allReplace(
                            returnTitle(volume.productId)
                          )}/${volume.productId}/${volume._id}/${
                            volume.chapters[0]._id
                          }/extract`}
                        >
                          <IconContainer className="circle segoesc">
                            <Img src={play} alt="extrait" />
                            <Span>extrait</Span>
                          </IconContainer>
                        </Link>
                      )}
                  </>
                )}

                <CartContainer
                  marginLeft={true}
                  inCart={
                    sameVolInCart(cart, volume._id) ||
                    isChapterFromVolumeInCart(cart, volume._id)
                  }
                  onClick={
                    sameVolInCart(cart, volume._id)
                      ? () =>
                          dispatch(removeArticleFromCart(volume._id, userId))
                      : () => addArticleToCart()
                  }
                >
                  <Cart />
                  {volume.digitalExclusive && (
                    <Span className="segoesc">Exclu</Span>
                  )}
                </CartContainer>
              </SoldContainer>
            ) : (
              <SoldContainer>
                {!isCart && volume.download && !isFetching && (
                  <IconContainer
                    onClick={() =>
                      !isFetching &&
                      dispatch(
                        generateDownolad(
                          JSON.stringify({ volumeId: volume._id, userId }),
                          setResult
                        )
                      )
                    }
                  >
                    <Download />
                  </IconContainer>
                )}

                {!isCart && volume.chapters.length > 0 && (
                  <Link
                    to={`/liseuse/${allReplace(
                      returnTitle(volume.productId)
                    )}/${volume.productId}/${volume._id}/${
                      volume.chapters[0]._id
                    }`}
                  >
                    <CartContainer>
                      <Library />
                    </CartContainer>
                  </Link>
                )}
              </SoldContainer>
            )}
            {isCart && (
              <CrossContainer onClick={() => setFade(true)}>
                <Cross />
              </CrossContainer>
            )}
          </Container>
        </Wrapper>
      )}
    </>
  );
};

const Wrapper = styled.div`
  opacity: ${(props) => (props.fade ? "0" : "1")};
  height: ${(props) => (props.fade ? "0px" : "85px")};
  transition: all 0.3s ease-in-out;
  box-sizing: border-box;
  width: 80%;
  padding: ${(props) => (props.fade ? "0 5px 0 0" : "0 20px 0 0")};
  border-radius: 0px;
  margin: ${(props) => (props.fade ? "0px" : "0 0 10px 0")};
  background-color: ${(props) =>
    props.volumeIsRead
      ? `rgb(${props.theme.primary.article},0.08)`
      : props.volumeIsSold
      ? `rgb(${props.theme.primary.readArticle},0.15)`
      : `rgb(${props.theme.primary.article},0.2)`};
  color: ${(props) => props.theme.primary.black};
  display: flex;
  align-items: center;
  justify-content: space-between;
  &:last-child {
    margin-bottom: 0px;
  }
  @media (max-width: 1000px) {
    width: ${(props) => (props.isCart ? "390px" : "95%")};
  }
  @media (max-width: 450px) {
    width: ${(props) => (props.isCart ? "100%" : "95%")};
    padding: ${(props) => (props.fade ? "0 5px 0 0" : "0 10px 0 0")};
  }
  @media (max-width: 415px) {
    padding: ${(props) => (props.fade ? "0 5px 0 0" : "0 10px 0 0")};
  }
`;

const ImgContainer = styled.div`
  height: 85px;
  width: 60px;
  overflow: hidden;
`;

const Container = styled.div`
  display: flex;
  flex: 1 1 auto;
  justify-content: space-between;
  align-items: center;
  align-self: stretch;
  padding-left: 10px;
`;

const Column = styled.div`
  display: flex;
  flex-direction: column;
  align-self: stretch;
  justify-content: space-around;
  box-sizing: border-box;
  &.icon {
    align-items: center;
  }
`;

const P = styled.p`
  font-size: 12px;
  line-height: 16px;
  text-transform: uppercase;
  &.lowercase {
    text-transform: lowercase;
    font-size: 13px;
    letter-spacing: 0.4px;
  }
`;

const Image = styled.img`
  height: ${(props) => (props.fade ? "0" : "85px")};
  transition: all 0.1s ease-in-out;
  @media (max-width: 450px) {
    height: ${(props) => (props.fade ? "0" : "85px")};
  }
`;

const Title = styled.h3`
  opacity: ${(props) => (props.fade ? "0" : "1")};
  transition: all 0.1s ease-in-out;
  min-width: 65px;
  font-size: 14px;
  letter-spacing: 0.3px;
  line-height: 19px;
  & span {
    display: inline;
  }
  @media (max-width: 450px) {
    line-height: 16px;
    font-size: 12px;
  }
`;

const Price = styled.p`
  opacity: ${(props) => (props.fade ? "0" : "1")};
  transition: all 0.1s ease-in-out;
  font-size: 14px;
  letter-spacing: 1px;
  width: auto;
  @media (max-width: 450px) {
    font-size: 12px;
  }
`;

const CartContainer = styled.div`
  & svg {
    width: 32px;
    & path {
      transition: fill 0.3s ease-in-out;
      fill: ${(props) =>
        props.inCart
          ? props.theme.primary.darkColor
          : props.theme.primary.black};
    }
  }
  width: 50px;
  height: 50px;
  margin-left: ${(props) => (props.marginLeft ? "20px" : "0")};
  cursor: pointer;
  background-color: white;
  border-radius: 5px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  @media (max-width: 415px) {
    width: 35px;
    height: 35px;
    margin-left: ${(props) => (props.marginLeft ? "10px" : "0")};
    & svg {
      width: 27px;
    }
  }
`;

const CrossContainer = styled.div`
  padding: 8px;
  background-color: ${(props) => props.theme.primary.black};
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 50%;
  cursor: pointer;
`;

const IconContainer = styled.div`
  cursor: pointer;
  width: 50px;
  height: 50px;
  background-color: white;
  border-radius: 5px;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-right: 20px;
  font-size: 9px;

  @media (max-width: 415px) {
    margin-right: 10px;
    width: 35px;
    height: 35px;
  }
  &.passive {
    box-sizing: border-box;
    margin-right: 0px;
    background-color: unset;
    border: solid grey 0.5px;
    position: relative;
    &:hover {
      &:after {
        content: "* téléchargement disponible";
        width: 110px;
        padding: 4px;
        text-align: center;
        position: absolute;
        font-size: 12px;
        top: -20px;
        right: -100px;
        background: white;
        border: solid black 1px;
        border-radius: 5px;
      }
    }
  }
  &.circle {
    margin-right: 0px;
    margin-left: 20px;
    display: flex;
    flex-direction: column;
    text-transform: uppercase;
    @media (max-width: 415px) {
      margin-left: 10px;
    }
  }
`;

const SoldContainer = styled.div`
  display: flex;
  align-items: center;
`;

const Img = styled.img`
  width: 30px;
  @media (max-width: 415px) {
    height: 27px;
    width: auto;
  }
`;

const Span = styled.span`
  font-size: 8px;
  text-transform: uppercase;
  letter-spacing: 0.8px;
  line-height: 7px;
  margin-top: 3px;
  @media (max-width: 415px) {
    display: none;
  }
`;

export default VolumeItem;
