import React from "react";
import styled from "styled-components";

const Profil2 = () => {
  return (
    <Svg
      xmlns="http://www.w3.org/2000/svg"
      width="30.315"
      height="30.277"
      viewBox="0 0 30.315 30.277"
    >
      <g id="profile" transform="translate(-1.734 -1.765)">
        <g
          id="Groupe_783"
          data-name="Groupe 783"
          transform="translate(1.734 1.765)"
        >
          <path
            id="Tracé_504"
            data-name="Tracé 504"
            d="M17.215,32.042A15.663,15.663,0,0,1,5.987,27.381,15.913,15.913,0,0,1,3.2,9.878,14.662,14.662,0,0,1,9.473,3.34a14.781,14.781,0,0,1,13.738.246,15.741,15.741,0,0,1,8.726,11.753,15.2,15.2,0,0,1-8.592,15.4,14.7,14.7,0,0,1-6.129,1.3ZM15.886,2.683A12.534,12.534,0,0,0,9.909,4.148a13.736,13.736,0,0,0-5.878,6.123A15.18,15.18,0,0,0,6.651,26.746,14.708,14.708,0,0,0,22.962,29.9a14.453,14.453,0,0,0,8.064-14.448A14.825,14.825,0,0,0,22.792,4.4a15.185,15.185,0,0,0-6.906-1.72ZM3.617,10.075h.006Z"
            transform="translate(-1.734 -1.765)"
            fill="#120909"
          />
        </g>
        <g
          id="Groupe_784"
          data-name="Groupe 784"
          transform="translate(7.712 17.063)"
        >
          <path
            id="Tracé_505"
            data-name="Tracé 505"
            d="M12.462,39.188a.459.459,0,0,1-.435-.313,9.688,9.688,0,0,1,.354-7.185,8.678,8.678,0,0,1,12.147-3.826,9.542,9.542,0,0,1,5.285,7.127,8.771,8.771,0,0,1-.412,3.884.459.459,0,1,1-.87-.293,7.836,7.836,0,0,0,.371-3.474,8.74,8.74,0,0,0-4.793-6.427,7.762,7.762,0,0,0-10.9,3.4,8.761,8.761,0,0,0-.313,6.5.459.459,0,0,1-.435.605Z"
            transform="translate(-11.502 -26.761)"
            fill="#120909"
          />
        </g>
        <g
          id="Groupe_785"
          data-name="Groupe 785"
          transform="translate(11.63 7.905)"
        >
          <path
            id="Tracé_506"
            data-name="Tracé 506"
            d="M23.43,21.868a5.6,5.6,0,0,1-.842-.065A5.691,5.691,0,0,1,18.6,19.252a4.353,4.353,0,0,1-.32-4.11,5.844,5.844,0,0,1,4.2-3.263,3.665,3.665,0,0,1,3.259.88A6.061,6.061,0,0,1,27.57,17.57,4.76,4.76,0,0,1,25.91,21.1a4.072,4.072,0,0,1-2.48.764Zm-.159-9.153a3.066,3.066,0,0,0-.616.064,4.983,4.983,0,0,0-3.539,2.729,3.409,3.409,0,0,0,.262,3.264h0A4.765,4.765,0,0,0,22.726,20.9a3.52,3.52,0,0,0,2.63-.524,4.01,4.01,0,0,0,1.3-2.872,5.173,5.173,0,0,0-1.531-4.052,2.737,2.737,0,0,0-1.852-.732Z"
            transform="translate(-17.903 -11.798)"
            fill="#120909"
          />
        </g>
      </g>
    </Svg>
  );
};

const Svg = styled.svg`
  cursor: pointer;
  @media (max-width: 1150px) {
    height: 26px;
  }
  @media (max-width: 430px) {
    & path {
      fill: white;
    }
  }
  @media (min-width: 1200px) {
    &:hover {
      transform: scale(1.1);
      transition: all 0.2s ease-in-out;
      & path {
        transition: all 0.2s ease-in-out;
      }
    }
  }
`;

export default Profil2;
