import React from "react";

const Cross = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="15.226"
      height="14.33"
      viewBox="0 0 15.226 14.33"
    >
      <g id="supprimer-la-croix" transform="translate(0.002 -5.642)">
        <path
          id="Tracé_189"
          data-name="Tracé 189"
          d="M15.193,15.776a9.45,9.45,0,0,0-2.341-2.414c-.869-.755-1.774-1.469-2.675-2.185,1.031-1.32,2.081-2.629,3.108-3.956a.359.359,0,0,0,.306-.421c.1-.248-.219-.513-.456-.413-.456-.27-.958-.5-1.428-.722-.19-.089-.408.1-.342.274-1.129,1.153-2.3,2.269-3.478,3.373-.493-.419-.979-.845-1.453-1.286l-.007-.007a.3.3,0,0,0-.117-.227c-.023-.019-.049-.036-.073-.055h0L6.23,7.731c-.09-.07-.181-.139-.274-.206A12.462,12.462,0,0,0,4.575,6.039a.362.362,0,0,0-.543-.323A11.339,11.339,0,0,0,1.252,7.7a.388.388,0,0,0-.054.081.273.273,0,0,0-.182.448A41.878,41.878,0,0,0,5.236,11.78c-1.61,1.5-3.2,3.016-4.709,4.61C.521,16.4.514,16.4.508,16.4Q.3,16.617.1,16.836a.374.374,0,0,0,.211.621,6.209,6.209,0,0,0,1.877,1.8c.486.332,1.373.94,1.985.638a.26.26,0,0,0,.295-.133,41.484,41.484,0,0,1,3.508-5.669c.729.64,1.452,1.287,2.182,1.923.214.187,2.588,2.7,3,1.862a.388.388,0,0,0,.172.012c.371-.068.665-.535.868-.812s.426-.588.61-.9C15.055,16.343,15.316,16.009,15.193,15.776ZM1.718,16.3a.37.37,0,0,0-.019.154.367.367,0,0,0-.177.235.508.508,0,0,0-.263.1l-.033-.014Q1.471,16.535,1.718,16.3Zm2.335,2.955a1.681,1.681,0,0,0-.2-.076,1.132,1.132,0,0,0,.314-.169C4.126,19.091,4.088,19.172,4.053,19.254Zm1.713-7.969c-.812-.657-1.636-1.3-2.461-1.94q.391.269.806.5c.544.488,1.107.957,1.674,1.422Z"
          transform="translate(0 0)"
          fill="#fff"
        />
      </g>
    </svg>
  );
};

export default Cross;
