import React from "react";
import styled from "styled-components";

const NoSubscripte = ({ height }) => {
  return (
    <Svg
      xmlns="http://www.w3.org/2000/svg"
      width="133"
      height={height}
      viewBox="0 0 133 119.598"
    >
      <g id="ad-blocker" transform="translate(33.914)">
        <rect
          id="Rectangle_462"
          data-name="Rectangle 462"
          width="2.329"
          transform="translate(21.9 38.234) rotate(135)"
          fill="none"
        />
        <path
          id="Tracé_382"
          data-name="Tracé 382"
          d="M180.594,298.845,169.842,309.6Z"
          transform="translate(-148.623 -260.607)"
          fill="none"
        />
        <path
          id="Tracé_383"
          data-name="Tracé 383"
          d="M187.951,283.686l-.334.334h0Z"
          transform="translate(-164.053 -247.448)"
          fill="none"
        />
        <path
          id="Tracé_384"
          data-name="Tracé 384"
          d="M296.619,234.39l2.914-2.914h0Z"
          transform="translate(-258.674 -202.126)"
          fill="none"
        />
        <path
          id="Tracé_385"
          data-name="Tracé 385"
          d="M213.012,191.191l-10.58,10.58h0Z"
          transform="translate(-176.913 -167.155)"
          fill="none"
        />
        <path
          id="Tracé_386"
          data-name="Tracé 386"
          d="M63,88.462a25.521,25.521,0,0,0,5.115,15.309l6.349-6.349h0L69.188,102.7,67.3,88.577V85.23l1.884-3.809L90.655,70.982l9.643.606-2.7,2.7h0l6.176-6.176A25.463,25.463,0,0,0,63,88.462Z"
          transform="translate(-55.876 -55.876)"
          fill="none"
        />
        <path
          id="Tracé_387"
          data-name="Tracé 387"
          d="M53.739,23A30.739,30.739,0,1,0,84.478,53.739,30.774,30.774,0,0,0,53.739,23ZM33.309,71.361h-.046a.924.924,0,0,1-.669-.338A27.309,27.309,0,0,1,71.022,32.594a.923.923,0,0,1,.068,1.368L33.962,71.09A.924.924,0,0,1,33.309,71.361ZM73.05,73.05a27.312,27.312,0,0,1-36.594,1.834.924.924,0,0,1-.068-1.368L73.516,36.388a.923.923,0,0,1,1.368.068A27.311,27.311,0,0,1,73.05,73.05Z"
          transform="translate(-21.153 -21.153)"
          fill="none"
        />
        <rect
          id="Rectangle_463"
          data-name="Rectangle 463"
          width="3.491"
          transform="translate(40.414 19.721) rotate(135)"
          fill="none"
        />
        <path
          id="Tracé_388"
          data-name="Tracé 388"
          d="M175.612,139.958l-9.679,9.679h0l8.226-8.226.983,1.489,1.627,10.334-2.639,7.509-9.862,5.783-11.928,5.146H143.9l-3.943,3.943a25.463,25.463,0,0,0,35.656-35.656Z"
          transform="translate(-122.679 -122.681)"
          fill="none"
        />
        <path
          id="Tracé_389"
          data-name="Tracé 389"
          d="M64.628,18.544A32.586,32.586,0,1,0,18.544,64.628,32.586,32.586,0,1,0,64.628,18.544ZM41.586,72.325A30.739,30.739,0,1,1,72.325,41.586,30.774,30.774,0,0,1,41.586,72.325Z"
          transform="translate(-9 -9)"
        />
        <path
          id="Tracé_390"
          data-name="Tracé 390"
          d="M93.929,55.833a.924.924,0,0,0-.338-.669A27.309,27.309,0,0,0,55.164,93.592a.925.925,0,0,0,.669.338h.046a.923.923,0,0,0,.653-.27L93.66,56.532A.924.924,0,0,0,93.929,55.833Zm-8.487,6.305h0l-1.306,1.306h0l-2.468,2.468h0l-1.847,1.847h0l-10.58,10.58h0l-1.62,1.62h0l-.334.334h0l-1.666,1.666h0l-1.647,1.647h0l-1.662,1.663h0l-6.349,6.349A25.463,25.463,0,0,1,91.618,55.962Z"
          transform="translate(-43.723 -43.723)"
        />
        <path
          id="Tracé_391"
          data-name="Tracé 391"
          d="M161.193,122.765a.923.923,0,0,0-1.368-.068L122.7,159.825a.924.924,0,0,0,.068,1.368,27.309,27.309,0,0,0,38.427-38.428ZM140.049,165.51a25.52,25.52,0,0,1-15.309-5.115l3.943-3.943L139.434,145.7h0l5.974-5.974h0l2.914-2.914h0l2.395-2.395h0l9.679-9.679a25.463,25.463,0,0,1-20.347,40.771Z"
          transform="translate(-107.463 -107.462)"
        />
        <path
          id="Tracé_392"
          data-name="Tracé 392"
          d="M113,212.895a4.189,4.189,0,0,0,4.184,4.184h3.7v-8.368h-3.7A4.188,4.188,0,0,0,113,212.895Z"
          transform="translate(-99.28 -182.364)"
          fill="none"
        />
        <path
          id="Tracé_393"
          data-name="Tracé 393"
          d="M296.622,156.82v1.423l2.468-2.468a1.454,1.454,0,0,0-2.468,1.045Z"
          transform="translate(-258.677 -136.054)"
          fill="none"
        />
        <path
          id="Tracé_394"
          data-name="Tracé 394"
          d="M118.976,123.5,97.509,133.939l-1.884,3.809V141.1l1.884,14.12,5.276-5.276-.592-4.984H102.1a6.031,6.031,0,0,1,0-12.061h4.623a.922.922,0,0,1,.764.4,32.255,32.255,0,0,0,12.807-4.087v-.052a3.3,3.3,0,0,1,5.621-2.351l2.7-2.7Z"
          transform="translate(-84.197 -108.394)"
          fill="none"
        />
        <path
          id="Tracé_395"
          data-name="Tracé 395"
          d="M159.513,286.136l.394,3.321,1.647-1.647-.194-1.674Z"
          transform="translate(-139.656 -249.574)"
          fill="none"
        />
        <path
          id="Tracé_396"
          data-name="Tracé 396"
          d="M187.325,283.678l.333-.334-.378-.049Z"
          transform="translate(-163.761 -247.108)"
          fill="none"
        />
        <path
          id="Tracé_397"
          data-name="Tracé 397"
          d="M186.753,186.79v7.57c.7.084,1.388.185,2.068.3l10.58-10.58V183A35.4,35.4,0,0,1,186.753,186.79Z"
          transform="translate(-163.303 -160.045)"
          fill="none"
        />
        <path
          id="Tracé_398"
          data-name="Tracé 398"
          d="M123.225,144.667v.052a32.255,32.255,0,0,1-12.807,4.087.922.922,0,0,0-.764-.4h-4.623a6.031,6.031,0,0,0,0,12.061h.093l.592,4.984,1.663-1.663-.394-3.321h1.848l.194,1.674,1.666-1.666-.044-.383.378.049,1.62-1.62c-.68-.115-1.371-.216-2.068-.3v-7.57a35.4,35.4,0,0,0,12.648-3.79v1.081l1.847-1.847v-1.423a1.454,1.454,0,0,1,2.468-1.045l1.306-1.306a3.3,3.3,0,0,0-5.621,2.351ZM108.73,158.615h-3.7a4.184,4.184,0,0,1,0-8.367h3.7Z"
          transform="translate(-87.127 -123.901)"
          fill="#00a3a3"
        />
        <path
          id="Tracé_399"
          data-name="Tracé 399"
          d="M298.079,243.877a1.464,1.464,0,0,0,1.457-1.457V231.475l-2.914,2.914v8.032A1.459,1.459,0,0,0,298.079,243.877Z"
          transform="translate(-258.677 -202.125)"
          fill="none"
        />
        <path
          id="Tracé_400"
          data-name="Tracé 400"
          d="M190.215,176.081l9.862-5.783,2.639-7.509-1.627-10.334-.983-1.489-8.226,8.226a3.68,3.68,0,0,1-.548,7.241v6.1a3.3,3.3,0,1,1-6.608,0v-.051a24.464,24.464,0,0,0-4.127-2.007l-10.752,10.752h8.442ZM201,161.845a.923.923,0,1,1,0,1.847h-3.413a.923.923,0,1,1,0-1.847Zm-6.473-3.788a.924.924,0,0,1,.338-1.261l3.118-1.8a.923.923,0,1,1,.924,1.6l-3.118,1.8a.923.923,0,0,1-1.262-.338Zm0,9.424a.924.924,0,0,1,1.262-.338l3.118,1.8a.923.923,0,1,1-.923,1.6l-3.118-1.8A.924.924,0,0,1,194.527,167.481Z"
          transform="translate(-148.625 -132.238)"
          fill="none"
        />
        <path
          id="Tracé_401"
          data-name="Tracé 401"
          d="M334.219,228.558a1.835,1.835,0,0,0-1.51-1.8v3.607A1.835,1.835,0,0,0,334.219,228.558Z"
          transform="translate(-290.003 -198.027)"
          fill="none"
        />
        <path
          id="Tracé_402"
          data-name="Tracé 402"
          d="M264.825,267.563l-2.7,2.7a26.638,26.638,0,0,1,2.7,1.292Z"
          transform="translate(-228.727 -233.452)"
          fill="none"
        />
        <path
          id="Tracé_403"
          data-name="Tracé 403"
          d="M356.458,274.362l3.118,1.8a.923.923,0,0,0,1.261-.338.924.924,0,0,0-.338-1.262l-3.118-1.8a.923.923,0,1,0-.923,1.6Z"
          transform="translate(-310.218 -237.858)"
          fill="#00a3a3"
        />
        <path
          id="Tracé_404"
          data-name="Tracé 404"
          d="M357.383,184.084l3.118-1.8a.923.923,0,1,0-.923-1.6l-3.118,1.8a.923.923,0,1,0,.923,1.6Z"
          transform="translate(-310.219 -157.927)"
          fill="#00a3a3"
        />
        <path
          id="Tracé_405"
          data-name="Tracé 405"
          d="M255.466,226.662a3.3,3.3,0,0,0,6.608,0v-6.1a3.68,3.68,0,0,0,.548-7.241l-2.395,2.395v10.946a1.457,1.457,0,0,1-2.914,0v-8.032l-5.974,5.974a24.438,24.438,0,0,1,4.127,2.007v.051Zm6.608-11.568a1.832,1.832,0,0,1,0,3.607Zm-9.313,8.088,2.7-2.7v4A26.719,26.719,0,0,0,252.761,223.182Z"
          transform="translate(-219.368 -186.367)"
          fill="#00a3a3"
        />
        <path
          id="Tracé_406"
          data-name="Tracé 406"
          d="M373.126,234.348a.923.923,0,0,0,.923.923h3.413a.923.923,0,0,0,0-1.847h-3.413A.923.923,0,0,0,373.126,234.348Z"
          transform="translate(-325.088 -203.817)"
          fill="#00a3a3"
        />
      </g>
      <text
        id="Sans_abonnement_Lecture_illimité"
        data-name="Sans abonnement
Lecture illimité"
        transform="translate(57 90.598)"
        fill="#120909"
        fontSize="14"
        className="segoesc"
      >
        <tspan x="-65.851" y="0">
          Sans abonnement
        </tspan>
        <tspan x="-53.114" y="22">
          Sans publicité
        </tspan>
      </text>
    </Svg>
  );
};

const Svg = styled.svg`
  & text {
    font-size: 16px;
  }
`;

export default NoSubscripte;
