import {
  forgotPassword,
  fetchUserDisconnected,
} from "../../../../lib/state/actions";

export const passwordReinit = (dispatch, navigate, email) => {
  dispatch(forgotPassword(JSON.stringify({ email })));
  localStorage.removeItem("token");
  dispatch(fetchUserDisconnected());
  navigate("/");
};
