import React, { useState } from "react";
import styled from "styled-components";
import { Link } from "react-router-dom";
import ConfirmButton from "../../../components/admin/button/ConfirmButton";
import { useSelector, useDispatch } from "react-redux";
import { deleteAdmin } from "../../../lib/state/actions";
import {
  useAdminsState,
  useSuperAdminState,
} from "../../marketplace/categoryPage/controllers/stateCtrl";

const Admins = () => {
  const dispatch = useDispatch();
  const [allowChanges, setAllowChanges] = useState(false);

  const admin = useAdminsState(useSelector);
  const superAdmin = useSuperAdminState(useSelector);

  const toggleAllowChanges = () => {
    setAllowChanges(!allowChanges);
  };

  const validateDeleteAdmin = (id) => {
    dispatch(deleteAdmin(id));
  };

  return (
    <Wrapper>
      {superAdmin && (
        <Button2 type="button" onClick={() => toggleAllowChanges()}>
          autoriser les modifications.
        </Button2>
      )}
      <H2>Liste des administrateurs:</H2>
      <Link to={`/admin/admins/create`}>
        <Button type="button">Créer un nouvel administrateur</Button>
      </Link>
      <Table>
        <thead>
          <tr>
            <Th>email:</Th>
            <Th>password :</Th>
            {allowChanges && (
              <>
                <Th>modifier :</Th>
                <Th>supprimer :</Th>
              </>
            )}
          </tr>
        </thead>
        {admin &&
          admin.length > 0 &&
          admin?.map((result, i) => (
            <tbody key={i}>
              <tr style={{ border: "1px solid black" }}>
                <Td>{result.email}</Td>
                <Td>{result.password && "***"}</Td>
                {allowChanges && (
                  <>
                    <Td>
                      <Link to={`/admin/admins/update/${result._id}`}>
                        <button
                          type="button"
                          style={{
                            backgroundColor: "lightgreen",
                            borderColor: "lightgreen",
                            textTransform: "uppercase",
                          }}
                        >
                          Modifier
                        </button>
                      </Link>
                    </Td>
                    <Td className="delete">
                      <ConfirmButton
                        message="effacer"
                        validate={() => validateDeleteAdmin(result._id)}
                        pad="0.4em"
                      />
                    </Td>
                  </>
                )}
              </tr>
            </tbody>
          ))}
      </Table>
    </Wrapper>
  );
};

const Wrapper = styled.div`
  position: relative;
  display: flex;
  align-items: center;
  flex-direction: column;
`;

const Button = styled.button`
  padding: 1em;
  border: solid 1px black;
  text-transform: uppercase;
  font-weight: bold;
  cursor: pointer;
`;

const H2 = styled.h2`
  margin: 20px;
  text-transform: uppercase;
  font-size: 16px;
  font-weight: bold;
`;

const Table = styled.table`
  border: 1px solid black;
  width: 80%;
  margin-top: 22px;
`;

const Td = styled.td`
  border: 1px solid black;
  padding: 10px;
  text-align: center;
  &.delete {
    width: 100px;
  }
`;

const Th = styled.th`
  border: 1px solid black;
  padding: 10px;
  text-align: center;
  font-weight: bold;
  text-transform: uppercase;
`;

const Button2 = styled.button`
  margin: 20px 20px 0px 0px;
  align-self: end;
`;

export default Admins;
