import React from "react";
import styled from "styled-components";

const NoAdvert = ({ height }) => {
  return (
    <Svg
      xmlns="http://www.w3.org/2000/svg"
      width="107"
      height={height}
      viewBox="0 0 107 119.598"
    >
      <g id="positive-vote" transform="translate(22.958)">
        <path
          id="Tracé_336"
          data-name="Tracé 336"
          d="M295.8,305.317l12.176-8.255-10.92,7.232a6.461,6.461,0,0,1-4.606,1,4.467,4.467,0,0,0-3.182.688l-1.317.872.09.135a2.682,2.682,0,0,1,.151.259l0,0Z"
          transform="translate(-250.927 -259.098)"
          fill="none"
        />
        <path
          id="Tracé_337"
          data-name="Tracé 337"
          d="M296.8,89.449l2.271-1.5h0Z"
          transform="translate(-258.93 -76.849)"
          fill="none"
        />
        <path
          id="Tracé_338"
          data-name="Tracé 338"
          d="M314.8,84.321l.791-.524-.79.523Z"
          transform="translate(-274.647 -73.226)"
          fill="none"
        />
        <path
          id="Tracé_339"
          data-name="Tracé 339"
          d="M175.85,89.534l.016.013a2.728,2.728,0,0,1,.419.363l2.444-10.558a12.374,12.374,0,0,0-.414-6.949C178.63,73.552,175.85,89.534,175.85,89.534Z"
          transform="translate(-153.41 -63.268)"
          fill="none"
        />
        <path
          id="Tracé_340"
          data-name="Tracé 340"
          d="M315.6,83.785l0,0-.786.525Z"
          transform="translate(-274.657 -73.213)"
          fill="none"
        />
        <path
          id="Tracé_341"
          data-name="Tracé 341"
          d="M285.676,72.24l0,.01,0-.01q.2.474.384.956l.6,1.624,2.273-1.505.786-.525A26.9,26.9,0,0,0,285.2,71.2Q285.451,71.716,285.676,72.24Z"
          transform="translate(-248.795 -62.221)"
          fill="none"
        />
        <path
          id="Tracé_342"
          data-name="Tracé 342"
          d="M202.555,59.454l1.317-.872a6.466,6.466,0,0,1,4.606-1,4.465,4.465,0,0,0,3.182-.688l12.557-8.316,0,0,3.518-2.33a2.644,2.644,0,1,0-2.92-4.41L223.213,42.9h0l-.464.307a1,1,0,0,1-1.107-1.672l1.478-.978.024-.016,1.5-.992a2.645,2.645,0,0,0-2.921-4.41l-1.606,1.064-.465.307a1,1,0,0,1-1.107-1.671l3-1.987a2.645,2.645,0,0,0-2.918-4.411l-2.072,1.372a1,1,0,0,1-1.148-1.642l.033-.024.008-.005,2.07-1.371h0l.928-.615a2.645,2.645,0,0,0-2.921-4.41L204.83,28.83a1,1,0,0,1-1.389-.282.973.973,0,0,1-.069-.124.5.5,0,0,1-.022-.046l-.008-.02-.006-.015-1.043-2.809a19.585,19.585,0,0,0-4.7-7.2l-1.915-1.856a2.185,2.185,0,0,0-3.342,2.775,14.2,14.2,0,0,1,.917,1.6.955.955,0,0,1,.042.089,14.4,14.4,0,0,1,1.055,9.454L191.494,42.74c.114.178.182.289.182.289Z"
          transform="translate(-166.639 -13.862)"
          fill="none"
        />
        <path
          id="Tracé_343"
          data-name="Tracé 343"
          d="M269.631,46.169a26.9,26.9,0,0,1,4.522,1.587c.821-.547,1.443-.956,1.948-1.288a28.924,28.924,0,0,0-7.812-2.644q-.43-.651-.905-1.27a20.945,20.945,0,0,1,2.724,4.65Q269.883,46.682,269.631,46.169Z"
          transform="translate(-233.223 -37.187)"
        />
        <path
          id="Tracé_344"
          data-name="Tracé 344"
          d="M216.592,27.617a4.617,4.617,0,0,0-2.859-1.968,4.644,4.644,0,0,0-3.1-6.694,4.657,4.657,0,0,0-.241-4.73,4.613,4.613,0,0,0-2.86-1.968,4.649,4.649,0,0,0-6.683-6.038l-3.4,2.251-1.23.815h0c-.5.332-1.127.741-1.948,1.288l0,0-.791.524-2.271,1.5-.6-1.624q-.18-.483-.384-.956a20.961,20.961,0,0,0-2.724-4.65,21.287,21.287,0,0,0-2.077-2.338l-1.914-1.856A4.189,4.189,0,0,0,177.1,6.5q.075.114.148.23l-.026.006a12.652,12.652,0,0,1,1.193,2.392s0,.007,0,.01a12.374,12.374,0,0,1,.414,6.949l-2.444,10.558a2.733,2.733,0,0,0-.419-.363,15,15,0,0,1,1.959,2.6l2.857-12.342a14.4,14.4,0,0,0-1.055-9.454.952.952,0,0,0-.042-.089,14.157,14.157,0,0,0-.917-1.6,2.185,2.185,0,0,1,3.342-2.775l1.915,1.856a19.584,19.584,0,0,1,4.7,7.2l1.043,2.809.006.015.008.02a.5.5,0,0,0,.022.046.974.974,0,0,0,.069.124,1,1,0,0,0,1.389.282L201.96,7.89a2.645,2.645,0,0,1,2.921,4.41l-.928.615h0l-2.07,1.371-.008.005-.033.024a1,1,0,0,0,1.148,1.642l2.072-1.372A2.645,2.645,0,0,1,207.979,19l-3,1.987a1,1,0,1,0,1.107,1.671l.464-.307,1.606-1.064a2.645,2.645,0,0,1,2.921,4.41l-1.5.992-.024.016-1.478.978a1,1,0,1,0,1.107,1.672l.464-.307h0l1.606-1.063a2.644,2.644,0,1,1,2.92,4.41l-3.518,2.33,0,0L198.1,43.037a4.461,4.461,0,0,1-3.182.688,6.466,6.466,0,0,0-4.606,1l-1.317.872L178.115,29.167h0L190.34,47.659a2.724,2.724,0,0,0-.15-.259l-.09-.135,1.317-.872A4.463,4.463,0,0,1,194.6,45.7a6.462,6.462,0,0,0,4.606-1l10.92-7.232,2.316-1.534h0l2.841-1.882a4.649,4.649,0,0,0,1.309-6.444Z"
          transform="translate(-153.076 0)"
          fill="#00a3a3"
        />
        <path
          id="Tracé_345"
          data-name="Tracé 345"
          d="M241.374,481.87l5.837-4.206a28.959,28.959,0,0,1-15.095,4.206Z"
          transform="translate(-202.412 -416.698)"
          fill="none"
        />
        <path
          id="Tracé_346"
          data-name="Tracé 346"
          d="M201.4,289.619a1,1,0,0,1-.984.82.954.954,0,0,1-.184-.018,1,1,0,0,1-.8-1.169,27.253,27.253,0,0,0,.369-2.815l-12.176,8.255-7.605,1.931,0,0a2.732,2.732,0,0,1-.921,3.53h0l-6.778,4.488h0l-8.156,5.4a27.177,27.177,0,0,0,34.122-16.549,1,1,0,1,1,1.885.681,29.034,29.034,0,0,1-8.574,12.336l11.234-8.095-.709-13.511-.236.156A29.316,29.316,0,0,1,201.4,289.619Z"
          transform="translate(-142.754 -248.255)"
          fill="none"
        />
        <path
          id="Tracé_347"
          data-name="Tracé 347"
          d="M.4,76.9A29.837,29.837,0,0,1,7.25,53.522a29.1,29.1,0,0,1,16.2-9.589c-.318-.534-.635-1.027-.872-1.379L15.2,44.93,5.74,53.037,0,64.718v14.4l3.49,9.6,7.4,9.149,12.952,4.49h5.439a28.679,28.679,0,0,1-11.151-2.236A29.545,29.545,0,0,1,.4,76.9Z"
          transform="translate(0 -37.186)"
          fill="none"
        />
        <path
          id="Tracé_348"
          data-name="Tracé 348"
          d="M23.515,76.974a27.147,27.147,0,0,0-6.634,17.787c0,.709.029,1.419.083,2.123l9.984-6.612h0l6.922-4.584a2.724,2.724,0,0,1,3.079.045l-.016-.013s2.78-15.982,2.464-17.132c-.066-.185-.135-.369-.21-.55A27.16,27.16,0,0,0,23.515,76.974Z"
          transform="translate(-14.751 -59.454)"
          fill="none"
        />
        <path
          id="Tracé_349"
          data-name="Tracé 349"
          d="M33.016,230.548a1,1,0,1,1,1.665-1.116L45.73,245.91l5.953-3.942h0a.729.729,0,0,0,.207-1.01l-13.507-20.4a.728.728,0,0,0-.463-.312.72.72,0,0,0-.547.106l-6.084,4.016,1.143,1.7a1,1,0,0,1-1.665,1.116l-1.15-1.716-10,6.6A27.239,27.239,0,0,0,35.56,252.645l8.5-5.628Z"
          transform="translate(-17.144 -191.745)"
          fill="none"
        />
        <path
          id="Tracé_350"
          data-name="Tracé 350"
          d="M191.673,229.2s-.069-.111-.182-.289l0,.01Z"
          transform="translate(-167.065 -199.715)"
          fill="none"
        />
        <path
          id="Tracé_351"
          data-name="Tracé 351"
          d="M444.008,286.439a27.086,27.086,0,0,1-.369,2.815,1,1,0,0,0,.8,1.169.953.953,0,0,0,.184.018,1,1,0,0,0,.984-.82,29.334,29.334,0,0,0,.479-4.559l.236-.156h0Z"
          transform="translate(-386.959 -248.532)"
        />
        <g
          id="Groupe_782"
          data-name="Groupe 782"
          transform="translate(0.125 6.731)"
        >
          <path
            id="Tracé_352"
            data-name="Tracé 352"
            d="M33.015,230.548a1,1,0,1,1,1.665-1.116l11.049,16.478,5.953-3.942h0a.729.729,0,0,0,.207-1.01l-13.507-20.4a.729.729,0,0,0-1.01-.206l-6.084,4.016,1.143,1.7a1,1,0,0,1-1.665,1.116l-1.15-1.716-10,6.6a27.238,27.238,0,0,0,15.941,20.571l8.5-5.628Z"
            transform="translate(-17.268 -198.475)"
            fill="#fff"
          />
          <path
            id="Tracé_353"
            data-name="Tracé 353"
            d="M57.67,91.008a1.015,1.015,0,0,0-1.3.61,27.526,27.526,0,0,1-34.542,16.752l8.256-5.467h0l6.861-4.543h0a2.765,2.765,0,0,0,.932-3.573L25.505,76.066l-.187-.282,0-.01a15.179,15.179,0,0,0-1.983-2.632A2.758,2.758,0,0,0,20.22,73.1l-7.008,4.64h0L3.1,84.432C3.048,83.719,3.02,83,3.02,82.283A27.494,27.494,0,0,1,25.6,55.232c.076.184.146.37.213.557,0,0,0-.007,0-.01a12.811,12.811,0,0,0-1.208-2.422,29.519,29.519,0,1,0,21.147,54.2c.07-.051,3.628-2.624,3.9-2.81a29.388,29.388,0,0,0,8.631-12.445A1.014,1.014,0,0,0,57.67,91.008Zm-54.3-4.357,10.119-6.679,1.165,1.737a1.015,1.015,0,0,0,1.686-1.13l-1.157-1.725L21.34,74.79A.738.738,0,0,1,22.363,75L36.036,95.643a.738.738,0,0,1-.209,1.023h0l-6.026,3.99L18.616,83.977a1.015,1.015,0,0,0-1.685,1.13l11.178,16.67-8.6,5.7A27.57,27.57,0,0,1,3.369,86.651Z"
            transform="translate(-0.99 -53.357)"
          />
          <path
            id="Tracé_354"
            data-name="Tracé 354"
            d="M351.509,458.543l-.034.02,0,0a23.034,23.034,0,0,0,3.86-2.783l-.008.006-.048.042Zm1.839-1.211q-.9.643-1.839,1.212l3.766-2.713Q354.346,456.616,353.347,457.331Z"
            transform="translate(-306.672 -404.307)"
            fill="#fff"
          />
          <path
            id="Tracé_355"
            data-name="Tracé 355"
            d="M353.606,457.662q1-.714,1.927-1.5l-3.766,2.713Q352.705,458.3,353.606,457.662Z"
            transform="translate(-306.932 -404.639)"
            fill="#fff"
          />
          <path
            id="Tracé_356"
            data-name="Tracé 356"
            d="M351.532,458.58l3.766-2.713.048-.042c-.265.184-3.779,2.725-3.848,2.776Z"
            transform="translate(-306.696 -404.345)"
            fill="#fff"
          />
        </g>
      </g>
      <text
        id="_100_légal_Sans_publicité"
        data-name="100% légal
Sans publicité"
        transform="translate(42 90.598)"
        fill="#120909"
        fontSize="14"
        className="segoesc"
      >
        <tspan x="-35.891" y="0">
          100% légal
        </tspan>
        <tspan x="-58" y="22">
          Lecture illimité
        </tspan>
      </text>
    </Svg>
  );
};

const Svg = styled.svg`
  & text {
    font-size: 16px;
  }
`;

export default NoAdvert;
