export const swithColors = (cat, theme) => {
  switch (cat) {
    case "tout public":
      return theme.forAll.color;
    case "Yaoi":
      return theme.yaoi.color;
    case "Yuri":
      return theme.yuri.color;
    case "Hentai":
      return theme.hentai.color;
    default:
      return theme.primary.lightColor;
  }
};

export const swithLightColors = (cat, theme) => {
  switch (cat) {
    case "tout public":
      return theme.forAll.lightColor;
    case "Yaoi":
      return theme.yaoi.lightColor;
    case "Yuri":
      return theme.yuri.lightColor;
    case "Hentai":
      return theme.hentai.lightColor;
    default:
      return theme.primary.lightColor;
  }
};

export const swithDarkColors = (cat, theme) => {
  switch (cat) {
    case "tout public":
      return theme.forAll.darkColor;
    case "Yaoi":
      return theme.yaoi.darkColor;
    case "Yuri":
      return theme.yuri.darkColor;
    case "Hentai":
      return theme.hentai.darkColor;
    default:
      return theme.primary.darkColor;
  }
};
