import React, { useEffect, useState } from "react";
import { fetchMessage } from "../../../lib/state/actions";
import { useDispatch } from "react-redux";
import { useParams } from "react-router-dom";
import ConnectForm from "../../../components/marketplace/connectForm";
import styled from "styled-components";

const ResetPassword = () => {
  const { token } = useParams();

  const [hide, setHide] = useState(false);

  const dispatch = useDispatch();

  const hideForm = () => setHide(!hide);

  useEffect(() => {
    dispatch(fetchMessage({ message: "C'est à vous !" }));
  }, [dispatch]);

  return (
    <>
      {!hide && (
        <ConnectForm
          toggleConnectForm={hideForm}
          forgetPass={true}
          token={token}
        />
      )}
      {hide && (
        <a href="/">
          <Wrapper>se connecter</Wrapper>
        </a>
      )}
    </>
  );
};

const Wrapper = styled.div`
  cursor: pointer;
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translatex(-50%) translatey(-50%);
  width: 200px;
  box-sizing: border-box;
  border-radius: 5px;
  background-color: ${(props) => props.theme.secondary.color};
  padding: 15px 30px;
  color: white;
  font-weight: 400;
  font-size: 12px;
  letter-spacing: 0.5px;
  text-transform: uppercase;
  text-align: center;
  border: solid 1px ${(props) => props.theme.secondary.color};
  &:hover {
    color: ${(props) => props.theme.secondary.color};
    background-color: white;

    transition: all 0.3s ease-in-out;
  }
`;

export default ResetPassword;
