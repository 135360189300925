import React, { useState } from "react";
import styled from "styled-components";
import Popup from "../popup";

const ConfirmButton = ({ message, validate, pad }) => {
  const [activePopup, setActivePopup] = useState(false);
  return (
    <>
      <Wrapper pad={pad} onClick={() => setActivePopup(true)}>
        {message}
      </Wrapper>
      {activePopup && (
        <Popup
          message={`Demande de confirmation : ${message} ?`}
          close={() => setActivePopup(false)}
          execute={validate}
        />
      )}
    </>
  );
};

const Wrapper = styled.div`
  border: none;
  min-width: 100px;
  padding: ${(props) => props.pad};
  font-weight: bold;
  font-size: 12px;
  background-color: black;
  color: white;
  border-radius: 5px;
  text-transform: uppercase;
  text-align: center;
  cursor: pointer;
  transition: all ease-in-out 0.2s;
  &:hover {
    background-color: darkred;
  }
`;

export default ConfirmButton;
