import { inputDate } from "./validateProduct/validate";

function uuidv4() {
  return "10000000-1000-4000-8000-100000000000".replace(/[018]/g, (c) =>
    (
      +c ^
      (crypto.getRandomValues(new Uint8Array(1))[0] & (15 >> (+c / 4)))
    ).toString(16)
  );
}

export const productObject = {
  releaseDate: inputDate(new Date()),
  lastModify: Date.now(), //inputDate(new Date()),
  digitalExclusive: false,
  category: "",
  age: 18,
  active: false,
  ended: false,
  readingDirection: "original",
  spinOff: "",
  frenchTitle: "",
  originalTitle: "",
  imageURL: "",
  paperURL: "",
  synopsis: "",
  frenchPublisher: "",
  author: [],
  illustrator: "",
  tags: [],
  totalLikes: 0,
  volumes: [],
};

export const volumeObject = {
  _id: "",
  volumeReleaseDate: inputDate(new Date()),
  originalReleaseDate: inputDate(new Date()),
  share: false,
  volume: 0,
  readingDirection: "original",
  isActive: true,
  volumeSale: true,
  chapterSale: false,
  chaptersPage: [],
  download: false,
  right: "",
  bookId: `urn:uuid:${uuidv4()}`,
  color: false,
  totalOfPage: 0,
  totalOfFile: 0,
  traductor: "",
  agent: "",
  chapters: [],
  freePeriod: inputDate(new Date(new Date().setDate(new Date().getDate() - 1))),
  digitalExclusive: false,
  imageURL: "",
  price: 0,
  tva: "",
  ISBN: "",
  extract: 12,
  likes: [],
  royaltyBuyingPrice: 0,
  royaltyRevenu: 0,
};
