import React from "react";
import styled from "styled-components";

const Chapters = () => {
  return (
    <>
      <Svg
        id="list-interface-symbol"
        xmlns="http://www.w3.org/2000/svg"
        width="26.365"
        height="20"
        viewBox="0 0 26.365 20"
      >
        <g id="_x31_0_21_" transform="translate(0 0)">
          <g id="Groupe_771" data-name="Groupe 771">
            <path
              id="Tracé_261"
              data-name="Tracé 261"
              d="M2.727,73.862a2.727,2.727,0,1,0,2.727,2.727A2.727,2.727,0,0,0,2.727,73.862Zm0,7.273a2.727,2.727,0,1,0,2.727,2.727A2.727,2.727,0,0,0,2.727,81.135Zm0,7.273a2.727,2.727,0,1,0,2.727,2.727A2.727,2.727,0,0,0,2.727,88.407Zm7.273-10H24.546a1.818,1.818,0,1,0,0-3.636H10a1.818,1.818,0,1,0,0,3.636Zm14.546,3.636H10a1.818,1.818,0,1,0,0,3.636H24.546a1.818,1.818,0,1,0,0-3.636Zm0,7.273H10a1.818,1.818,0,1,0,0,3.636H24.546a1.818,1.818,0,1,0,0-3.636Z"
              transform="translate(0 -73.862)"
              fill="#fff"
            />
          </g>
        </g>
      </Svg>
    </>
  );
};

const Svg = styled.svg`
  position: fixed;
  top: 0px;
  left: 0px;
  cursor: pointer;
  z-index: 10000;
  filter: drop-shadow(2px 2px 2px rgb(0 0 0 / 0.6));
  transform: scale(1);
  transition: transform 0.2s ease-in-out;
  padding: 12px 20px 20px 20px;
  &:hover {
    transform: scale(1.2);
  }
`;

export default Chapters;
