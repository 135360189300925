import React from "react";
import styled from "styled-components";
import EncryptChapter from "./EncryptChapter";
import { useSelector, useDispatch } from "react-redux";
import { deleteChapter } from "../../../lib/state/actions";
import { Link, useParams } from "react-router-dom";
import {
  useKeysState,
  useTvaState,
} from "../../marketplace/categoryPage/controllers/stateCtrl";

const ChaptersList = ({ chapters, allowChanges, setVolume }) => {
  const tva = useTvaState(useSelector);
  const key = useKeysState(useSelector);

  const { volumeId, productId } = useParams();

  const dispatch = useDispatch();

  const hasKey = (id) => {
    if (key && key.length > 0) {
      const keyExist = key.find((k) => k.chapterId === id);
      if (keyExist) {
        return true;
      } else {
        return false;
      }
    }
  };

  return (
    <div>
      {chapters?.length > 0 &&
        chapters
          .sort((a, b) => a.chapter - b.chapter)
          .map((chap) => (
            <ChapterContainer key={chap._id}>
              <>
                <Div>
                  <P>Chapitre numéro {chap.chapterNumber}</P>
                  <P>Titre : {chap.title}</P>
                  <P>
                    ISBN : {chap.ISBN ? chap.ISBN : "pas de vente au chapitre"}
                  </P>
                  <P>id : {chap._id}</P>
                  <P>Traducteur : {chap?.traductor}</P>
                  <P>Agent : {chap?.agent}</P>
                </Div>
                <Div>
                  {chap.ISBN && (
                    <>
                      <P>
                        {chap?.releaseDate &&
                          `Date de création: ${new Date(
                            chap?.releaseDate
                          ).toLocaleDateString("fr")}`}
                      </P>
                      <P>{chap?.gift && "Ce chapitre est gratuit."}</P>
                      <P>Prix : {chap?.price}</P>
                      <P>
                        Tva : {chap?.tva + ` : `}
                        {tva?.find((t) => t.name === chap?.tva)?.rate}%
                      </P>
                      <P>Royalty sur le revenu :{chap?.royaltyRevenu}</P>
                      <P>Royalty prix d'achat : {chap?.royaltyBuyingPrice}</P>
                    </>
                  )}
                </Div>

                <Div>
                  {allowChanges && (
                    <Link
                      to={`/admin/products/updatechapter/${productId}/${volumeId}/${chap._id}`}
                    >
                      <Button type="button">Modifier</Button>
                    </Link>
                  )}
                  {!hasKey(chap._id) && allowChanges ? (
                    <Button
                      type="button"
                      onClick={() =>
                        dispatch(deleteChapter(volumeId, chap._id, setVolume))
                      }
                    >
                      Effacer
                    </Button>
                  ) : (
                    <p>Pour supprimer un chapitre, supprimer la clef.</p>
                  )}
                </Div>

                <EncryptChapter
                  chapterId={chap._id}
                  allowChanges={allowChanges}
                />
              </>
            </ChapterContainer>
          ))}
    </div>
  );
};

const Div = styled.div`
  font-size: 15px;
  margin-right: 50px;
`;

const Button = styled.div`
  display: block;
  background-color: lightgrey;
  padding: 10px;
  text-align: center;
  margin: 20px 0;
  max-width: 200px;
  cursor: pointer;
  &.big {
    padding: 20px 20px;
    margin: 0 auto;
    font-size: 18px;
    font-weight: bold;
  }
`;

const ChapterContainer = styled.div`
  background-color: white;
  padding: 20px;
  margin-top: 10px;
  display: flex;
  align-items: center;
`;

const P = styled.p`
  padding: 5px 0;
  &.warning {
    font-size: 18px;
    padding: 20px 0;
    font-weight: bold;
  }
`;

export default ChaptersList;
