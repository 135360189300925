import React from "react";

const Visa = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="39.962"
      height="12.933"
      viewBox="0 0 39.962 12.933"
    >
      <g id="logo-cb-visa" transform="translate(0 -10)">
        <g id="Groupe_794" data-name="Groupe 794">
          <g id="Groupe_793" data-name="Groupe 793">
            <g id="Groupe_792" data-name="Groupe 792">
              <path
                id="Tracé_511"
                data-name="Tracé 511"
                d="M17.317,12.747H14.08L16.1.227h3.237ZM29.052.533A7.991,7.991,0,0,0,26.148,0c-3.2,0-5.448,1.705-5.461,4.142-.027,1.8,1.611,2.8,2.837,3.4C24.776,8.151,25.2,8.551,25.2,9.1c-.013.838-1.012,1.224-1.944,1.224a6.453,6.453,0,0,1-3.038-.665l-.426-.2-.453,2.81a9.844,9.844,0,0,0,3.61.666c3.4,0,5.609-1.678,5.635-4.275.013-1.426-.852-2.518-2.717-3.41-1.133-.573-1.826-.96-1.826-1.545.013-.533.586-1.079,1.864-1.079a5.485,5.485,0,0,1,2.412.48l.293.132.44-2.7Zm4.3,7.778c.267-.719,1.293-3.5,1.293-3.5-.014.027.266-.732.426-1.2L35.3,4.689s.613,3,.746,3.622h-2.69Zm4-8.084h-2.5a1.6,1.6,0,0,0-1.692,1.039l-4.808,11.48h3.4l.679-1.878h4.156c.093.44.387,1.878.387,1.878h3L37.35.226Zm-25.974,0L8.206,8.764,7.859,7.033A9.579,9.579,0,0,0,3.383,1.785l2.9,10.948H9.711L14.8.227H11.376Z"
                transform="translate(0 10)"
                fill="#00579f"
              />
              <path
                id="Tracé_512"
                data-name="Tracé 512"
                d="M5.262.227H.053L0,.48C4.063,1.519,6.754,4.023,7.859,7.033L6.727,1.28A1.34,1.34,0,0,0,5.262.227Z"
                transform="translate(0 10)"
                fill="#faa61a"
              />
            </g>
          </g>
        </g>
      </g>
    </svg>
  );
};

export default Visa;
