import React, { useEffect, useState, useRef } from "react";
import Burger from "./picto/Burger";
import Bar from "./toolBar/Bar";
import Chapters from "./picto/Chapters";
import ScrollBarHori from "./ScrollBarHori";
import ScrollBar from "./ScrollBar";
import styled from "styled-components";
import ChaptersPop from "./popup/ChaptersPop";

const TopBar = ({
  chapters,
  wrapperRef,
  toggleReadDirection,
  landscape,
  myBook,
  zoom,
  pageNumber,
  imageCorrection,
  windowSize,
  scrollNavOnBar,
  selectChapter,
  mobileAgent,
  scroll,
  extract,
  completeVolumeChapters,
  goToPage,
}) => {
  const [menu, setMenu] = useState(true);
  const [seeChapters, setSeeChapters] = useState(false);

  const navRef = useRef();

  //show menu on loading
  useEffect(() => {
    const timer = setTimeout(() => {
      setMenu(false);
    }, 2000);
    return () => {
      clearTimeout(timer);
    };
  }, []);

  //close all menu on click
  useEffect(() => {
    const wrapperEl = wrapperRef.current;
    wrapperEl.addEventListener("click", closeMenu);

    return () => {
      wrapperEl.removeEventListener("click", closeMenu);
    };
  });

  const toggleMenu = () => {
    setMenu(!menu);
  };

  //close menu/chapters/popEnd
  const closeMenu = () => {
    if (menu) {
      setMenu(false);
    }
    if (seeChapters) {
      setSeeChapters(false);
    }
  };

  //chapters
  const toggleChapters = () => {
    setSeeChapters(!seeChapters);
  };

  return (
    <NavBar ref={navRef}>
      {!extract && (
        <ChaptersPop
          isVisible={seeChapters}
          chapters={chapters}
          selectChapter={selectChapter}
          completeVolumeChapters={completeVolumeChapters}
          goToPage={goToPage}
          pageNumber={pageNumber}
          landscape={landscape}
        />
      )}
      <div onClick={toggleMenu}>
        <Burger />
      </div>
      <div onClick={toggleChapters}>
        <Chapters />
      </div>
      <Bar
        visible={menu}
        toggleReadDirection={toggleReadDirection}
        landscape={landscape}
        readingDirection={myBook?.readingDirection}
        zoom={zoom}
        mobileAgent={mobileAgent}
      />
      {landscape ? (
        <ScrollBarHori
          pageNumber={pageNumber}
          hide={
            imageCorrection?.divWidth + 120 > windowSize.width ? true : false
          }
          width={windowSize?.width}
          myBook={myBook}
          windowSize={windowSize}
          scrollNavOnBar={scrollNavOnBar}
          menu={menu}
        />
      ) : (
        <ScrollBar
          windowSize={windowSize}
          scroll={scroll}
          myBook={myBook}
          imageCorrection={imageCorrection}
          scrollNavOnBar={scrollNavOnBar}
          menu={menu}
        />
      )}
    </NavBar>
  );
};

const NavBar = styled.div``;

export default TopBar;
