//search by title and volume isbn
export const titleToProduct = (products, volumes, search, setState) => {
  if (products && products.length > 0 && volumes && volumes.length > 0) {
    const myProduct = products.filter((product) => {
      let searchString = product.frenchTitle.substring(0, search.length);
      if (searchString) {
        return searchString.toLowerCase() === search.toLowerCase();
      } else {
        return "";
      }
    });

    const myVolume = () => {
      if (volumes && volumes.length > 0) {
        let isbnVol = volumes.find((vol) => vol.ISBN === search);
        if (isbnVol) {
          let product = products.find(
            (product) => product._id === isbnVol.productId
          );
          if (product) {
            return [product];
          }
        } else {
          return [];
        }
      }
    };
    if (myProduct.length > 0 && myVolume().length === 0) {
      setState(myProduct);
    }
    if (myProduct.length === 0 && myVolume().length > 0) {
      setState(myVolume());
    }
  }
};

//return _id's object if isbn exist in Volumes or Chapters
export const isbnIsExist = (volumes, search) => {
  if (search.length === 13 && volumes && volumes.length > 0) {
    let id;
    let isbnVol = volumes.find((vol) => vol.ISBN === search);

    if (isbnVol) {
      id = isbnVol._id;
    } else {
      volumes.forEach((volume) => {
        let isbnChapter = volume.chapters.find(
          (chapter) => chapter.ISBN === search
        );
        if (isbnChapter) {
          id = isbnChapter._id;
        }
      });
    }
    return id;
  }
};
