import { messageNotif, requiredMessage } from "../validateProduct/validate";
import {
  postVolume,
  updateVolume,
  postChapter,
  updateChapter,
} from "../../state/actions";

export const volumeNumberIsExist = (product, currentVolume, dispatch) => {
  const isExist = product?.volumes?.find((volume) => {
    return (
      volume?.volume === currentVolume?.volume &&
      volume?._id !== currentVolume?._id
    );
  });
  if (isExist) {
    messageNotif("Ce numéro de volume / épisode existe déjà !", dispatch);
  }
};

export const validateVolume = (e, title, newVolume, dispatch, navigate) => {
  e.preventDefault();

  const form = e.target;
  let myFormData = new FormData();

  const coverElement = Array.from(form.elements).find(
    (input) => input.name === "cover"
  );

  if (coverElement.value) {
    let file = coverElement.files[0];
    myFormData.append("cover", file);
  }
  if (!newVolume.ISBN || newVolume.ISBN.length !== 13) {
    messageNotif("Le champ ISBN est invalide !", dispatch);
  }
  if (!newVolume.agent) {
    requiredMessage("Agent", dispatch);
  }
  if (!newVolume.price) {
    requiredMessage("Prix", dispatch);
  }
  if (!newVolume.totalOfPage) {
    requiredMessage("Nombre de page", dispatch);
  }
  if (!newVolume.traductor) {
    requiredMessage("Traducteur", dispatch);
  }
  if (!newVolume.tva) {
    requiredMessage("TVA", dispatch);
  }
  if (!newVolume.readingDirection) {
    requiredMessage("Sens de lecture", dispatch);
  }
  if (
    newVolume.ISBN.length === 13 &&
    newVolume.agent &&
    newVolume.price &&
    newVolume.totalOfPage &&
    newVolume.traductor &&
    newVolume.tva &&
    newVolume.readingDirection
  ) {
    if (title === "créer un nouveau volume") {
      if (!coverElement.value) {
        requiredMessage("enregistrement de fichier");
      } else {
        for (let key of myFormData.keys()) {
          if (key === "cover") {
            myFormData.append("volume", JSON.stringify(newVolume));
            dispatch(postVolume(myFormData, true, navigate));
          }
        }
      }
    }
    if (title === "modifier le volume") {
      myFormData.append("volume", JSON.stringify(newVolume));
      dispatch(updateVolume(navigate, myFormData, newVolume._id, true));
    }
  }
};

export const validateChapter = (title, volumeId, body, dispatch, navigate) => {
  if (!body.chapterNumber) {
    requiredMessage("Numéro de chapitre", dispatch);
  }
  if (!body.traductor) {
    requiredMessage("Traducteur", dispatch);
  }
  if (body.agent === "") {
    requiredMessage("Agent", dispatch);
  }
  if (body.price) {
    if (!body.ISBN || body.ISBN.length !== 13) {
      messageNotif(
        "Attention : bien compléter les champs ISBN, prix, TVA...",
        dispatch
      );
    }
    if (!body.tva) {
      requiredMessage("TVA", dispatch);
    }
  }
  if (body.ISBN) {
    if (!body.price) {
      messageNotif(
        "Attention : bien compléter les champs ISBN, prix, TVA...",
        dispatch
      );
    }
    if (!body.tva) {
      requiredMessage("TVA", dispatch);
    }
  }
  if (body.chapterNumber && body.traductor && body.agent) {
    if (title === "créer un chapitre") {
      dispatch(postChapter(JSON.stringify(body), volumeId, navigate));
    }
    if (title === "modifier un chapitre") {
      dispatch(updateChapter(JSON.stringify(body), volumeId, navigate));
    }
  }
};
