import React from "react";
import styled from "styled-components";
import neko from "./image/neko.jpg";

const Maintenance = () => {
  return (
    <Wrapper>
      <Img src={neko} alt="fille avec des oreilles de chat" />
      <P marginTop={25} className="montserratSemiBold">
        Maintenance en cours...
      </P>
      <P marginTop={10} className="montserratSemiBold">
        Nous revenons vite !
      </P>
    </Wrapper>
  );
};

const Wrapper = styled.div`
  width: 100%;
  min-height: calc(100dvh - 120px);
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background-color: white;
`;

const P = styled.p`
  text-align: center;
  font-size: 35px;
  margin-top: ${(props) =>
    props.marginTop ? `${props.marginTop}px` : "unset"};

  @media (max-width: 500px) {
    font-size: 22px;
    width: 300px;
  }
`;

const Img = styled.img`
  width: 480px;
  @media (max-width: 500px) {
    width: 300px;
  }
`;

export default Maintenance;
