import React, { useRef, useEffect, useState } from "react";
import styled from "styled-components";

const ScrollBarHori = ({
  pageNumber,
  myBook,
  scrollNavOnBar,
  menu,
  hide,
  windowSize,
}) => {
  const barRef = useRef();
  const draggableRef = useRef(null);
  const total = myBook?.even ? myBook?.pages - 1 : myBook?.pages; // !!! -2 !!!total of page - page 0

  const [position, setPosition] = useState(0);
  const [isDrag, setIsDrag] = useState(false);
  const [barWidth, setBarWidth] = useState();
  const [barOffsetLeft, setBarOffsetLeft] = useState();
  const [onePage, setOnePage] = useState(
    Math.round((barWidth / total) * 100) / 100
  );
  const [touch, setTouch] = useState(false);

  useEffect(() => {
    setBarWidth(barRef.current.offsetWidth);
    setBarOffsetLeft(barRef.current.offsetLeft);
  }, [barRef, windowSize]);

  useEffect(() => {
    setOnePage(Math.round((barWidth / total) * 100) / 100);
  }, [barWidth, total]);

  const toggleBarPosition = (e) => {
    let clientX = touch ? e.changedTouches[0].clientX : e.clientX;
    let goodPosition = clientX - barOffsetLeft;

    if (myBook?.readingDirection === "original") {
      if (goodPosition >= barWidth) {
        setPosition(0); //round position on bar
        scrollNavOnBar(0);
      }
      if (goodPosition <= 0) {
        setPosition(barWidth);
        scrollNavOnBar(total);
      }
      if (goodPosition > 0 && goodPosition < barWidth) {
        let newPosition = Math.ceil((barWidth - goodPosition) / onePage);

        setPosition(barWidth - goodPosition);

        scrollNavOnBar(newPosition);
      }
    }

    if (myBook?.readingDirection === "europe") {
      if (goodPosition <= 0) {
        setPosition(0); //round position on bar
        scrollNavOnBar(0); // scroll to page when portrait
      }
      if (goodPosition >= barWidth) {
        setPosition(barWidth);
        scrollNavOnBar(total);
      }
      if (goodPosition > 0 && goodPosition < barWidth) {
        let newPosition = Math.ceil(goodPosition / onePage);

        setPosition(goodPosition);
        scrollNavOnBar(newPosition);
      }
    }
  };

  const onloadBarPosition = () => {
    if (!isDrag && !isNaN(pageNumber)) {
      const startPosition = Math.round(pageNumber * onePage);
      setPosition(startPosition);
    }
  };

  const updatePage = (e) => {
    isDrag && toggleBarPosition(e);
  };

  const mouseUp = () => {
    setIsDrag(false);
  };

  const setClientPosition = (e) => {
    e.preventDefault();
    setIsDrag(true);
    updatePage(e);

    let xPosition = touch ? e.touches[0].clientX : e.clientX;

    if (myBook?.readingDirection === "original") {
      let roundPosition = barWidth - (xPosition - barOffsetLeft);
      if (touch) {
        setIsDrag(false);
      }
      if (isDrag) {
        setTouch(false);
      }
      if (roundPosition <= 0) {
        setPosition(0);
      }
      if (roundPosition >= barWidth) {
        setPosition(barWidth);
      }
      if (roundPosition > 0 && roundPosition < barWidth) {
        setPosition(roundPosition);
      }
    }
    if (myBook?.readingDirection === "europe") {
      let roundPosition = xPosition - barOffsetLeft;
      if (touch) {
        setIsDrag(false);
      }
      if (isDrag) {
        setTouch(false);
      }
      if (roundPosition <= 0) {
        setPosition(0);
      }
      if (roundPosition >= barWidth) {
        setPosition(barWidth);
      }
      if (roundPosition > 0 && roundPosition < barWidth) {
        setPosition(roundPosition);
      }
    }
  };

  useEffect(() => {
    const draggableElemt = draggableRef.current;
    isDrag && window.addEventListener("mousemove", setClientPosition);
    draggableElemt.addEventListener("touchstart", handleTouchStart, false);
    touch &&
      draggableElemt.addEventListener("touchmove", handleTouchMove, false);
    draggableElemt.addEventListener("touchend", handleTouchEnd, false);

    return () => {
      window.removeEventListener("mousemove", setClientPosition);
      draggableElemt.removeEventListener("touchstart", handleTouchStart, false);
      draggableElemt.removeEventListener("touchmove", handleTouchMove, false);
      draggableElemt.removeEventListener("touchend", handleTouchEnd, false);
    };
  });

  //original or europe progressBar
  const toggleDirection = () => {
    if (myBook?.readingDirection === "original") {
      return "row-reverse";
    }
    if (myBook?.readingDirection === "europe") {
      return "row";
    }
  };

  //onload bar position if localstorage
  useEffect(() => {
    if (!isNaN(pageNumber)) {
      onloadBarPosition();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pageNumber, onePage]);

  function handleTouchStart(evt) {
    evt.preventDefault();
    setTouch(true);
  }

  function handleTouchMove(evt) {
    setClientPosition(evt);
    touch && toggleBarPosition(evt);
  }

  const handleTouchEnd = (evt) => {
    setTouch(false);
  };

  return (
    <Wrapper onMouseUp={mouseUp} isDrag={isDrag} hide={hide}>
      <ScrollBarCont menu={menu} />
      <Bar onClick={toggleBarPosition}>
        <Scroll ref={barRef} menu={menu} toggleDirection={toggleDirection}>
          <ProgressBar position={position}></ProgressBar>

          <TouchDiv
            id="rond"
            ref={draggableRef}
            toggleDirection={toggleDirection}
            position={position}
            onMouseDown={setClientPosition}
          >
            <Round touch={touch}>
              {myBook?.readingDirection !== "webtoon" || !touch
                ? Math.floor(pageNumber.toString())
                : null}
            </Round>
          </TouchDiv>
        </Scroll>
      </Bar>
    </Wrapper>
  );
};

const Wrapper = styled.div`
  height: ${(props) => (props.isDrag ? "100%" : "10px")};
  position: fixed;
  bottom: ${(props) => (props.isDrag ? "0px" : "16px")};
  width: 100%;
  justify-content: center;
  z-index: 100;
  cursor: ${(props) => (props.isDrag ? "wait" : "auto")};
  display: flex;
`;

const ScrollBarCont = styled.div`
  position: fixed;
  display: flex;
  align-items: center;
  justify-content: center;
  bottom: ${(props) => (props.menu ? "0" : "-60")}px;
  left: 0;
  width: 100%;
  height: 60px;
  background-color: rgba(15, 24, 31, 0.8);
  transition: bottom 0.3s ease-in-out;
  border-top: solid 1px rgba(255, 255, 255, 0.1);
`;

const Bar = styled.div`
  width: 80%;
  position: fixed;
  bottom: 26px;
  height: 10px;
  display: flex;
  cursor: pointer;
`;

const Scroll = styled.div`
  position: fixed;
  display: flex;
  flex-direction: ${(props) => props.toggleDirection()};
  background-color: #f0f4f7;
  width: 80%;
  height: 2px;
  bottom: ${(props) => (props.menu ? "30px" : "-30px")};
  filter: drop-shadow(2px 2px 2px rgb(0 0 0 / 0.6));
  transition: bottom 0.3s ease-in-out;
`;

const ProgressBar = styled.div.attrs(({ position }) => ({
  style: {
    width: position + "px",
  },
}))`
  position: relative;
  background-color: #0087e0;
  height: 2px;
`;

const TouchDiv = styled.span.attrs(({ position, toggleDirection }) => ({
  style: {
    left: toggleDirection() === "row" && position + "px",
    right: toggleDirection() === "row-reverse" && position - 60 + "px",
  },
}))`
  position: absolute;
  transform-origin: center;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 60px;
  width: 60px;
  transform: translateX(-50%) translateY(-50%);
`;

const Round = styled.div`
  width: 30px;
  height: 30px;
  border-radius: 50%;
  background-color: #00568f;
  display: flex;
  justify-content: center;
  align-items: center;
  font-family: montserrat;
  font-size: 12px;
  letter-spacing: 1px;
  color: #f0f4f7;
  line-height: 0px;
  filter: ${(props) =>
    props.touch ? "none" : "drop-shadow(2px 2px 2px rgb(0 0 0 / 0.6))"};
`;

export default ScrollBarHori;
