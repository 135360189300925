import { adminSignup, updateAdmin } from "../../state/actions";
import { messageNotif } from "../validateProduct/validate";

let regex = new RegExp(
  // eslint-disable-next-line
  "([!#-'*+/-9=?A-Z^-~-]+(.[!#-'*+/-9=?A-Z^-~-]+)*|\"([]!#-[^-~ \t]|(\\[\t -~]))+\")@([!#-'*+/-9=?A-Z^-~-]+(.[!#-'*+/-9=?A-Z^-~-]+)*|[[\t -Z^-~]*])"
);

export const validateAdmin = (
  e,
  title,
  adminId,
  body,
  admin,
  dispatch,
  navigate
) => {
  e.preventDefault();

  if (title === "créer un nouvel administrateur") {
    searchSimilarPseudo(body.pseudo, admin, dispatch);
    searchSimilarEmail(body.email, admin, dispatch);
    passLength(body.password, 8, dispatch);
    if (!body.email.match(regex)) {
      messageNotif("Email non valide !", dispatch);
    }
    if (
      searchSimilarPseudo(body.pseudo, admin, dispatch) === false &&
      searchSimilarEmail(body.email, admin, dispatch) === false &&
      passLength(body.password, 8, dispatch) === false &&
      body.email.match(regex)
    ) {
      dispatch(adminSignup(JSON.stringify(body), navigate));
    }
  }
  if (title === "modifier un administrateur") {
    searchSimilarPseudoUpdate(body.pseudo, adminId, admin, dispatch);
    searchSimilarEmailUpdate(body.email, adminId, admin, dispatch);
    if (body.password || body.newPassword) {
      passLength(body.password, 8, dispatch);
      passLength(body.newPassword, 8, dispatch);
      if (body.newPasswordBis !== body.newPassword) {
        messageNotif(
          " Les nouveau Mots de passe ne sont pas identiques !",
          dispatch
        );
      }
    }
    if (
      searchSimilarPseudoUpdate(body.pseudo, adminId, admin, dispatch) ===
        false &&
      searchSimilarEmailUpdate(body.email, adminId, admin, dispatch) ===
        false &&
      !body.password &&
      !body.newPassword
    ) {
      dispatch(updateAdmin(JSON.stringify(body), adminId, navigate));
    }
    if (
      searchSimilarPseudoUpdate(body.pseudo, adminId, admin, dispatch) ===
        false &&
      searchSimilarEmailUpdate(body.email, adminId, admin, dispatch) ===
        false &&
      body.password.length >= 8 &&
      body.newPassword.length >= 8 &&
      body.newPassword === body.newPasswordBis
    ) {
      dispatch(updateAdmin(JSON.stringify(body), adminId, navigate));
    }
  }
};

export const searchSimilarPseudo = (pseudo, state, dispatch) => {
  let similarPseudo = state.find((user) => user.pseudo === pseudo);
  if (similarPseudo) {
    return messageNotif("Ce pseudo existe déjà en BDD !", dispatch);
  } else {
    return false;
  }
};

export const searchSimilarEmail = (email, state, dispatch) => {
  let similarEmail = state.find((user) => user.email === email);
  if (similarEmail) {
    return messageNotif("Cet email existe déjà en BDD !", dispatch);
  } else {
    return false;
  }
};

export const passLength = (password, length, dispatch) => {
  if (password.length < length) {
    return messageNotif("Mot de passe trop court !", dispatch);
  } else {
    return false;
  }
};

export const filterCollectionNotSameId = (id, state) => {
  let filter = state.filter((obj) => {
    return obj._id !== id;
  });
  if (filter) {
    return filter;
  } else {
    return;
  }
};

export const searchSimilarPseudoUpdate = (pseudo, id, state, dispatch) => {
  const isExist = filterCollectionNotSameId(id, state).find(
    (user) => user.pseudo === pseudo
  );
  if (isExist) {
    messageNotif("Ce pseudo existe en BDD !", dispatch);
  } else {
    return false;
  }
};

export const searchSimilarEmailUpdate = (email, id, state, dispatch) => {
  const isExist = filterCollectionNotSameId(id, state).find(
    (user) => user.email === email
  );
  if (isExist) {
    messageNotif("Cet email existe en BDD !", dispatch);
  } else {
    return false;
  }
};
