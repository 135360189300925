import React from "react";
import styled from "styled-components";
import LibrarySearch from "./LibrarySearch";

const Header = ({ onchange, search }) => {
  return (
    <>
      <Container2>
        <LibrarySearch onchange={onchange} search={search} />
      </Container2>
    </>
  );
};

const Container2 = styled.div`
  display: flex;
  justify-content: center;
  width: 100%;
  padding-top: 30px;
`;

export default Header;
