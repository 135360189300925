import React from "react";
import styled from "styled-components";

const MiniLogo = () => {
  return (
    <Svg
      xmlns="http://www.w3.org/2000/svg"
      width="34.603"
      height="33.442"
      viewBox="0 0 34.603 33.442"
    >
      <g id="Groupe_526" data-name="Groupe 526" transform="translate(0.59 0.5)">
        <path
          id="Tracé_93"
          data-name="Tracé 93"
          d="M219.742,363.232l-6.371,1.02L209.1,342.2,202.446,355l-6.6-12.8-3.189,16.19-6.337-.984,4.965-25.518h6.4l4.768,9.288,4.845-9.288h6.37Z"
          transform="translate(-186.318 -331.881)"
          fill="#a41916"
          stroke="#000"
          strokeMiterlimit="10"
          strokeWidth="1"
        />
      </g>
    </Svg>
  );
};

const Svg = styled.svg`
  @media (max-width: 950px) {
    height: 30px;
  }
  @media (min-width: 951px) {
    display: none;
  }
`;

export default MiniLogo;
