import { createUser, updateUser } from "../../state/actions";
import { messageNotif } from "../validateProduct/validate";
import {
  searchSimilarEmail,
  searchSimilarEmailUpdate,
  searchSimilarPseudo,
  passLength,
} from "../validateAdmin/validate";

export const regex = new RegExp(
  // eslint-disable-next-line
  "([!#-'*+/-9=?A-Z^-~-]+(.[!#-'*+/-9=?A-Z^-~-]+)*|\"([]!#-[^-~ \t]|(\\[\t -~]))+\")@([!#-'*+/-9=?A-Z^-~-]+(.[!#-'*+/-9=?A-Z^-~-]+)*|[[\t -Z^-~]*])"
);

export const validateUser = (
  e,
  title,
  userId,
  body,
  user,
  dispatch,
  navigate
) => {
  e.preventDefault();

  if (title === "créer un nouvel utilisateur") {
    searchSimilarPseudo(body.pseudo, user, dispatch);
    searchSimilarEmail(body.email, user, dispatch);
    passLength(body.password, 8, dispatch);
    if (!body.email.match(regex)) {
      messageNotif("Email non valide !", dispatch);
    }
    if (
      searchSimilarPseudo(body.pseudo, user, dispatch) === false &&
      searchSimilarEmail(body.email, user, dispatch) === false &&
      passLength(body.password, 8, dispatch) === false &&
      body.email.match(regex)
    ) {
      dispatch(createUser(JSON.stringify(body), navigate));
    }
  }
  if (title === "modifier un utilisateur") {
    //searchSimilarPseudoUpdate(body.pseudo, userId, user, dispatch);
    searchSimilarEmailUpdate(body.email, userId, user, dispatch);
    if (body.password || body.newPassword) {
      passLength(body.password, 8, dispatch);
      passLength(body.newPassword, 8, dispatch);
      if (body.newPasswordBis !== body.newPassword) {
        messageNotif(
          " Les nouveau Mots de passe ne sont pas identiques !",
          dispatch
        );
      }
    }
    if (
      /*searchSimilarPseudoUpdate(body.pseudo, userId, user, dispatch) ===
        false &&*/
      searchSimilarEmailUpdate(body.email, userId, user, dispatch) === false &&
      !body.password &&
      !body.newPassword
    ) {
      dispatch(updateUser(JSON.stringify(body), userId, navigate));
    }
    if (
      /*searchSimilarPseudoUpdate(body.pseudo, userId, user, dispatch) ===
        false &&*/
      searchSimilarEmailUpdate(body.email, userId, user, dispatch) === false &&
      body.password.length >= 8 &&
      body.newPassword.length >= 8 &&
      body.newPassword === body.newPasswordBis
    ) {
      dispatch(updateUser(JSON.stringify(body), userId, navigate));
    }
  }
};
