const userStorage = {
  notifs: [],
  cart: [],
  bookMark: [],
};

export const localstorageCtrl = (type, value, userId = null) => {
  switch (type) {
    case "login":
      if (value) {
        localStorage.setItem("token", JSON.stringify(value.token));
        const storage = localStorage.getItem(value.userId);
        if (storage === null || !storage) {
          localStorage.setItem(value.userId, JSON.stringify(userStorage));
        }
      } else {
        return;
      }
      break;
    case "logout":
      const storage = localStorage.getItem("token");
      if (storage !== null && storage) {
        localStorage.removeItem("token");
      }
      break;
    case "adminLogin":
      if (value) {
        localStorage.setItem("admtoken", JSON.stringify(value));
      } else {
        return;
      }
      break;
    case "adminLogout":
      const admStorage = localStorage.getItem("admtoken");
      if (admStorage !== null && admStorage) {
        localStorage.removeItem("admtoken");
      }
      break;
    case "isNotified":
      if (value && userId) {
        const storage = localStorage.getItem(userId);
        if (storage !== null && storage) {
          const storageObject = JSON.parse(storage);
          const updateStorage = {
            ...storageObject,
            notifs: [
              ...storageObject.notifs.filter((notif) => notif !== value),
              value,
            ],
          };
          localStorage.setItem(userId, JSON.stringify(updateStorage));
        } else {
          localStorage.setItem(
            userId,
            JSON.stringify({
              ...userStorage,
              notifs: [
                ...userStorage.notifs.filter((notif) => notif !== value),
                value,
              ],
            })
          );
        }
      }
      break;
    case "clearNotified":
      if (value && userId) {
        const storage = localStorage.getItem(userId);
        if (storage !== null && storage) {
          const storageObject = JSON.parse(storage);
          const updateStorage = {
            ...storageObject,
            notifs: [
              ...storageObject.notifs.filter((notif) => notif !== value),
            ],
          };
          localStorage.setItem(userId, JSON.stringify(updateStorage));
        }
      } else {
        return;
      }
      break;
    case "addToCart":
      if (value && userId) {
        const storage = localStorage.getItem(userId);
        if (storage !== null && storage) {
          const storageObject = JSON.parse(storage);
          const updateStorage = {
            ...storageObject,
            cart: [
              ...storageObject.cart.filter((art) => art._id !== value._id),
              {
                _id: value._id,
                volumeId: value.volumeId,
              },
            ],
          };
          localStorage.setItem(userId, JSON.stringify(updateStorage));
        } else {
          localStorage.setItem(
            userId,
            JSON.stringify({
              ...userStorage,
              cart: [
                ...userStorage.cart.filter((art) => art._id !== value._id),
                {
                  _id: value._id,
                  volumeId: value.volumeId,
                },
              ],
            })
          );
        }
      }
      break;
    case "removeFromCart":
      if (value && userId) {
        const storage = localStorage.getItem(userId);
        if (storage !== null && storage) {
          const storageObject = JSON.parse(storage);
          const updateStorage = {
            ...storageObject,
            cart: [...storageObject.cart.filter((art) => art._id !== value)],
          };
          localStorage.setItem(userId, JSON.stringify(updateStorage));
        } else {
          return;
        }
      }
      break;
    case "updateBookMark":
      if (value && userId) {
        const storage = localStorage.getItem(userId);
        if (storage !== null && storage) {
          const storageObject = JSON.parse(storage);
          const updateStorage = {
            ...storageObject,
            bookMark: [
              { book: value, page: "0" },

              ...storageObject.bookMark.filter(
                (object) => object.book !== value
              ),
            ].slice(0, 6),
          };
          localStorage.setItem(userId, JSON.stringify(updateStorage));
        } else {
          return;
        }
      }
      break;
    case "clearBookMark":
      if (value && userId) {
        const storage = localStorage.getItem(userId);
        if (storage !== null && storage) {
          const storageObject = JSON.parse(storage);
          const updateStorage = {
            ...storageObject,
            bookMark: [
              ...storageObject.bookMark.filter(
                (object) => object.book !== value
              ),
            ].slice(0, 6),
          };
          localStorage.setItem(userId, JSON.stringify(updateStorage));
        } else {
          return;
        }
      }
      break;
    default:
      return;
  }
};

export const updateBookmarkPage = (userId, volumeId, chapterId, page) => {
  if (userId && volumeId && page) {
    const storage = localStorage.getItem(userId);
    if (storage !== null && storage) {
      const storageObject = JSON.parse(storage);
      const isExist = storageObject.bookMark.find(
        (obj) => obj.book === volumeId
      );
      if (isExist) {
        const updateStorage = {
          ...storageObject,
          bookMark: [
            { book: volumeId, chapter: chapterId, page: page },
            ...storageObject.bookMark.filter(
              (object) => object.book !== volumeId
            ),
          ].slice(0, 6),
        };
        localStorage.setItem(userId, JSON.stringify(updateStorage));
      } else {
        const updateStorage = {
          ...storageObject,
          bookMark: [
            { book: volumeId, chapter: chapterId, page: page },
            ...storageObject.bookMark.filter(
              (object) => object.book !== volumeId
            ),
          ].slice(0, 6),
        };
        localStorage.setItem(userId, JSON.stringify(updateStorage));
      }
    }
  }
};

export const bookMarkCollection = (userId) => {
  const storage = localStorage.getItem(userId);
  if (storage !== null && storage) {
    const storageObject = JSON.parse(storage);
    return storageObject.bookMark;
  }
};

export const arrayOfNotified = (userId, notification) => {
  if (userId) {
    const storage = localStorage.getItem(userId);
    if (storage !== null && storage) {
      const storageObject = JSON.parse(storage);
      storageObject.notifs.forEach((notifId) => {
        const isExist = notification.find((notif) => notif._id === notifId);
        if (!isExist) {
          localstorageCtrl("clearNotified", notifId, userId);
        }
      });
      return storageObject.notifs;
    } else {
      return [];
    }
  } else {
    return [];
  }
};

export const clearCartStorage = (userId) => {
  if (userId) {
    const cartStorage = localStorage.getItem(userId);
    if (cartStorage !== null && cartStorage) {
      const storageObject = JSON.parse(cartStorage);
      const updateStorage = {
        ...storageObject,
        cart: [],
      };
      localStorage.setItem(userId, JSON.stringify(updateStorage));
    } else {
      return;
    }
  }
};
