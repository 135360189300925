import React from "react";
import styled from "styled-components";
import Sticker from "../../../components/marketplace/Sticker";
import WrapperContainer from "../../../components/marketplace/WrapperContainer";
import { useSelector } from "react-redux";
import Calc from "../../../components/marketplace/cart/Calc";
import VolumeItem from "../../../components/marketplace/productSheet/VolumeItem";
import ChapterItem from "../../../components/marketplace/productSheet/ChapterItem";
import { useCartState } from "../categoryPage/controllers/stateCtrl";
import { Helmet } from "react-helmet-async";

const Cart = () => {
  const cart = useCartState(useSelector);

  return (
    <>
      {cart && (
        <>
          <Helmet>
            <title>Validez et payez vos achats par carte bancaire.</title>
            <meta name="description" content={`gérez et validez vos achats.`} />
            <meta
              name="title"
              content={`Validez et payez vos achats par carte bancaire.`}
            />
            <meta
              name="prerender-header"
              content="Location: http://www.mangaebook.com/cart"
            ></meta>
          </Helmet>
          <H1 className="segoesc">
            <a href="/">MangaeBook /</a>Panier
          </H1>

          <Sticker title="Mes achats" center={true} marginTop="60" />
          <WrapperContainer>
            <Container>
              <Articles>
                {cart && cart.length > 0 ? (
                  cart.map((item) => {
                    if (!item.chapterNumber) {
                      return (
                        <VolumeItem
                          key={item._id}
                          volume={item}
                          isCart={true}
                        />
                      );
                    } else {
                      return (
                        <ChapterItem
                          key={item._id}
                          product={item.productId}
                          chapter={item}
                          isCart={true}
                        />
                      );
                    }
                  })
                ) : (
                  <Vide className="segoesc">Aucun article...</Vide>
                )}
              </Articles>
              {<Calc cart={cart} />}
            </Container>
          </WrapperContainer>
        </>
      )}
    </>
  );
};

const Container = styled.section`
  width: 90%;
  margin: 0 auto;
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  @media (max-width: 1000px) {
    flex-direction: column-reverse;
    align-items: center;
  }
  @media (max-width: 450px) {
    width: 100%;
  }
`;
const H1 = styled.h1`
  position: absolute;
  top: 70px;
  left: 30px;
  font-size: 14px;
  @media (max-width: 1150px) {
    left: 10px;
  }
`;

const Articles = styled.article`
  display: flex;
  flex-direction: column;
  width: 65%;
  align-items: flex-start;
  @media (max-width: 1000px) {
    width: 100%;
    align-items: center;
    min-width: 300px;
  }
`;

const Vide = styled.p`
  font-size: 18px;
  text-align: center;
`;

export default Cart;
