import React, { useState, useEffect } from "react";
import styled from "styled-components";
import VolumeItem from "./VolumeItem";
import ChapterItem from "./ChapterItem";
import { useDispatch } from "react-redux";
import { getVolumes } from "../../../lib/state/actions";
import { useParams, useNavigate } from "react-router-dom";
import Popup from "../reader/popup/Popup";

const VolumesOrChapters = ({ product }) => {
  const { chapter } = useParams();

  const [volumeList, setVolumeList] = useState(true);
  const [allVolumes, setAllVolumes] = useState([]);
  const [byChapter, setByChapter] = useState([]);
  const [popup, setPopup] = useState(false);

  const navigate = useNavigate();

  const togglePopup = () => {
    setPopup(!popup);
  };

  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(getVolumes(product._id, setAllVolumes));
  }, [product, product._id, dispatch]);

  useEffect(() => {
    if (allVolumes && allVolumes.length > 0 && byChapter.length === 0) {
      allVolumes.map((volume) => {
        return volume.chapters.map((chapter) => {
          if (volume.chapterSale && volume.isActive && chapter.isActive) {
            return setByChapter((oldState) => [
              ...oldState,
              {
                ...chapter,
                volume: volume.volume,
                volumeId: volume._id,
                imageURL: volume.imageURL,
                digitalExclusive: volume.digitalExclusive,
              },
            ]);
          }
          return byChapter;
        });
      });
    }

    if ((allVolumes.length === 0 && byChapter.length > 0) || chapter) {
      setVolumeList(false);
    } else {
      setVolumeList(true);
    }
  }, [allVolumes, byChapter, chapter]);

  const selectVolumes = () => {
    setVolumeList(true);
    navigate(`/item/${product.frenchTitle.replace(" ", "-")}/${product._id}`);
  };

  const selectChapters = () => {
    setVolumeList(false);
    navigate(
      `/item/${product.frenchTitle.replace(" ", "-")}/${product._id}/chapter`
    );
  };

  return (
    <Wrapper oneShot={product.ended && product.volumes.length === 1}>
      {
        <Popup
          visible={popup}
          message={"Connectez-vous !"}
          togglePopup={togglePopup}
        />
      }
      <Separator
        className="column"
        oneShot={product.ended && product.volumes.length === 1}
      />
      <Choice>
        {allVolumes.length > 0 && (
          <Container
            onClick={() => selectVolumes()}
            byVolume={allVolumes.length}
            byChapter={byChapter.length}
            oneShot={product.ended && product.volumes.length === 1}
          >
            <P volumeList={volumeList} className="montserratSemiBold volumes">
              {allVolumes.length === 1 && allVolumes[0].volume === 0
                ? "Volume"
                : "Volumes"}
            </P>
          </Container>
        )}
        {byChapter.length > 0 && (
          <Container
            onClick={() => selectChapters()}
            byChapter={byChapter.length}
            byVolume={allVolumes.length}
          >
            <P volumeList={volumeList} className="montserratSemiBold chapitres">
              {product.readingDirection === "webtoon"
                ? "Épisodes"
                : "Chapitres"}
            </P>
          </Container>
        )}
      </Choice>
      <Separator>
        <Line
          volumeList={volumeList}
          byVolume={allVolumes.length}
          byChapter={byChapter.length}
        />
      </Separator>
      <Articles className={volumeList ? "volumes" : "chapters"}>
        {volumeList
          ? allVolumes &&
            allVolumes.map((vol) => (
              <VolumeItem
                volume={vol}
                key={vol._id}
                togglePopup={togglePopup}
              />
            ))
          : allVolumes &&
            byChapter.map((chapter) => {
              return (
                <ChapterItem
                  key={chapter._id}
                  product={product}
                  chapter={chapter}
                  togglePopup={togglePopup}
                />
              );
            })}
      </Articles>
    </Wrapper>
  );
};

const Wrapper = styled.div`
  box-sizing: border-box;
  width: 100%;
  min-height: 100%;
  margin-left: ${(props) => (props.oneShot ? "0" : "20px")};
  @media (max-width: 815px) {
    margin-left: 0px;
  }
`;

const Articles = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  box-sizing: border-box;
  padding: 20px 0px 10px 0px;
  height: calc(100% - 57px);
  overflow-y: scroll;
  @media (max-width: 815px) {
    overflow-y: hidden;
  }
`;

const Choice = styled.div`
  width: 100%;
  display: flex;
`;

const Container = styled.div`
  cursor: pointer;
  width: ${(props) => (!props.byChapter || !props.byVolume ? "100%" : "50%")};
  text-align: center;
  padding-top: ${(props) => (props.oneShot ? "30px" : "10px")};
  padding-bottom: 30px;
  color: ${(props) => props.theme.primary.black};
  @media (max-width: 815px) {
    padding-top: 30px;
  }
`;

const P = styled.p`
  font-size: 15px;
  transition: all 0.2s ease-in-out;
  &.volumes {
    color: ${(props) =>
      props.volumeList ? props.theme.primary.black : "grey"};
  }
  &.chapitres {
    color: ${(props) =>
      props.volumeList ? "grey" : props.theme.primary.black};
  }
`;

const Separator = styled.div`
  width: 100%;
  height: 4px;
  background-color: #f0f0f0;
  &.column {
    display: ${(props) => (props.oneShot ? "block" : "none")};
    margin-top: ${(props) => (props.oneShot ? "30px" : "0")};
    @media (max-width: 815px) {
      display: block;
      margin-top: 30px;
    }
  }
`;

const Line = styled.div`
  width: ${(props) => (!props.byChapter || !props.byVolume ? "100%" : "50%")};
  height: 4px;
  transition: transform 0.2s ease-in-out;
  background-color: ${(props) => props.theme.primary.lightColor};
  transform: ${(props) =>
    props.volumeList
      ? "translateX(0px)"
      : !props.byChapter || !props.byVolume
      ? "translateX(0px)"
      : "translateX(100%)"};
`;

export default VolumesOrChapters;
