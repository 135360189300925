//debounce
export function debounce(inputFunction, timeToWaitBeforeFiringInMs = 500) {
  let timer;
  return (...args) => {
    clearTimeout(timer);
    timer = setTimeout(() => {
      inputFunction.apply(this, args);
    }, timeToWaitBeforeFiringInMs);
  };
}

//landscape or portrait
export const toggleLandscape = (screenSize, infoState, setLandscapeState) => {
  let ratio = screenSize?.height / infoState?.height;
  if (
    screenSize?.width >= infoState?.width * ratio * 2 &&
    infoState?.readingDirection !== "webtoon"
  ) {
    setLandscapeState(true);
  }
  if (
    screenSize?.width < infoState?.width * ratio * 2 &&
    infoState?.readingDirection !== "webtoon"
  ) {
    setLandscapeState(false);
  }
  if (infoState?.readingDirection === "webtoon") {
    setLandscapeState(false);
  }
};

//tabView
export const toggleTabView = (screenSize, landscape, cb) => {
  if (!landscape && screenSize?.width < 850) {
    cb(true);
  } else cb(false);
};

export const prevPage = (state, setState) => {
  state >= 2 && setState(state - 2);
};

export const nextPage = (state, setState, bookInfo) => {
  if (
    bookInfo?.pages % 2 === 0 &&
    bookInfo?.even &&
    state < bookInfo?.pages - 2
  ) {
    setState(state + 2);
  }
  if (bookInfo?.pages % 2 === 0 && !bookInfo?.even && state < bookInfo?.pages) {
    setState(state + 2);
  }
  if (bookInfo?.pages % 2 !== 0 && state < bookInfo?.pages - 2) {
    setState(state + 2);
  }
};

//key press horizontal reading direction
export const handleKeyUpHori = (e, page, setPage, bookInfo) => {
  e.preventDefault();
  if (e.keyCode === 37) {
    bookInfo?.readingDirection === "original"
      ? nextPage(page, setPage, bookInfo)
      : prevPage(page, setPage);
  }
  if (e.keyCode === 39) {
    bookInfo?.readingDirection === "original"
      ? prevPage(page, setPage)
      : nextPage(page, setPage, bookInfo);
  }
};

//invert images if original or european direction
export const toggleDirection = (bookInfo) => {
  if (bookInfo?.readingDirection === "europe") {
    return "row";
  }
  if (bookInfo?.readingDirection === "original") {
    return "row-reverse";
  }
};

export const getTouches = (evt) => {
  if (evt.touches.length === 2) {
    evt.preventDefault();
    evt.stopPropagation();
  } else {
    return (
      evt.touches || // browser API
      evt.originalEvent.touches
    );
  }
};

export const allReplace = (
  str,
  obj = {
    " ": "-",
    "&": "and",
    "[‘’“”\"']": "-",
    "[,:;%]": "",
    "[éèêë]": "e",
    "[àäâ]": "a",
    "[îï]": "i",
    "[ô]": "o",
    "[üû]": "u",
    "[œ]": "oe",
  }
) => {
  str = str.toLowerCase();
  for (const x in obj) {
    str = str.replace(new RegExp(x, "g"), obj[x]);
  }
  return str;
};
