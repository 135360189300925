import React, { useEffect, useState } from "react";
import styled from "styled-components";
import { useSelector, useDispatch } from "react-redux";
import Header from "../../../components/marketplace/library/Header";
import { Link } from "react-router-dom";
import Sticker from "../../../components/marketplace/Sticker";
import { getProductIdFromVolumeId } from "../../../lib/state/actions";
import Slider from "../../../components/marketplace/library/Slider/Slider";
import { bookMarkCollection } from "../../../lib/state/localStorage/localStorageCtrl";
import { sortByOrder } from "../categoryPage/controllers/algoCtrl";
import {
  useProductsState,
  useCategoryState,
  useUserState,
} from "../categoryPage/controllers/stateCtrl";
import CatChoice from "./CatChoice";
import { Helmet } from "react-helmet-async";

const Library = () => {
  const [soldBooks, setSoldBooks] = useState([]);
  const [soldProducts, setSoldProducts] = useState([]);
  const [sortLibrary, setSortLibrary] = useState([]);
  const [bookMark, setBookMark] = useState([]);
  const [search, setSearch] = useState("");
  const [searchResult, setSearchResult] = useState([]);
  const [selectedCategory, setSelectedCategory] = useState([]);
  const [notRead, setNotRead] = useState([]);
  const [notReadObjects, setNotReadObjects] = useState([]);

  const dispatch = useDispatch();

  const { userConnected, userEbooks, userId } = useUserState(useSelector);
  const products = useProductsState(useSelector);
  const category = useCategoryState(useSelector);

  //request to add to state sold product _id
  const loadSoldBookId = (array, setState) => {
    const body = JSON.stringify(array);
    dispatch(getProductIdFromVolumeId(body, setState));
  };

  //unique
  function onlyUnique(value, index, array) {
    return array.indexOf(value) === index;
  }

  //bookMark
  useEffect(() => {
    if (userId && products && products.length > 0) {
      const lastReadingVolumes = bookMarkCollection(userId);
      if (lastReadingVolumes && lastReadingVolumes.length > 0) {
        lastReadingVolumes.forEach((bookmark) => {
          products.forEach((p) => {
            const currentProduct = p.volumes.find(
              (vol) => vol === bookmark.book
            );
            if (currentProduct) {
              setBookMark((oldValues) => [
                ...oldValues /*.filter((prod) => prod._id !== p._id)*/,
                p,
              ]);
            }
          });
        });
      }
    }
  }, [userId, products]);

  //set not read
  useEffect(() => {
    if (
      userEbooks &&
      userEbooks.length > 0 &&
      soldProducts &&
      soldProducts.length > 0
    ) {
      const notReadArray = userEbooks.filter((ebook) => !ebook.isRead);
      if (notReadArray && notReadArray.length > 0) {
        const sortArray = notReadArray.sort((a, b) => {
          let aPurchaseDate = a.purchaseDate ? a.purchaseDate : 0;
          let bPurchaseDate = b.purchaseDate ? b.purchaseDate : 0;
          return bPurchaseDate - aPurchaseDate;
        });
        loadSoldBookId(sortArray, setNotRead);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [userEbooks, soldProducts]);

  //set not read product object
  useEffect(() => {
    if (
      notRead &&
      notRead.length > 0 &&
      notReadObjects &&
      notReadObjects.length === 0
    ) {
      notRead.forEach((productId) => {
        soldProductObject(productId, setNotReadObjects);
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [notRead, notReadObjects]);

  //add to state sold volume _id
  useEffect(() => {
    if (
      userEbooks &&
      userEbooks.length > 0 &&
      soldBooks &&
      soldBooks.length === 0
    ) {
      userEbooks.forEach((ebook) => {
        setSoldBooks((oldValues) => [
          ...oldValues.filter((value) => value !== ebook._id),
          ebook._id,
        ]);
      });
    }
  }, [userEbooks, soldBooks]);

  //add to state sold books id onload
  useEffect(() => {
    if (soldBooks && soldBooks.length > 0 && soldProducts.length === 0) {
      loadSoldBookId(soldBooks, setSoldProducts);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [soldBooks, soldProducts]);

  useEffect(() => {
    if (sortLibrary && sortLibrary.length === 0 && soldProducts.length > 0) {
      soldProducts.forEach((productId) => {
        soldProductObject(productId, setSortLibrary);
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [soldProducts, sortLibrary]);

  const soldProductObject = (productId, setState) => {
    if (products && products.length > 0) {
      const productObject = products.find(
        (product) => product._id === productId
      );
      if (productObject) {
        setState((oldValues) => [...oldValues, productObject]);
      }
    }
  };

  const categoryFilter = (cat) => {
    const categoryArray = sortLibrary.filter(
      (product) => product.category === cat.name
    );
    return categoryArray;
  };

  const catNameFilter = (catArray, array) => {
    let catFilter = [];
    catArray.forEach((cat) => {
      const catExist = array.find((product) => product.category === cat.name);
      if (catExist) {
        catFilter.push({ imageURL: cat.imageURL, name: cat.name });
      }
    });
    return catFilter;
  };

  //selected category
  const toggleCategory = (catName) => {
    const isExist = selectedCategory.find((obj) => obj.name === catName);
    if (isExist) {
      setSelectedCategory([
        ...selectedCategory.filter((obj) => obj.name !== catName),
      ]);
    } else {
      setSelectedCategory((oldValues) => [...oldValues, { name: catName }]);
    }
  };

  //active category icon if selected
  const categoryIsActive = (catName) => {
    const isExist = selectedCategory.find((obj) => obj.name === catName);
    if (isExist) {
      return true;
    } else {
      return false;
    }
  };

  const onchange = (value) => setSearch(value);

  useEffect(() => {
    if (sortLibrary && sortLibrary.length > 0 && search && search.length > 0) {
      let byAuthor = sortLibrary.filter(
        (book) =>
          book.author[0].slice(0, search.length).toLowerCase() ===
          search.toLowerCase()
      );

      let byIllustrator = sortLibrary.filter(
        (book) =>
          book.illustrator.slice(0, search.length).toLowerCase() ===
          search.toLowerCase()
      );

      let byTitle = sortLibrary.filter(
        (book) =>
          book.frenchTitle.slice(0, search.length).toLowerCase() ===
          search.toLowerCase()
      );

      let byOriginalPublisher = sortLibrary.filter(
        (book) =>
          book.originalPublisher.slice(0, search.length).toLowerCase() ===
          search.toLowerCase()
      );

      let byKeyWord = () => {
        let array = [];

        sortLibrary.forEach((book) => {
          if (
            book.frenchTitle.toLowerCase().indexOf(search.toLowerCase()) >= 0
          ) {
            array.push(book);
          }
        });
        return array;
      };

      let sortSearch = [
        byAuthor,
        byTitle,
        byIllustrator,
        byOriginalPublisher,
        byKeyWord(),
      ];
      const sort = sortSearch.sort((a, b) => b.length - a.length);
      setSearchResult([...sort[0]]);
    }
  }, [search, sortLibrary]);

  return (
    <Wrapper>
      <Helmet>
        <title>Retouvez tous vos ebooks achetés et vos dernières achats.</title>
        <meta
          name="description"
          content={`Accédez facilement à vos lectures en cours ou vos derniers achats`}
        />
        <meta
          name="title"
          content={`Retouvez tous vos ebooks achetés et vos dernières achats.`}
        />
        <meta
          name="prerender-header"
          content="Location: http://www.mangaebook.com/library"
        ></meta>
      </Helmet>
      <H1 className="segoesc">
        <a href="/">MangaeBook /</a>Mes eBooks
      </H1>
      <header>
        <Link to="./">
          <Sticker
            link="./"
            title={`Bibliothèque de ${userConnected}`}
            marginTop="0"
            center={true}
          />
        </Link>
        {<Header onchange={onchange} search={search} />}
      </header>
      {!search && notReadObjects && notReadObjects.length > 0 && (
        <Section>
          <Slider
            title="À lire !"
            array={notReadObjects}
            article={false}
            open={true}
          />
        </Section>
      )}

      <Section
        className={
          searchResult && searchResult.length === 0 ? "block" : "hidden"
        }
      >
        {search && searchResult && searchResult.length === 0 && (
          <Span className="segoesc">aucun résultat...</Span>
        )}
      </Section>
      <Section
        className={
          searchResult && searchResult.length === 0 ? "hidden" : "block"
        }
      >
        {search && (
          <Slider
            title={`Résultat(s)`}
            array={searchResult}
            article={false}
            open={false}
          />
        )}

        {!search && bookMark.length > 0 && (
          <Slider
            title={"Dernières lectures"}
            array={bookMark.filter(onlyUnique)}
            article={false}
          />
        )}
      </Section>
      {sortLibrary && sortLibrary.length > 0 && (
        <Sticker title="Par catégorie" marginTop="50" center={true} />
      )}
      <Section>
        {category &&
          category.length > 0 &&
          sortLibrary &&
          sortLibrary.length > 0 && (
            <CatChoice
              cat={catNameFilter(category, sortLibrary)}
              toggleCategory={toggleCategory}
              categoryIsActive={categoryIsActive}
            ></CatChoice>
          )}
        {sortLibrary.length > 0 &&
          category.length > 0 &&
          selectedCategory &&
          selectedCategory.length === 0 &&
          category.map(
            (cat) =>
              categoryFilter(cat).length > 0 && (
                //sort slider by category if no category selected
                <Slider
                  key={cat._id}
                  title={cat.name}
                  array={sortByOrder(categoryFilter(cat))}
                  article={false}
                />
              )
          )}
        {sortLibrary.length > 0 &&
          category.length > 0 &&
          selectedCategory &&
          selectedCategory.length > 0 &&
          selectedCategory.map(
            (cat) =>
              categoryFilter(cat).length > 0 && (
                //sort slider by category selected
                <Slider
                  key={cat.name}
                  title={cat.name}
                  array={sortByOrder(categoryFilter(cat))}
                  article={false}
                />
              )
          )}
      </Section>
    </Wrapper>
  );
};

const Wrapper = styled.section`
  padding-top: 60px;
`;
const H1 = styled.h1`
  position: absolute;
  top: 70px;
  left: 30px;
  font-size: 14px;
  @media (max-width: 1150px) {
    left: 10px;
  }
`;

const Section = styled.section`
  max-width: 1200px;
  margin: 0 auto;
  &.hidden {
    display: none;
  }
`;

const Span = styled.span`
  display: inline-block;
  width: 100%;
  text-transform: uppercase;
  text-align: center;
  margin-top: 20px;
`;

export default Library;
