import React from "react";
import styled from "styled-components";
import CloseButton from "../connectForm/CloseButton";

const LibrarySearch = ({ onchange, search }) => {
  return (
    <Wrapper>
      {search?.length > 0 && <CloseButton close={() => onchange("")} />}
      <Input
        className="montserrat"
        type="text"
        name="librarySearch"
        id="librarySearch"
        placeholder="Rechercher dans la bibliothèque"
        onChange={(e) => onchange(e.target.value)}
        value={search}
      />
    </Wrapper>
  );
};

const Wrapper = styled.div`
  position: relative;
`;

const Input = styled.input`
  box-sizing: border-box;
  border: none;
  border: solid 3px ${(props) => props.theme.primary.lightColor};
  border-radius: 5px;
  box-sizing: border-box;
  height: 40px;
  width: 280px;
  font-size: 17px;
  letter-spacing: 0.5px;
  text-align: center;
  line-height: 10px;
  transition: all 0.2s ease-in-out;
  color: ${(props) => props.theme.primary.black};
  background-color: white;
  &:focus {
    transform: scale(1.1);
    outline: none;
    border: solid 2px ${(props) => props.theme.secondary.color};
    &::placeholder {
      opacity: 0;
    }
  }
  &::placeholder {
    text-align: center;
    font-size: 13px;
    font-weight: bold;
    color: ${(props) => props.theme.primary.black};
    opacity: 0.5;
  }
  &:-webkit-autofill {
    -webkit-box-shadow: 0 0 0px 1000px white inset;
  }
  @media (max-width: 600px) {
    width: 250px;
    height: 30px;
    font-size: 14px;
    &::placeholder {
      font-size: 13px;
    }
  }
`; //no blue background when google autocomplete`

export default LibrarySearch;
