export const propertyToTitle = (property) => {
  switch (property) {
    case "frenchTitles":
      return "titres français";
    case "originalTitles":
      return "titres d'origine";
    case "authors":
      return "auteurs";
    case "illustrators":
      return "illustrateurs";
    case "traductors":
      return "traducteurs";
    case "frenchPublishers":
      return "éditeurs français";
    case "originalPublishers":
      return "éditeurs d'origine";
    case "prices":
      return "prix (en centimes)";
    default:
      return property;
  }
};
