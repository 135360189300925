import React, { useState, useEffect, useRef } from "react";
import styled from "styled-components";

const ScrollBar = ({
  windowSize,
  scroll,
  myBook,
  imageCorrection,
  scrollNavOnBar,
  menu,
}) => {
  const [barHeight, setBarHeight] = useState(0);
  const [containerHeight, setContainerHeight] = useState(0);
  const [isDrag, setIsDrag] = useState(false);
  const [touch, setTouch] = useState(false);
  const [displayPages, setDisplayPages] = useState("0");

  //scroll percent
  const percent = (scroll / containerHeight) * 100;

  const barRef = useRef();
  const draggableRef = useRef(null);

  //set page to display on scrollbar
  useEffect(() => {
    if (myBook?.pages && percent >= 0) {
      let pageToDisplay = Math.floor((myBook.pages * percent) / 100);
      if (
        pageToDisplay >= 0 &&
        !isNaN(pageToDisplay) /*pageToDisplay !== NaN*/
      ) {
        setDisplayPages(pageToDisplay.toString());
      } else {
        setDisplayPages("0");
      }
    }
  }, [myBook?.pages, percent]);

  //container height
  useEffect(() => {
    if (imageCorrection?.divHeight && windowSize?.height && myBook?.pages) {
      const calcContainerHeight =
        myBook.pages * imageCorrection.divHeight - windowSize.height;
      setContainerHeight(calcContainerHeight);
    }
  }, [imageCorrection, windowSize?.height, myBook]);

  //scroll bar height
  useEffect(() => {
    const barEl = barRef.current;
    if (barEl) {
      setBarHeight(barEl.offsetHeight);
    }
  }, [windowSize]);

  //draggable
  useEffect(() => {
    const draggableElemt = draggableRef.current;
    isDrag && window.addEventListener("mousemove", dragStart);
    draggableElemt.addEventListener("touchstart", handleTouchStart, false);
    touch &&
      draggableElemt.addEventListener("touchmove", handleTouchMove, false);
    draggableElemt.addEventListener("touchend", handleTouchEnd, false);

    return () => {
      window.removeEventListener("mousemove", dragStart);
      draggableElemt.removeEventListener("touchstart", handleTouchStart, false);
      draggableElemt.removeEventListener("touchmove", handleTouchMove, false);
      draggableElemt.removeEventListener("touchend", handleTouchEnd, false);
    };
  });

  const goToPage = (e) => {
    e.preventDefault();

    let yPosition = touch ? e.touches[0].clientY : e.clientY;

    let newPosition = yPosition - (windowSize.height + 46 - barHeight) / 2;
    if (touch) {
      setIsDrag(false);
    }
    if (isDrag) {
      setTouch(false);
    }
    if (newPosition <= 0) {
      scrollNavOnBar(0);
    }
    if (newPosition >= barHeight) {
      scrollNavOnBar(containerHeight);
    }
    if (newPosition > 0 && newPosition < barHeight) {
      let calc = (newPosition / barHeight) * 100;
      scrollNavOnBar(((Math.round(calc * containerHeight) / 100) * 100) / 100);
    }
  };

  const dragStart = (e) => {
    setIsDrag(true);
    goToPage(e);
  };

  const dragEnd = () => {
    setIsDrag(false);
  };

  function handleTouchStart(evt) {
    evt.preventDefault();
    setTouch(true);
  }

  function handleTouchMove(e) {
    //setClientPosition(evt);
    touch && goToPage(e);
  }

  const handleTouchEnd = () => {
    setTouch(false);
  };

  return (
    <Wrapper isDrag={isDrag} onMouseUp={dragEnd} menu={menu}>
      <Container onClick={goToPage}>
        <BarContainer>
          <Bar ref={barRef}>
            <ProgressBar percent={percent} barHeight={barHeight} />
            <Position
              ref={draggableRef}
              onMouseDown={dragStart}
              percent={percent}
              barHeight={barHeight}
            >
              {myBook?.readingDirection !== "webtoon" ? displayPages : ""}
            </Position>
          </Bar>
        </BarContainer>
      </Container>
    </Wrapper>
  );
};

const Wrapper = styled.div`
  box-sizing: border-box;

  width: ${(props) => (props.isDrag ? "100%" : "auto")};
  position: fixed;
  bottom: 0;
  right: ${(props) => (props.menu ? "0" : "-61px")};
  height: calc(100% - 45px);
  z-index: 100;
  display: flex;
  justify-content: flex-end;
  transition: right 0.3s ease-in-out;
`;

const Container = styled.div`
  width: 60px;
  align-items: center;
  justify-content: center;
  display: flex;
  background-color: rgba(15, 24, 31, 0.8);
`;
const BarContainer = styled.div`
  box-sizing: border-box;
  position: relative;
  height: 90%;
  cursor: pointer;
  padding: 5px;
`;

const Bar = styled.div`
  width: 2px;
  height: 100%;
  background-color: white;
  filter: drop-shadow(2px 2px 2px rgb(0 0 0 / 0.6));
`;

const ProgressBar = styled.div.attrs(({ barHeight, percent }) => ({
  style: {
    height: percent ? (barHeight * percent) / 100 + "px" : "0",
  },
}))`
  width: 2px;
  background-color: #00568f;
`;

const Position = styled.div.attrs(({ barHeight, percent }) => ({
  style: {
    top: (barHeight * percent) / 100 - 15 + "px",
  },
}))`
  position: absolute;
  width: 30px;
  height: 30px;
  border-radius: 50%;
  color: #f0f4f7;
  background-color: #00568f;
  display: flex;
  justify-content: center;
  align-items: center;
  font-family: montserrat;
  font-size: 12px;
  letter-spacing: 1px;
  transform: translate(-14px);
  z-index: 999;
  cursor: pointer;
`;

export default ScrollBar;
