import React, { useEffect, useState } from "react";
import styled from "styled-components";
import { useNavigate } from "react-router-dom";
import { REACT_APP_API_HOST } from "../../../service";

const Carousel = ({ carousel }) => {
  const [index, setIndex] = useState(0);

  const navigate = useNavigate();

  useEffect(() => {
    if (carousel && carousel.length > 1) {
      let timer = setTimeout(() => changeCarousel(), 5000);
      return () => clearTimeout(timer);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [carousel, index]);

  const changeCarousel = () => {
    if (carousel && carousel.length > 0) {
      if (index >= 0 && index < carousel.length - 1) {
        setIndex(index + 1);
      }
      if (index === carousel.length - 1) {
        setIndex(0);
      }
    }
  };

  const sortByDate = (array) => {
    let sort = [...array].sort(
      (a, b) => new Date(b.date).getTime() - new Date(a.date).getTime()
    );
    return sort;
  };

  return (
    <Wrapper>
      <Container>
        {sortByDate(carousel).map((ban) => (
          <Img
            key={ban._id}
            src={REACT_APP_API_HOST + ban.imageURL}
            index={index}
            onClick={() => (ban.link ? navigate(ban.link) : null)}
            alt="bannière"
          />
        ))}
      </Container>
      <PointContainer>
        {sortByDate(carousel).map((ban, i) => (
          <Point
            key={ban._id}
            color={(index === i).toString()}
            onClick={() => setIndex(i)}
          />
        ))}
      </PointContainer>
    </Wrapper>
  );
};

const Wrapper = styled.section`
  margin: 20px 0px;
  width: 100%;
`;

const Container = styled.div`
  margin: 0 auto;
  max-height: 470px;
  max-width: 1050px;
  overflow: hidden;
  display: flex;
`;

const PointContainer = styled.div`
  display: flex;
  justify-content: center;
  padding: 10px;
`;

const Point = styled.button`
  border: none;
  display: block;
  padding: 0px;
  cursor: pointer;
  width: 10px;
  height: 10px;
  margin: 0 10px;
  border-radius: 50%;
  background-color: ${(props) =>
    props.color === "true"
      ? props.theme.primary.lightColor
      : props.theme.primary.offBlack};
`;

const Img = styled.img`
  height: 100%;
  width: 100%;
  transform: ${(props) =>
    props.index && `translate(calc(${props.index} * -100%))`};
  transition: transform ease-in-out 0.8s;
  cursor: pointer;
`;

export default Carousel;
