import React, { useState, useEffect } from "react";
import styled from "styled-components";
import { Link } from "react-router-dom";
import ConfirmButton from "../../../components/admin/button/ConfirmButton";
import { useSelector, useDispatch } from "react-redux";
import { deleteUser } from "../../../lib/state/actions";
import {
  useUsersState,
  useSuperAdminState,
} from "../../marketplace/categoryPage/controllers/stateCtrl";

const Users = () => {
  const [search, setSearch] = useState("");
  const [searchResults, setSearchResults] = useState([]);
  const [allowChanges, setAllowChanges] = useState(false);
  const dispatch = useDispatch();

  const users = useUsersState(useSelector);
  const superAdmin = useSuperAdminState(useSelector);

  const toggleAllowChanges = () => {
    setAllowChanges(!allowChanges);
  };

  useEffect(() => {
    if (users) {
      let byPseudo = users.filter(
        (user) =>
          user.pseudo.slice(0, search.length).toLowerCase() ===
          search.toLowerCase()
      );

      let byeMail = users.filter(
        (user) =>
          user.email.slice(0, search.length).toLowerCase() ===
          search.toLowerCase()
      );

      let sortSearch = [byPseudo, byeMail];
      const sort = sortSearch.sort((a, b) => b.length - a.length);
      setSearchResults([...sort[0]]);
    }
  }, [search, users]);

  const validateDeleteUser = (id) => {
    dispatch(deleteUser(id));
  };

  return (
    <Wrapper>
      {superAdmin && (
        <Button type="button" onClick={() => toggleAllowChanges()}>
          autoriser les modifications.
        </Button>
      )}

      <H2>Liste des utilisateurs:</H2>
      <Search>
        <label>Recherche par pseudo / mail: </label>
        <input
          type="text"
          value={search}
          onChange={(e) => setSearch(e.target.value)}
        ></input>
      </Search>
      {allowChanges && (
        <Link to={`/admin/users/create`}>
          <Button type="button">Créer un nouvel utilisateur</Button>
        </Link>
      )}
      <Table>
        <thead>
          <tr>
            <Th>pseudo:</Th>
            <Th>id:</Th>
            <Th>email:</Th>
            <Th>actif:</Th>
            {allowChanges && (
              <>
                <Th>modifier :</Th>
                <Th>supprimer :</Th>
              </>
            )}
          </tr>
        </thead>
        {searchResults &&
          searchResults.length > 0 &&
          searchResults.map((result, i) => (
            <tbody key={i}>
              <tr style={{ border: "1px solid black" }}>
                <Td>{result?.pseudo}</Td>
                <Td>{result?._id}</Td>
                <Td>{result?.email}</Td>
                <Td>{result.status ? "oui" : "non"}</Td>
                {allowChanges && (
                  <>
                    <Td>
                      <Link to={`/admin/users/update/${result._id}`}>
                        <button
                          type="button"
                          style={{
                            backgroundColor: "lightgreen",
                            borderColor: "lightgreen",
                            textTransform: "uppercase",
                          }}
                        >
                          Modifier
                        </button>
                      </Link>
                    </Td>
                    <Td className="delete">
                      <ConfirmButton
                        message="effacer"
                        validate={() => validateDeleteUser(result._id)}
                        pad="0.4em"
                      />
                    </Td>
                  </>
                )}
              </tr>
            </tbody>
          ))}
      </Table>
    </Wrapper>
  );
};

const Wrapper = styled.div`
  position: relative;
  display: flex;
  align-items: center;
  flex-direction: column;
`;

const H2 = styled.h2`
  margin: 20px;
  text-transform: uppercase;
  font-size: 16px;
  font-weight: bold;
`;

const Search = styled.div`
  padding-bottom: 20px;
  font-weight: bold;
  font-size: 14px;
  color: grey;
  & input {
    width: 500px;
  }
`;

const Table = styled.table`
  border: 1px solid black;
  width: 80%;
  margin-top: 22px;
`;

const Td = styled.td`
  border: 1px solid black;
  padding: 10px;
  text-align: center;
  &.delete {
    width: 100px;
  }
`;

const Th = styled.th`
  border: 1px solid black;
  padding: 10px;
  text-align: center;
  font-weight: bold;
  text-transform: uppercase;
`;

const Button = styled.button`
  margin: 20px 20px 0px 0px;
  align-self: end;
`;

export default Users;
