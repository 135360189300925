import React, { useState, useRef, useEffect } from "react";
import styled from "styled-components";
import TagButton from "./TagButton";
import {
  swithLightColors,
  swithDarkColors,
} from "../../../../views/marketplace/categoryPage/controllers/themeCtrl";
import theme from "../../../../theme";

const TagsBar = ({ tags, cat, selectTag, selectedTags }) => {
  const [tagMenuHeight, setTagMenuHeight] = useState("");
  const [seeMore, setSeeMore] = useState(false);
  const tagMenuRef = useRef();

  const setMenuHeight = () => {
    let height = tagMenuRef.current.offsetHeight;
    setTagMenuHeight(height);
  };

  const toggleSeeMore = () => {
    setSeeMore(!seeMore);
  };

  useEffect(() => {
    window.addEventListener("resize", setMenuHeight);
    return () => window.removeEventListener("resize", setMenuHeight);
  });

  useEffect(() => {
    setMenuHeight();
  }, [tags, cat]);

  return (
    <>
      <Wrapper tagMenuHeight={tagMenuHeight} seeMore={seeMore}>
        <Container ref={tagMenuRef} tagMenuHeight={tagMenuHeight}>
          {tags.map((tag) => (
            <Li key={tag} onClick={() => selectTag(tag)}>
              <TagButton tag={tag} cat={cat} selectedTags={selectedTags} />
            </Li>
          ))}
        </Container>
      </Wrapper>
      {tagMenuHeight > 70 && (
        <Line color={swithDarkColors(cat, theme)} seeMore={seeMore}>
          <MoreTagsCont>
            <Triangle
              color={swithLightColors(cat, theme)}
              onClick={() => toggleSeeMore()}
            >
              <Span className="montserratSemiBold">{!seeMore ? "+" : "-"}</Span>
            </Triangle>
          </MoreTagsCont>
        </Line>
      )}
    </>
  );
};

const Wrapper = styled.div`
  padding: 0 10px;
  height: ${(props) =>
    props.tagMenuHeight > 70 && !props.seeMore
      ? "70px"
      : `${props.tagMenuHeight}px`};
  overflow: clip;
  transition: height 0.2s ease-in-out;
  @media (max-width: 1150px) {
    padding: 0 10px;
  }
`;

const Container = styled.menu`
  list-style: none;
`;

const Li = styled.li`
  display: inline-block;
  margin-top: 10px;
  margin-right: 10px;
  color: ${(props) => props.theme.primary.black};
  &:last-child {
    margin-right: 0;
  }
`;

const Triangle = styled.div`
  display: inline-block;
  height: 0;
  width: 0;
  border-top: 20px solid ${(props) => props.color};
  border-right: 40px solid transparent;
  border-left: 40px solid transparent;
  display: flex;
  justify-content: center;
  align-items: center;
`;
const Span = styled.span`
  position: absolute;
  top: 0;
  font-size: 18px;
`;

const MoreTagsCont = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
`;

const Line = styled.div`
  position: relative;
  color: white;
  margin-top: 3px;
  width: 100%;
  border-top: solid ${(props) => props.color} 1px;
  transform: ${(props) => (props.seeMore ? "scaleY(-1)" : "unset")};
  transition: transform 0.3s ease-in-out;
`;

export default TagsBar;
