//when add chapter, verify if not the same in cart
export const sameInCart = (cart, chapterId) => {
  if (cart && cart.length > 0) {
    const isExist = cart.filter((art) => art._id === chapterId);
    if (isExist && isExist.length > 0) {
      return true;
    } else {
      return false;
    }
  } else {
    return false;
  }
};

//when add chapter, verify if not volume with the same chapter in cart
export const isVolumeWithSameChapter = (cart, volumeId) => {
  const isExist = cart.find((art) => art._id === volumeId);
  if (isExist) {
    return true;
  } else {
    return false;
  }
};

//when  add volume, verify if not same volume in cart
export const sameVolInCart = (cart, volumeId) => {
  if (cart && cart.length > 0) {
    const isExist = cart.filter((art) => {
      return art._id === volumeId;
    });
    if (isExist && isExist.length > 0) {
      return true;
    } else {
      return false;
    }
  } else {
    return false;
  }
};

//when add volume, verify if no chapter of this volume in cart
export const isChapterFromVolumeInCart = (cart, volumeId) => {
  if (cart && cart.length > 0) {
    const isExist = cart.find((art) => art.volumeId === volumeId);
    if (isExist) {
      return true;
    } else {
      return false;
    }
  } else {
    return false;
  }
};

export const volumePrice = (volume, tva) => {
  if (volume && tva) {
    const searchTva = tva.find((tax) => tax.name === volume.tva);
    if (searchTva) {
      const rate = Number(searchTva?.rate);
      if (rate) {
        let volumeTva = (volume.price * rate) / 100;
        let priceWithTaxe = Math.round(
          Number(volume.price) + volumeTva
        ).toFixed(2);
        if (priceWithTaxe && volumeTva) {
          return {
            price: Number(priceWithTaxe / 100).toFixed(2),
            taxe: volumeTva / 100,
          };
        }
      }
    }
  }
};
