import React from "react";

const Facebook = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="55.475"
      height="55.475"
      viewBox="0 0 55.475 55.475"
    >
      <g id="Layer_2" dataname="Layer 2" transform="translate(0 0)">
        <g id="_01.facebook" dataname="01.facebook" transform="translate(0 0)">
          <path
            id="background"
            d="M45.546,54.523a167.86,167.86,0,0,1-35.615,0A10.108,10.108,0,0,1,.942,45.546a167.862,167.862,0,0,1,0-35.616A10.108,10.108,0,0,1,9.918.942a167.86,167.86,0,0,1,35.615,0,10.108,10.108,0,0,1,8.989,8.976,167.863,167.863,0,0,1,0,35.616,10.108,10.108,0,0,1-8.976,8.989Z"
            transform="translate(0.005 0.005)"
            fill="#365094"
          />
          <path
            id="icon"
            d="M74.6,44.586l-.46,3.984a.746.746,0,0,1-.733.668H69.246l-.021,11.813a.533.533,0,0,1-.52.538H64.492a.52.52,0,0,1-.52-.541V49.239H60.854a.746.746,0,0,1-.738-.754L60.1,44.5a.749.749,0,0,1,.738-.759h3.134V39.9c0-4.465,2.651-6.9,6.539-6.9H73.7a.749.749,0,0,1,.741.759v3.353a.749.749,0,0,1-.741.759H71.743c-2.113,0-2.524,1.04-2.524,2.542v3.329h4.644a.749.749,0,0,1,.733.845Z"
            transform="translate(-39.541 -19.294)"
            fill="#fff"
          />
        </g>
      </g>
    </svg>
  );
};

export default Facebook;
