import React from "react";
import styled from "styled-components";

const Heart = ({ small }) => {
  return (
    <Svg
      small={small}
      id="Line"
      xmlns="http://www.w3.org/2000/svg"
      width="15"
      height="13"
      viewBox="0 0 22.91 19.788"
    >
      <path
        id="Tracé_128"
        data-name="Tracé 128"
        d="M18.865,5a6,6,0,0,0-4.27,1.77l-1.14,1.14-1.14-1.14A6.04,6.04,0,0,0,2,11.042a8.851,8.851,0,0,0,3.124,6.769l7.992,6.847a.521.521,0,0,0,.677,0l7.992-6.868a8.851,8.851,0,0,0,3.124-6.769A6.055,6.055,0,0,0,18.865,5Z"
        transform="translate(-2 -4.995)"
        fill="#c00"
      />
    </Svg>
  );
};

const Svg = styled.svg`
  width: ${(props) => (props.small ? "15px" : "unset")};
  height: 13px;
`;

export default Heart;
