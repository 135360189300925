import React, { useState } from "react";
import styled from "styled-components";

const Tags = ({ name, myArray, deleteElement }) => {
  const [seeMore, setSeeMore] = useState("disable");
  const [activateModif, setActivateModif] = useState(
    name === "achats" ? false : true
  );

  const toggleSeeMore = () => {
    seeMore === "disable" ? setSeeMore("active") : setSeeMore("disable");
  };

  const SeeMorecomp = () => (
    <Button onClick={toggleSeeMore}>voir plus...</Button>
  );

  return (
    <Wrapper>
      <Title>{name} :</Title>
      <Active
        name={name}
        type="button"
        onClick={() => setActivateModif(!activateModif)}
      >
        voir tous les titres achetés
      </Active>
      <Container active={activateModif}>
        {myArray?.map((element, i) => (
          <Tag key={i} className={i < 10 ? "active" : seeMore}>
            {name === "achats"
              ? `${element.title} acheté le ${new Date(
                  element.date
                ).toLocaleDateString("fr")}`
              : element}
            <Button
              className={i < 10 ? "active" : seeMore}
              onClick={() => deleteElement(element)}
            >
              x
            </Button>
          </Tag>
        ))}
        {myArray?.length > 10 && <SeeMorecomp />}
      </Container>
    </Wrapper>
  );
};

const Wrapper = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-self: flex-start;
  padding: 1em 0em;
`;

const Active = styled.button`
  display: ${(props) => props.name !== "achats" && "none"};
  width: 250px;
  text-transform: uppercase;
`;

const Container = styled.div`
  flex-wrap: wrap;
  align-items: center;
  align-self: flex-start;
  margin-bottom: 1em;
  display: ${(props) => (props.active ? "flex" : "none")};
`;

const Title = styled.p`
  font-weight: bold;
  font-size: 12px;
  margin-right: 1em;
`;

const Tag = styled.div`
  z-index: 100;
  position: relative;
  margin: 0.5em;
  border: solid orange 1px;
  border-radius: 5px;
  padding: 4px;
  background-color: white;
  font-size: 12px;
  display: flex;
  align-items: center;
  opacity: 1;
  transition: all 0.5s ease-in-out;
  &.disable {
    position: absolute;
    opacity: 0;
    z-index: 0;
    transform: translateX(-500px);
  }
`;

const Button = styled.div`
  margin-left: 10px;
  font-size: 12px;
  padding: 4px;
  background-color: grey;
  cursor: pointer;
  transition: all 0.2s ease-in-out;
  font-weight: bold;
  letter-spacing: 1px;
  color: white;
  &:hover {
    background-color: black;
  }
  &.disable {
  }
`;

export default Tags;
