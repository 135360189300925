import React from "react";
import styled from "styled-components";

const Profil = () => {
  return (
    <Svg
      xmlns="http://www.w3.org/2000/svg"
      width="22.852"
      height="26"
      viewBox="0 0 22.852 26"
    >
      <g id="user_1_" data-name="user (1)" transform="translate(-31)">
        <g id="Groupe_835" data-name="Groupe 835" transform="translate(35.526)">
          <g id="Groupe_834" data-name="Groupe 834">
            <path
              id="Tracé_545"
              data-name="Tracé 545"
              d="M127.9,0a6.9,6.9,0,1,0,6.9,6.9A6.908,6.908,0,0,0,127.9,0Zm0,12.267A5.367,5.367,0,1,1,133.267,6.9,5.373,5.373,0,0,1,127.9,12.267Z"
              transform="translate(-121)"
            />
          </g>
        </g>
        <g
          id="Groupe_837"
          data-name="Groupe 837"
          transform="translate(31 15.164)"
        >
          <g id="Groupe_836" data-name="Groupe 836">
            <path
              id="Tracé_546"
              data-name="Tracé 546"
              d="M50.955,302.974A9.742,9.742,0,0,0,43.949,300H40.9a9.742,9.742,0,0,0-7.006,2.974,10.089,10.089,0,0,0-2.9,7.094.764.764,0,0,0,.762.767H53.09a.764.764,0,0,0,.762-.767A10.089,10.089,0,0,0,50.955,302.974Zm-18.4,6.328a8.453,8.453,0,0,1,8.345-7.769h3.047a8.453,8.453,0,0,1,8.345,7.769Z"
              transform="translate(-31 -300)"
            />
          </g>
        </g>
      </g>
    </Svg>
  );
};

const Svg = styled.svg`
  cursor: pointer;
  @media (max-width: 1150px) {
    height: 26px;
  }
  @media (max-width: 600px) {
    & path {
      fill: white;
    }
  }
`;

export default Profil;
