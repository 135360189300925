import React from "react";
import styled from "styled-components";
import { NavLink as Link, useNavigate } from "react-router-dom";
import { firstLetterUpper } from "../controllers/marketPlaceControlers";
import { cutString } from "../profil/controllers/shortTitleCtrl";
import { useSelector, useDispatch } from "react-redux";
import Profil from "../../../components/marketplace/navbar/picto/Profil";
import {
  useCensored,
  useUserState,
  useCategoryState,
} from "../../../views/marketplace/categoryPage/controllers/stateCtrl";
import { userLogout } from "../../../lib/state/actions";

const Nav = ({
  list,
  toggleConnectForm,
  toggleUniversMenu,
  displayCenseoredPopup,
}) => {
  const category = useCategoryState(useSelector);
  const { userConnected } = useUserState(useSelector);
  const isCensored = useCensored(useSelector);

  const dispatch = useDispatch();
  const navigate = useNavigate();

  return (
    <Ul list={list ? "0" : "200px"}>
      {userConnected && (
        <>
          <Li className="hidden">
            <Link to="/profil">
              <Profil />
              <Span>{cutString(userConnected, 15)}</Span>
            </Link>
          </Li>
        </>
      )}
      <Li>
        <Link to="/" active="">
          Accueil
        </Link>
      </Li>
      <Li onClick={toggleUniversMenu} className="hide">
        Univers
      </Li>

      {category &&
        category.length > 0 &&
        category.map(
          (cat) =>
            cat.active && (
              <Li key={cat._id} className="hideFromHeader">
                <Link to={`/category/${cat.name}`}>
                  {firstLetterUpper(cat.name)}
                </Link>
              </Li>
            )
        )}
      <Li className="small">
        <Link to={"/search"}>Rechercher</Link>
      </Li>
      {!userConnected && (
        <Li>
          <ConnectButton
            onClick={!isCensored ? toggleConnectForm : displayCenseoredPopup}
          >
            {firstLetterUpper("se connecter")}
          </ConnectButton>
        </Li>
      )}
      {userConnected && (
        <Li className="small" onClick={() => userLogout(dispatch, navigate)}>
          Se déconnecter
        </Li>
      )}
    </Ul>
  );
};

const Ul = styled.menu`
  list-style: none;
  display: flex;
  align-items: center;

  @media (max-width: 600px) {
    display: flex;
    flex-direction: column;
    align-items: center;
    position: fixed;
    right: 0;
    transform: translate(${(props) => props.list});
    top: 60px;
    padding: 20px;
    background-color: ${(props) => props.theme.primary.black};
    border-radius: 5px;
    transition: transform 0.3s ease-in-out;
  }
`;

const Li = styled.li`
  display: inline-block;
  font-weight: bold;
  color: ${(props) => props.theme.primary.black};
  font-size: 16px;
  cursor: pointer;
  margin: 0 30px;
  &.relative {
    position: relative;
  }
  & .active {
    color: ${(props) => props.theme.primary.color};
  }
  &:hover {
    color: ${(props) => props.theme.primary.color};
    transition: color 0.2s ease-in-out;
  }
  &.small {
    display: none;
  }
  &.hidden {
    margin-top: 0;
    text-align: center;
    border: solid white 1px;
    padding: 10px;
    border-radius: 8px;
    @media (min-width: 431px) {
      display: none;
    }
  }

  &.hide {
    @media (max-width: 600px) {
      display: none;
    }
  }
  &.hideFromHeader {
    @media (min-width: 601px) {
      display: none;
    }
  }
  @media (max-width: 750px) {
    margin: 0 20px;
  }
  @media (max-width: 1150px) {
    font-size: 14px;
  }

  @media (max-width: 600px) {
    margin: 20px;
    color: white;
    & .active {
      color: white;
    }
    &:hover {
      color: white;
    }
    &.small {
      display: flex;
    }
  }
`;

const ConnectButton = styled.div`
  box-sizing: border-box;
  border: solid ${(props) => props.theme.primary.darkColor} 2px;
  background-color: white;
  border-radius: 5px;
  color: ${(props) => props.theme.primary.color};
  font-size: 14px;
  text-transform: uppercase;
  padding: 5px 15px;
  width: 149px;
  font-weight: 500;
  &:hover {
    background-color: ${(props) => props.theme.primary.darkColor};
    color: white;
    transition: all 0.3s ease-in-out;
  }
  @media (max-width: 1150px) {
    font-size: 12px;
    width: 133px;
  }
  @media (max-width: 600px) {
    border: none;
    text-transform: unset;
    font-size: 14px;
    padding: 0;
    width: unset;
    background-color: unset;
    color: white;
    font-weight: bold;
    &:hover {
      background-color: unset;
      color: white;
      transition: none;
    }
  }
`;

const Span = styled.span`
  display: block;
  padding-top: 5px;
  font-size: 10px;
  font-weight: 400;
`;

export default Nav;
