import { actions } from "../actions/index";

const { FETCH_PRODUCT, FETCH_ALLPRODUCT } = actions;

const initialState = {
  products: [],
};

export function allProducts(state = initialState, action) {
  switch (action.type) {
    case FETCH_PRODUCT:
      return {
        ...state,
        products: action.payload.products,
      };
    case FETCH_ALLPRODUCT:
      return {
        ...state,
        products: action.payload.products,
      };
    default:
      return state;
  }
}
