import React, { useRef } from "react";
import styled, { keyframes, css } from "styled-components";
import { useSelector } from "react-redux";
import { Link } from "react-router-dom";
import { useCategoryState } from "../../../views/marketplace/categoryPage/controllers/stateCtrl";
import { REACT_APP_API_HOST } from "../../../service";

const CatPop = ({ universMenu, closeUniversMenu }) => {
  const category = useCategoryState(useSelector);
  const menuRef = useRef();

  return (
    <>
      {category && (
        <Univers universMenu={universMenu} ref={menuRef}>
          <UlCat>
            {category &&
              category.length > 0 &&
              category.map(
                (cat) =>
                  cat.active && (
                    <LiCat
                      className="segoesc"
                      key={cat._id}
                      onClick={() => closeUniversMenu()}
                    >
                      <Link to={`/category/${cat.name}`}>
                        <Img
                          src={REACT_APP_API_HOST + cat.imageURL}
                          alt={cat.name}
                        />
                      </Link>
                    </LiCat>
                  )
              )}
          </UlCat>
        </Univers>
      )}
    </>
  );
};

const UlCat = styled.menu`
  list-style: none;
  box-sizing: border-box;
  align-self: center;
  display: flex;
  justify-content: center;
  border-radius: 0 0 8px 8px;
`;

const LiCat = styled.li`
  margin: 5px;
  display: inline-block;
  height: 60px;
  border: solid black 2px;
  border-radius: 10px;
  box-shadow: 8px 10px 10px rgba(0, 0, 0, 0.3);
  & a {
    display: inline-block;
    height: 60px;
  }
`;

const bounce = keyframes`
  0% {
    opacity: 0;
    right: -100%;
  }
  60% {
    opacity: 1;
   right: -30%;
  }
  80% { right: 55%; }
  100% { right: 50%; }
  `;

const hideMenu = keyframes`
  0% {
    opacity: 1;
    right: 50%;
  }
  60% {
    opacity: 0;
    right:0%;
  }
  100% { opacity: 0;right: -100%; }
  `;

const Univers = styled.nav`
  opacity: ${(props) => (props.universMenu ? "1" : "0")};
  z-index: 100;
  position: fixed;
  right: 50%;
  top: 60px;
  transform: translate(50%);
  color: white;
  animation: ${(props) =>
    props.universMenu
      ? css`
          ${bounce} 0.3s ease both
        `
      : css`
          ${hideMenu} 0.3s ease both
        `};
  @media (max-width: 600px) {
    display: none;
  }
`;

const Img = styled.img`
  box-sizing: border-box;
  cursor: pointer;
  max-width: 60px;
  max-height: 60px;
  border-radius: 10px;
  transition: transform 0.1s ease-in-out;
  @media (max-width: 600px) {
    margin: 0;
  }
`;

export default CatPop;
