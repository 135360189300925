import { actions } from "../actions/index";
import { localstorageCtrl } from "../localStorage/localStorageCtrl";

const { FETCH_USERCONNECTED, FETCH_USERDISCONNECTED } = actions;

const initialState = {
  userConnected: null,
  userEmail: null,
  date: null,
  userEbooks: null,
  google: null,
};

export function userIsConnected(state = initialState, action) {
  switch (action.type) {
    case FETCH_USERCONNECTED:
      if (action.payload.token) {
        localstorageCtrl("login", action.payload);
      }
      return {
        ...state,
        userId: action.payload.userId,
        userConnected: action.payload.userPseudo,
        userEmail: action.payload.userEmail,
        date: new Date(action.payload.date).toLocaleDateString("fr"),
        userEbooks: action.payload.userEbooks,
        google: action.payload.google,
      };
    case FETCH_USERDISCONNECTED:
      localstorageCtrl("logout");
      return {
        ...state,
        userId: null,
        userConnected: null,
        userEmail: null,
        date: null,
        userEbooks: null,
        google: null,
      };
    default:
      return state;
  }
}
