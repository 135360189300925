import React, { useState, useEffect, useCallback } from "react";
import styled from "styled-components";
import { useParams, useNavigate, Link } from "react-router-dom";
import Sticker from "../../../components/marketplace/Sticker";
import ExcluSlider from "../../../components/marketplace/home/excluSlider/ExcluSlider";
import Slider from "../../../components/marketplace/library/Slider/Slider";
import { firstToUpperCase } from "../../../components/marketplace/profil/controllers/shortTitleCtrl";
import { useSelector } from "react-redux";
import {
  sortByLikes,
  sortByReleaseDate,
  sortByOrder,
  filterDigitalExclu,
} from "./controllers/algoCtrl";
import TagsBar from "../../../components/marketplace/categoryPage/tagBar/TagsBar";
import { useCategoryState, useProductsState } from "./controllers/stateCtrl";
import { Helmet } from "react-helmet-async";
import { REACT_APP_API_HOST } from "../../../service/index.js";

const CategoryPage = () => {
  const [catProducts, setCatProducts] = useState([]);
  const [digitalExclu, setDigitalExclu] = useState([]);

  //all products tags
  const [tags, setTags] = useState([]);

  //selected tags
  const [selectedTags, setSelectedTags] = useState([]);

  //products with selected tags
  const [tagSearch, setTagSearch] = useState([]);

  const { categorie, tag } = useParams();

  const products = useProductsState(useSelector);
  const category = useCategoryState(useSelector);

  const navigate = useNavigate();

  const categoryUlr = () => {
    if (category && category.length > 0 && categorie) {
      const activecat = category.find((cat) => cat.name === categorie);
      if (activecat) {
        return REACT_APP_API_HOST + activecat.imageURL;
      }
    }
  };

  //useparam tag search
  useEffect(() => {
    if (tag && tags.length > 0) {
      setSelectedTags([tag]);
    }
  }, [tag, tags]);

  //verify if category param exist in category state else redirect pagenotfound
  useEffect(() => {
    if (categorie && category) {
      const isExist = category.find((cat) => cat.name === categorie);
      if (!isExist) {
        navigate("/pagenotfound");
      }
    }
  }, [categorie, category, navigate]);

  //reset tag state on category change
  useEffect(() => {
    setSelectedTags([]);
  }, [categorie]);

  //products tags filter and set to state
  const tagsFilter = useCallback(() => {
    let array = [];
    catProducts.forEach((product) => {
      product.tags.map((tag) => {
        return array.push(tag);
      });
    });

    const unique = array.filter(
      (obj, index) => array.findIndex((item) => item === obj) === index
    );
    const primaryTags = ["ecchi", "seinen", "shojo", "shonen", "one-shot"];
    primaryTags.forEach((ptag) => {
      let isExist = unique.find((tag) => tag === ptag);
      if (isExist) {
        const index = unique.indexOf(ptag);
        const toIndex = 0;
        const tagEl = unique.splice(index, 1)[0];
        unique.splice(toIndex, 0, tagEl);
      }
    });
    setTags(unique);
  }, [catProducts]);

  const filterByCat = useCallback(() => {
    if (products && products.length > 0) {
      let byCat = products.filter((p) => p.category === categorie);
      setCatProducts(sortByReleaseDate(byCat));
    }
  }, [products, categorie]);

  useEffect(() => {
    filterByCat();
    setDigitalExclu([]);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [categorie, products]);

  useEffect(() => {
    if (
      catProducts &&
      category &&
      catProducts.length > 0 &&
      category.length > 0
    ) {
      filterDigitalExclu(catProducts, category, setDigitalExclu);
      tagsFilter();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [catProducts, category]);

  //select tag
  const selectTag = (tag) => {
    let findTag = selectedTags.find((t) => t === tag);

    if (findTag) {
      setSelectedTags([...selectedTags.filter((t) => t !== tag)]);
    } else {
      setSelectedTags([...selectedTags, tag]);
    }
  };

  //search tag in catProducts
  const searchTags = () => {
    catProducts.forEach((el) => {
      const alltags = selectedTags.every((t) => el.tags.includes(t));
      if (alltags) {
        setTagSearch((oldValues) => [
          ...oldValues.filter((value) => value._id !== el._id),
          el,
        ]);
      }
      if (!alltags) {
        setTagSearch((oldValues) => [
          ...oldValues.filter((value) => value._id !== el._id),
        ]);
      }
      if (selectedTags.length === 0) {
        setTagSearch([]);
      }
    });
  };

  useEffect(() => {
    searchTags();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedTags]);

  return (
    <>
      <Helmet>
        <title>Manga ebook {categorie} en exclusivité.</title>
        <meta
          id="og-title"
          property="og:title"
          content={`Mangas ebook ${
            categorie !== "Yaoi" ? categorie : `${categorie}/Boy's Love`
          } en exclusivité.`}
        />
        <meta
          name="title"
          content={`Mangas ebook ${
            categorie !== "Yaoi" ? categorie : `${categorie}/Boy's Love`
          } en exclusivité.`}
        />
        <meta
          name="description"
          content={`Mangas  ${
            categorie !== "Yaoi" ? categorie : `${categorie}/Boy's Love`
          } en ligne et téléchargement d'epub.`}
        />
        <meta
          property="og:description"
          content={`Mangas  ${
            categorie !== "Yaoi" ? categorie : `${categorie}/Boy's Love`
          } en ligne et téléchargement d'epub.`}
        />
        <meta
          name="prerender-header"
          content={`Location: http://www.mangaebook.com/category/${categorie}`}
        ></meta>
        <meta id="og-image" property="og:image" content={categoryUlr()} />
        <meta property="og:type" content="website" />
        <meta
          property="og:url"
          content={`http://www.mangaebook.com/category/${categorie}`}
        />
      </Helmet>
      <H1 className="segoesc">
        {" "}
        <Link to="/">MangaeBook /</Link>
        <SpanCat>{firstToUpperCase(categorie)}</SpanCat>
      </H1>
      {tags.length > 0 && (
        <TagsBar
          tags={tags}
          cat={categorie}
          selectTag={selectTag}
          selectedTags={selectedTags}
        />
      )}
      {selectedTags.length > 0 ? (
        <Container>
          {tagSearch.length > 0 ? (
            <Slider
              title={selectedTags.map((tag) => `# ${tag} `)}
              array={tagSearch}
              article={true}
              isFixed={true}
            />
          ) : (
            <Span className="segoesc">aucun résultat...</Span>
          )}
        </Container>
      ) : (
        <>
          {digitalExclu && digitalExclu.length > 0 && (
            <section>
              <ExcluSlider products={digitalExclu} />
            </section>
          )}
          {catProducts &&
            catProducts.length > 0 &&
            (sortByReleaseDate(catProducts).length > 0 ||
              sortByLikes(catProducts).length > 0) && (
              <Sticker title="à decouvrir !" marginTop="30" center={true} />
            )}
          {catProducts &&
            catProducts.length > 0 &&
            sortByReleaseDate(catProducts).length > 0 && (
              <Container>
                <Slider
                  title="nouveautés"
                  array={sortByReleaseDate(catProducts)}
                  article={true}
                />
              </Container>
            )}
          {catProducts &&
            catProducts.length > 0 &&
            sortByLikes(catProducts).length > 0 && (
              <Container>
                <Slider
                  title="au top !"
                  array={sortByLikes(catProducts)}
                  article={true}
                />
              </Container>
            )}
          {catProducts && catProducts.length > 0 && (
            <>
              <Sticker
                title={`toutes les collections :`}
                marginTop="50"
                center={true}
              />
              <Container>
                <Slider
                  array={sortByOrder(catProducts)}
                  title={`#${categorie}`}
                  article={true}
                  isFixed={true}
                />
              </Container>
            </>
          )}
        </>
      )}
    </>
  );
};

const H1 = styled.h1`
  float: left;
  font-size: 14px;
  padding: 11px 10px 0px 30px;
  @media (max-width: 1150px) {
    padding-left: 10px;
  }
`;

const Container = styled.section`
  max-width: 1200px;
  margin: 0 auto;
`;

const Span = styled.span`
  display: inline-block;
  text-transform: uppercase;
  text-align: center;
  margin-top: 50px;
  width: 100%;
`;

const SpanCat = styled.strong`
  font-weight: bold;
  margin-left: 6px;
  text-transform: uppercase;
`;

export default CategoryPage;
