import React from "react";
import styled from "styled-components";
import { useSelector, useDispatch } from "react-redux";
import {
  encryptBook,
  removeLibrary,
  postChapterImages,
} from "../../../lib/state/actions/index";
import { useParams } from "react-router-dom";
import { postKey, deleteKey } from "../../../lib/state/actions/index";
import { useKeysState } from "../../marketplace/categoryPage/controllers/stateCtrl";

const EncryptVol = ({ chapterId, allowChanges }) => {
  const key = useKeysState(useSelector);
  const { volumeId } = useParams();

  const dispatch = useDispatch();

  const keyIsCreate = (chapterId) => {
    if (chapterId && key && key.length > 0) {
      const keyExist = key.find((k) => k.chapterId === chapterId);
      if (keyExist) {
        return true;
      } else {
        return false;
      }
    }
  };

  const isDecrypt = (chapterId) => {
    let result = key.find((k) => k?.chapterId === chapterId);
    if (result) {
      return result?.isDecrypt ? true : false;
    }
  };

  const body = JSON.stringify({ chapterId, volumeId });

  const submitChapterImages = (e) => {
    e.preventDefault();

    let myFormData = new FormData();
    const files = e.target.uploadedImages.files;
    if (files.length !== 0) {
      for (const single_file of files) {
        myFormData.append("uploadedImages", single_file);
      }
      dispatch(postChapterImages(myFormData, chapterId, true));
    }
  };

  return (
    <Wrapper>
      {!keyIsCreate(chapterId) && allowChanges && (
        <button onClick={() => dispatch(postKey(body))} type="button">
          créer une clef
        </button>
      )}

      {keyIsCreate(chapterId) && allowChanges && (
        <>
          <p>Procédure à suivre:</p>

          <p>
            - Apprès avoir complétez toutes les rubiques du{" "}
            <span>nouveau chapitre</span>.
          </p>
          <p>
            - Créez une nouvelle <span>clef</span>.
          </p>
          <p>
            - Sélectionnez et uploadez les images (jpg) de votre chapitre,
            numérotées dans l'ordre en partant de 0.
          </p>
          {
            <Container>
              {isDecrypt(chapterId) && (
                <button
                  type="button"
                  style={{ marginRight: "20px" }}
                  onClick={() => dispatch(removeLibrary(body))}
                >
                  effacer le dossier contenant toutes les images cryptées.
                </button>
              )}
              {keyIsCreate && !isDecrypt(chapterId) && (
                <>
                  <button
                    style={{ marginLeft: "20px" }}
                    type="button"
                    onClick={() => dispatch(deleteKey(chapterId))}
                  >
                    effacer la clef
                  </button>
                  <Upload>
                    <form
                      action="/api/keys"
                      method="POST"
                      encType="multipart/form-data"
                      onSubmit={(e) => submitChapterImages(e)}
                    >
                      <label htmlFor="file">Selectionnez vos images : </label>
                      <input
                        type="file"
                        multiple="multiple"
                        accept="image/*"
                        name="uploadedImages"
                        id="file"
                      />
                      <span>format: jpg.</span>
                      <button type="submit">upload</button>
                    </form>
                  </Upload>
                </>
              )}
              {isDecrypt(chapterId)
                ? "Les fichiers sont cryptés."
                : "Cryptez les fichiers!"}
              {!isDecrypt(chapterId) && (
                <button
                  type="button"
                  style={{
                    marginLeft: "20px",
                    padding: "10px",
                    fontSize: "16px",
                  }}
                  onClick={() => dispatch(encryptBook(body))}
                >
                  crypter
                </button>
              )}
            </Container>
          }
        </>
      )}
    </Wrapper>
  );
};

const Wrapper = styled.div`
  font-size: 16px;
  font-weight: bold;
  user-select: text;
  padding: 0 100px;
  & span {
    color: red;
  }
`;

const Container = styled.div`
  padding: 15px 0;
`;

const Upload = styled.div`
  margin: 20px 0;
`;

export default EncryptVol;
