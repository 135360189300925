import React, { useState, useEffect } from "react";
import styled, { css, keyframes } from "styled-components";
import Cross from "./picto/Cross";
import { useSelector, useDispatch } from "react-redux";
import { removeFromNotifs } from "../../../lib/state/actions";
import { useUserState } from "../../../views/marketplace/categoryPage/controllers/stateCtrl";
import { useNavigate } from "react-router-dom";
import { REACT_APP_API_HOST } from "../../../service";

const NotifCard = ({
  title,
  message,
  image,
  link,
  id,
  notifDate,
  userId,
  pseudo,
}) => {
  const [close, setClose] = useState(false);
  const dispatch = useDispatch();

  const { userConnected } = useUserState(useSelector);
  const navigate = useNavigate();

  const closeCard = () => {
    if (userConnected) {
      setClose(true);
    }
  };

  useEffect(() => {
    if (close) {
      const timer = setTimeout(() => {
        dispatch(removeFromNotifs(id, userId));
      }, 800);
      return () => clearTimeout(timer);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [close, dispatch]);

  //if link, go to new tab or not...
  const goToLink = () => {
    if (link) {
      let hasProtocol = link.substring(0, 4);
      if (hasProtocol === "http") {
        window.open(link);
      } else {
        navigate(link);
      }
    }
  };

  return (
    <SurWrapper close={close}>
      <Close onClick={() => closeCard()}>
        <Cross />
      </Close>
      <Wrapper className="montserrat" image={image} onClick={goToLink}>
        {image !== "none" ? (
          <Div>
            <Img src={REACT_APP_API_HOST + image} />
          </Div>
        ) : null}
        <Title className="segoesc">{title.replace("~pseudo~", pseudo)}</Title>
        <Message image={image}>{message.replace("~pseudo~", pseudo)}</Message>
      </Wrapper>
    </SurWrapper>
  );
};

const disappear = keyframes`
 0% { transform: translate(0px); opacity:1; display:none;}
 100% { transform: translate(500px); opacity:0; }
`;

const SurWrapper = styled.article`
  display: block;
  z-index: 100;
  position: relative;
  animation: ${(props) =>
    props.close
      ? css`
          ${disappear} 1s ease-in-out both
        `
      : null};
`;

const Wrapper = styled.div`
  cursor: zoom-in;
  box-sizing: border-box;
  min-height: ${(props) => (props.image === "none" ? "40px" : "104px")};
  max-width: 500px;
  min-width: 296px;
  background-color: ${(props) => props.theme.primary.black};
  border: solid white 2px;
  border-radius: 15px;
  padding: 10px 10px 10px 10px;
  color: white;
  margin-bottom: 5px;
  margin-right: 5px;
  font-size: 14px;
  letter-spacing: 0.2px;
`;

const Close = styled.div`
  position: absolute;
  top: 10px;
  right: 15px;
  cursor: pointer;
`;

const Div = styled.div`
  height: 80px;
  margin-right: 10px;
  float: left;
`;

const Img = styled.img`
  height: 80px;
  vertical-align: middle;
`;

const Message = styled.p`
  word-break: break-word;
  line-height: 18px;
  margin-right: ${(props) => (props.image === "none" ? "40px" : "0")};
`;

const Title = styled.h2`
  word-break: break-word;
  font-size: 14px;
  font-weight: bold;
  text-transform: uppercase;
  margin-bottom: 10px;
  padding-right: 30px;
`;

export default NotifCard;
