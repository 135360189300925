import React from "react";
import styled from "styled-components";
import CloseButton from "../../connectForm/CloseButton";
import { loadCensorship } from "../../../../lib/state/actions";
import { useDispatch } from "react-redux";

const Popup = ({
  visible,
  message,
  togglePopup,
  censored,
  close = togglePopup,
}) => {
  const dispatch = useDispatch();

  const set18ToState = () => {
    dispatch(loadCensorship(false));
  };

  return (
    <Wrapper visible={visible} censored={censored}>
      <CloseButton close={close} />
      <p className="montserratSemiBold">{message}</p>
      <Container>
        {censored && (
          <Button
            type="button"
            censored={censored}
            className={"segoesc"}
            onClick={() => set18ToState()}
          >
            oui
          </Button>
        )}
        <Button
          type="button"
          censored={censored}
          className={"segoesc"}
          onClick={() => togglePopup()}
        >
          {censored ? "non" : "OK"}
        </Button>
      </Container>
    </Wrapper>
  );
};

const Wrapper = styled.div`
  max-width: 310px;
  z-index: 9999;
  position: fixed;
  padding: 20px;
  min-width: 180px;
  top: ${(props) => (props.visible ? "50%" : "-50%")};
  left: 50%;
  transform: translate(-50%, -50%);
  background-color: ${(props) => props.theme.primary.black};
  color: white;
  transition: top 0.3s ease-in-out;
  border-radius: 3px;
  border: solid white 1px;
  & p {
    font-size: ${(props) => (props.censored === "true" ? "25px" : "16px")};
    letter-spacing: 0.2px;
    text-align: center;
    line-height: 25px;
  }
`;

const Container = styled.div`
  width: 100%;
  text-align: center;
`;

const Button = styled.button`
  margin: 15px 15px 0px 15px;
  border: solid white 1px;
  background-color: unset;
  min-width: ${(props) => (props.censored === "true" ? "100px" : "")};
  padding: 5px 15px;
  text-transform: uppercase;
  font-size: ${(props) => (props.censored === "true" ? "18px" : "12px")};
  color: white;
  cursor: pointer;
  letter-spacing: 0.5px;
`;

export default Popup;
