import { actions } from "../actions/index";

const { FETCH_NETWORK } = actions;

const initialState = {
  networks: [],
};

export function network(state = initialState, action) {
  switch (action.type) {
    case FETCH_NETWORK:
      return action.payload;
    default:
      return state;
  }
}
