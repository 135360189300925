import React, { useState } from "react";
import styled from "styled-components";
import Text from "../../../components/admin/input/Text";
import ValidateButton from "../../../components/admin/button/ValidateButton";
import { useParams, useNavigate } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import { fetchMessage, postTva, updateTva } from "../../../lib/state/actions";
import Option from "../../../components/admin/input/optionInput/Option";
import { useTvaState } from "../../marketplace/categoryPage/controllers/stateCtrl";

const TvaUpdate = ({ title }) => {
  const myTva = ["TVA 1", "TVA 2", "TVA 3", "TVA 4", "TVA 5"];

  const { tvaId } = useParams();
  const tva = useTvaState(useSelector);

  const tvaObject = {
    name: "",
    rate: 0,
  };

  const [inputValues, setInputValues] = useState(
    tvaId ? tva.find((obj) => obj._id === tvaId) : tvaObject
  );

  const navigate = useNavigate();

  function handleClose() {
    navigate("/admin/tva");
  }
  const dispatch = useDispatch();

  const validate = (e) => {
    e.preventDefault();
    const body = JSON.stringify({
      ...inputValues,
    });
    if (tva && tva.length > 0) {
      let similarName = tva.find((tva) => tva.name === inputValues.name);
      let similarRate = tva.find(
        (tva) => Number(tva.rate) === Number(inputValues.rate)
      );

      let similarTvaExcept = tva.filter((tva) => tva._id !== tvaId);
      let similarRateExcept = similarTvaExcept?.find(
        (tva) => Number(tva.rate) === Number(inputValues.rate)
      );

      if (
        title === "créer une nouvelle TVA" &&
        similarName === undefined &&
        similarRate === undefined &&
        inputValues.name !== ""
      ) {
        dispatch(postTva(body, navigate));
      }
      if (
        title === "modifier une TVA existante" &&
        similarRateExcept === undefined
      ) {
        dispatch(updateTva(body, tvaId, navigate));
      } else {
        dispatch(
          fetchMessage({
            message:
              "Le nom ou le taux existe déjà... vérifiez, corrigez ou annulez.",
          })
        );
      }
    } else {
      dispatch(postTva(body, navigate));
    }
  };

  return (
    <Wrapper>
      <H2>{title} :</H2>
      <Span onClick={handleClose}>page précédente</Span>
      {inputValues && (
        <FormEl
          action="/api/tva"
          enctype="multipart/form-data"
          method={title === "créer une nouvelle TVA" ? "post" : "put"}
          onSubmit={(e) => validate(e)}
        >
          {title === "créer une nouvelle TVA" && (
            <Option
              name="name"
              title="Nom (TVA 1, TVA 2 ...)"
              array={
                title === "créer une nouvelle TVA" ? myTva : [inputValues?.name]
              }
              currentValue={inputValues?.name}
              onchange={
                title === "créer une nouvelle TVA" &&
                ((e) =>
                  setInputValues({ ...inputValues, name: e.target.value }))
              }
            />
          )}
          {title === "modifier une TVA existante" && (
            <p>Modification de {inputValues?.name}</p>
          )}
          <Text
            title="Taux en %"
            name="rate"
            type="number"
            value={inputValues?.rate ?? ""}
            onchange={(e) =>
              setInputValues({ ...inputValues, rate: e.target.value })
            }
          />

          <ValidateButton message={title} pad="1.5em" />
        </FormEl>
      )}
    </Wrapper>
  );
};

const Wrapper = styled.div`  
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  box-sizing: border-box;
  background-color: lightblue;
  min-height: calc(100dvh - 100px);
  z-index: 100;
  padding: 2em;
  }
`;

const FormEl = styled.form`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
`;

const H2 = styled.h2`
  margin: 20px;
  text-transform: uppercase;
  font-size: 16px;
  font-weight: bold;
`;

const Span = styled.span`
  position: absolute;
  padding: 7px;
  right: 0px;
  top: 100px;
  text-transform: uppercase;
  font-weight: bold;
  font-size: 12px;
  cursor: pointer;
`;

export default TvaUpdate;
