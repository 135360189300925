import React, { useEffect, useState } from "react";

import styled from "styled-components";

const Popup = ({ message, close, execute }) => {
  const [disabledButton, setDisabledButton] = useState(true);

  useEffect(() => {
    let timer;
    if (message === "Demande de confirmation : effacer ?") {
      setDisabledButton(false);
      timer = setTimeout(() => {
        setDisabledButton(true);
      }, 1000);

      return () => clearTimeout(timer);
    }
  }, [message]);

  return (
    <Wrapper>
      <Message>
        <Text>
          {message !== "Demande de confirmation : effacer ?"
            ? message
            : `ATTENTION ! TOUTE SUPPRESSION EST DEFINITIVE ! Supprimer un article, titre, catégorie... de la base de donnée est définitif. Les utilisateurs n'auront plus accès aux articles, 
            ni à l'achat, ni à la consultation. Si vous désirez retirer l'article de la vente sans en empécher 
            la lecture, préférez 'Modifier' l'article puis changer 'produit actif' vers 'inactif'. Toute suppression 
            définitive peut porter atteinte à la présente base de données et au bon fonctionnement du site.`}
        </Text>
        <Container>
          <Button type="button" visible={disabledButton} onClick={close}>
            Annuler
          </Button>
          <Button
            visible={disabledButton}
            onClick={() => {
              execute();
              close();
            }}
          >
            Valider
          </Button>
        </Container>
      </Message>
    </Wrapper>
  );
};

const Wrapper = styled.div`
  position: fixed;
  top: 0px;
  left: 0px;
  width: calc(100%);
  height: calc(100% + 100px);
  min-height: 100vh;
  transform: translateY(-100px);
  background-color: rgba(0, 0, 0, 0.4);
  z-index: 1000;
`;

const Message = styled.div`
  box-sizing: content-box;
  position: sticky;
  top: 50%;
  background-color: darkred;
  padding: 1.5em;
  display: flex;
  flex-direction: column;
  align-items: center;
  alig-items: center;
  padding: 3em;
`;

const Text = styled.div`
  font-size: 14px;
  font-weight: bold;
  color: white;
  margin-bottom: 1em;
  line-height: 20px;
`;

const Container = styled.div`
  width: 200px;
  display: flex;
  justify-content: space-between;
`;

const Button = styled.button`
  display: ${(props) => (props.visible ? "block" : "none")};
  cursor: pointer;
`;

export default Popup;
