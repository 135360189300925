import React from "react";
import styled from "styled-components";

const Notif = () => {
  return (
    <Svg
      xmlns="http://www.w3.org/2000/svg"
      width="36.52"
      height="39.391"
      viewBox="0 0 36.52 39.391"
    >
      <g
        id="_x31_23"
        transform="matrix(0.914, 0.407, -0.407, 0.914, 13.016, -0.384)"
      >
        <path
          id="Tracé_138"
          data-name="Tracé 138"
          d="M.389.789,9.907.911A.394.394,0,0,0,9.912.122L.394,0A.394.394,0,0,0,.389.789Z"
          transform="translate(6.517 22.275)"
          fill="#120909"
        />
        <path
          id="Tracé_139"
          data-name="Tracé 139"
          d="M2.155,3.806a.394.394,0,1,0,.775-.143A5.659,5.659,0,0,0,.62.071.394.394,0,0,0,.169.718,4.867,4.867,0,0,1,2.155,3.806Z"
          transform="translate(14.187 6.053)"
          fill="#120909"
        />
        <path
          id="Tracé_140"
          data-name="Tracé 140"
          d="M0,.394A.394.394,0,0,0,.394.789H.548A.394.394,0,0,0,.548,0H.394A.394.394,0,0,0,0,.394Z"
          transform="translate(16.69 10.808)"
          fill="#120909"
        />
        <path
          id="Tracé_141"
          data-name="Tracé 141"
          d="M.039.225A.394.394,0,0,0,.225.75c3.382,1.6,3.264,1.6,3.431,1.6a.4.4,0,0,0,.17-.75L.564.039A.394.394,0,0,0,.039.225Z"
          transform="translate(0.929 5.482)"
          fill="#120909"
        />
        <path
          id="Tracé_142"
          data-name="Tracé 142"
          d="M.717.168A.394.394,0,0,0,.072.621L2.016,3.384a.394.394,0,1,0,.645-.454Z"
          transform="translate(3.191 2.356)"
          fill="#120909"
        />
        <path
          id="Tracé_143"
          data-name="Tracé 143"
          d="M.758,3.957a.394.394,0,0,0,.352-.432L.787.355A.387.387,0,0,0,.354,0,.394.394,0,0,0,0,.435L.326,3.6A.394.394,0,0,0,.758,3.957Z"
          transform="translate(5.508 0)"
          fill="#120909"
        />
        <path
          id="Tracé_144"
          data-name="Tracé 144"
          d="M1.4,27.259c1.037.614,2.112.565,6.7.576A4.386,4.386,0,0,0,13.26,31.5a4.124,4.124,0,0,0,4-4.012c.484-.047,2.558-.016,5.786-.026a2.872,2.872,0,1,0,0-5.743h-.657a.6.6,0,0,1-.592-.523c-.4-3.579-.809-7.279-1.424-10.919a13.052,13.052,0,0,0-.93-3.439,6.8,6.8,0,0,0-3.325-3.14c.193-2.987-2.492-4.722-4.637-3.03A3.968,3.968,0,0,0,9.644,3.836l-.019.009A4.01,4.01,0,0,0,7.008,5.484c-1.55,1.854-1.826,3.369-2.291,5.74-1.316,6.714-.289,8.951-.962,10-.466.723-1.876.771-2.573,1.275A2.851,2.851,0,0,0,1.4,27.259Zm13.508,3.005a3.409,3.409,0,0,1-5.175-2.435c2.267-.016,4.531-.086,6.783-.276a3.515,3.515,0,0,1-1.609,2.711ZM15.068,1.97a3.2,3.2,0,0,1,.325,1.437,7.133,7.133,0,0,0-4.474-.1C11.076.759,13.984-.252,15.068,1.97ZM3.229,22.815l.188,0A1.515,1.515,0,0,0,4.96,21.464c.291-2.573.592-5.083.73-7.447a17.472,17.472,0,0,1,1.158-6A6.576,6.576,0,0,1,15.54,4.262,6.376,6.376,0,0,1,18.769,7.15a12.263,12.263,0,0,1,.87,3.246c.611,3.619,1.021,7.309,1.418,10.88a1.338,1.338,0,0,0,1.328,1.18h.657a2.131,2.131,0,1,1,0,4.262H3.191a1.951,1.951,0,1,1,.039-3.9Z"
          transform="translate(0 0.42)"
          fill="#120909"
        />
      </g>
    </Svg>
  );
};

const Svg = styled.svg`
  cursor: pointer;
  @media (max-width: 1150px) {
    height: 34px;
  }
  @media (min-width: 1200px) {
  &:hover {
    transform: scale(1.1);
    transition: all 0.2s ease-in-out;
    & path {
      transition: all 0.2s ease-in-out;
    }
  }
`;

export default Notif;
