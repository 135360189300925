import React from "react";
import styled from "styled-components";

const Glass = () => {
  return (
    <Svg
      xmlns="http://www.w3.org/2000/svg"
      width="15.5"
      height="15.081"
      viewBox="0 0 15.5 15.081"
    >
      <path
        id="Icon_awesome-search"
        data-name="Icon awesome-search"
        d="M15.289,13.039,12.271,10.1a.737.737,0,0,0-.515-.206h-.493a5.992,5.992,0,0,0,1.332-3.77A6.213,6.213,0,0,0,6.3,0,6.213,6.213,0,0,0,0,6.126a6.213,6.213,0,0,0,6.3,6.126,6.379,6.379,0,0,0,3.875-1.3v.48a.7.7,0,0,0,.212.5L13.4,14.874a.738.738,0,0,0,1.026,0l.857-.834A.7.7,0,0,0,15.289,13.039ZM6.3,9.9a3.822,3.822,0,0,1-3.875-3.77A3.821,3.821,0,0,1,6.3,2.356a3.822,3.822,0,0,1,3.875,3.77A3.821,3.821,0,0,1,6.3,9.9Z"
        fill="#a30000"
      />
    </Svg>
  );
};

const Svg = styled.svg`
  position: absolute;
  z-index: 2;
  top: 50%;
  left: 15px;
  transform: translateY(-45%);
  & path {
    fill: black;
  }
`;

export default Glass;
