import React from "react";

const Arrow = () => {
  return (
    <svg
      id="Groupe_827"
      data-name="Groupe 827"
      xmlns="http://www.w3.org/2000/svg"
      width="20.236"
      height="15.177"
      viewBox="0 0 20.236 15.177"
    >
      <g id="Groupe_826" data-name="Groupe 826" transform="translate(0)">
        <path
          id="Tracé_541"
          data-name="Tracé 541"
          d="M268.259,336.616h-7.588v-2.529a1.265,1.265,0,0,0-2-1.029l-8.853,6.324a1.265,1.265,0,0,0,0,2.059l8.853,6.324a1.265,1.265,0,0,0,2-1.03V344.2h7.588a1.264,1.264,0,0,0,1.265-1.265v-5.059A1.264,1.264,0,0,0,268.259,336.616Z"
          transform="translate(-249.288 -332.822)"
          fill="#fff"
        />
      </g>
    </svg>
  );
};

export default Arrow;
