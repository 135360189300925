import React from "react";
import styled from "styled-components";
import Logo from "../../../components/marketplace/navbar/logo/Logo";
import Nav from "../../../components/marketplace/navbar/Nav";
import Menu from "../../../components/marketplace/navbar/picto/Menu";
import MiniLogo from "../../../components/marketplace/navbar/logo/MiniLogo";
import IconNav from "../../../components/marketplace/navbar/IconNav";
import BigGlass from "../../../components/marketplace/navbar/picto/BigGlass";
import { NavLink as Link } from "react-router-dom";

const NavBar = ({
  list,
  userConnected,
  toggleList,
  toggleConnectForm,
  toggleUniversMenu,
  toggleNotifs,
  displayCenseoredPopup,
}) => {
  return (
    <Wrapper>
      <Link to="/">
        <Logo />
        <MiniLogo />
      </Link>
      <Bar>
        <Nav
          list={list}
          toggleConnectForm={toggleConnectForm}
          toggleUniversMenu={toggleUniversMenu}
          toggleNotifs={toggleNotifs}
          displayCenseoredPopup={displayCenseoredPopup}
        />
        {userConnected && <IconNav toggleNotifs={toggleNotifs} />}
      </Bar>
      <SearchIcon>
        <Link to="/search" active="">
          <div>
            <BigGlass />
          </div>
        </Link>
      </SearchIcon>

      <Container onClick={() => toggleList()}>
        <Menu />
      </Container>
    </Wrapper>
  );
};

const Wrapper = styled.header`
  position: fixed;
  width: 100%;
  height: 60px;
  box-shadow: 0px 3px 5px rgba(0, 0, 0, 0.15);
  padding: 0px 30px;
  box-sizing: border-box;
  display: flex;
  z-index: 999;
  justify-content: space-between;
  align-items: center;
  background-color: white;
  @media (max-width: 1150px) {
    padding: 0px 10px;
  }
  @media (max-width: 600px) {
    justify-content: space-between;
  }
`;

const Container = styled.div`
  box-sizing: border-box;
  cursor: pointer;
  -webkit-tap-highlight-color: transparent;
  @media (min-width: 601px) {
    display: none;
  }
`;

const Bar = styled.div`
  display: flex;
`;

const SearchIcon = styled.div`
  cursor: pointer;
  margin-left: 175px;
  @media (max-width: 1150px) {
    margin-left: 125px;
  }
  @media (max-width: 950px) {
    margin-left: 10px;
  }
  @media (max-width: 600px) {
    display: none;
  }
  & .active {
    & path {
      fill: ${(props) => props.theme.primary.color};
    }
  }
`;

export default NavBar;
