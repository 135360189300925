import React from "react";
import styled, { keyframes } from "styled-components";

const HeartSpinner = () => {
  return (
    <Wrapper>
      <Container></Container>
    </Wrapper>
  );
};

const Wrapper = styled.div`
  display: block;
  position: fixed;
  top: 50%;
  left: 50%;
  width: 80px;
  height: 80px;
  transform: translate(-50%, -50%) rotate(45deg);
  transform-origin: 40px 40px;
  z-index: 5000;
`;

const heart = keyframes`
    0% {
      transform: scale(0.95);
    }
    5% {
      transform: scale(1.1);
    }
    39% {
      transform: scale(0.85);
    }
    45% {
      transform: scale(1);
    }
    60% {
      transform: scale(0.95);
    }
    100% {
      transform: scale(0.9);
    }
  `;

const Container = styled.div`
  top: 32px;
  left: 32px;
  position: absolute;
  z-index: 9;
  width: 32px;
  height: 32px;
  background: #a30000;
  animation: ${heart} 1.2s infinite cubic-bezier(0.215, 0.61, 0.355, 1);
  &:before {
    content: " ";
    position: absolute;
    display: block;
    width: 32px;
    height: 32px;
    background: #a30000;
    left: -24px;
    border-radius: 50% 0 0 50%;
  }
  &:after {
    content: " ";
    position: absolute;
    display: block;
    width: 32px;
    height: 32px;
    background: #a30000;
    top: -24px;
    border-radius: 50% 50% 0 0;
  }
`;

export default HeartSpinner;
