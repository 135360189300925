import React from "react";
import styled from "styled-components";

const Option = ({
  name,
  title,
  currentValue,
  onchange,
  array,
  property,
  deleteFromList,
}) => {
  const List = () => (
    <TagsList>
      {currentValue?.map((opt, i) => (
        <Tag key={i} onClick={() => deleteFromList(opt, name)}>
          {opt}
        </Tag>
      ))}
    </TagsList>
  );

  const bookDirectionInfo = () => {
    if (name === "readingDirection") {
      switch (currentValue) {
        case "europe":
          return "Lecture de la gauche vers la droite.";
        case "original":
          return "Lecture de la droite vers la gauche.";
        case "webtoon":
          return "Lecture au scroll vertival.";
        default:
          return "Choisir un sens de lecture.";
      }
    }
  };

  const Info = () => {
    return <p> {bookDirectionInfo()}</p>;
  };

  return (
    <InputBlock>
      <Container>{deleteFromList && <List />}</Container>
      <Container>
        <Label htmlFor={title}>{title} :</Label>
        <Select
          name={name}
          id={title}
          value={
            name === "tags" ||
            name === "author" ||
            name === "chapters" ||
            name === "chaptersPage"
              ? ""
              : currentValue
          }
          onChange={(e) => onchange(e)}
          disabled={currentValue !== "" && title === "Catégorie" ? true : false}
        >
          <option value="">{title}</option>
          {array &&
            array.length > 0 &&
            array?.map((opt, i) => {
              return (
                <option key={i} value={property ? opt[property] : opt}>
                  {property ? opt[property] : opt}
                  {title === "TVA" && `  ------------  ${opt.rate} %`}
                </option>
              );
            })}
        </Select>
      </Container>
      <Info />
    </InputBlock>
  );
};

const Label = styled.label`
  font-weight: bold;
  font-size: 12px;
`;

const TagsList = styled.div`
  display: flex;
  min-height: calc(12px + 1.2em + 2em);
`;

const Tag = styled.div`
  padding: 0.6em 1em;
  background-color: white;
  margin: 1em;
  cursor: pointer;
`;

const Container = styled.div`
  display: flex;
  flex-direction: column;
  width: 700px;
`;

const InputBlock = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;

  padding: 1em 0em;
  border-bottom: solid 1px lightGrey;
`;

const Select = styled.select`
  color: blue;
  margin-top: 3px;
  margin-bottom: 1em;
  &::placeholder {
    color: blue;
  }
  &:focus {
    outline: solid black 2px;
    border-radius: 2px;
  }
`;

export default Option;
