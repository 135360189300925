import React from "react";
import styled from "styled-components";

const Zoom = () => {
  return (
    <Svg
      id="loupe"
      xmlns="http://www.w3.org/2000/svg"
      width="19.926"
      height="20"
      viewBox="0 0 19.926 20"
    >
      <g id="Groupe_773" data-name="Groupe 773" transform="translate(0)">
        <path
          id="Tracé_263"
          data-name="Tracé 263"
          d="M20.213,15.761l-3.292-3.292a9.081,9.081,0,0,0,1-3.99,8.479,8.479,0,1,0-8.479,8.479,9.082,9.082,0,0,0,3.99-1l3.292,3.292a2.411,2.411,0,0,0,3.491,0A2.6,2.6,0,0,0,20.213,15.761Zm-10.773-.8a6.484,6.484,0,1,1,6.484-6.484A6.471,6.471,0,0,1,9.439,14.963Z"
          transform="translate(-0.96)"
          fill="#fff"
        />
      </g>
    </Svg>
  );
};

const Svg = styled.svg`
  transform: scale(1);
  transition: transform 0.2s ease-in-out;
  filter: drop-shadow(2px 2px 2px rgb(0 0 0 / 0.6));
  &:hover {
    transform: scale(1.2);
  }
`;

export default Zoom;
