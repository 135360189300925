import React, { useState, useEffect } from "react";
import styled from "styled-components";
import { useSelector, useDispatch } from "react-redux";
import {
  fetchPending,
  fetchSuccess,
  getFormToken,
  submitPayment,
} from "../../../lib/state/actions";
import { useUserState } from "../../../views/marketplace/categoryPage/controllers/stateCtrl";
import KRGlue from "@lyracom/embedded-form-glue";
import { Link, useNavigate } from "react-router-dom";

const PaidButton = ({ cart, cgu, price }) => {
  const [formToken, setFormToken] = useState({});
  const { userId, userEmail } = useUserState(useSelector);

  const dispatch = useDispatch();
  const navigate = useNavigate();

  useEffect(() => {
    if (formToken && formToken.formToken && cart && cart.length > 0 && userId) {
      async function setupPaymentForm() {
        dispatch(fetchPending());
        const endpoint = "https://static.systempay.fr";
        const publicKey =
          "15749788:publickey_CxZb93oEVuuzQ8kqlqcot4ca0RSKHSqCL811BrkjmXPli";
        let token = formToken.formToken;

        const { KR } = await KRGlue.loadLibrary(
          endpoint,
          publicKey
        ); /* Load the remote library */

        await KR.setFormConfig({
          /* set the minimal configuration */ formToken: token,
          "kr-language": "fr-FR" /* to update initialization parameter */,
        });

        await KR.renderElements(
          "#paymentForm"
        ); /* Render the payment form into myPaymentForm div*/

        await KR.onFormReady(() => dispatch(fetchSuccess()));

        await KR.onSubmit((paymentData) => {
          const cartList =
            cart &&
            cart.length > 0 &&
            cart.map((art) => {
              return {
                _id: art._id,
                volumeId: art.volumeId,
              };
            });

          const body = JSON.stringify({ paymentData, cartList, userId });
          dispatch(submitPayment(body, KR, navigate));
        });
      }

      setupPaymentForm();
    }
  }, [formToken, dispatch, cart, navigate, userId]);

  const active = () => {
    if (userId && cart && cart.length > 0 && cgu) {
      return true;
    } else {
      return false;
    }
  };

  function onCheckout() {
    if (userId && cart && cart.length > 0 && cgu) {
      // Create the order, based on your cart
      const order = {
        amount: price,
        currency: "EUR",
        orderId: userId + Date.now(),
        customer: {
          email: userEmail,
        },
      };
      const body = JSON.stringify(order);
      // Call merchant server to get form token and then display payment form
      dispatch(getFormToken(body, setFormToken));
    }
  }

  return (
    <Wrapper>
      {
        /*cart && cart.length > 0 &&*/ /*formToken.formToken && */ <FormPayment
          id="paymentForm"
          className="kr-embedded"
          style={{ display: "none" }}
        >
          <h2>Paiement sécurisé</h2>
          <div>
            {/*payment form fields*/}
            <div className="kr-pan"></div>
            <div className="kr-expiry"></div>
            <div className="kr-security-code"></div>

            {/*payment form submit button*/}
            <button className="kr-payment-button"></button>

            {/*error zone*/}
            <div className="kr-form-error"></div>
          </div>
          <Link to="/cart">Annuler et revenir au panier</Link>
        </FormPayment>
      }
      <Button
        active={active()}
        className="montserratSemiBold"
        /*onClick={() => confirm()}*/
        onClick={() => onCheckout()}
      >
        passer au paiement
      </Button>
    </Wrapper>
  );
};

const Wrapper = styled.div`
  text-align: center;
`;

const Button = styled.button`
  margin-top: 20px;
  align-self: center;
  cursor: ${(props) => props.active && `pointer`};
  opacity: ${(props) => (props.active ? "1" : "0.5")};
  background-color: ${(props) => props.theme.secondary.color};
  color: white;
  border: solid 3px ${(props) => props.theme.secondary.color};
  padding: 5px 20px;
  text-transform: uppercase;
  font-size: 13px;
  letter-spacing: 0.1px;
  transition: all 0.3s ease-in-out;
  &:hover {
    border: ${(props) =>
      props.active && `solid 3px ${props.theme.secondary.color}`};
    color: ${(props) => props.active && props.theme.primary.black};
    background-color: ${(props) => props.active && "white"};
  }
  @media (max-width: 840px) {
    display: block;
    &:last-child {
      margin-bottom: 0px;
    }
  }
`;

const FormPayment = styled.section`
  position: fixed;
  z-index: 999;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(15, 22, 46, 1);
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  & h2 {
    color: white;
    font-size: 22px;
    margin-bottom: 20px;
  }
  & a {
    color: white;
    margin-top: 10px;
    cursor: pointer;
  }

  @media (max-width: 400px) {
    box-sizing: border-box;
    padding: 20px;
  }
`;

export default PaidButton;
