import { getByPurchaseDate } from "../../../lib/state/actions";

export const sortByQuantity = (purchases, setState) => {
  let volumeArray = [];
  let chapterArray = [];
  for (let i = 0; i < purchases.length; i++) {
    if (purchases[i].volumeId && !purchases[i].chapterId) {
      //si achat volume
      const isExist = volumeArray.find(
        (obj) => obj.volumeId === purchases[i].volumeId
      );
      if (isExist) {
        volumeArray = [
          ...volumeArray.filter(
            (obj) => obj.volumeId !== purchases[i].volumeId
          ),
          {
            ...isExist,
            quantity: isExist.quantity + 1,
            price: isExist.price + purchases[i].price,
          },
        ];
      } else {
        volumeArray = [...volumeArray, { ...purchases[i], quantity: 1 }];
      }
    } else {
      const isExist = chapterArray.find(
        (obj) => obj.chapterId === purchases[i].chapterId
      );
      if (isExist) {
        chapterArray = [
          ...chapterArray.filter(
            (obj) => obj.chapterId !== purchases[i].chapterId
          ),
          {
            ...isExist,
            quantity: isExist.quantity + 1,
            price: isExist.price + purchases[i].price,
          },
        ];
      } else {
        chapterArray = [...chapterArray, { ...purchases[i], quantity: 1 }];
      }
    }
  }
  setState([...volumeArray, ...chapterArray]);
};

const dateToTimestamp = (inputDate) => {
  const [y, m, d] = inputDate.split(/-|\//);
  const date = new Date(y, m - 1, d);
  return date.getTime();
};

const endDateToTimestamp = (inputDate) => {
  const [y, m, d] = inputDate.split(/-|\//);
  const date = new Date(y, m - 1, d);
  const endDate = date.setDate(date.getDate() + 1);
  return endDate;
};

export const validateSearch = (startDate, endDate, dispatch, setState) => {
  const startTimeStamp = dateToTimestamp(startDate);
  const endTimeStamp = endDateToTimestamp(endDate);
  dispatch(getByPurchaseDate(startTimeStamp, endTimeStamp, setState));
};
