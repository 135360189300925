import { actions } from "../actions/index";

const { FETCH_CATEGORY } = actions;

const initialState = {
  category: [],
};

export function allCategories(state = initialState, action) {
  switch (action.type) {
    case FETCH_CATEGORY:
      return {
        ...state,
        category: action.payload.category,
      };
    default:
      return state;
  }
}
