//export const REACT_APP_API_HOST = "http://localhost:3000";
export const REACT_APP_API_HOST = "https://manga-ebook.com";
export const Constant = {
  BASE_URL: `${REACT_APP_API_HOST}/api/`,
  PRODUCTS: `products/`,
  VOLUME: `volumes/`,
  CHAPTER: `chapters/`,
  CART: `cart/`,
  ALLPRODUCTS: `products/allproducts/`,
  NOTIFICATIONS: `notifications/`,
  CATEGORY: `category/`,
  TVA: `tva/`,
  ADMIN: `authadmin/`,
  SIGNUP: `authadmin/signup/`,
  LOGIN: `authadmin/login/`,
  LOGOUT: `authadmin/logout/`,
  VERIFY: `authadmin/verify/`,
  USER: `authuser/`,
  USERSIGNUP: `authuser/signup/`,
  USERLOGIN: `authuser/login/`,
  USERVERIFY: `authuser/verify/`,
  ACTIVATEACCOUNT: `authuser/activateaccount/`,
  FORGOTPASSWORD: `authuser/forgotpassword/`,
  RESETPASSWORD: `authuser/resetpassword/`,
  KEY: `security/`,
  BOOKS: `books/`,
  REMOVELIBRARY: `books/removeLibrary/`,
  LIKE_PRODUCT: `products/like/`,
  DOWNLOAD: "download/",
  NETWORK: "network/",
  CAROUSEL: "carousel/",
};

let findToken = (who) => {
  let userToken = localStorage.getItem("token");
  let adminToken = localStorage.getItem("admtoken");

  let token = JSON.parse(userToken);
  let admToken = JSON.parse(adminToken);
  if (userToken === null || !userToken) {
    token = "noToken";
  }
  if (adminToken === null || !admToken) {
    admToken = "noToken";
  }
  return who === "user" ? token : admToken;
};

export class Request {
  constructor(api) {
    this.url = `${Constant.BASE_URL}${api}`;
    this.authorize = `Bearer ${findToken("user")} , Basic ${findToken(
      "admin"
    )}`;
  }

  get = () => {
    const headers = {
      "Content-type": "application/json ; charset= UTF-8",
      Authorization: this.authorize,
    };
    return new Promise((onSuccess, onFailure) => {
      fetch(this.url, { method: "GET", headers })
        .then((response) => {
          if (response.ok && response.status === 200) {
            return response.json();
          } else {
            return response.json().then((res) => {
              let errMess = res.message;
              throw errMess;
            });
          }
        })
        .then((prod) => {
          onSuccess(prod);
          window.prerenderReady = true;
        })

        .catch((err) => onFailure(err));
    });
  };

  post = (body, multipart) => {
    const headers = multipart
      ? {
          Authorization: this.authorize,
        }
      : {
          "Content-type": "application/json ; charset= UTF-8",
          Authorization: this.authorize,
        };

    return new Promise((onSuccess, onFailure) => {
      fetch(this.url, { method: "POST", headers, body })
        .then((response) => {
          if (response.ok) {
            return response.json();
          } else {
            return response.json();
          }
        })
        .then((successMessage) => {
          onSuccess(successMessage);
          window.prerenderReady = true;
        })
        .catch((err) => onFailure(err));
    });
  };

  put = (body, multipart) => {
    const headers = multipart
      ? {
          Authorization: this.authorize,
        }
      : {
          "Content-type": "application/json ; charset= UTF-8",
          Authorization: this.authorize,
        };
    return new Promise((onSuccess, onFailure) => {
      fetch(this.url, { method: "PUT", headers, body })
        .then((response) => {
          if (response.ok) {
            return response.json();
          } else {
            return response.json();
          }
        })
        .then((successMessage) => {
          onSuccess(successMessage);
          window.prerenderReady = true;
        })
        .catch((err) => onFailure(err));
    });
  };

  delete = () => {
    const headers = {
      "Content-type": "application/json ; charset= UTF-8",
      Authorization: this.authorize,
    };
    return new Promise((onSuccess, onFailure) => {
      fetch(this.url, { method: "DELETE", headers })
        .then((response) => {
          if (response.ok) {
            return response.json();
          } else {
            return response.status;
          }
        })
        .then((successMessage) => {
          onSuccess(successMessage);
          window.prerenderReady = true;
        })
        .catch((err) => onFailure(err));
    });
  };
}
