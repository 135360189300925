import React from "react";
import styled, { keyframes } from "styled-components";

const LittleSpinner = () => {
  return <Spinner></Spinner>;
};

const spin = keyframes`
   to { transform: rotate(360deg); }
}
@-webkit-keyframes spin {
  to { transform: rotate(360deg); }
}
`;

const Spinner = styled.div`
  display: inline-block;
  width: 20px;
  height: 20px;
  border: 3px solid rgba(255, 255, 255, 0.3);
  border-radius: 50%;
  border-top-color: #fff;
  animation: ${spin} 1s ease-in-out infinite;
  -webkit-animation: spin 1s ease-in-out infinite;
`;

export default LittleSpinner;
