import React from "react";
import styled from "styled-components";

const Left = () => {
  return (
    <Svg
      xmlns="http://www.w3.org/2000/svg"
      width="24.969"
      height="39.592"
      viewBox="0 0 24.969 39.592"
    >
      <g id="left-arrow_1_" data-name="left-arrow (1)" transform="translate(0)">
        <path
          id="Tracé_190"
          data-name="Tracé 190"
          d="M30.127,25.418q-.922-1.035-1.839-2.077l-3.157-3.6c3.4-2.3,6.038-5.506,8.578-8.606.634-.781,1.352-1.743,2.043-2.671s1.392-1.879,2.009-2.637a.566.566,0,0,0-.079-.8L31.944.413A1.545,1.545,0,0,0,31.021,0,1.505,1.505,0,0,0,29.89.6L25.12,5.692c-.373.4-1.222,1.307-2.3,2.478-3.254,3.514-8.72,9.416-9.693,10.3a.566.566,0,0,0-.158.656c.5,1.075,3.4,4.334,7.169,8.488,1.624,1.771,3.016,3.3,3.627,4.068a23.54,23.54,0,0,0,1.607,1.731L31,39.077a1.579,1.579,0,0,0,.854.515h.17a1.7,1.7,0,0,0,1.205-.707l4.47-5.211a.566.566,0,0,0-.051-.787,102.028,102.028,0,0,1-7.52-7.469Zm2.263,12.748c-.243.283-.334.311-.34.311a1.132,1.132,0,0,1-.226-.187l-5.63-5.658a22.188,22.188,0,0,1-1.533-1.647c-.634-.787-2.031-2.326-3.638-4.114-2.507-2.767-5.851-6.468-6.79-7.865.685-.679,2.014-2.082,3.542-3.712a6.36,6.36,0,0,0,1.839.837,6.021,6.021,0,0,0,2.461.187,22.578,22.578,0,0,0-2.32-.747c-.605-.164-1.205-.334-1.839-.464,1.29-1.375,2.7-2.891,3.961-4.283a11.736,11.736,0,0,1,2.546,1.019,6.224,6.224,0,0,0,1.466.5,2.371,2.371,0,0,0,1.545-.175A3.723,3.723,0,0,1,26,11.917c-.453-.164-.894-.373-1.341-.566a8.544,8.544,0,0,0-1.437-.566,3.152,3.152,0,0,0-1.205-.074l1.652-1.782.17-.187c.605.045,1.194.04,1.782.04a16.822,16.822,0,0,0,2.3-.147,5.562,5.562,0,0,0-2.3-.419,6.79,6.79,0,0,0-1.471.192l1.816-1.958.328-.345a5.6,5.6,0,0,0,.939.243,10.021,10.021,0,0,0,1.29.153,12.357,12.357,0,0,0,1.3,0,6.021,6.021,0,0,0,1.256-.232,14.27,14.27,0,0,0-2.563-.334c-.7-.034-1.4,0-2.1,0L30.7,1.369a1.041,1.041,0,0,1,.294-.238,1.228,1.228,0,0,1,.243.164l5.291,4.261c-.566.685-1.132,1.471-1.7,2.235-.685.917-1.386,1.867-2.009,2.626-2.609,3.2-5.313,6.5-8.85,8.725a.571.571,0,0,0-.119.854l3.587,4.091c.611.7,1.228,1.4,1.85,2.088a101.458,101.458,0,0,0,7.186,7.209Z"
          transform="translate(-12.92 0)"
        />
        <path
          id="Tracé_191"
          data-name="Tracé 191"
          d="M30.719,24.246a19.652,19.652,0,0,0-2.6-.86,20.483,20.483,0,0,0-2.682-.566,8.849,8.849,0,0,0,5.279,1.426Z"
          transform="translate(-18.356 -9.907)"
        />
      </g>
    </Svg>
  );
};

const Svg = styled.svg`
  transition: transform 0.2s ease-in-out;
  &:hover {
    transform: scale(1.2);
  }
`;

export default Left;
