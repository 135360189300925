import React, { useState } from "react";
import styled from "styled-components";
import { firstLetterUpper } from "../controllers/marketPlaceControlers";
import PaidButton from "./PaidButton";
import Mastercard from "./picto/Mastercard";
import Visa from "./picto/Visa";
import { cutString } from "../profil/controllers/shortTitleCtrl";
import { useSelector } from "react-redux";
import { volumePrice } from "../productSheet/controller/cartController";
import {
  useProductsState,
  useTvaState,
} from "../../../views/marketplace/categoryPage/controllers/stateCtrl";
import { Link } from "react-router-dom";

const Calc = ({ cart }) => {
  const [cgu, setCgu] = useState(false);
  const products = useProductsState(useSelector);
  const tva = useTvaState(useSelector);

  const title = (productId) => {
    if (products && products.length > 0) {
      const product = products.find((p) => p._id === productId);
      if (product) {
        return product.frenchTitle;
      }
    }
  };

  const displayPriceTTC = (cart) => {
    if (cart && cart.length > 0) {
      const price = cart
        .reduce((acc, obj) => acc + Number(volumePrice(obj, tva)?.price), 0)
        .toFixed(2);
      return price * 100;
    } else {
      return null;
    }
  };

  //title component if ChapterItem or VolumeItem
  const Test = ({ item }) => {
    return (
      <H4>
        {firstLetterUpper(
          cutString(
            title(item.productId) +
              ` ${
                item.volume !== 0 ? ` - vol. ${item.volume} ` : " - One-shot "
              }` +
              (item.chapterNumber ? ` / chap. ${item.chapterNumber}` : ""),
            60
          )
        )}
      </H4>
    );
  };

  return (
    <Wrapper>
      <H3 className="montserratSemiBold">Total :</H3>
      <Line />
      {
        <>
          {cart &&
            cart.length > 0 &&
            cart.map((item) => (
              <Books key={item._id} className="montserrat">
                <Test item={item} />
                <Price className="montserratSemiBold">
                  {volumePrice(item, tva)?.price.toString().replace(".", ",")} €
                </Price>
              </Books>
            ))}
          <Total>
            <Books className="montserratSemiBold total">
              <Title className="bold">Total TTC :</Title>
              <Price className=" bold">
                {cart && cart.length > 0 && tva
                  ? cart
                      .reduce(
                        (acc, obj) =>
                          acc + Number(volumePrice(obj, tva)?.price),
                        0
                      )
                      .toFixed(2)
                  : `0 `}
                €
              </Price>
            </Books>
            <Books className="montserrat">
              <Title>Dont taxes :</Title>
              <Price className="montserratSemiBold">
                {cart && cart.length > 0 && tva
                  ? cart
                      .reduce(
                        (acc, obj) => acc + Number(volumePrice(obj, tva)?.taxe),
                        0
                      )
                      .toFixed(2)
                  : `0 `}
                €
              </Price>
            </Books>
          </Total>
          <Checkbox>
            <label htmlFor="cgu">
              Acceptez-vous les{" "}
              <Link to="/cgu" target="_blank" rel="noopener noreferrer">
                <Strong>CGV </Strong>
              </Link>
              ?{" "}
            </label>
            <Cont>
              <input
                type="checkbox"
                id="cgu"
                checked={cgu}
                onChange={() => setCgu(!cgu)}
              />
              <label htmlFor="cgu" bckcolor={cgu.toString()}></label>
            </Cont>
          </Checkbox>
          {cart && cart.length > 0 && tva && (
            <PaidButton cart={cart} cgu={cgu} price={displayPriceTTC(cart)} />
          )}
          <Picto>
            <Visa />
            <Mastercard />
          </Picto>
        </>
      }
    </Wrapper>
  );
};

const Wrapper = styled.aside`
  display: flex;
  flex-direction: column;
  box-sizing: border-box;
  min-width: 390px;
  padding: 20px 0 10px 0;
  border: solid ${(props) => props.theme.primary.offBlack} 2px;
  border-radius: 10px;
  @media (max-width: 1000px) {
    align-self: center;
    margin-bottom: 30px;
  }
  @media (max-width: 450px) {
    width: 100%;
    min-width: 300px;
  }
`;

const Strong = styled.strong`
  color: ${(props) => props.theme.secondary.color};
`;

const H3 = styled.h3`
  font-size: 15px;
  text-transform: uppercase;
  text-align: center;
`;

const Line = styled.div`
  width: 100%;
  height: 1px;
  background-color: ${(props) => props.theme.primary.offBlack};
  margin: 20px 0;
`;

const Books = styled.article`
  box-sizing: border-box;
  width: 100%;
  padding: 5px 20px 5px 20px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  &.total {
    padding-top: 20px;
  }
  @media (max-width: 450px) {
    align-items: flex-end;
  }
`;

const Title = styled.p`
  color: ${(props) => props.theme.primary.offBlack};
  font-size: 15px;
  line-height: 18px;
  margin-right: 15px;
  &.bold {
    color: ${(props) => props.theme.primary.black};
    font-size: 15px;
  }
`;

const H4 = styled.h4`
  color: ${(props) => props.theme.primary.offBlack};
  font-size: 15px;
  line-height: 18px;
  word-break: break-word;
  width: 260px;
  @media (max-width: 450px) {
    width: 165px;
  }
`;

const Price = styled.p`
  display: flex;
  justify-content: flex-end;
  min-width: 70px;
  color: ${(props) => props.theme.primary.offBlack};
  font-size: 13px;
  line-height: 18px;
  &.bold {
    color: ${(props) => props.theme.primary.black};
    font-size: 15px;
  }
`;

const Total = styled.div``;

const Picto = styled.div`
  margin-top: 10px;
  width: 90px;
  align-self: center;
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

const Checkbox = styled.div`
  box-sizing: border-box;
  display: flex;
  width: 100%;
  justify-content: space-between;
  align-items: center;
  font-size: 12px;
  font-weight: 500;
  letter-spacing: 0.5px;
  color: ${(props) => props.theme.primary.black};
  padding: 15px 20px 0 20px;
`;

const Cont = styled.div`
  & input {
    display: none;
    & + label {
      display: block;
      position: relative;
      width: 3em;
      height: 1.6em;

      border-radius: 1em;
      background: #f3f3f3;
      box-shadow: inset 0px 0px 3px 1px rgba(0, 0, 0, 0.3);
      cursor: pointer;
      select: none;
      -webkit-user-select: none;
      -moz-user-select: none;
      -ms-user-select: none;
      -webkit-transition: background 0.1s ease-in-out;
      transition: background 0.1s ease-in-out;
      &:before {
        content: "";
        display: block;
        width: 1.2em;
        height: 1.2em;
        border-radius: 1em;
        background: #fff;
        box-shadow: 2px 0px 5px rgba(0, 0, 0, 0.2);
        position: absolute;
        left: 0.2em;
        top: 0.2em;
        -webkit-transition: all 0.2s ease-in-out;
        transition: all 0.2s ease-in-out;
      }
    }
    &:checked {
      & + label {
        background: ${(props) =>
          props.bckcolor === "false" ? "#fff" : props.theme.secondary.color};
        transition: all 0.2s ease-in-out;
        &:before {
          box-shadow: -2px 0px 5px rgba(0, 0, 0, 0.2);
          left: 1.6em;
        }
      }
    }
  }
`;

export default Calc;
