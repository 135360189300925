import { actions } from "../actions/index";
import {
  arrayOfNotified,
  localstorageCtrl,
} from "../localStorage/localStorageCtrl";

const { FETCH_NOTIFICATIONS, REMOVE_NOTIFICATION } = actions;

const initialState = {
  notifications: [],
};

export function allNotifications(state = initialState, action) {
  switch (action.type) {
    case FETCH_NOTIFICATIONS:
      const notifiedArray = arrayOfNotified(
        action.payload.userId,
        action.payload.notifications
      );
      if (notifiedArray && notifiedArray.length > 0) {
        return {
          ...state,
          notifications: action.payload.notifications.filter((n) => {
            return notifiedArray.every((id) => {
              return n._id !== id;
            });
          }),
        };
      } else {
        return {
          ...state,
          notifications: action.payload.notifications,
        };
      }
    case REMOVE_NOTIFICATION:
      localstorageCtrl("isNotified", action.payload.id, action.payload.userId);
      return {
        ...state,
        notifications: [
          ...state.notifications.filter((n) => n._id !== action.payload.id),
        ],
      };
    default:
      return state;
  }
}
