import React, { useEffect, useState } from "react";
import { useParams, useNavigate } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import { categoryObject } from "../../../lib/adminControler/categoryObject";
import styled from "styled-components";
import ValidateButton from "../../../components/admin/button/ValidateButton";
import Text from "../../../components/admin/input/Text";
import Radio from "../../../components/admin/input/Radio";
import { postCategory, updateCategory } from "../../../lib/state/actions";
import { fetchMessage } from "../../../lib/state/actions";
import Form from "./Form";
import InputFile from "../../../components/admin/input/InputFile";
import { useCategoryState } from "../../marketplace/categoryPage/controllers/stateCtrl";
import { REACT_APP_API_HOST } from "../../../service";

const CategoryUpdate = ({ title }) => {
  const { myId } = useParams();

  const category = useCategoryState(useSelector);

  const [inputValues, setInputValues] = useState({});

  useEffect(() => {
    if (myId && category && category.length > 0) {
      setInputValues(category.find((categorie) => categorie._id === myId));
    } else {
      setInputValues(categoryObject);
    }
  }, [myId, category]);

  const navigate = useNavigate();

  function handleClose() {
    navigate("/admin/category");
  }

  const onChangeProperty = (property, value) => {
    let stringOrNumber;
    property === "prices"
      ? (stringOrNumber = Number(value))
      : (stringOrNumber = value);
    setInputValues({
      ...inputValues,
      [property]: [...inputValues[property], stringOrNumber],
    });
  };

  const deleteFromList = (property, el) => {
    setInputValues({
      ...inputValues,
      [property]: [...inputValues[property].filter((item) => item !== el)],
    });
  };

  const dispatch = useDispatch();
  const messageNotif = (message) => {
    dispatch(
      fetchMessage({
        message: message,
      })
    );
  };

  const validate = (e) => {
    e.preventDefault();
    let sameCatName =
      category &&
      category.length > 0 &&
      category.find((categorie) => categorie.name === inputValues.name);
    let sameCatExcept =
      category &&
      category.length > 0 &&
      category.filter((categorie) => categorie._id !== inputValues._id);
    let sameCatNameExcept =
      sameCatExcept &&
      sameCatExcept.length > 0 &&
      sameCatExcept.find((categorie) => categorie.name === inputValues.name);

    const myFormData = new FormData();
    myFormData.append("category", JSON.stringify(inputValues));

    Array.from(e.target.elements).forEach((input) => {
      let name = input.name;
      let value = input.value;
      if (name === "category" && value) {
        let file = input.files[0];
        myFormData.append("category", file);
      }
    });
    if (inputValues.name !== "" && inputValues.name !== "nom de la catégorie") {
      if (title === "modifier une catégorie" && sameCatNameExcept) {
        messageNotif("Il existe déjà une catégorie avec ce nom !");
      }
      if (title === "créer une nouvelle catégorie" && sameCatName) {
        messageNotif(`La catégorie "${inputValues.name}" existe déjà !!!`);
      }
      if (title === "créer une nouvelle catégorie" && !sameCatName) {
        dispatch(postCategory(myFormData, true, navigate));
      }
      if (title === "modifier une catégorie" && !sameCatNameExcept) {
        dispatch(updateCategory(myFormData, inputValues._id, true, navigate));
      }
    }
  };

  return (
    <Wrapper>
      <H2>{title}</H2>
      <Span onClick={handleClose}>page précédente</Span>
      {category && inputValues && (
        <FormEl onSubmit={(e) => validate(e)}>
          <Text
            title="Nom"
            type="text"
            value={inputValues?.name ?? ""}
            onchange={(e) =>
              title !== "modifier une catégorie" &&
              setInputValues({ ...inputValues, name: e.target.value })
            }
          />
          <Radio
            currentValue={inputValues?.active}
            legend="Catégorie active "
            name="active"
            onchange={() =>
              setInputValues({ ...inputValues, active: !inputValues.active })
            }
          />
          <Form
            inputValues={inputValues}
            deleteElement={deleteFromList}
            onchange={onChangeProperty}
          />
          <InputFile
            name="category"
            src={REACT_APP_API_HOST + inputValues?.imageURL}
            alt="category"
          />
          <ValidateButton message={title} pad="1.5em" />
        </FormEl>
      )}
    </Wrapper>
  );
};

const Wrapper = styled.div`  
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  box-sizing: border-box;
  background-color: lightblue;
  min-height: calc(100dvh - 100px);
  z-index: 100;
  padding: 2em;
  }
`;

const H2 = styled.h2`
  margin: 20px;
  text-transform: uppercase;
  font-size: 16px;
  font-weight: bold;
`;

const Span = styled.span`
  position: absolute;
  padding: 7px;
  right: 0px;
  top: 100px;
  text-transform: uppercase;
  font-weight: bold;
  font-size: 12px;
  cursor: pointer;
`;

const FormEl = styled.form`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
`;

export default CategoryUpdate;
