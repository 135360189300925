import React, { useState, useEffect } from "react";
import styled from "styled-components";
import Text from "../../../components/admin/input/Text";
import ValidateButton from "../../../components/admin/button/ValidateButton";
import { notifObject } from "../../../lib/adminControler/notifObject";
import { useParams, useNavigate } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import {
  fetchMessage,
  postNotif,
  updateNotif,
} from "../../../lib/state/actions";
import Textarea from "../../../components/admin/input/TextArea";
import InputFile from "../../../components/admin/input/InputFile";
import { useNotifState } from "../../marketplace/categoryPage/controllers/stateCtrl";
import { REACT_APP_API_HOST } from "../../../service";

const NotifUpdate = ({ title }) => {
  const { notifId } = useParams();
  const notifications = useNotifState(useSelector);

  const [inputValues, setInputValues] = useState(notifObject);

  useEffect(() => {
    if (notifications && notifications.length > 0 && notifId) {
      const notif = notifications.find((notif) => notif._id === notifId);
      setInputValues(notif);
    }
  }, [notifications, notifId]);

  const navigate = useNavigate();

  function handleClose() {
    navigate("/admin/notifications");
  }
  const dispatch = useDispatch();

  const validate = (e) => {
    e.preventDefault();
    if (inputValues.message === "" || inputValues.title === "") {
      dispatch(
        fetchMessage({
          message: "Il manque le message ou le titre !",
        })
      );
    } else {
      const myFormData = new FormData();
      myFormData.append("notification", JSON.stringify(inputValues));

      Array.from(e.target.elements).forEach((input) => {
        let name = input.name;
        let value = input.value;
        if (name === "notifImage" && value) {
          let file = input.files[0];
          myFormData.append("notifImage", file);
        }
      });

      if (title === "créer une nouvelle notification") {
        dispatch(postNotif(myFormData, true, navigate));
      }
      if (title === "modifier une notification") {
        dispatch(updateNotif(myFormData, inputValues._id, true, navigate));
      }
    }
  };

  return (
    <Wrapper>
      <H2>{title} :</H2>
      <Span onClick={handleClose}>page précédente</Span>
      {inputValues && (
        <FormEl
          action="/api/notifications"
          enctype="multipart/form-data"
          onSubmit={(e) => validate(e)}
        >
          <Text
            title="date"
            type="date"
            value={inputValues?.date ?? ""}
            onchange={(e) =>
              setInputValues({ ...inputValues, date: e.target.value })
            }
          />
          <Text
            title="title"
            type="text"
            value={inputValues?.title ?? ""}
            onchange={(e) =>
              setInputValues({ ...inputValues, title: e.target.value })
            }
          />
          <Textarea
            name="message"
            title="message"
            currentValue={inputValues?.message}
            onchange={(e) =>
              setInputValues({ ...inputValues, message: e.target.value })
            }
          />
          <Text
            title="lien"
            type="text"
            value={inputValues?.link ?? ""}
            onchange={(e) =>
              setInputValues({ ...inputValues, link: e.target.value })
            }
          />
          <InputFile
            name="notifImage"
            src={REACT_APP_API_HOST + inputValues.imageURL}
            alt="notification"
          />
          <ValidateButton message={title} pad="1.5em" />
        </FormEl>
      )}
    </Wrapper>
  );
};

const Wrapper = styled.div`  
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  box-sizing: border-box;
  background-color: lightblue;
  min-height: calc(100dvh - 100px);
  z-index: 100;
  padding: 2em;
  }
`;

const H2 = styled.h2`
  margin: 20px;
  text-transform: uppercase;
  font-size: 16px;
  font-weight: bold;
`;

const Span = styled.span`
  position: absolute;
  padding: 7px;
  right: 0px;
  top: 100px;
  text-transform: uppercase;
  font-weight: bold;
  font-size: 12px;
  cursor: pointer;
`;

const FormEl = styled.form`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
`;

export default NotifUpdate;
