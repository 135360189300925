import React, { useEffect } from "react";
import styled from "styled-components";
import {
  fetchMessage,
  resetPassword,
  userLogin,
  userSignup,
} from "../../../lib/state/actions";
import { useDispatch } from "react-redux";
import {
  pseudoVerify,
  emailVerify,
  passwordVerify,
} from "../controllers/connectFormControler";
import {
  forgotPassword,
  fetchUserDisconnected,
} from "../../../lib/state/actions";

const ConnectButton = ({ toggleConnectForm, message, form, token }) => {
  const dispatch = useDispatch();

  const messageNotif = (message) => {
    dispatch(
      fetchMessage({
        message: message,
      })
    );
  };

  const body = (log) => {
    switch (log) {
      case "login":
        if (form.resetPassword) {
          return JSON.stringify({ email: form.email });
        }
        return JSON.stringify({
          ...form,
        });

      case "signup":
        return JSON.stringify({
          ...form,
        });

      case "forgetpass":
        return JSON.stringify({
          email: form.email,
          newPassword: form.password,
          token: token,
        });
      default:
        return null;
    }
  };

  const validate = (body) => {
    if (form.logOrSign === "login") {
      if (!form.email || (!form.password && !form.resetPassword)) {
        messageNotif("Certains champs sont manquants !");
      }
      emailVerify(form.email, messageNotif);
      passwordVerify(form.password, messageNotif);
      if (
        emailVerify(form.email, messageNotif) &&
        passwordVerify(form.password, messageNotif)
      ) {
        toggleConnectForm();
        dispatch(userLogin(body));
      }
      if (
        emailVerify(form.email, messageNotif) &&
        !form.password &&
        form.resetPassword
      ) {
        toggleConnectForm();
        dispatch(forgotPassword(body));
        localStorage.removeItem("token");
        dispatch(fetchUserDisconnected());
      }
    }
    if (form.logOrSign === "signup") {
      if (!form.email || !form.password || !form.pseudo || !form.passwordBis) {
        messageNotif("Certains champs sont manquants !");
      }
      pseudoVerify(form.pseudo, messageNotif);
      emailVerify(form.email, messageNotif);
      passwordVerify(form.password, messageNotif);
      if (form.password !== form.passwordBis) {
        messageNotif("Les mots de passe ne sont pas identiques !");
      }
      if (!form.cgu) {
        messageNotif("Vous devez acceptez les CGU !");
      }
      if (
        form.pseudo &&
        form.password === form.passwordBis &&
        emailVerify(form.email, messageNotif) &&
        passwordVerify(form.password, messageNotif) &&
        form.cgu
      ) {
        toggleConnectForm();
        dispatch(userSignup(body));
      }
    }
    if (form.logOrSign === "forgetpass") {
      if (!form.email || !form.password || !form.passwordBis) {
        messageNotif("Certains champs sont manquants !");
      }
      emailVerify(form.email, messageNotif);
      passwordVerify(form.password, messageNotif);
      if (form.password !== form.passwordBis) {
        messageNotif("Les mots de passe ne sont pas identiques !");
      }
      if (
        form.password === form.passwordBis &&
        emailVerify(form.email, messageNotif) &&
        passwordVerify(form.password, messageNotif)
      ) {
        toggleConnectForm();
        dispatch(resetPassword(body));
      }
    }
  };

  const handleKeyDown = (e) => {
    if (e.keyCode === 13) {
      validate(body(form.logOrSign));
    }
  };

  useEffect(() => {
    document.addEventListener("keydown", handleKeyDown);

    return () => {
      document.removeEventListener("keydown", handleKeyDown);
    };
  });

  return (
    <Wrapper type="button" onClick={() => validate(body(form.logOrSign))}>
      {message}
    </Wrapper>
  );
};

const Wrapper = styled.button`
  cursor: pointer;
  box-sizing: border-box;
  border-radius: 5px;
  background-color: ${(props) => props.theme.secondary.color};
  padding: 5px 20px;
  margin: 30px 0;
  color: white;
  font-weight: 400;
  font-size: 12px;
  letter-spacing: 0.5px;
  text-transform: uppercase;
  text-align: center;
  border: solid 1px ${(props) => props.theme.secondary.color};
  &:hover {
    color: ${(props) => props.theme.secondary.color};
    background-color: white;

    transition: all 0.3s ease-in-out;
  }
`;

export default ConnectButton;
