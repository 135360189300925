import React from "react";
import styled from "styled-components";

const Button = ({ validate, text }) => {
  return (
    <Wrapper className="montserratSemiBold" onClick={validate && validate}>
      {text}
    </Wrapper>
  );
};

const Wrapper = styled.div`
  box-sizing: border-box;
  width: 140px;
  cursor: pointer;
  border: solid ${(props) => props.theme.forAll.color} 1px;
  border-radius: 5px;
  padding: 5px 10px;
  text-align: center;
  color: ${(props) => props.theme.primary.black};
  font-size: 14px;
  transition: all 0.2s ease-in-out;
  background-color: white;
  &:hover {
    background-color: ${(props) => props.theme.forAll.color};
    color: white;
  }
`;

export default Button;
